import { ChangePasswordParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const changePassword = (builder: Builder) =>
  builder.mutation<void, ChangePasswordParams>({
    query: (body) => ({
      url: '/user/change-password',
      method: 'POST',
      body,
    }),
  });
