import { ReorderWidgetSet } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const reorderWidgetSet = (builder: Builder) =>
  builder.mutation<void, ReorderWidgetSet[]>({
    query: (body) => ({
      url: `/widget-set/reorder`,
      method: 'POST',
      body,
    }),
  });
