import {
  ContentSummaryDatum,
  ContentSummaryDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const contentSummaryDatum = (builder: Builder) =>
  builder.query<ContentSummaryDatum, ContentSummaryDatumParams>({
    query: (params) => ({ url: '/datum/content-summary-datum', params }),
    providesTags: ['ContentSummary'],
  });
