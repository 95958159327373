import { DeleteUserParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deleteUser = (builder: Builder) =>
  builder.mutation<void, DeleteUserParams>({
    query: ({ id }) => ({
      url: `/user/delete/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err, { id }) =>
      (!err && [{ type: 'Users', id }]) || [],
  });
