import { baseApi } from '../base/query/Api';
import { addWidgetSet } from './AddWidgetSet.api';
import { deleteWidgetSet } from './DeleteWidgetSet.api';
import { reorderWidgetSet } from './ReorderWidgetSet.api';
import { updateWidgetSet } from './UpdateWidgetSet.api';
import { widgetSet } from './WidgetSet.api';
import { widgetSets } from './WidgetSets.api';

export const widgetSetEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    widgetSets: widgetSets(builder),
    widgetSet: widgetSet(builder),
    addWidgetSet: addWidgetSet(builder),
    updateWidgetSet: updateWidgetSet(builder),
    deleteWidgetSet: deleteWidgetSet(builder),
    reorderWidgetSet: reorderWidgetSet(builder),
  }),
});

export const {
  useWidgetSetsQuery,
  useLazyWidgetSetQuery,
  useAddWidgetSetMutation,
  useUpdateWidgetSetMutation,
  useDeleteWidgetSetMutation,
  useReorderWidgetSetMutation,
} = widgetSetEndpoints;
