import { UndatedPage, UndatedPageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const undatedPage = (builder: Builder) =>
  builder.query<UndatedPage, UndatedPageParams>({
    query: (params) => ({
      url: '/page/undated',
      params,
    }),
    async onQueryStarted({ planner }, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        if (data.page) {
          await dispatch(
            pageEndpoints.util.upsertQueryData(
              'page',
              { page: data.page.id },
              data.page,
            ),
          );

          dispatch(
            pageEndpoints.util.updateQueryData(
              'pages',
              { planner },
              (draft) => {
                if (!draft.find((i) => i.id === data.page.id)) {
                  draft.push(data.page);
                }
              },
            ),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
