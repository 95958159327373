import { formatDistance as formatDistanceFn } from 'date-fns';
import { format as formatTimezoneFn } from 'date-fns-tz';
import { useCallback } from 'react';

import { FormatLocaleOptions, FormatOptions, Settings } from '../RegionContext';

export const useDatetimeFunctions = (
  settings: Settings,
  formatOptions: FormatOptions,
) => {
  const format = useCallback(
    (date: Date, formatStr: string) => {
      const { timeZone } = settings;

      return formatTimezoneFn(date, formatStr, {
        timeZone,
        ...formatOptions,
      });
    },
    [settings, formatOptions],
  );

  const formatDistance = useCallback(
    (date: Date, baseDate: Date) =>
      formatDistanceFn(date, baseDate, {
        locale: formatOptions.locale,
      }),
    [formatOptions],
  );

  const formatLocale = useCallback(
    (date: Date, options?: FormatLocaleOptions) => {
      const { language, timeZone, hourFormat } = settings;

      return date.toLocaleString(language, {
        timeZone,
        hour12: hourFormat === '12',
        ...options,
      });
    },
    [settings],
  );

  return { format, formatLocale, formatDistance };
};
