import { Builder } from '../base/query/Api';

export const deleteWidgetSet = (builder: Builder) =>
  builder.mutation<void, string>({
    query: (set) => ({
      url: `/widget-set/delete/${set}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['WidgetSets']) || [],
  });
