'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { animated, useTransition } from '@react-spring/web';
import React from 'react';

import Icon from '../../icons/Icon';
import { OverflowButtonProps } from './OverflowButton.types';

const OverflowButton: React.FC<OverflowButtonProps> = ({
  onClick,
  visible,
  overflowOpen,
  className,
}) => {
  const transitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions((style, open) => {
    if (!open) {
      return;
    }

    return (
      <animated.div
        style={style}
        className={classNames(styles.root, className)}
        onClick={onClick}
      >
        <div className={styles.container}>
          <Icon name={overflowOpen ? 'Up' : 'Menu'} />
        </div>
      </animated.div>
    );
  });
};

const styles = {
  root: classes(
    'z-30',
    'rounded-lg',
    'h-3.5',
    'w-12',
    'border-2',
    'border-skin-silent',
    'bg-skin-primary',
    'text-skin-accent',
    'grid',
    'place-content-center',
    'cursor-pointer',
    'absolute',
    'left-[50%]',
    '-translate-x-[50%]',
    '-bottom-2',
  ),
  container: classes('h-6', 'w-12', 'grid', 'place-content-center'),
};

export default React.memo(OverflowButton);
