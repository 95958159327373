import {
  AddStickerPackItemsParams,
  StickerPackItemsAdded,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addStickerPackItems = (builder: Builder) =>
  builder.mutation<StickerPackItemsAdded[], AddStickerPackItemsParams>({
    query: (body) => ({
      url: '/sticker-pack-item/add',
      method: 'POST',
      body,
    }),
  });
