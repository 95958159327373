import { DeleteFontPackParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deleteFontPack = (builder: Builder) =>
  builder.mutation<void, DeleteFontPackParams>({
    query: ({ id }) => ({
      url: `/font/delete-pack/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) =>
      (!err && ['FontPacks', 'WebFontPacks']) || [],
  });
