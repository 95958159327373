import { NotificationItem } from '@nowadays/ui/components';

export const somethingWentWrong = (translate: Translate): NotificationItem => {
  return {
    title: translate('ui.common.auth.notifications.somethingWentWrong.message'),
    color: 'red',
    description: translate(
      'ui.common.auth.notifications.somethingWentWrong.description',
    ),
  };
};
