import { FavoritePageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const favoritePage = (builder: Builder) =>
  builder.mutation<boolean, FavoritePageParams>({
    query: (params) => ({
      url: `/page/favorite`,
      method: 'POST',
      params,
    }),
    async onQueryStarted(
      { planner, page },
      { dispatch, queryFulfilled, getState },
    ) {
      try {
        const { data: favorite } = await queryFulfilled;

        const activePage = (getState() as RootState).page.activePage;

        dispatch(
          pageEndpoints.util.updateQueryData(
            'pages',
            { planner, period: activePage?.period },
            (draft) => {
              draft.forEach((i, index) => {
                if (i.id === page) {
                  draft[index].favorite = favorite;
                }
              });
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
