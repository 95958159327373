import { DeleteArticleParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deleteArticle = (builder: Builder) =>
  builder.mutation<void, DeleteArticleParams>({
    query: ({ id }) => ({
      url: `/article/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err, { id }) => [{ type: 'Articles', id }],
  });
