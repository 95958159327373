import {
  StickerPackItemUpdated,
  UpdateStickerPackItemParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const updateStickerPackItem = (builder: Builder) =>
  builder.mutation<StickerPackItemUpdated, UpdateStickerPackItemParams>({
    query: (body) => ({
      url: `/sticker-pack-item/update/`,
      method: 'PUT',
      body,
    }),
  });
