import { useTranslate } from '@nowadays/ui/i18n/client';

import { DropFirst } from './Notification.types';
import { useNotification } from './notification-context/NotificationContext';

export const useNotifications = <
  T extends Record<string, (...args: Any) => Any>,
>(
  notifications: T,
) => {
  const translate = useTranslate();
  const { addNotification } = useNotification();

  const callNotificationByKey = <K extends keyof T>(
    key: K,
    ...args: DropFirst<Parameters<T[K]>>
  ) => {
    const selectedNotification = notifications[key];

    addNotification(selectedNotification(translate, ...args));
  };

  return callNotificationByKey;
};
