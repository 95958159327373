export type ChartProps = React.ComponentPropsWithoutRef<'div'>;

export enum ChartColors {
  Green = '#10b981',
  Red = '#ef4444',
  Blue = '#0ea5e9',
  Orange = '#f97316',
  Purple = '#8b5cf6',
  Brown = '#ba7264',
  Pink = '#ec4899',
  Teal = '#438e96',
  Gold = '#f49d0c',
  Violet = '#843dff',
  LeafGreen = '#61a146',
  Coral = '#d6675d',
  SeaGreen = '#507c6a',
  Zinc = '#52525b',
}
