'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { useColorPicker } from '../context/ColorPickerContext';
import { PresetColorsProps } from './PresetColors.types';

const PRESET_COLORS = [
  ['#09090b', '#454547', '#828283', '#bebebe', '#fafafa'],
  ['#ef4444', '#f25039', '#f45c2d', '#f76722', '#f97316'],
  ['#f59e0b', '#d9aa0e', '#bdb511', '#a0c113', '#84cc16'],
  ['#22c55e', '#1fc270', '#1bbf82', '#18bb94', '#14b8a6'],
  ['#06b6d4', '#13a9dd', '#219ce5', '#2e8fee', '#3b82f6'],
  ['#6366f1', '#7462f3', '#865ef4', '#9759f6', '#a855f7'],
  ['#d946ef', '#e044cb', '#e743a7', '#ed4182', '#f43f5e'],
];

const PresetColors: React.FC<PresetColorsProps> = ({ className }) => {
  const { changeColor } = useColorPicker();

  return (
    <div className={classNames(styles.root, className)}>
      {PRESET_COLORS.map((i, key) => (
        <div key={key} className={styles.row}>
          {i.map((j) => (
            <div
              key={j}
              className={styles.bullet}
              style={{ background: j }}
              onClick={() => changeColor(j)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const styles = {
  root: classes('flex', 'gap-2.5'),
  row: classes('flex', 'gap-2.5', 'flex-col-reverse'),
  bullet: classes('h-5', 'w-5', 'rounded-sm', 'cursor-pointer'),
};

export default PresetColors;
