export const findDuplicateObjects = (arr) => {
  const countMap = new Map();
  const duplicateObjects = [];

  for (const obj of arr) {
    const serialized = JSON.stringify(obj);
    countMap.set(serialized, (countMap.get(serialized) || 0) + 1);
  }

  for (const [serialized, count] of countMap.entries()) {
    if (count > 1) {
      duplicateObjects.push(JSON.parse(serialized));
    }
  }

  return duplicateObjects;
};

export const arePropertiesDefined = (obj: Record<string, Any>): boolean => {
  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== undefined
    ) {
      return true;
    }
  }

  return false;
};

export const updateObjectWithPath = (obj: Any, path: string, value: Any) => {
  let currentObj = obj;
  const keys = path.split('.');

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    const nextKey = keys[i + 1];
    const nextKeyIsIndex = !isNaN(Number(nextKey));

    if (!currentObj[key]) {
      currentObj[key] = nextKeyIsIndex ? [] : {};
    }

    currentObj = currentObj[key];
  }

  const lastKey = keys[keys.length - 1];

  const lastKeyIndex = parseInt(lastKey);

  if (lastKey === '' && Array.isArray(value)) {
    return value;
  }

  if (!isNaN(lastKeyIndex)) {
    if (!Array.isArray(currentObj)) {
      currentObj = [];
    }
    if (value === null) {
      currentObj.splice(lastKeyIndex, 1);
    } else {
      if (!currentObj[lastKeyIndex]) {
        for (let j = currentObj.length; j <= lastKeyIndex; j++) {
          currentObj[j] = null;
        }
      }
      currentObj[lastKeyIndex] = value;
    }
  } else {
    if (typeof value === 'object' && value !== null) {
      currentObj[lastKey] = {
        ...currentObj[lastKey],
        ...value,
      };
    } else {
      currentObj[lastKey] = value;
    }
  }

  const removeNulls = (obj) => {
    if (Array.isArray(obj)) {
      return obj
        .filter((item) => item !== null)
        .map((item) => removeNulls(item));
    } else if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        obj[key] = removeNulls(obj[key]);
      }
      return obj;
    } else {
      return obj;
    }
  };

  return removeNulls(obj);
};

export const objectsEqual = (o1: object, o2: object) =>
  typeof o1 === 'object' && Object.keys(o1).length
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const arraysEqual = (a1: Any[], a2: Any[]) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const isSorted = (array: string[], desiredOrder: string[]): boolean => {
  for (let i = 0; i < array.length - 1; i++) {
    if (desiredOrder.indexOf(array[i]) > desiredOrder.indexOf(array[i + 1])) {
      return false;
    }
  }
  return true;
};
