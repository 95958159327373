'use client';

import {
  eachDayOfInterval,
  endOfMonth,
  getMonth,
  isSameMonth,
  isThisMonth as isThisMonthFn,
  startOfMonth,
} from 'date-fns';
import { useMemo } from 'react';

import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseMonthProps } from './CalendarBaseMonth.types';

const CalendarBaseMonth: React.FC<CalendarBaseMonthProps> = ({
  month,
  children,
}) => {
  const {
    currentMonth,
    currentPeriod,
    handleMonthChanged,
    handlePeriodChanged,
  } = useCalendarBase();

  const onChanged = (month: Date) => {
    handleMonthChanged(month);
    handlePeriodChanged('monthly');
  };

  const isSelected = useMemo(
    () =>
      currentPeriod === 'monthly' &&
      (month ? isSameMonth(currentMonth, month) : true),
    [month, currentPeriod, currentMonth],
  );

  const isLastMonth = useMemo(() => getMonth(month) === 11, [month]);

  const isThisMonth = useMemo(() => month && isThisMonthFn(month), [month]);

  const days = useMemo(
    () =>
      (currentMonth &&
        eachDayOfInterval({
          start: startOfMonth(currentMonth),
          end: endOfMonth(currentMonth),
        })) ||
      [],

    [currentMonth],
  );

  return children({
    month: month || currentMonth,
    days,
    onChanged,
    isSelected,
    isLastMonth,
    isThisMonth,
  });
};

export default CalendarBaseMonth;
