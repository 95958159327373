import { VerifyJwtParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const verifyJwt = (builder: Builder) =>
  builder.query<void, VerifyJwtParams>({
    query: (params) => ({
      url: '/auth/verify-jwt',
      params,
    }),
  });
