import { classes } from '@nowadays/ui/utils';

export const textHoverActiveStyles = {
  base: {
    text: classes(
      'hover:active:text-skin-primary',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
  primary: {
    text: classes(
      'hover:active:text-skin-primary',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
  inverted: {
    text: classes(
      'hover:active:text-skin-inverted',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
  muted: {
    text: classes(
      'hover:active:text-skin-muted',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
  silent: {
    text: classes(
      'hover:active:text-skin-silent',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
  accent: {
    text: classes(
      'hover:active:text-skin-accent',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
  green: {
    text: classes(
      'hover:active:text-skin-green',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
  red: {
    text: classes('hover:active:text-skin-red', 'hover:active:text-opacity-80'),
    flat: null,
    outlined: null,
    contained: null,
  },
  blue: {
    text: classes(
      'hover:active:text-skin-blue',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
  orange: {
    text: classes(
      'hover:active:text-skin-orange',
      'hover:active:text-opacity-80',
    ),
    flat: null,
    outlined: null,
    contained: null,
  },
};
