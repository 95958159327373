import {
  ExpenseSummaryDatum,
  ExpenseSummaryDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const expenseSummaryDatum = (builder: Builder) =>
  builder.query<ExpenseSummaryDatum, ExpenseSummaryDatumParams>({
    query: (params) => ({ url: '/datum/expense-summary-datum', params }),
    providesTags: ['ExpenseSummary'],
  });
