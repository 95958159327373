import { AddPlanner, Planner } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { plannerEndpoints } from './Planner.endpoints';

export const addPlanner = (builder: Builder) =>
  builder.mutation<Planner, AddPlanner>({
    query: (body) => ({
      url: '/planner/add',
      method: 'POST',
      body,
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const { data: addedPlanner } = await queryFulfilled;

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'planners',
            undefined,
            (draft) => {
              draft.unshift(addedPlanner);
            },
          ),
        );

        dispatch(
          plannerEndpoints.util.upsertQueryData(
            'planner',
            { id: addedPlanner.id },
            addedPlanner,
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
