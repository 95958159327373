import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { useCallback } from 'react';

import { Button } from '../../../../../button';
import { Form, useFormHook } from '../../../../../form';
import { Popover } from '../../../../../popover';
import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';
import { RichTextLinkForm } from './RichTextLink.types';

const RichTextLink = () => {
  const { editor } = useRichText();
  const translate = useTranslate();
  const { hook, getValues, setValue } = useFormHook<RichTextLinkForm>();

  const handleOpen = () => {
    const previousLink = editor.getAttributes('link').href;

    if (previousLink) {
      setValue('link', previousLink);
    }
  };

  const handleConfirm = useCallback(() => {
    const { link } = getValues();

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: link })
      .run();
  }, [editor, getValues]);

  const handleUnset = () => editor.chain().focus().unsetLink().run();

  if (!editor) {
    return null;
  }

  const hasLink = editor.isActive('link');

  return (
    <Popover
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          handleConfirm();
        }
      }}
    >
      <Popover.Button
        as={RichTextToolbarButton}
        name='Link'
        color={hasLink ? 'accent' : 'primary'}
        onClick={handleOpen}
      />

      <Popover.Panel
        className={styles.panel}
        floating={{ placement: 'bottom-start' }}
      >
        {({ close }) => (
          <Form<RichTextLinkForm> hook={hook}>
            <Form.Panel>
              <Form.Text
                field='link'
                autoComplete='off'
                label={translate('ui.components.inputs.richText.link.title')}
              />
            </Form.Panel>

            <Form.Footer
              confirm={{
                type: 'button',
                kind: 'add',
                onClick: handleConfirm,
              }}
              cancel={{
                onClick: close,
              }}
              className={styles.footer}
            >
              {hasLink && (
                <Button color='red' onClick={handleUnset} type='button'>
                  {translate('ui.components.inputs.richText.link.remove')}
                </Button>
              )}
            </Form.Footer>
          </Form>
        )}
      </Popover.Panel>
    </Popover>
  );
};

const styles = {
  panel: classes('w-64'),
  footer: classes('flex', 'flex-row-reverse', 'justify-between'),
};

export default RichTextLink;
