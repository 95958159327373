'use client';

import { animated, useTransition } from '@react-spring/web';
import React from 'react';

import { FloatingContainerProps } from './FloatingContainer.types';

const FloatingContainer: React.FC<FloatingContainerProps> = (
  { open, style, className, immediate, children, ...props },
  ref: React.RefObject<HTMLDivElement>,
) => {
  const transitions = useTransition(open, {
    immediate,
    from: { opacity: 0, scale: 0.95 },
    enter: { opacity: 1, scale: 1 },
    leave: {
      opacity: 0,
      scale: 0.95,
    },
    config: {
      mass: 0.1,
      tension: 300,
      friction: 10,
    },
  });

  return transitions((trans, open) => {
    if (!open) {
      return;
    }

    return (
      <animated.div
        ref={ref}
        style={{ ...style, ...trans }}
        className={className}
        {...props}
      >
        {children}
      </animated.div>
    );
  });
};

export default React.memo(React.forwardRef(FloatingContainer));
