import { Loader } from '@nowadays/ui/components';
import { classes, classNames } from '@nowadays/ui/utils';
import React, { Suspense } from 'react';

import Sidebar from '../sidebar/Sidebar';
import { LayoutProps } from './Layout.types';

const Layout: React.FC<LayoutProps> = ({ children, className }) => {
  return (
    <div className={styles.root}>
      <Sidebar />
      <div className={classNames(styles.outlet, className)}>
        <Suspense
          fallback={
            <div className={styles.loader}>
              <Loader />
            </div>
          }
        >
          {children}
        </Suspense>
      </div>
    </div>
  );
};

const styles = {
  root: classes('flex', 'h-full'),
  outlet: classes('p-3', 'w-full', 'max-h-screen', 'overflow-x-auto'),
  loader: classes('w-full', 'h-full', 'grid', 'place-content-center'),
};

export default React.memo(Layout);
