'use client';

import {
  autoUpdate as autoUpdater,
  flip as flipMiddleware,
  offset as offsetMiddleware,
  shift as shiftMiddleware,
  size as sizeMiddleware,
  useFloating,
} from '@floating-ui/react';
import { useEffect, useState } from 'react';
import React from 'react';

import { FloatingProps } from './Floating.types';
import FloatingContainer from './floating-container/FloatingContainer';
import FloatingWrapper from './floating-wrapper/FloatingWrapper';

const isServer = typeof window === 'undefined';

const Floating: React.FC<FloatingProps> = ({
  open = false,
  onClose,
  portal = true,
  immediate,
  className,
  style,
  children,
  reference,
  placement = 'bottom',
  strategy = 'absolute',
  shift = {},
  offset = 6,
  autoUpdate,
  dependencies,
  sameWidth,
}) => {
  const [floating, setFloating] = useState<HTMLDivElement>(null);

  const { update, floatingStyles } = useFloating({
    middleware: [
      flipMiddleware(),
      shiftMiddleware({ padding: 12, crossAxis: true, ...shift }),
      offsetMiddleware(offset),
      sizeMiddleware({
        apply: ({ rects, elements }) => {
          sameWidth &&
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
        },
      }),
    ],
    whileElementsMounted: autoUpdate && autoUpdater,
    strategy,
    placement,
    open,
    elements: {
      floating,
      reference,
    },
    onOpenChange: onClose,
  });

  useEffect(() => {
    if (dependencies) {
      update && update();
    }
  }, [dependencies]);

  if (isServer) {
    return null;
  }

  return (
    <FloatingWrapper portal={portal}>
      <FloatingContainer
        ref={setFloating}
        open={open}
        immediate={immediate}
        className={className}
        style={{
          ...floatingStyles,
          ...{ ...(style && style) },
        }}
      >
        {children}
      </FloatingContainer>
    </FloatingWrapper>
  );
};

export default React.memo(Floating);
