import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgTextStrokeThrough = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M7 12h10.667m-.889-3V8a4 4 0 0 0-4-4v0a4 4 0 0 0-4 4v0a4 4 0 0 0 4 4v0a4 4 0 0 1 4 4v0a4 4 0 0 1-4 4v0a4 4 0 0 1-4-4v-1'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTextStrokeThrough);
export default ForwardRef;
