'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { useImageSlider } from '../image-slider-context/ImageSliderContext';
import { ImageSliderPagingProps } from './ImageSliderPaging.types';

const ImageSliderPaging: React.FC<ImageSliderPagingProps> = ({
  className,
  children,
}) => {
  const { loading, images, active, changeActive } = useImageSlider();

  if (!loading && (!images || images.length === 0)) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, className)}>
        {typeof children === 'function'
          ? images.map((src, index) =>
              children({
                src,
                index,
                selected: active === index,
                handleSelect: changeActive,
              }),
            )
          : children}
      </div>
    </div>
  );
};

const styles = {
  root: classes(
    'pb-2',
    'md:pb-0',
    'md:pr-2',
    'overflow-auto',
    'flex',
    'max-w-full',
    'md:inline',
    'md:content-center',
    'md:overflow-x-hidden',
  ),
  container: classes('flex', 'flex-row', 'md:flex-col', 'min-w-fit', 'gap-1.5'),
};

export default ImageSliderPaging;
