import { baseApi } from '../base/query/Api';
import { addPlanner } from './AddPlanner.api';
import { archivedPlanners } from './ArchivedPlanners.api';
import { archivePlanner } from './ArchivePlanner.api';
import { deletedPlanners } from './DeletedPlanners.api';
import { deletePlanner } from './DeletePlanner.api';
import { permanentlyDeletePlanner } from './PermanentlyDeletePlanner.api';
import { permanentlyDeletePlanners } from './PermanentlyDeletePlanners.api';
import { planner } from './Planner.api';
import { planners } from './Planners.api';
import { restorePlanner } from './RestorePlanner.api';
import { unarchivePlanner } from './UnarchivePlanner.api';
import { updatePlanner } from './UpdatePlanner.api';

export const plannerEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    planners: planners(builder),
    planner: planner(builder),
    addPlanner: addPlanner(builder),
    updatePlanner: updatePlanner(builder),
    archivedPlanners: archivedPlanners(builder),
    archivePlanner: archivePlanner(builder),
    unarchivePlanner: unarchivePlanner(builder),
    deletedPlanners: deletedPlanners(builder),
    deletePlanner: deletePlanner(builder),
    permanentlyDeletePlanner: permanentlyDeletePlanner(builder),
    restorePlanner: restorePlanner(builder),
    permanentlyDeletePlanners: permanentlyDeletePlanners(builder),
  }),
});

export const {
  usePlannersQuery,
  usePlannerQuery,
  useLazyPlannerQuery,
  useAddPlannerMutation,
  useUpdatePlannerMutation,
  useArchivedPlannersQuery,
  useArchivePlannerMutation,
  useUnarchivePlannerMutation,
  useDeletedPlannersQuery,
  useDeletePlannerMutation,
  useRestorePlannerMutation,
  usePermanentlyDeletePlannerMutation,
  usePermanentlyDeletePlannersMutation,
} = plannerEndpoints;
