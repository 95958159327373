import { Stickers } from '@nowadays/base/types';
import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { StickerState } from './Sticker.slice.types';

const initialState: StickerState = {
  search: '',
  searchListed: '',
};

export const stickerSlice = createSlice({
  name: 'sticker',
  initialState,
  reducers: {
    changeSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    changeSearchListed: (state, { payload }: PayloadAction<string>) => {
      state.searchListed = payload;
    },
    changeListedSticker: (
      state,
      action: PayloadAction<Stickers | undefined>,
    ) => {
      state.listed = action.payload;
      state.searchListed = '';
    },
  },
});

export const stickerReducer = stickerSlice.reducer;

export const useStickerActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(stickerSlice.actions, dispatch),
    [dispatch],
  );
};
