import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgGlass = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M16.176 17.822a2.622 2.622 0 0 1-.694 1.56c-.387.402-.887.622-1.404.618H9.441c-.517.004-1.017-.216-1.404-.618a2.622 2.622 0 0 1-.694-1.56L6 4h11.54l-1.364 13.822Zm-9.61-8.899h10.41'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGlass);
export default ForwardRef;
