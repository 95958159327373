'use client';

import { Description as HeadlessDescription } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';

import { DialogDescriptionProps } from './DialogDescription.types';

const DialogDescription: React.FC<
  AtLeastOne<DialogDescriptionProps, 'description' | 'children'>
> = ({ description, children, className }) => {
  return (
    <HeadlessDescription className={classNames(styles.description, className)}>
      {children || description}
    </HeadlessDescription>
  );
};

const styles = {
  description: classes('p-1', 'text-sm', 'text-skin-muted'),
};

export default DialogDescription;
