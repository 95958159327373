import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgSad = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M7.846 15.461c.62-1.977 2.976-3.185 5.332-2.636a4.252 4.252 0 0 1 2.976 2.636M7.565 9.753c.127-.248.526-.688.987-.955.462-.267 1.043-.391 1.321-.378m4.254-.002c.278-.014.859.111 1.32.378.462.266.86.707.988.955'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSad);
export default ForwardRef;
