import { baseApi } from '../base/query/Api';
import { addStickerPack } from './AddStickerPack.api';
import { deleteStickerPack } from './DeleteStatusPack.api';
import { reorderStickerPack } from './ReorderStickerPack.api';
import { stickerPack } from './StickerPack.api';
import { stickerPacks } from './StickerPacks.api';
import { updateStickerPack } from './UpdateStickerPack.api';

export const stickerPackEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    stickerPacks: stickerPacks(builder),
    stickerPack: stickerPack(builder),
    addStickerPack: addStickerPack(builder),
    updateStickerPack: updateStickerPack(builder),
    deleteStickerPack: deleteStickerPack(builder),
    reorderStickerPack: reorderStickerPack(builder),
  }),
});

export const {
  useStickerPacksQuery,
  useLazyStickerPackQuery,
  useAddStickerPackMutation,
  useUpdateStickerPackMutation,
  useDeleteStickerPackMutation,
  useReorderStickerPackMutation,
} = stickerPackEndpoints;
