'use client';

import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { classNames } from '@nowadays/ui/utils';
import React from 'react';

import Input from '../input/Input';
import { RadioProps } from './Radio.types';
import RadioOption from './radio-option/RadioOption';

const RadioBase: React.FC<RadioProps> = (
  { input, children, disabled, onChange, className, ...props },
  ref: React.Ref<HTMLDivElement>,
) => {
  const { disable, ...inputProps } = input || {};

  return (
    <Input disable={disabled || disable} {...inputProps}>
      {({ errorStyles, disabledStyles, customStyles }) => (
        <HeadlessRadioGroup
          as='div'
          ref={ref}
          onChange={onChange}
          disabled={disabled}
          className={classNames(
            errorStyles,
            disabledStyles,
            customStyles,
            className,
          )}
          {...props}
        >
          {children}
        </HeadlessRadioGroup>
      )}
    </Input>
  );
};

const Radio = React.forwardRef(RadioBase);

export default Object.assign(Radio, {
  Option: RadioOption,
});
