import { DeletePresetPlanner } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { presetPlannerEndpoints } from './PresetPlanner.endpoints';

export const deletePresetPlanner = (builder: Builder) =>
  builder.mutation<void, DeletePresetPlanner>({
    query: ({ id }) => ({
      url: `/preset-planner/delete/${id}`,
      method: 'DELETE',
    }),
    async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled;

        dispatch(
          presetPlannerEndpoints.util.updateQueryData(
            'presetPlanners',
            undefined,
            (draft) => {
              draft.splice(
                draft.findIndex((i) => i.id === id),
                1,
              );
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
