'use client';

import { add, isSameMonth, isSameYear } from 'date-fns';
import { useCallback } from 'react';

import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseIteratorProps } from './CalendarBaseIterator.types';

const CalendarBaseIterator: React.FC<CalendarBaseIteratorProps> = ({
  className,
  children,
}) => {
  const {
    currentDay,
    currentWeek,
    currentMonth,
    currentYear,
    currentPeriod,
    handleDayChanged,
    handleWeekChanged,
    handleMonthChanged,
    handleYearChanged,
  } = useCalendarBase();

  const iterateDay = useCallback(
    (days: number) => {
      const iteration = add(currentDay, { days });
      handleDayChanged(iteration);

      if (!isSameMonth(iteration, currentMonth)) {
        const iteration = add(currentMonth, { months: days < 0 ? -1 : 1 });
        handleMonthChanged(iteration);
      }
    },
    [currentDay, currentMonth],
  );

  const iterateWeek = useCallback(
    (weeks: number) => {
      const iteration = add(currentWeek, { weeks });
      handleWeekChanged(iteration);

      if (!isSameMonth(iteration, currentMonth)) {
        const iteration = add(currentMonth, { months: weeks < 0 ? -1 : 1 });
        handleMonthChanged(iteration);
      }
    },
    [currentWeek, currentMonth],
  );

  const iterateMonth = useCallback(
    (months: number) => {
      const iteration = add(currentMonth, { months });
      handleMonthChanged(iteration);

      if (!isSameYear(iteration, currentYear)) {
        const iteration = add(currentYear, { years: months < 0 ? -1 : 1 });
        handleYearChanged(iteration);
      }
    },
    [currentMonth, currentYear],
  );

  const iterateYear = useCallback(
    (years: number) => {
      const iteration = add(currentYear, { years });
      handleYearChanged(iteration);

      if (!isSameYear(iteration, currentMonth)) {
        const iteration = add(currentMonth, { months: 12 * years });
        handleMonthChanged(iteration);
      }
    },
    [currentYear, currentMonth],
  );

  const previous = () => {
    switch (currentPeriod) {
      case 'daily':
        iterateDay(-1);
        break;
      case 'weekly':
        iterateWeek(-1);
        break;
      case 'monthly':
        iterateMonth(-1);
        break;
      case 'yearly':
        iterateYear(-1);
        break;

      default:
        break;
    }
  };

  const next = () => {
    switch (currentPeriod) {
      case 'daily':
        iterateDay(1);
        break;
      case 'weekly':
        iterateWeek(1);
        break;
      case 'monthly':
        iterateMonth(1);
        break;
      case 'yearly':
        iterateYear(1);
        break;

      default:
        break;
    }
  };

  const previousMonth = () => iterateMonth(-1);

  const nextMonth = () => iterateMonth(1);

  return (
    <div className={className}>
      {children({ previous, next, previousMonth, nextMonth })}
    </div>
  );
};

export default CalendarBaseIterator;
