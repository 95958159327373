'use client';

import './Chart.styles.css';

import { classes, classNames } from '@nowadays/ui/utils';
import React, { useRef, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { TooltipPosition } from '../chart-context/ChartContext';
import { ChartProvider } from '../chart-context/ChartProvider';
import CustomPieLabel from '../custom-pie-label/CustomPieLabel';
import CustomTooltip from '../custom-tooltip/CustomTooltip';
import { ChartColors, ChartProps } from './Chart.types';

const Chart: React.FC<ChartProps> = ({ children, className, onMouseMove }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({
    left: 0,
    top: 0,
  });

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const { clientX, clientY } = e;
    setTooltipPosition({ left: clientX, top: clientY });
    onMouseMove && onMouseMove(e);
  };

  return (
    <div
      ref={ref}
      onMouseMove={handleMouseMove}
      className={classNames(styles.root, className)}
    >
      <ChartProvider tooltipPosition={tooltipPosition}>
        {children}
      </ChartProvider>
    </div>
  );
};

const styles = {
  root: classes('w-full', 'h-full', 'relative'),
};

export default Object.assign(Chart, {
  Colors: ChartColors,
  ResponsiveContainer,
  BarChart,
  LineChart,
  PieChart,
  Tooltip,
  CustomTooltip,
  CustomPieLabel,
  CartesianGrid,
  LabelList,
  Legend,
  Bar,
  Pie,
  Line,
  Cell,
  XAxis,
  YAxis,
  ReferenceLine,
});
