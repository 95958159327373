'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { TValues } from '../Listbox.types';
import { ListboxSelectionProps } from './ListboxSelection.types';

const ListboxSelection = <T extends TValues>({
  selected,
  disabled,
  seperator,
  className,
  placeholder,
  ...props
}: ListboxSelectionProps<T>) => {
  const wrapperClasses = classNames(
    styles.root,
    disabled && styles.disabled,
    className,
  );

  if (selected && !Array.isArray(selected)) {
    return (
      <div className={wrapperClasses} {...props}>
        <span className={styles.input}>
          {selected.children || selected.key}
        </span>
      </div>
    );
  }

  if (selected && Array.isArray(selected) && selected.length) {
    return (
      <div className={wrapperClasses} {...props}>
        {selected.map((item, key) => (
          <span key={key} className={styles.input}>
            {item.children || item.key}
            {seperator && selected.length - 1 !== key && seperator}
          </span>
        ))}
      </div>
    );
  }

  if (placeholder) {
    return (
      <div className={wrapperClasses} {...props}>
        <input
          disabled
          placeholder={placeholder}
          className={classNames(styles.input, styles.placeholder)}
        />
      </div>
    );
  }

  return <div className={wrapperClasses} {...props} />;
};

const styles = {
  root: classes(
    'flex',
    'w-full',
    'h-full',
    'truncate',
    'cursor-pointer',
    'items-center',
  ),
  disabled: classes('cursor-default'),
  placeholder: classes(
    'cursor-pointer',
    'pointer-events-none',
    'placeholder-skin-primary',
  ),
  input: classes('truncate', 'bg-transparent'),
};

export default ListboxSelection;
