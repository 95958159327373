'use client';

import { parseISO } from 'date-fns';
import React from 'react';

import { CalendarPeriod } from '../../calendar';
import Calendar from '../../calendar/calendar/Calendar';
import DatePickerInput from './date-picker-input/DatePickerInput';
import DatePickerPanel from './date-picker-panel/DatePickerPanel';
import { DatePickerProps } from './DatePicker.types';

const DatePicker: React.FC<DatePickerProps> = (
  {
    date,
    disabledBefore,
    disabledAfter,
    onChange,
    className,
    children,
    ...props
  },
  ref: React.MutableRefObject<HTMLDivElement>,
) => {
  const parsed = typeof date === 'string' ? parseISO(date) : date;

  const handleChange = (date: Date, period: CalendarPeriod) =>
    period === 'daily' && onChange(date, 'daily');

  return (
    <Calendar
      date={parsed}
      period='daily'
      onChange={handleChange}
      disabledBefore={disabledBefore}
      disabledAfter={disabledAfter}
    >
      <div ref={ref} className={className} {...props}>
        {children}
      </div>
    </Calendar>
  );
};

export default Object.assign(React.forwardRef(DatePicker), {
  Input: DatePickerInput,
  Panel: DatePickerPanel,
});
