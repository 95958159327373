import { ErrorCode } from '@nowadays/base/types';
import { isServerError } from '@nowadays/ui/services';
import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';

import { appSlice } from '../slices/app/App.slice';

export const queryErrorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const { payload, meta } = action;
      const { changeNetworkError } = appSlice.actions;

      if (isServerError(payload)) {
        if (payload.data.error?.code === ErrorCode.IntervalServerError) {
          dispatch(changeNetworkError('INTERVAL_SERVER_ERROR'));
        }
      } else {
        if ((meta?.arg as Any)?.endpointName === 'authUser') {
          dispatch(changeNetworkError('ACCESS_ERROR'));
        } else {
          dispatch(changeNetworkError('REQUEST_ERROR'));
        }
      }
    }

    return next(action);
  };
