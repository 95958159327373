import { Builder } from '../base/query/Api';

export const deleteWidgetSetItem = (builder: Builder) =>
  builder.mutation<void, string>({
    query: (widget) => ({
      url: `/widget-set-item/delete/${widget}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) =>
      (!err && ['WidgetSetItems', 'WidgetSetItemsCodes']) || [],
  });
