'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { flexRender } from '@tanstack/react-table';

import { Sortable } from '../../../sortable';
import { TableCellProps } from './TableCell.types';

const TableCell: React.FC<TableCellProps> = ({
  cell,
  className,
  children,
  listeners,
}) => {
  if (cell.column.id === 'sortable') {
    return (
      <td key={cell.id} className={classNames(styles.root, className)}>
        <Sortable.Handle listeners={listeners} />
      </td>
    );
  }

  return (
    <td key={cell.id} className={classNames(styles.root, className)}>
      {children || flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

const styles = {
  root: classes('px-2', 'py-2'),
};

export default TableCell;
