'use client';

import { addYears } from 'date-fns';
import { useMemo } from 'react';

import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseYearsProps } from './CalendarBaseYears.types';

const CalendarBaseYears: React.FC<CalendarBaseYearsProps> = ({ children }) => {
  const { currentYear } = useCalendarBase();

  const years = useMemo(
    () => [addYears(currentYear, -1), currentYear, addYears(currentYear, 1)],
    [currentYear],
  );

  return children({ years });
};

export default CalendarBaseYears;
