'use client';

import MenuBase from '../menu-base/MenuBase';
import { MenuBaseProps } from '../menu-base/MenuBase.types';

const Menu: React.FC<MenuBaseProps> & {
  Button: typeof MenuBase.Button;
  Item: typeof MenuBase.Item;
  Items: typeof MenuBase.Items;
} = (props) => <MenuBase {...props} />;

Menu.Button = MenuBase.Button;
Menu.Item = MenuBase.Item;
Menu.Items = MenuBase.Items;

export default Menu;
