'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import { UploadIndicator } from '../../../indicator';
import ImagePicker from '../../../inputs/image-picker/ImagePicker';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormImagePickerProps } from './FormImagePicker.types';

const FormImagePicker: React.FC<FormImagePickerProps> = ({
  field,
  status,
  label,
  input,
  disabled,
  multiple,
  onDrop,
  container,
  ...props
}) => {
  const { control, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div key={field} className={classNames(styles.root, container?.className)}>
      {label && <FieldLabel label={label} htmlFor={field}></FieldLabel>}
      <Controller
        control={control}
        name={field}
        render={({ field: { value, onBlur, onChange } }) => (
          <div className={styles.container}>
            <ImagePicker
              id={field}
              key={field}
              value={value}
              multiple={multiple}
              disabled={isDisable}
              input={{ error: isError, disable: isDisable, ...input }}
              onBlur={onBlur}
              onClear={() => onChange(null)}
              onDrop={(files: File[], ...rest) => {
                onDrop && onDrop(files, ...rest);
                !multiple && onChange(files[0]);
              }}
              {...props}
            />
            <Controller
              control={control}
              name={status}
              render={({ field: { value } }) => (
                <UploadIndicator status={value} />
              )}
            />
          </div>
        )}
      />
      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
  container: classes(
    'flex',
    'flex-col',
    'gap-2',
    'items-center',
    'w-full',
    'h-min',
  ),
};

export default FormImagePicker;
