import { Planner, UsePresetPlannerParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { plannerEndpoints } from '../planner/Planner.endpoints';

export const usePresetPlanner = (builder: Builder) =>
  builder.mutation<Planner, UsePresetPlannerParams>({
    query: ({ id }) => ({
      url: `/preset-planner/use/${id}`,
      method: 'POST',
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'planners',
            undefined,
            (draft) => {
              draft.unshift(data);
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
