import { FallbackLanguage, LANGUAGES, Namespaces } from './Locales.types';
import { resources } from './resources';

export const resourceLoader = (namespaces: Namespaces[]) => {
  const loaded = {};

  LANGUAGES.forEach((language) => {
    const resource = resources[language];

    loaded[language] = {};

    namespaces.forEach((i) => {
      if (!loaded[language][i]) {
        loaded[language][i] = {};
      }

      loaded[language][i] = resource[i];
    });
  });

  return { resources: loaded, ns: Object.keys(loaded[FallbackLanguage]) };
};
