'use client';

import DialogBase from '../dialog-base/DialogBase';
import { DialogBaseProps } from '../dialog-base/DialogBase.types';

const Dialog: React.FC<DialogBaseProps> & {
  Panel: typeof DialogBase.Panel;
  Title: typeof DialogBase.Title;
  Description: typeof DialogBase.Description;
  Footer: typeof DialogBase.Footer;
  Backdrop: typeof DialogBase.Backdrop;
} = (props) => <DialogBase {...props} />;

Dialog.Panel = DialogBase.Panel;
Dialog.Title = DialogBase.Title;
Dialog.Description = DialogBase.Description;
Dialog.Footer = DialogBase.Footer;
Dialog.Backdrop = DialogBase.Backdrop;

export default Dialog;
