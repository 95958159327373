import { UpdateWidgetSetParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const updateWidgetSet = (builder: Builder) =>
  builder.mutation<void, UpdateWidgetSetParams>({
    query: ({ id, ...body }) => ({
      url: `/widget-set/update/${id}`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['WidgetSets']) || [],
  });
