export const base = {
  error: {
    array: {
      length: '{{label}} {{length}} eleman içeriyor olmalıdır',
      max: '{{label}} {{max}} veya daha az öğeye sahip olmalıdır',
      min: '{{label}} en az {{min}} öğeye sahip olmalıdır',
    },
    boolean: { isValue: '{{label}} {{value}} olmalıdır' },
    custom: {
      alreadyExist: '{{label}} zaten mevcut',
      alreadyUsing: "'{{value}}' zaten kullanılıyor",
      incorrect: '{{label}} hatalı, lütfen kontrol ediniz',
      notMatch: '{{label}} eşleşmiyor, lütfen kontrol ediniz',
      sameValue: '{{label}} mevcut değerden farklı olmalıdır',
    },
    date: {
      max: '{{label}} {{max}} tarihinden önce olmalıdır',
      min: '{{label}} {{min}} tarihinden sonra olmalıdır',
    },
    mixed: {
      default: '{{label}} geçerli değildir',
      defined: '{{label}} tanımlı olmalıdır',
      notOneOf: '{{label}} şu değerlerden biri olmamalıdır: {{values}}',
      notType: '{{label}} doğru tip değildir',
      oneOf: '{{label}} şu değerlerden biri olmalıdır: {{values}}',
      required: '{{label}} boş bırakılamaz',
    },
    number: {
      integer: '{{label}} bir tam sayı olmalıdır',
      lessThan: '{{label}} {{less}} değerinden az olmalıdır',
      max: '{{label}} {{max}} değerinden küçük veya eşit olmalıdır',
      min: '{{label}} {{min}} değerinden büyük veya eşit olmalıdır',
      moreThan: '{{label}} {{more}} değerinden büyük olmalıdır',
      negative: '{{label}} negatif bir sayı olmalıdır',
      positive: '{{label}} pozitif bir sayı olmalıdır',
    },
    object: {
      noUnknown:
        '{{label}} nesne biçiminde belirtilmemiş anahtarlara sahip olamaz',
    },
    string: {
      email: '{{label}} geçerli bir e-posta olmalıdır',
      length: '{{label}} {{length}} karakter uzunluğunda olmalıdır',
      lowercase: '{{label}} küçük harflerden oluşmalıdır',
      matches: "{{label}} şunlarla eşleşmelidir: '{{regex}}'",
      max: '{{label}} en fazla {{max}} karakter uzunluğunda olmalıdır',
      min: '{{label}} en az {{min}} karakter uzunluğunda olmalıdır',
      trim: '{{label}} için fazla boşluklar kaldırılmalıdır',
      uppercase: '{{label}} büyük harflerden oluşmalıdır',
      url: '{{label}} geçerli bir URL olmalıdır',
      uuid: '{{label}} geçerli bir UUID olmalıdır',
    },
  },
  language: {
    constant: {
      'en-US': 'English (US)',
      'es-ES': 'Español (ES)',
      'es-LA': 'Español (LA)',
      'fr-FR': 'Français',
      'ja-JP': '日本語',
      'tr-TR': 'Türkçe',
    },
    options: {
      'en-US': 'İngilizce (US)',
      'es-ES': 'İspanyolca (ES)',
      'es-LA': 'İspanyolca (LA)',
      'fr-FR': 'Fransızca',
      'ja-JP': 'Japonca',
      'tr-TR': 'Türkçe',
    },
  },
};
