'use client';

import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import { SortableProps } from './Sortable.types';
import SortableContext from './sortable-context/SortableContext';
import SortableHandle from './sortable-handle/SortableHandle';
import SortableItem from './sortable-item/SortableItem';
import SortableOverlay from './sortable-overlay/SortableOverlay';

const ACTIVATION = { activationConstraint: { distance: { x: 0, y: 0 } } };

const Sortable: React.FC<SortableProps> & {
  Item: typeof SortableItem;
  Context: typeof SortableContext;
  Overlay: typeof SortableOverlay;
  Handle: typeof SortableHandle;
} = ({ children, modifiers }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, ACTIVATION),
    useSensor(TouchSensor, ACTIVATION),
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={modifiers}
      collisionDetection={closestCenter}
    >
      {children}
    </DndContext>
  );
};

Sortable.Item = SortableItem;
Sortable.Context = SortableContext;
Sortable.Overlay = SortableOverlay;
Sortable.Handle = SortableHandle;

export default Sortable;
