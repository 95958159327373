'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import Adornment from '../adornment/Adornment';
import { AdornmentProps } from '../adornment/Adornment.types';
import { BoxProps } from './Box.types';

const hasChildren = (obj): obj is AdornmentProps =>
  (obj as AdornmentProps)?.children !== undefined;

const Box = <T extends React.ElementType = 'div'>(
  { as, className, children, start, end, ...props }: BoxProps<T>,
  ref: AsRef<T>,
) => {
  const Component = as || 'div';

  return (
    <Component
      ref={ref}
      className={classNames(styles.root, className)}
      {...props}
    >
      <Adornment
        position='start'
        {...(hasChildren(start) ? start : { children: start })}
      />

      {children}

      <Adornment
        position='end'
        {...(hasChildren(end) ? end : { children: end })}
      />
    </Component>
  );
};

const styles = {
  root: classes('relative', 'w-full'),
};

export default React.forwardRef(Box);
