'use client';

import { startOfMonth, startOfToday, startOfWeek } from 'date-fns';
import { useEffect, useState } from 'react';

import { useRegion } from '../../../region/RegionContext';
import { CalendarBaseContext } from './CalendarBaseContext';

export type CalendarPeriod = 'daily' | 'weekly' | 'monthly' | 'yearly';

export interface CalendarBaseProviderProps {
  date?: Date;
  disabledBefore?: boolean | Date;
  disabledAfter?: boolean | Date;
  period?: CalendarPeriod;
  onChange?: (date: Date, period: CalendarPeriod) => void;
  children: React.ReactNode;
}

export const CalendarBaseProvider: React.FC<CalendarBaseProviderProps> = ({
  date,
  disabledBefore,
  disabledAfter,
  period,
  onChange,
  children,
}) => {
  const { formatOptions } = useRegion();

  const current = date || startOfToday();

  const initialWeek = startOfWeek(current, formatOptions);
  const initialMonth = startOfMonth(current);
  const initialYear = startOfMonth(current);

  const [currentDay, setCurrentDay] = useState<Date>(date);
  const [currentWeek, setCurrentWeek] = useState<Date>(initialWeek);
  const [currentMonth, setCurrentMonth] = useState<Date>(initialMonth);
  const [currentYear, setCurrentYear] = useState<Date>(initialYear);
  const [currentPeriod, setCurrentPeriod] = useState<CalendarPeriod>(period);

  useEffect(() => {
    if (onChange && currentPeriod) {
      switch (currentPeriod) {
        case 'daily':
          onChange(currentDay, currentPeriod);
          break;
        case 'weekly':
          onChange(currentWeek, currentPeriod);
          break;
        case 'monthly':
          onChange(currentMonth, currentPeriod);
          break;
        case 'yearly':
          onChange(currentYear, currentPeriod);
          break;
        default:
          break;
      }
    }
  }, [currentDay, currentWeek, currentMonth, currentYear, currentPeriod]);

  const handleDayChanged = (day: Date) => setCurrentDay(day);

  const handleWeekChanged = (week: Date) => setCurrentWeek(week);

  const handleMonthChanged = (month: Date) => setCurrentMonth(month);

  const handleYearChanged = (year: Date) => setCurrentYear(year);

  const handlePeriodChanged = (period: CalendarPeriod) =>
    setCurrentPeriod(period);

  return (
    <CalendarBaseContext.Provider
      value={{
        date,
        disabledBefore,
        disabledAfter,
        currentDay,
        currentWeek,
        currentMonth,
        currentYear,
        currentPeriod,
        handleDayChanged,
        handleWeekChanged,
        handleMonthChanged,
        handleYearChanged,
        handlePeriodChanged,
      }}
    >
      {children}
    </CalendarBaseContext.Provider>
  );
};
