import { baseApi } from '../base/query/Api';
import { authUser } from './AuthUser.api';
import { login } from './Login.api';
import { loginAdmin } from './LoginAdmin.api';
import { loginJwt } from './LoginJwt.api';
import { logout } from './Logout.api';
import { signup } from './Signup.api';
import { verifyEmail } from './VerifyEmail.api';
import { verifyJwt } from './VerifyJwt.api';

export const authEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    authUser: authUser(builder),
    login: login(builder),
    loginJwt: loginJwt(builder),
    loginAdmin: loginAdmin(builder),
    signup: signup(builder),
    verifyJwt: verifyJwt(builder),
    verifyEmail: verifyEmail(builder),
    logout: logout(builder),
  }),
});

export const {
  useAuthUserQuery,
  useLoginMutation,
  useLoginAdminMutation,
  useLoginJwtQuery,
  useSignupMutation,
  useVerifyJwtQuery,
  useLazyVerifyJwtQuery,
  useVerifyEmailQuery,
  useLogoutMutation,
} = authEndpoints;
