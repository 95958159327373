import { DeleteLanguageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deleteLanguage = (builder: Builder) =>
  builder.mutation<void, DeleteLanguageParams>({
    query: ({ language }) => ({
      url: `/locale/languages/${language}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['Languages']) || [],
  });
