'use client';

import { Editor } from '@tiptap/react';
import { createContext, useContext } from 'react';

interface RichTextContextProps {
  editor: Editor;
}

export const RichTextContext = createContext<RichTextContextProps>({
  editor: null,
});

export const useRichText = () => {
  return useContext(RichTextContext);
};
