import { SetAsDefaultTemplateParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const setAsDefaultTemplate = (builder: Builder) =>
  builder.mutation<void, SetAsDefaultTemplateParams>({
    query: (params) => ({
      url: `/template/set`,
      method: 'POST',
      params,
    }),
    invalidatesTags: (res, err) => (!err && ['Templates']) || [],
  });
