import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgBackgroundColor = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M12.626 20.476c1.283-.203 2.344-1.264 4.465-3.385 2.121-2.121 3.182-3.182 3.385-4.465.033-.208.05-.417.05-.626H3.474a4 4 0 0 0 .05.626c.202 1.283 1.263 2.344 3.384 4.465s3.182 3.182 4.465 3.385c.415.066.837.066 1.252 0Z'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M3.475 12a4 4 0 0 1 .05-.626c.202-1.283 1.263-2.344 3.384-4.465s3.182-3.182 4.465-3.385a4 4 0 0 1 1.252 0c1.283.203 2.344 1.264 4.465 3.385 2.121 2.121 3.182 3.182 3.385 4.465.033.208.05.417.05.626M3.474 12a4 4 0 0 0 .05.626c.202 1.283 1.263 2.344 3.384 4.465s3.182 3.182 4.465 3.385c.415.066.837.066 1.252 0 1.283-.203 2.344-1.264 4.465-3.385 2.121-2.121 3.182-3.182 3.385-4.465.033-.208.05-.417.05-.626M3.474 12h17.05'
    />
    <path
      fill='currentColor'
      d='m21.5 15 1.538 3.692a1.667 1.667 0 1 1-3.077 0L21.5 15Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBackgroundColor);
export default ForwardRef;
