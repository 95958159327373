import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgSendBackward = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='m7.714 12.5-3.986-2.093a1 1 0 0 1 .017-1.78l7.36-3.68a2 2 0 0 1 1.79 0l7.36 3.68a1 1 0 0 1 .017 1.78L16.286 12.5M12 10.5v8m0 .5-4-2m4 2 4-2'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSendBackward);
export default ForwardRef;
