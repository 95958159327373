import { PasswordResetParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const passwordReset = (builder: Builder) =>
  builder.mutation<void, PasswordResetParams>({
    query: (body) => ({
      url: '/user/password-reset',
      method: 'POST',
      body,
    }),
  });
