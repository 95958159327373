import { Article, ArticleParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const article = (builder: Builder) =>
  builder.query<Article, ArticleParams>({
    query: (params) => ({
      url: `/article`,
      params,
    }),
    providesTags: (res, err, { id }) => [{ type: 'Articles', id }],
  });
