'use client';

import { DragEndEvent, DragStartEvent, useDndMonitor } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext as DndSortableContext,
} from '@dnd-kit/sortable';
import { useState } from 'react';

import { SortableContextProps } from './SortableContext.types';

const SortableContext = <T,>({
  children,
  disabled,
  strategy,
  items = [],
  select,
  onStart,
  onEnd,
  onChange,
}: SortableContextProps<T>) => {
  const [active, setActive] = useState<T | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    onStart && onStart();
    setActive(items.find((item) => item[select] === event.active.id));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item[select] === active.id);
      const newIndex = items.findIndex((item) => item[select] === over.id);

      const moved = arrayMove(items, oldIndex, newIndex);
      onChange(moved);
    }

    onEnd && onEnd();
    setActive(null);
  };

  const handleDragCancel = () => setActive(null);

  useDndMonitor({
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd,
    onDragCancel: handleDragCancel,
  });

  return (
    <DndSortableContext
      disabled={disabled}
      strategy={strategy}
      items={items.map((item) => item[select] as string)}
    >
      {typeof children === 'function' ? children({ active }) : children}
    </DndSortableContext>
  );
};

export default SortableContext;
