import { AddLanguageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addLanguage = (builder: Builder) =>
  builder.mutation<void, AddLanguageParams>({
    query: ({ language }) => ({
      url: `/locale/languages/${language}`,
      method: 'POST',
    }),
    invalidatesTags: (res, err) => (!err && ['Languages']) || [],
  });
