import { UpdateStickerPackParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const updateStickerPack = (builder: Builder) =>
  builder.mutation<void, UpdateStickerPackParams>({
    query: ({ id, ...body }) => ({
      url: `/sticker-pack/update/${id}`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['StickerPacks']) || [],
  });
