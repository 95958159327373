'use client';

import { Editor } from '@tiptap/react';

import { RichTextContext } from './RichTextContext';

interface RichTextProviderProps {
  editor: Editor;
  children: React.ReactNode;
}

export const RichTextProvider: React.FC<RichTextProviderProps> = ({
  editor,
  children,
}) => {
  return (
    <RichTextContext.Provider
      value={{
        editor,
      }}
    >
      {children}
    </RichTextContext.Provider>
  );
};
