'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { animated } from '@react-spring/web';

import { useBottomSheet } from '../bottom-sheet-context/BottomSheetContext';
import { BottomSheetBackdropProps } from './BottomSheetBackdrop.types';

const BottomSheetBackdrop: React.FC<BottomSheetBackdropProps> = ({
  className,
}) => {
  const { open, onClose, drag, transition } = useBottomSheet();

  return transition(({ opacity }, innerOpen) => (
    <animated.div
      {...drag()}
      style={{ opacity }}
      className={classNames(
        styles.root,
        !open && styles.closed,
        !innerOpen && styles.hidden,
        className,
      )}
      onClick={() => onClose()}
    />
  ));
};

const styles = {
  root: classes(
    'z-[1599]',
    'absolute',
    'bg-skin-black',
    'bg-opacity-70',
    'cursor-pointer',
    'bottom-0',
    'top-0',
    'left-0',
    'right-0',
    'touch-none',
  ),
  closed: classes('pointer-events-none'),
  visible: classes('block'),
  hidden: classes('hidden'),
};

export default BottomSheetBackdrop;
