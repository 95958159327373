'use client';

import { MenuItems as HeadlessMenuItems } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import Floating from '../../../floating/Floating';
import { useMenuBase } from '../menu-context/MenuContext';
import { MenuItemsProps } from './MenuItems.types';

const MenuItems: React.FC<MenuItemsProps> = (
  { reference, children, floating, className, ...props },
  ref: React.Ref<HTMLDivElement>,
) => {
  const { open, close, defaultreference } = useMenuBase();

  return (
    <Floating
      reference={reference || defaultreference}
      open={open}
      className={classNames(styles.root, floating?.className)}
      {...floating}
    >
      <HeadlessMenuItems
        as='div'
        ref={ref}
        className={classNames(styles.items, className)}
        {...props}
      >
        {typeof children === 'function' ? children({ open, close }) : children}
      </HeadlessMenuItems>
    </Floating>
  );
};

const styles = {
  root: classes('z-[1600]'),
  items: classes(
    'p-1.5',
    'relative',
    'shadow-lg',
    'bg-skin-primary',
    'rounded-lg',
    'border-2',
    'border-opacity-60',
    'border-skin-silent',
  ),
};

export default React.forwardRef(MenuItems);
