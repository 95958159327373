'use client';

import React from 'react';

import { CalendarBaseProvider } from './calendar-base-context/CalendarBaseProvider';
import CalendarBaseDay from './calendar-base-day/CalendarBaseDay';
import CalendarBaseDays from './calendar-base-days/CalendarBaseDays';
import CalendarBaseHours from './calendar-base-hours/CalendarBaseHours';
import CalendarBaseIterator from './calendar-base-iterator/CalendarBaseIterator';
import CalendarBaseMonth from './calendar-base-month/CalendarBaseMonth';
import CalendarBaseMonths from './calendar-base-months/CalendarBaseMonths';
import CalendarBaseWeek from './calendar-base-week/CalendarBaseWeek';
import CalendarBaseWeekDays from './calendar-base-week-days/CalendarBaseWeekDays';
import CalendarBaseWeeks from './calendar-base-weeks/CalendarBaseWeeks';
import CalendarBaseYear from './calendar-base-year/CalendarBaseYear';
import CalendarBaseYears from './calendar-base-years/CalendarBaseYears';
import { CalendarBaseProps } from './CalendarBase.types';

const CalendarBase: React.FC<CalendarBaseProps> & {
  Iterator: typeof CalendarBaseIterator;
  Years: typeof CalendarBaseYears;
  Year: typeof CalendarBaseYear;
  Months: typeof CalendarBaseMonths;
  Month: typeof CalendarBaseMonth;
  Weeks: typeof CalendarBaseWeeks;
  Week: typeof CalendarBaseWeek;
  WeekDays: typeof CalendarBaseWeekDays;
  Days: typeof CalendarBaseDays;
  Day: typeof CalendarBaseDay;
  Hours: typeof CalendarBaseHours;
} = ({ children, ...props }) => {
  return <CalendarBaseProvider {...props}>{children}</CalendarBaseProvider>;
};

CalendarBase.Iterator = CalendarBaseIterator;
CalendarBase.Years = CalendarBaseYears;
CalendarBase.Year = CalendarBaseYear;
CalendarBase.Months = CalendarBaseMonths;
CalendarBase.Month = CalendarBaseMonth;
CalendarBase.Weeks = CalendarBaseWeeks;
CalendarBase.Week = CalendarBaseWeek;
CalendarBase.WeekDays = CalendarBaseWeekDays;
CalendarBase.Days = CalendarBaseDays;
CalendarBase.Day = CalendarBaseDay;
CalendarBase.Hours = CalendarBaseHours;

export default CalendarBase;
