import {
  FallbackLanguage,
  Language,
  LANGUAGES,
  Namespaces,
  resourceLoader,
} from '@nowadays/base/locales';

export const getI18NOptions = (lng: Language, namespaces: Namespaces[]) => {
  const { resources, ns } = resourceLoader(namespaces);

  return {
    lng,
    resources,
    ns,
    supportedLngs: LANGUAGES,
    fallbackLng: FallbackLanguage,
    nsSeparator: '.',
  };
};
