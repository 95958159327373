import { DailyActiveUser, DailyActiveUserParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const dailyActiveUser = (builder: Builder) =>
  builder.query<DailyActiveUser, DailyActiveUserParams>({
    query: (params) => ({
      url: '/user/dau',
      params,
    }),
  });
