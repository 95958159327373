'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { useState } from 'react';
import React, { Fragment, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { Floating } from '../floating';
import { TooltipProps } from './Tooltip.types';

const Tooltip: React.FC<TooltipProps> = ({
  title,
  open: isOpen = false,
  delay = 400,
  textWrap = true,
  floating,
  className,
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [hoverElement, setHoverElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setOpen(isOpen || false);
  }, [isOpen]);

  useEffect(() => {
    if (!hoverElement) {
      return;
    }

    const checkHover = (e: MouseEvent | TouchEvent) => {
      const mouseOver = hoverElement.contains(e.target as Node);
      if (!isHovering && mouseOver) {
        setIsHovering(true);
      }

      if (isHovering && !mouseOver) {
        setIsHovering(false);
      }
    };

    window.addEventListener('mousedown', checkHover);
    window.addEventListener('touchstart', checkHover);
    return () => {
      window.removeEventListener('mousedown', checkHover);
      window.removeEventListener('touchstart', checkHover);
    };
  }, [hoverElement]);

  useEffect(() => {
    if (isHovering) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, delay);

      return () => clearTimeout(timer);
    } else {
      setOpen(false);
    }
  }, [isHovering, delay]);

  if (!title || title === '' || isMobile || children.props?.disabled) {
    return children;
  }

  return (
    <Fragment>
      {React.cloneElement(children, {
        ref: (el: HTMLElement) => setHoverElement(el),
        onMouseEnter: () => setIsHovering(true),
        onMouseLeave: () => setIsHovering(false),
        onFocus: () => setIsHovering(true),
        onBlur: () => setIsHovering(false),
      })}
      <Floating
        reference={hoverElement}
        open={open}
        placement='bottom'
        className={classNames(styles.root, floating?.className)}
      >
        <div
          className={classNames(
            styles.title,
            textWrap && styles.textWrap,
            className,
          )}
        >
          {title}
        </div>
      </Floating>
    </Fragment>
  );
};

const styles = {
  root: classes('z-[1700]'),
  title: classes(
    'rounded-lg',
    'shadow-md',
    'p-1.5',
    'text-xs',
    'text-skin-inverted',
    'bg-skin-inverted',
  ),
  textWrap: classes('whitespace-nowrap'),
};

export default React.memo(Tooltip);
