import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgCloudy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M18.346 11.846c-.79.001-1.56.258-2.192.73a6.577 6.577 0 1 0-6.577 6.578h8.77a3.654 3.654 0 0 0 0-7.308Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCloudy);
export default ForwardRef;
