import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgParagraph = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M15.5 5v15m-4-15v15m8-16H9.25A4.25 4.25 0 0 0 5 8.25v0a4.25 4.25 0 0 0 4.25 4.25H11'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgParagraph);
export default ForwardRef;
