'use client';

import { classes } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import IconButton from '../../../button/icon-button/IconButton';
import ComboboxBase from '../combobox-base/ComboboxBase';
import { ComboboxAdornmentProps } from './ComboboxAdornment.types';

const ComboboxAdornment: React.FC<ComboboxAdornmentProps> = ({
  loading,
  disabled,
  className,
}) => {
  return (
    <ComboboxBase.Button as={Fragment}>
      <IconButton
        as='span'
        name='Down'
        type='button'
        variant='text'
        loading={loading}
        disabled={disabled}
        className={styles.button}
        icon={{ className: className }}
      />
    </ComboboxBase.Button>
  );
};

const styles = {
  button: classes('p-1'),
};

export default ComboboxAdornment;
