'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import Icon from '../../../icons/Icon';
import { ComboboxClearProps } from './ComboboxClear.types';

const ComboboxClear: React.FC<ComboboxClearProps> = ({
  multiple,
  disabled,
  className,
  onClear,
}) => {
  const translate = useTranslate();

  const onClick = () => !disabled && onClear();

  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.root,
        disabled && styles.disabled,
        className,
      )}
    >
      <Icon name='Close' className={styles.icon} />
      <span>
        {translate(
          multiple
            ? 'ui.components.inputs.combobox.clearAll'
            : 'ui.components.inputs.combobox.clear',
        )}
      </span>
    </div>
  );
};

const styles = {
  root: classes(
    'p-2',
    'cursor-pointer',
    'text-skin-accent',
    'flex',
    'items-center',
    'space-x-2',
  ),
  icon: classes('w-4', 'h-4', 'min-w-max'),
  disabled: classes('opacity-70', 'cursor-default'),
};

export default ComboboxClear;
