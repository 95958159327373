import { Languages } from '@nowadays/base/locales';

import { PlannerImage } from '../planner-image/PlannerImages.types';

export const WIDGET_WIDTH = 480;

export const WIDGET_HEIGHT = 320;

export const PLANNER_KINDS = ['dated', 'undated'] as const;

export type PlannerKind = (typeof PLANNER_KINDS)[number];

export const PAGE_PERIODS = ['daily', 'weekly', 'monthly', 'yearly'] as const;

export enum PagePeriods {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export type PagePeriod = (typeof PAGE_PERIODS)[number];

export const PAGE_LAYOUTS = [
  'landscape',
  'portrait',
  'square',
  'tabloid',
] as const;

export type PageLayout = (typeof PAGE_LAYOUTS)[number];

export type Planner = {
  id: string;
  title?: string;
  titleLocale?: Languages;
  description?: string;
  descriptionLocale?: Languages;
  image?: PlannerImage;
  kind: PlannerKind;
  periods?: PagePeriod[];
  layout: PageLayout;
  createdAt: string;
  deletedAt?: string;
  lastEditedAt?: string;
  preset?: boolean;
  status?: boolean;
  order?: number;
};

export type PlannerParams = Pick<Planner, 'id'>;
