'use client';

import { classes } from '@nowadays/ui/utils';

import IconButton from '../../../button/icon-button/IconButton';
import { ListboxAdornmentProps } from './ListboxAdornment.types';

const ListboxAdornment: React.FC<ListboxAdornmentProps> = ({
  disabled,
  className,
}) => {
  return (
    <IconButton
      as='span'
      name='Down'
      variant='text'
      disabled={disabled}
      className={styles.button}
      icon={{ className: className }}
    />
  );
};

const styles = {
  button: classes('p-1'),
};

export default ListboxAdornment;
