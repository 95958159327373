import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgPassword = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m6.455 21.755.309-.95-.31.95Zm-3.21-3.21.95-.309-.95.31Zm17.51 0-.95-.309.95.31Zm-3.21 3.21-.309-.95.31.95Zm0-13.51-.309.95.31-.95Zm3.21 3.21-.95.309.95-.31Zm-14.3-3.21.309.95-.31-.95Zm-3.21 3.21.95.309-.95-.31ZM14.4 3.799a1 1 0 0 0 1.2-1.598l-1.2 1.598ZM10 9h4V7h-4v2Zm4 12h-4v2h4v-2Zm-4 0c-1.94 0-2.672-.012-3.236-.196l-.618 1.902c.942.306 2.07.294 3.854.294v-2Zm-8-6c0 1.783-.012 2.912.294 3.854l1.902-.618C4.012 17.672 4 16.94 4 15H2Zm4.764 5.804a4 4 0 0 1-2.568-2.568l-1.902.618a6 6 0 0 0 3.852 3.852l.618-1.902ZM20 15c0 1.94-.012 2.672-.196 3.236l1.902.618c.306-.942.294-2.07.294-3.854h-2Zm-6 8c1.783 0 2.912.012 3.854-.294l-.618-1.902C16.672 20.988 15.94 21 14 21v2Zm5.804-4.764a4 4 0 0 1-2.568 2.568l.618 1.902a6 6 0 0 0 3.852-3.852l-1.902-.618ZM14 9c1.94 0 2.672.012 3.236.196l.618-1.902C16.912 6.988 15.784 7 14 7v2Zm8 6c0-1.783.012-2.912-.294-3.854l-1.902.618c.184.565.196 1.297.196 3.236h2Zm-4.764-5.804a4 4 0 0 1 2.568 2.568l1.902-.618a6 6 0 0 0-3.852-3.852l-.618 1.902ZM4 15c0-1.94.012-2.671.196-3.236l-1.902-.618C1.988 12.088 2 13.216 2 15h2Zm2.146-7.706a6 6 0 0 0-3.852 3.852l1.902.618a4 4 0 0 1 2.568-2.568l-.618-1.902ZM8 8V7H6v1h2Zm4-7a6 6 0 0 0-6 6h2a4 4 0 0 1 4-4V1Zm0 2c.902 0 1.731.297 2.4.8l1.2-1.6A5.978 5.978 0 0 0 12 1v2ZM9 15a3 3 0 0 0 3 3v-2a1 1 0 0 1-1-1H9Zm3 3a3 3 0 0 0 3-3h-2a1 1 0 0 1-1 1v2Zm3-3a3 3 0 0 0-3-3v2a1 1 0 0 1 1 1h2Zm-3-3a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1v-2Zm-2-5c-1.401 0-2.388-.003-3.166.128L7.166 9.1C7.738 9.003 8.518 9 10 9V7Zm-3.166.128c-.238.04-.465.093-.688.166l.618 1.902a2.79 2.79 0 0 1 .402-.096l-.332-1.972ZM6 8v.114h2V8H6Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPassword);
export default ForwardRef;
