import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const permanentlyDeletePages = (builder: Builder) =>
  builder.mutation<void, string>({
    query: (planner) => ({
      url: `/page/permanently-delete-pages/${planner}`,
      method: 'DELETE',
    }),
    async onQueryStarted(planner, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled;

        dispatch(
          pageEndpoints.util.updateQueryData(
            'deletedPages',
            planner,
            (draft) => {
              draft = [];
              return draft;
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
