import { ReorderStickerPackItem } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const reorderStickerPackItem = (builder: Builder) =>
  builder.mutation<void, ReorderStickerPackItem[]>({
    query: (body) => ({
      url: '/sticker-pack-item/reorder',
      method: 'POST',
      body,
    }),
  });
