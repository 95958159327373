import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgOrderedList = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M8 14h11M8 18h11M8 6h11M8 10h11'
    />
    <path
      fill='currentColor'
      d='M5.024 4.364V8h-.987V5.273h-.021l-.796.476V4.91l.895-.547h.91ZM3.241 12v-.71l1.357-1.122c.09-.075.167-.145.23-.21a.794.794 0 0 0 .15-.204.506.506 0 0 0 .053-.233.453.453 0 0 0-.06-.24.391.391 0 0 0-.163-.152.512.512 0 0 0-.242-.055.512.512 0 0 0-.241.055.383.383 0 0 0-.16.161.545.545 0 0 0-.057.26H3.17c0-.256.058-.476.173-.66.115-.185.277-.328.486-.427.21-.1.455-.15.737-.15.291 0 .543.048.756.141.215.093.38.223.496.39a1.072 1.072 0 0 1 .089 1.009c-.058.138-.163.29-.313.457a6.39 6.39 0 0 1-.64.596l-.342.284v.022h1.42V12h-2.79Zm1.421 4.05a1.88 1.88 0 0 1-.764-.148 1.274 1.274 0 0 1-.522-.41 1.003 1.003 0 0 1-.191-.6h.994a.31.31 0 0 0 .064.188c.043.055.1.097.174.128.073.03.157.046.252.046a.574.574 0 0 0 .242-.048.396.396 0 0 0 .163-.136.338.338 0 0 0 .057-.2.293.293 0 0 0-.068-.195.44.44 0 0 0-.19-.133.77.77 0 0 0-.282-.048h-.37v-.681h.37a.655.655 0 0 0 .26-.048.407.407 0 0 0 .175-.134.308.308 0 0 0 .062-.195.326.326 0 0 0-.051-.188.36.36 0 0 0-.15-.128.582.582 0 0 0-.46.002.403.403 0 0 0-.165.133.34.34 0 0 0-.062.195h-.944c0-.222.062-.419.183-.59a1.23 1.23 0 0 1 .5-.4 1.72 1.72 0 0 1 .73-.146c.269 0 .506.046.712.137.207.09.369.216.485.376.117.159.175.34.174.545a.638.638 0 0 1-.21.5.912.912 0 0 1-.536.232v.028c.294.032.514.122.662.27a.738.738 0 0 1 .219.554.884.884 0 0 1-.192.566 1.265 1.265 0 0 1-.534.387 2.05 2.05 0 0 1-.787.14Zm-1.456 3.382v-.767l1.463-2.301h.696v1.022h-.398l-.788 1.25v.029h2.16v.767H3.205ZM4.974 20v-.803l.022-.333v-2.5h.923V20h-.945Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgOrderedList);
export default ForwardRef;
