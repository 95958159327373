'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Icon from '../icons/Icon';
import Spinner from '../indicator/spinner/Spinner';
import { FeedbackProps } from './Feedback.types';

const Feedback: React.FC<FeedbackProps> = ({
  title,
  description,
  color = 'green',
  icon = { name: 'InfoCircle' },
  border = true,
  loading = false,
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        styles.root,
        border && styles.border[color],
        className,
      )}
    >
      <div className={styles.header}>
        {loading ? (
          <Spinner
            className={classNames(styles.iconDefault, styles.icon[color])}
          />
        ) : (
          <Icon
            {...icon}
            className={classNames(
              styles.iconDefault,
              icon.className,
              styles.icon[color],
            )}
          />
        )}
        <span className={classNames(styles.title, styles.text[color])}>
          {title}
        </span>
      </div>

      {(description || children) && (
        <div className={styles.container}>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          {children && children}
        </div>
      )}
    </div>
  );
};

const styles = {
  root: classes(
    'p-3',
    'rounded-lg',
    'text-sm',
    'space-y-1',
    'bg-skin-primary',
    'flex',
    'flex-col',
  ),
  header: classes('space-x-3', 'flex', 'items-center'),
  title: classes('align-middle', 'font-medium'),
  container: classes('ml-8', 'space-y-2'),
  description: classes('text-xs', 'text-skin-muted'),
  border: {
    green: classes('border-2', 'border-skin-green'),
    red: classes('border-2', 'border-skin-red'),
    blue: classes('border-2', 'border-skin-blue'),
    orange: classes('border-2', 'border-skin-orange'),
    muted: classes('border-2', 'border-skin-muted'),
  },
  iconDefault: classes('w-5', 'h-5', 'flex-shrink-0'),
  icon: {
    green: classes('text-skin-green'),
    red: classes('text-skin-red'),
    blue: classes('text-skin-blue'),
    orange: classes('text-skin-orange'),
    muted: classes('text-skin-muted'),
  },
  text: {
    green: classes('text-skin-green'),
    red: classes('text-skin-red'),
    blue: classes('text-skin-blue'),
    orange: classes('text-skin-orange'),
    muted: classes('text-skin-muted'),
  },
};

export default Feedback;
