import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    default: `base.error.mixed.default`,
    required: `base.error.mixed.required`,
    oneOf: `base.error.mixed.oneOf`,
    notOneOf: `base.error.mixed.notOneOf`,
    notType: `base.error.mixed.notType`,
    defined: `base.error.mixed.defined`,
  },
  string: {
    length: `base.error.string.length`,
    min: `base.error.string.min`,
    max: `base.error.string.max`,
    matches: `base.error.string.matches`,
    email: `base.error.string.email`,
    url: `base.error.string.url`,
    uuid: `base.error.string.uuid`,
    trim: `base.error.string.trim`,
    lowercase: `base.error.string.lowercase`,
    uppercase: `base.error.string.uppercase`,
  },
  number: {
    min: `base.error.number.min`,
    max: `base.error.number.max`,
    lessThan: `base.error.number.lessThan`,
    moreThan: `base.error.number.moreThan`,
    positive: `base.error.number.positive`,
    negative: `base.error.number.negative`,
    integer: `base.error.number.integer`,
  },
  date: {
    min: `base.error.date.min`,
    max: `base.error.date.max`,
  },
  object: {
    noUnknown: `base.error.object.noUnknown`,
  },
  array: {
    length: `base.error.array.length`,
    min: `base.error.array.min`,
    max: `base.error.array.max`,
  },
  boolean: {
    isValue: `base.error.boolean.isValue`,
  },
});
