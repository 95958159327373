'use client';

import { useLanguage, useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';
import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import AnchorButton from '../button/anchor-button/AnchorButton';
import { useInterface } from '../interface';
import { CAPTCHAProps } from './CAPTCHA.types';

const CAPTCHA: React.FC<CAPTCHAProps> = (
  { size, siteKey, hidden, hideInfo, className },
  ref: React.Ref<ReCAPTCHA>,
) => {
  const { deviceTheme } = useInterface();
  const translate = useTranslate();
  const { language } = useLanguage();

  useEffect(() => {
    const domObserver = new MutationObserver(() => {
      const iframe = document.querySelector(
        'iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]',
      );

      if (iframe) {
        domObserver.disconnect();
        (
          iframe.parentNode?.parentNode?.lastChild as HTMLDivElement
        ).style.transform = 'translateY(20%)';
      }
    });

    domObserver.observe(document.documentElement || document.body, {
      childList: true,
      subtree: true,
    });
  }, [ref]);

  return (
    <div className={className}>
      <ReCAPTCHA
        ref={ref}
        hl={language}
        size={size || 'invisible'}
        hidden={hidden === undefined ? true : hidden}
        sitekey={siteKey}
        theme={deviceTheme}
      />
      {!hideInfo && (
        <span className={classNames(styles.root, styles.text)}>
          {translate('ui.components.captcha.pre')}
          <AnchorButton
            href='https://policies.google.com/terms'
            color='accent'
            target='_blank'
            className={styles.text}
          >
            {translate('ui.components.captcha.googleTerms')}
          </AnchorButton>
          {translate('ui.components.captcha.and')}
          <AnchorButton
            href='https://policies.google.com/privacy'
            color='accent'
            target='_blank'
            className={styles.text}
          >
            {translate('ui.components.captcha.privacyPolicy')}
          </AnchorButton>
          {translate('ui.components.captcha.post')}
        </span>
      )}
    </div>
  );
};

const styles = {
  root: classes('block', 'font-light', 'text-skin-muted'),
  text: classes('text-xs'),
};

export default React.forwardRef(CAPTCHA);
