import { Planner, UpdatePlanner } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { plannerEndpoints } from './Planner.endpoints';

export const updatePlanner = (builder: Builder) =>
  builder.mutation<Planner, UpdatePlanner>({
    query: (body) => ({
      url: '/planner/update',
      method: 'PUT',
      body,
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const { data: updatedPlanner } = await queryFulfilled;

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'planners',
            undefined,
            (draft) => {
              draft.forEach((i, index) => {
                if (i.id === updatedPlanner.id) {
                  draft[index] = updatedPlanner;
                }
              });
            },
          ),
        );

        dispatch(
          plannerEndpoints.util.upsertQueryData(
            'planner',
            { id: updatedPlanner.id },
            updatedPlanner,
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
