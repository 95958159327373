import { baseApi } from '../base/query/Api';
import { favoriteWidget } from './FavoriteWidget.api';
import { widgets } from './Widgets.api';

export const widgetEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    widgets: widgets(builder),
    favoriteWidget: favoriteWidget(builder),
  }),
});

export const { useWidgetsQuery, useFavoriteWidgetMutation } = widgetEndpoints;
