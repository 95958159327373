'use client';

import { Radio as HeadlessRadio } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import React, { Fragment } from 'react';

import Input from '../../input/Input';
import { RadioOptionProps } from './RadioOption.types';

const RadioOption: React.FC<RadioOptionProps> = (
  { value, children, className, disabled, input, ...props },
  ref: React.Ref<HTMLDivElement>,
) => {
  return (
    <Input disable={disabled} {...input}>
      {({
        inputStyles,
        focusStyles,
        borderStyles,
        backgroundStyles,
        disabledStyles,
        customStyles,
      }) => (
        <HeadlessRadio
          as='div'
          ref={ref}
          value={value}
          disabled={disabled}
          className={(props) =>
            classNames(
              styles.option,
              inputStyles,
              focusStyles,
              borderStyles,
              backgroundStyles,
              customStyles,
              props.checked && styles.checked,
              (disabled || props.disabled) && disabledStyles,
              typeof className === 'function' ? className(props) : className,
            )
          }
          {...props}
        >
          {(args) => (
            <Fragment>
              {typeof children === 'function' ? children(args) : children}
            </Fragment>
          )}
        </HeadlessRadio>
      )}
    </Input>
  );
};

const styles = {
  option: classes('py-4', 'cursor-pointer', 'duration-200', 'select-none'),
  checked: classes('bg-skin-inverted', 'text-skin-inverted', 'font-medium'),
};

export default React.forwardRef(RadioOption);
