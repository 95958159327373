import { baseApi } from '../base/query/Api';
import { changeEmail } from './ChangeEmail.api';
import { changePassword } from './ChangePassword.api';
import { createPassword } from './CreatPassword.api';
import { dailyActiveUser } from './DailyActiveUser.api';
import { deleteUser } from './DeleteUser.api';
import { emailVerification } from './EmailVerification.api';
import { passwordReset } from './PasswordReset.api';
import { resetPassword } from './ResetPassword.api';
import { totalUser } from './TotalUser.api';
import { updateUser } from './UpdateUser.api';
import { user } from './User.api';
import { users } from './Users.api';
import { verifyPassword } from './VerifyPassword.api';

export const userEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    users: users(builder),
    user: user(builder),
    updateUser: updateUser(builder),
    deleteUser: deleteUser(builder),
    totalUser: totalUser(builder),
    dailyActiveUser: dailyActiveUser(builder),
    passwordReset: passwordReset(builder),
    resetPassword: resetPassword(builder),
    createPassword: createPassword(builder),
    verifyPassword: verifyPassword(builder),
    emailVerification: emailVerification(builder),
    changeEmail: changeEmail(builder),
    changePassword: changePassword(builder),
  }),
});

export const {
  useUsersQuery,
  useLazyUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useTotalUserQuery,
  useDailyActiveUserQuery,
  usePasswordResetMutation,
  useResetPasswordMutation,
  useCreatePasswordMutation,
  useVerifyPasswordMutation,
  useEmailVerificationMutation,
  useChangeEmailMutation,
  useChangePasswordMutation,
} = userEndpoints;
