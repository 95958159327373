import { baseApi } from '../base/query/Api';
import { addEmail } from './AddEmail.api';
import { deleteEmail } from './DeleteEmail.api';
import { emails } from './Emails.api';
import { testEmail } from './TestEmail.api';
import { updateEmail } from './UpdateEmail.api';

export const emailEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    emails: emails(builder),
    addEmail: addEmail(builder),
    updateEmail: updateEmail(builder),
    deleteEmail: deleteEmail(builder),
    testEmail: testEmail(builder),
  }),
});

export const {
  useEmailsQuery,
  useAddEmailMutation,
  useUpdateEmailMutation,
  useDeleteEmailMutation,
  useTestEmailMutation,
} = emailEndpoints;
