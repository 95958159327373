import { Button, NotificationItem } from '@nowadays/ui/components';
import { classes } from '@nowadays/ui/utils';

export const invalidCredentials = (
  translate: Translate,
  onPasswordResetClick?: () => void,
): NotificationItem => {
  return {
    title: translate('ui.common.auth.notifications.invalidCredentials.message'),
    color: 'red',
    description: translate(
      'ui.common.auth.notifications.invalidCredentials.description',
    ),
    content: onPasswordResetClick
      ? ({ close }) => (
          <div className={styles.container}>
            <span>
              {translate(
                'ui.common.auth.notifications.invalidCredentials.passwordReset',
              )}
            </span>
            <Button
              variant='contained'
              color='accent'
              onClick={() => {
                onPasswordResetClick();
                close();
              }}
            >
              {translate(
                'ui.common.auth.notifications.invalidCredentials.resetPassword',
              )}
            </Button>
          </div>
        )
      : undefined,
  };
};

const styles = {
  container: classes(
    'flex',
    'text-xs',
    'items-center',
    'text-skin-muted',
    'justify-between',
  ),
};
