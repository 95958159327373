'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { FormPanelProps } from './FormPanel.types';

const FormPanel: React.FC<FormPanelProps> = ({ className, children }) => {
  return <div className={classNames(styles.root, className)}>{children}</div>;
};

const styles = {
  root: classes('flex', 'flex-col', 'gap-2'),
};

export default FormPanel;
