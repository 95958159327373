'use client';

import { ComboboxOption as HeadlessComboboxOption } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import { TValues } from '../Combobox.types';
import { ComboboxOptionProps } from './ComboboxOption.types';

const ComboboxOption = <T extends TValues>({
  option,
  className,
  children,
}: ComboboxOptionProps<T>) => {
  const { disabled, hidden } = option;

  return (
    <HeadlessComboboxOption
      value={option}
      disabled={disabled}
      className={({ disabled, active, selected }) =>
        classNames(
          styles.root,
          active && styles.active,
          selected && styles.selected,
          disabled && styles.disabled,
          hidden && styles.hidden,
          className,
        )
      }
    >
      {(props) => (
        <Fragment>
          {typeof children === 'function'
            ? children({ ...props, hidden })
            : children}
        </Fragment>
      )}
    </HeadlessComboboxOption>
  );
};

const styles = {
  root: classes('py-2', 'cursor-pointer'),
  disabled: classes('cursor-default', 'opacity-70'),
  active: classes('bg-skin-base'),
  selected: classes('bg-skin-silent'),
  hidden: classes('p-0'),
};

export default ComboboxOption;
