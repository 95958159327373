import { PermanentlyDeletePlanner } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { plannerEndpoints } from './Planner.endpoints';

export const permanentlyDeletePlanner = (builder: Builder) =>
  builder.mutation<void, PermanentlyDeletePlanner>({
    query: ({ id }) => ({
      url: `/planner/permanently-delete/${id}`,
      method: 'DELETE',
    }),
    async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled;

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'deletedPlanners',
            undefined,
            (draft) => {
              draft.splice(
                draft.findIndex((i) => i.id === id),
                1,
              );
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
