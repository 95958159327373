'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import Adornment from './adornment/Adornment';
import Box from './box/Box';
import { InnerInputProps } from './Input.types';

const Input: React.FC<InnerInputProps> & {
  Box: typeof Box;
  Adornment: typeof Adornment;
} = ({
  error,
  disable,
  focus = true,
  border = true,
  readOnly,
  children,
  className,
  ...boxProps
}) => (
  <Fragment>
    {children({
      inputStyles: classNames(styles.inputStyles, readOnly && styles.readOnly),
      borderStyles: border && styles.borderStyles,
      backgroundStyles: styles.backgroundStyles,
      adornmentStyles: styles.adornmentStyles,
      focusStyles: !disable && focus && styles.focusStyles,
      disabledStyles: (disable && styles.disabledStyles) || undefined,
      errorStyles: (error && !disable && styles.errorStyles) || undefined,
      customStyles: className,
      boxProps,
    })}
  </Fragment>
);

Input.Box = Box;
Input.Adornment = Adornment;

const styles = {
  inputStyles: classes(
    'h-8',
    'px-2',
    'w-full',
    'transition',
    'duration-200',
    'ease-in-out',
    'text-sm',
    'rounded-[0.425rem]',
    'bg-transparent',
    'placeholder-skin-primary',
  ),
  focusStyles: classes('focus-within:ring-2', 'focus-within:ring-skin-accent'),
  backgroundStyles: classes('bg-skin-base'),
  disabledStyles: classes(
    'opacity-80',
    'disabled:opacity-80',
    'cursor-default',
    'pointer-events-none',
  ),
  borderStyles: classes('border-2', 'rounded-lg', 'border-skin-silent'),
  errorStyles: classes(
    'rounded-[0.575rem]',
    'outline-2',
    'outline-offset-0',
    'outline-skin-red',
  ),
  adornmentStyles: classes('text-skin-adornment', 'w-6', 'h-6'),
  readOnly: classes('cursor-default'),
};

export default Input;
