import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgTextBold = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M13.625 11.5v1.25-1.25Zm0-7.5v1.25V4ZM6.75 5.5v6h2.5v-6h-2.5Zm0 6v6h2.5v-6h-2.5ZM8 12.75h5.625v-2.5H8v2.5Zm5.625-1.25c0 1.25 0 1.25.002 1.25h.013a.715.715 0 0 0 .072-.002 3.863 3.863 0 0 0 .623-.086 4.14 4.14 0 0 0 1.39-.59c1.093-.73 1.963-2.083 1.963-4.322h-2.5c0 1.51-.537 2.033-.85 2.241a1.627 1.627 0 0 1-.737.26h.013l.005-.001h.004c.001 0 .002 0 .002 1.25Zm4.063-3.75c0-2.239-.87-3.592-1.963-4.32-1.004-.67-2-.68-2.1-.68v2.5c-.1 0 .31-.01.713.259.313.209.85.73.85 2.241h2.5Zm-4.063-5h-4.36v2.5h4.36v-2.5Zm-4.36 17.5h4.36v-2.5h-4.36v2.5Zm4.36-1.25c0 1.25 0 1.25.002 1.25h.013a.715.715 0 0 0 .072-.002 3.863 3.863 0 0 0 .623-.086 4.14 4.14 0 0 0 1.39-.59c1.093-.73 1.963-2.083 1.963-4.322h-2.5c0 1.51-.537 2.033-.85 2.241a1.627 1.627 0 0 1-.737.26h.013l.005-.001h.004c.001 0 .002 0 .002 1.25Zm4.063-3.75c0-2.24-.87-3.592-1.963-4.321a4.124 4.124 0 0 0-2.013-.677 2.846 2.846 0 0 0-.072-.002h-.013c-.001 0-.002 0-.002 1.25s0 1.25-.002 1.25h-.022l.026.002a1.627 1.627 0 0 1 .71.257c.314.208.85.73.85 2.241h2.5ZM6.75 17.5c0 1.311.936 2.75 2.516 2.75v-2.5c.029 0 .053.008.067.015.012.006.007.006-.007-.01a.403.403 0 0 1-.076-.255h-2.5Zm2.5-12c0-.133.046-.219.076-.255.014-.016.019-.016.007-.01a.157.157 0 0 1-.067.015v-2.5c-1.58 0-2.516 1.439-2.516 2.75h2.5Zm4.375 12.25v2.5-2.5 2.5-2.5Zm0-15v2.5-2.5Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTextBold);
export default ForwardRef;
