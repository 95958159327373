import { ErrorCode, LoginAdmin } from '@nowadays/base/types';
import { useAuth, useAuthNotifications } from '@nowadays/ui/common';
import {
  CAPTCHA,
  Form,
  useCAPTCHA,
  useFormHook,
} from '@nowadays/ui/components';
import { useTranslate } from '@nowadays/ui/i18n/client';
import { isServerError } from '@nowadays/ui/services';
import { useLoginAdminMutation } from '@nowadays/ui/services';
import {
  ADMIN_ENVS,
  classes,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@nowadays/ui/utils';
import { useEffect } from 'react';

import { loginFormSchema } from './LoginForm.schema';

const LoginForm = () => {
  const { login } = useAuth();
  const translate = useTranslate();
  const authNotifications = useAuthNotifications();
  const [loginAdmin, { data, isSuccess, error }] = useLoginAdminMutation();
  const { hook, reset, getValues, setFieldErrors } = useFormHook<LoginAdmin>({
    schema: loginFormSchema,
    defaultValues: {
      email: getLocalStorage('remember'),
    },
  });
  const { getToken, captchaRef } = useCAPTCHA({
    onBackgroundClicked: () => reset(getValues()),
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    login(data.user);
  }, [data]);

  useEffect(() => {
    if (isServerError(error)) {
      if (error.data.errors) {
        setFieldErrors(error.data.errors);
      } else if (error.data.error?.code === ErrorCode.AccountNotFoundError) {
        authNotifications('AccountNotFound');
      } else if (error.data.error?.code === ErrorCode.InvalidCredentialsError) {
        authNotifications('InvalidCredentials');
      } else {
        authNotifications('SomethingWentWrong');
      }
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      const data = getValues();

      if (data.rememberMe) {
        setLocalStorage('remember', data.email);
      } else {
        removeLocalStorage('remember');
      }
    }
  }, [hook, isSuccess]);

  const handleSubmit = async (data: LoginAdmin) => {
    try {
      const captchaToken = (ADMIN_ENVS.PROD && (await getToken())) || 'dev';

      delete data.rememberMe;

      await loginAdmin({ ...data, captchaToken });
    } catch {
      authNotifications('SomethingWentWrong');
    }
  };

  return (
    <Form<LoginAdmin> hook={hook} handleSubmit={handleSubmit}>
      <Form.Panel>
        <Form.Text
          field='email'
          label={translate('admin.login.form.email')}
          placeholder={translate('admin.login.form.email')}
          autoCapitalize='off'
          spellCheck='false'
          autoComplete='username'
          autoCorrect='off'
          tabIndex={3}
        />
        <Form.Text
          field='password'
          type='password'
          label={translate('admin.login.form.password')}
          placeholder={translate('admin.login.form.password')}
          spellCheck='false'
          autoComplete='current-password'
          autoCorrect='off'
          tabIndex={4}
        />
        <Form.Checkbox
          field='rememberMe'
          label={translate('admin.login.form.rememberMe')}
          tabIndex={5}
        />
      </Form.Panel>
      <CAPTCHA
        ref={captchaRef}
        hideInfo
        siteKey={ADMIN_ENVS.GOOGLE_RECAPTCHA_SITE_KEY}
      />
      <Form.Footer
        confirm={{
          className: styles.button,
          text: translate('admin.login.form.login'),
          tabIndex: 6,
        }}
        cancel={{ hidden: true }}
      />
    </Form>
  );
};

const styles = {
  button: classes('w-full'),
};

export default LoginForm;
