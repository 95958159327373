'use client';

import { AuthUser } from '@nowadays/base/types';
import { createContext, useContext } from 'react';

interface AuthContextProps {
  user: AuthUser | null;
  isLoading: boolean;
  login: (user: AuthUser | null) => void;
  logout: () => void;
  updateUser: (
    update: Pick<
      Partial<AuthUser>,
      'firstName' | 'lastName' | 'picture' | 'settings' | 'preferences'
    >,
  ) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  isLoading: true,
  login: () => null,
  logout: () => null,
  updateUser: () => null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};
