'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import FilePicker from '../../../inputs/file-picker/FilePicker';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormFilePickerProps } from './FormFilePicker.types';

const FormFilePicker: React.FC<FormFilePickerProps> = ({
  field,
  label,
  disabled,
  multiple,
  input,
  onDrop,
  container,
  ...props
}) => {
  const { control, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div key={field} className={classNames(styles.root, container?.className)}>
      {label && <FieldLabel label={label} htmlFor={field} />}
      <Controller
        control={control}
        name={field}
        render={({ field: { onBlur, onChange, value } }) => (
          <FilePicker
            id={field}
            key={field}
            value={value}
            multiple={multiple}
            onBlur={onBlur}
            disabled={isDisable}
            input={{ error: isError, disable: isDisable, ...input }}
            onDrop={(files, refects, event) => {
              onDrop && onDrop(files, refects, event);
              multiple ? onChange(files) : onChange(files[0]);
            }}
            {...props}
          />
        )}
      />
      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
};

export default FormFilePicker;
