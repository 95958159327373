'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { isSameWeek } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { useRegion } from '../../../region/RegionContext';
import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseWeekProps } from './CalendarBaseWeek.types';

const CalendarBaseWeek: React.FC<CalendarBaseWeekProps> = ({
  week,
  formatStr,
  children,
}) => {
  const { format } = useRegion();
  const translate = useTranslate();
  const { currentWeek, currentPeriod, handleWeekChanged, handlePeriodChanged } =
    useCalendarBase();

  const weekLetter = useMemo(
    () => translate('ui.components.calendar.datepicker.weeks.week'),
    [translate],
  );

  const formatted = useMemo(
    () => `${weekLetter}${format(week, formatStr || 'ww')}`,
    [weekLetter, week, formatStr, format],
  );

  const onChanged = useCallback(
    (week: Date) => {
      handleWeekChanged(week);
      handlePeriodChanged('weekly');
    },
    [handleWeekChanged, handlePeriodChanged],
  );

  const isSelected = useMemo(
    () => currentPeriod === 'weekly' && isSameWeek(week, currentWeek),
    [week, currentPeriod, currentWeek],
  );

  return typeof children === 'function'
    ? children({
        week,
        formatted,
        isSelected,
        onChanged,
      })
    : children;
};

export default CalendarBaseWeek;
