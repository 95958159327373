import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgTick = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='m20 7-5.39 6.469c-2.137 2.563-3.205 3.845-4.61 3.845-1.405 0-2.473-1.282-4.61-3.845L4 11.8'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTick);
export default ForwardRef;
