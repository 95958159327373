import { baseApi } from '../base/query/Api';
import { elements } from './Elements.api';

export const elementEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    elements: elements(builder),
  }),
});

export const { useElementsQuery } = elementEndpoints;
