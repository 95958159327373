import { baseApi } from '../base/query/Api';
import { addReport } from './AddReport.api';
import { closeReport } from './CloseReport.api';
import { deleteReport } from './DeleteReport.api';
import { report } from './Report.api';
import { reports } from './Reports.api';

export const reportEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    reports: reports(builder),
    report: report(builder),
    addReport: addReport(builder),
    closeReport: closeReport(builder),
    deleteReport: deleteReport(builder),
  }),
});

export const {
  useReportsQuery,
  useLazyReportQuery,
  useAddReportMutation,
  useCloseReportMutation,
  useDeleteReportMutation,
} = reportEndpoints;
