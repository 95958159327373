'use client';

import { DEVICE_THEMES, Theme, THEMES } from '@nowadays/base/types';
import { useThemeDetector } from '@nowadays/ui/hooks';
import { useEffect, useMemo, useState } from 'react';

const isServer = typeof window === 'undefined';

const findDeviceTheme = () => {
  if (isServer) {
    return;
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};

export const initTheme = (theme: Theme) => {
  if (isServer) {
    return;
  }

  let active = theme;

  if (theme === 'system') {
    active = findDeviceTheme();
  }

  DEVICE_THEMES.forEach((i) => {
    if (i === active) {
      document.documentElement.classList.add(i);
    } else {
      document.documentElement.classList.remove(i);
    }
  });
};

export const findTheme = (theme?: Theme) => {
  if (isServer) {
    return;
  }

  return THEMES.find((i) => i === theme) || 'system';
};

export const useTheme = (initial?: Theme) => {
  const device = useThemeDetector();

  const [currentTheme, setCurrentTheme] = useState<Theme>(findTheme(initial));

  useEffect(() => {
    initTheme(currentTheme);
  }, [device, currentTheme]);

  const deviceTheme = useMemo(
    () =>
      DEVICE_THEMES.find((i) =>
        currentTheme === 'system'
          ? findDeviceTheme() === i
          : i === currentTheme,
      ),
    [currentTheme],
  );

  const changeTheme = (theme: Theme) => setCurrentTheme(findTheme(theme));

  return { currentTheme, deviceTheme, changeTheme };
};
