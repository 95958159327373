import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgUndo = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M5 9v1-1Zm1 9a1 1 0 1 0 0 2v-2Zm.496-4.29a1 1 0 0 0 1.408-1.42l-1.408 1.42Zm-.706-2.108-.704.71.704-.71Zm0-5.204-.704-.71.704.71Zm2.114-.688a1 1 0 0 0-1.408-1.42l1.408 1.42ZM4.016 9.25l-.992.127.992-.126Zm0-.5-.992-.127.992.126ZM5 10h9V8H5v2Zm9 8H6v2h8v-2Zm4-4a4 4 0 0 1-4 4v2a6 6 0 0 0 6-6h-2Zm-4-4a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6v2Zm-6.096 2.29-1.41-1.398-1.408 1.42 1.41 1.398 1.408-1.42Zm-1.41-5.182 1.41-1.398-1.408-1.42-1.41 1.398 1.408 1.42Zm0 3.784c-.58-.574-.953-.946-1.2-1.255-.236-.293-.276-.431-.286-.513l-1.984.253c.076.598.361 1.078.71 1.512.336.418.806.883 1.352 1.423l1.408-1.42ZM5.086 5.688c-.546.54-1.016 1.004-1.352 1.423-.349.434-.634.914-.71 1.512l1.984.253c.01-.082.05-.22.285-.513.248-.309.621-.681 1.2-1.255l-1.407-1.42Zm-.078 3.436A.982.982 0 0 1 5 9H3c0 .126.008.252.024.377l1.984-.253ZM5 9c0-.041.003-.083.008-.124l-1.984-.253A2.982 2.982 0 0 0 3 9h2Zm0-1H4v2h1V8Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUndo);
export default ForwardRef;
