import { classes, classNames } from '@nowadays/ui/utils';

import CalendarBase from '../../calendar-base/CalendarBase';
import Calendar from '../Calendar';
import { CalendarMonthsProps } from './CalendarMonths.types';

const CalendarMonths: React.FC<CalendarMonthsProps> = ({
  onClick,
  className,
  ...props
}) => {
  return (
    <tbody>
      <CalendarBase.Months>
        {({ monthChunks }) => {
          return monthChunks.map((monthChunk, key) => (
            <tr key={key}>
              {monthChunk.map((month, key) => (
                <td key={key} className={styles.td}>
                  <Calendar.Month
                    month={month}
                    onClick={(e) => onClick && onClick(e, month)}
                    color='muted'
                    className={({ isThisMonth, isSelected }) =>
                      classNames(
                        styles.month,
                        isThisMonth && styles.thisMonth,
                        typeof className === 'function'
                          ? className({ isThisMonth, isSelected })
                          : className,
                      )
                    }
                    {...props}
                  />
                </td>
              ))}
            </tr>
          ));
        }}
      </CalendarBase.Months>
    </tbody>
  );
};

const styles = {
  td: classes('text-center', 'px-1'),
  month: classes('w-full'),
  thisMonth: classes('text-skin-red', 'font-semibold'),
};

export default CalendarMonths;
