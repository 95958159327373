import CalendarBase from '../../calendar-base/CalendarBase';
import Calendar from '../Calendar';
import { CalendarWeeksProps } from './CalendarWeeks.types';

const CalendarWeeks: React.FC<CalendarWeeksProps> = ({ children }) => {
  return (
    <tbody>
      <CalendarBase.Weeks>
        {({ week, days }) =>
          children ? (
            <tr key={week.toString()}>{children({ week, days })}</tr>
          ) : (
            <tr key={week.toString()}>
              <Calendar.Week week={week} />

              {days.map((day) => (
                <Calendar.Day key={day.toString()} day={day} />
              ))}
            </tr>
          )
        }
      </CalendarBase.Weeks>
    </tbody>
  );
};

export default CalendarWeeks;
