'use client';

import { Table } from '@tanstack/react-table';
import { createContext, useContext } from 'react';

import { TableData } from '../Table.types';

interface TableContextProps<T extends TableData> {
  table?: Table<T>;
  filter?: string | number;
  sortable?: keyof T;
  changeFilter?: (keyword: string | number) => void;
}

export const TableContext = createContext<TableContextProps<Any>>({});

export const useTable = <T extends TableData>() => {
  return useContext(TableContext) as TableContextProps<T>;
};
