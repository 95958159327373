import {
  ScheduleTableDatum,
  ScheduleTableDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const scheduleTableDatum = (builder: Builder) =>
  builder.query<ScheduleTableDatum, ScheduleTableDatumParams>({
    query: (params) => ({ url: '/datum/schedule-table-datum', params }),
  });
