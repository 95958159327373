'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';

import Button from '../../../button/button/Button';
import { ButtonProps } from '../../../button/button/Button.types';

const CaptionRefresh: React.FC<ButtonProps> = ({ ...props }) => {
  const translate = useTranslate();

  return (
    <Button
      color='accent'
      variant='contained'
      startIcon={{ name: 'Sync' }}
      {...props}
    >
      {translate('ui.components.table.caption.refresh.text')}
    </Button>
  );
};

export default CaptionRefresh;
