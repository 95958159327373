import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgBringForward = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='m16.286 11.5 3.986 2.093a1 1 0 0 1-.017 1.78l-7.36 3.68a2 2 0 0 1-1.79 0l-7.36-3.68a1 1 0 0 1-.017-1.78L7.714 11.5m4.286 2v-8m0-.5 4 2m-4-2L8 7'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBringForward);
export default ForwardRef;
