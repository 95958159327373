'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';
import React, { useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { IconButton } from '../../button';
import { useSnackbar } from '../../snackbar/snackbar-context/SnackbarContext';
import Input from '../input/Input';
import DropZone from './drop-zone/DropZone';
import { ImagePickerProps } from './ImagePicker.types';

const ImagePicker: React.FC<ImagePickerProps> = (
  {
    input,
    value,
    className,
    disabled,
    children,
    multiple,
    maxSize = 16,
    maxFiles,
    info,
    img,
    nullable = true,
    onBlur,
    onDrop,
    onClear,
    ...props
  },
  ref: React.Ref<HTMLInputElement>,
) => {
  const translate = useTranslate();
  const { addMessage } = useSnackbar();
  const { disable, ...inputProps } = input || {};
  const [loaded, setLoaded] = useState<boolean>(false);
  const [preview, setPreview] = useState<string | undefined>(
    typeof value !== 'object' && value,
  );

  useEffect(() => {
    if (value && typeof value === 'string') {
      setPreview(value);
    }

    if (value && typeof value === 'object') {
      setPreview(URL.createObjectURL(value));
    }

    if (!value) {
      setPreview(null);
    }
  }, [value]);

  useEffect(() => {
    if (!preview) {
      return;
    }

    return () => URL.revokeObjectURL(preview);
  }, [preview]);

  const isFilesExceeded = (files: File[]) => {
    if (files.length > maxFiles) {
      addMessage({
        item: translate('ui.components.inputs.file.maxFiles', {
          max: maxFiles,
        }),
        color: 'red',
      });

      return true;
    }
  };

  const isMaxSizeExceeded = (files: File[]) => {
    const maxInMb = maxSize * 1024 * 1024;

    const isBigger = multiple
      ? files.find((i) => i.size > maxInMb)
      : files[0].size > maxInMb;

    if (isBigger) {
      addMessage({
        item: translate('ui.components.inputs.file.maxSize', {
          size: maxSize,
        }),
        color: 'red',
      });

      return true;
    }
  };

  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setLoaded(true);
    img?.onLoad && img?.onLoad(e);
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (preview) {
      setPreview(undefined);
      URL.revokeObjectURL(preview);
      onClear && onClear();
    }
  };

  const handleDrop: DropzoneOptions['onDrop'] = (event, ...rest) => {
    const files = event as File[];

    if (isFilesExceeded(files) || isMaxSizeExceeded(files)) {
      return;
    }

    if (!multiple) {
      setPreview(URL.createObjectURL(files[0]));
    }

    onDrop && onDrop(files, ...rest);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    disabled,
    onDrop: handleDrop,
    ...props,
  });

  return (
    <Input disable={disabled || disable} {...inputProps}>
      {({
        backgroundStyles,
        focusStyles,
        borderStyles,
        errorStyles,
        disabledStyles,
        customStyles,
      }) => (
        <div
          {...getRootProps()}
          onBlur={onBlur}
          className={classNames(
            styles.root,
            backgroundStyles,
            focusStyles,
            borderStyles,
            errorStyles,
            disabledStyles,
            customStyles,
            className,
            isDragActive && styles.active,
            preview && !loaded && styles.loading,
          )}
        >
          <input ref={ref} {...getInputProps()} />

          {preview && nullable && loaded && (
            <IconButton
              name='Trash'
              color='red'
              variant='contained'
              className={styles.clear}
              onClick={handleClear}
            />
          )}

          {multiple &&
            (children || <DropZone info={info} multiple={multiple} />)}

          {!multiple &&
            (preview ? (
              <img src={preview} onLoad={handleLoad} {...img} />
            ) : (
              children || <DropZone info={info} multiple={multiple} />
            ))}
        </div>
      )}
    </Input>
  );
};

const styles = {
  root: classes(
    'relative',
    'flex',
    'items-center',
    'justify-center',
    'cursor-pointer',
    'overflow-hidden',
    'w-full ',
  ),
  active: classes('border-2', 'border-skin-accent'),
  loading: classes('bg-skin-base', 'animate-pulse'),
  clear: classes('absolute', 'right-0.5', 'top-0.5', 'p-0.5'),
};

export default React.forwardRef(ImagePicker);
