'use client';

import { classes } from '@nowadays/ui/utils';

const NotFoundMagnify = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={styles.root}
      fill='none'
      viewBox='0 0 400 400'
    >
      <path
        fill='currentColor'
        className='text-skin-primary'
        d='M128 368c-65.17 0-118-10.743-118-24 0-13.25 52.83-24 118-24 65.169 0 118 10.75 118 24 0 13.257-52.831 24-118 24zM343.347 377.367c-20.751 0-37.571-3.139-37.571-7.011 0-3.872 16.82-7.011 37.571-7.011 20.75 0 37.57 3.139 37.57 7.011 0 3.872-16.82 7.011-37.57 7.011z'
      ></path>
      <path
        fill='currentColor'
        className='text-skin-accent'
        d='M240.683 67.007v246.779c0 5.828-2.316 11.42-6.437 15.544a21.988 21.988 0 01-15.543 6.436H40.979a21.986 21.986 0 01-15.542-6.436A21.99 21.99 0 0119 313.786V43.977A21.976 21.976 0 0140.979 22h153.095a6.377 6.377 0 014.44 1.795l40.461 39.178a5.714 5.714 0 011.264 1.845c.292.692.444 1.438.444 2.189z'
      ></path>
      <path
        fill='#BB4C02'
        d='M240.683 66.91h-21.698a23.874 23.874 0 01-16.59-6.688 23.88 23.88 0 01-7.284-16.335L194.344 22c1.493 0 2.928.578 3.999 1.618l40.704 39.408a5.41 5.41 0 011.636 3.885zM205.631 99.569H41.357a4.107 4.107 0 100 8.213H205.63a4.106 4.106 0 100-8.213zM179.105 124.21H41.725a4.476 4.476 0 100 8.952h137.38a4.475 4.475 0 004.477-4.477 4.475 4.475 0 00-4.477-4.475zM179.105 149.588H41.725a4.477 4.477 0 00-4.475 4.477 4.474 4.474 0 004.475 4.474h137.38a4.476 4.476 0 100-8.951zM126.411 175.136H41.726a4.477 4.477 0 000 8.954h84.685a4.478 4.478 0 000-8.954zM126.411 200.519H41.726a4.475 4.475 0 100 8.951h84.685a4.475 4.475 0 100-8.951zM126.411 225.899H41.726a4.476 4.476 0 100 8.952h84.685a4.476 4.476 0 000-8.952zM205.631 249.376H41.357a4.106 4.106 0 100 8.213H205.63a4.108 4.108 0 100-8.213zM179.105 274.017H41.725a4.475 4.475 0 100 8.952h137.38a4.477 4.477 0 000-8.952zM212.667 299.394H42.785c-3.057 0-5.535 2.006-5.535 4.478 0 2.471 2.478 4.474 5.535 4.474h169.882c3.058 0 5.536-2.003 5.536-4.474 0-2.472-2.478-4.478-5.536-4.478z'
      ></path>
      <path
        fill='currentColor'
        className='text-skin-primary'
        d='M270.829 276.818l-9.457 9.458 22.306 22.309 9.461-9.458-22.31-22.309z'
      ></path>
      <path
        fill='#BB4C02'
        d='M203.277 309.88c52.683 0 95.395-42.709 95.395-95.395 0-52.685-42.712-95.398-95.395-95.398-52.686 0-95.397 42.713-95.397 95.398 0 52.686 42.711 95.395 95.397 95.395z'
      ></path>
      <path
        fill='currentColor'
        className='text-skin-accent'
        d='M203.274 295.389c44.684 0 80.907-36.223 80.907-80.904 0-44.684-36.223-80.904-80.907-80.904-44.681 0-80.904 36.22-80.904 80.904 0 44.681 36.223 80.904 80.904 80.904z'
      ></path>
      <path
        fill='#BB4C02'
        d='M284.116 298.64l-.003.003c-5.043 5.039-5.043 13.215 0 18.257l50.118 50.118c5.042 5.04 13.214 5.04 18.254 0l.006-.003c5.039-5.042 5.039-13.218 0-18.257l-50.118-50.118c-5.042-5.043-13.218-5.043-18.257 0z'
      ></path>
    </svg>
  );
};

const styles = {
  root: classes('h-52', 'w-52', 'sm:h-72', 'sm:w-72'),
};

export default NotFoundMagnify;
