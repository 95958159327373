import { FavoritedWidget, FavoriteWidgetParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { widgetEndpoints } from './Widget.endpoints';

export const favoriteWidget = (builder: Builder) =>
  builder.mutation<FavoritedWidget, FavoriteWidgetParams>({
    query: (params) => ({
      url: '/widget/favorite',
      method: 'POST',
      params,
    }),
    async onQueryStarted({ widget, planner }, { dispatch, queryFulfilled }) {
      try {
        const { data: favorite } = await queryFulfilled;

        dispatch(
          widgetEndpoints.util.updateQueryData(
            'widgets',
            { planner },
            (draft) => {
              draft.forEach((i) => {
                i.widgets.forEach((e) => {
                  if (e.id === widget) {
                    e.favorite = favorite;
                  }
                });
              });
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
