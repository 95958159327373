import { classes } from '@nowadays/ui/utils';

export const textStyles = {
  base: {
    text: classes('text-skin-primary'),
    flat: classes('text-skin-primary'),
    outlined: classes('text-skin-primary'),
    contained: classes('text-skin-primary'),
  },
  primary: {
    text: classes('text-skin-primary'),
    flat: classes('text-skin-primary'),
    outlined: classes('text-skin-primary'),
    contained: classes('text-skin-inverted'),
  },
  inverted: {
    text: classes('text-skin-inverted'),
    flat: classes('text-skin-inverted'),
    outlined: classes('text-skin-inverted'),
    contained: classes('text-skin-primary'),
  },
  muted: {
    text: classes('text-skin-muted'),
    flat: classes('text-skin-muted'),
    outlined: classes('text-skin-muted'),
    contained: classes('text-skin-primary'),
  },
  silent: {
    text: classes('text-skin-silent'),
    flat: classes('text-skin-silent'),
    outlined: classes('text-skin-silent'),
    contained: classes('text-skin-primary'),
  },
  accent: {
    text: classes('text-skin-accent'),
    flat: classes('text-skin-accent'),
    outlined: classes('text-skin-accent'),
    contained: classes('text-skin-light'),
  },
  green: {
    text: classes('text-skin-green'),
    flat: classes('text-skin-green'),
    outlined: classes('text-skin-green'),
    contained: classes('text-skin-light'),
  },
  red: {
    text: classes('text-skin-red'),
    flat: classes('text-skin-red'),
    outlined: classes('text-skin-red'),
    contained: classes('text-skin-light'),
  },
  blue: {
    text: classes('text-skin-blue'),
    flat: classes('text-skin-blue'),
    outlined: classes('text-skin-blue'),
    contained: classes('text-skin-light'),
  },
  orange: {
    text: classes('text-skin-orange'),
    flat: classes('text-skin-orange'),
    outlined: classes('text-skin-orange'),
    contained: classes('text-skin-light'),
  },
};
