import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';

const RichTextUndo = () => {
  const { editor } = useRichText();

  if (!editor) {
    return null;
  }

  return (
    <RichTextToolbarButton
      name='Undo'
      onClick={() => editor.chain().focus().undo().run()}
      disabled={!editor.can().chain().focus().undo().run()}
    />
  );
};

export default RichTextUndo;
