import { UpdateArticle, UpdateArticleParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const updateArticle = (builder: Builder) =>
  builder.mutation<void, UpdateArticleParams & UpdateArticle>({
    query: (body) => ({
      url: `/article`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (res, err, { id }) =>
      (!err && ['Articles', { type: 'Articles', id }]) || [],
  });
