'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { FormTitleProps } from './FormTitle.types';

const FormTitle: React.FC<FormTitleProps> = ({
  title,
  className,
  children,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      {title && <span className={styles.title}>{title}</span>}
      {children && children}
    </div>
  );
};

const styles = {
  root: classes('flex', 'pl-1'),
  title: classes('font-medium', 'text-skin-accent'),
};

export default FormTitle;
