'use client';

import { useCallback, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { CAPTCHA } from './CAPTCHA.types';

export const useCAPTCHA = (args?: CAPTCHA) => {
  const { onBackgroundClicked } = args || {};
  const captchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    const domObserver = new MutationObserver(() => {
      const iframe = document.querySelector(
        'iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]',
      );

      if (iframe) {
        domObserver.disconnect();

        const captchaBackground = iframe.parentNode?.parentNode?.firstChild;
        captchaBackground?.addEventListener('click', () => {
          onBackgroundClicked && onBackgroundClicked();
        });
      }
    });

    domObserver.observe(document.documentElement || document.body, {
      childList: true,
      subtree: true,
    });
  }, [onBackgroundClicked]);

  const getToken = useCallback(async () => {
    if (captchaRef.current) {
      try {
        const token = await captchaRef.current.executeAsync();

        captchaRef.current?.reset();

        if (token) {
          return token;
        } else {
          throw new Error('Captcha error');
        }
      } catch {
        throw new Error('Captcha error');
      }
    }
  }, [captchaRef.current]);

  return {
    getToken,
    captchaRef,
  };
};
