import { baseApi } from '@nowadays/ui/services';
import { configureStore } from '@reduxjs/toolkit';

import { queryErrorMiddleware } from '../middleware/QueryError';
import {
  appRuducer,
  datebarReducer,
  elementReducer,
  languageReducer,
  pageReducer,
  plannerReducer,
  sheetReducer,
  stickerReducer,
  tabReducer,
  templateReducer,
  widgetReducer,
} from '../slices';
import { aiReducer } from '../slices/ai';
import { drawReducer } from '../slices/draw';

export const store = configureStore({
  reducer: {
    ai: aiReducer,
    app: appRuducer,
    language: languageReducer,
    datebar: datebarReducer,
    planner: plannerReducer,
    sheet: sheetReducer,
    page: pageReducer,
    tab: tabReducer,
    element: elementReducer,
    template: templateReducer,
    widget: widgetReducer,
    sticker: stickerReducer,
    draw: drawReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware)
      .concat(queryErrorMiddleware),
});
