import { AnchorButton, BrandLogoText } from '@nowadays/ui/components';
import { classes } from '@nowadays/ui/utils';
import { useNavigate } from 'react-router-dom';

const SidebarHeader = () => {
  const navigate = useNavigate();

  const handleClick = () => navigate('/');

  return (
    <AnchorButton onClick={handleClick} className={styles.button}>
      <BrandLogoText className={styles.brand} />
    </AnchorButton>
  );
};

const styles = {
  button: classes('justify-start'),
  brand: classes('h-8'),
};

export default SidebarHeader;
