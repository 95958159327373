import { DatedPageParams, Page } from '@nowadays/base/types';
import { parseISO } from 'date-fns';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const datedPage = (builder: Builder) =>
  builder.query<Page | null, DatedPageParams>({
    query: (params) => ({
      url: '/page/dated',
      params,
    }),
    async onQueryStarted({ planner, period }, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        if (data) {
          await dispatch(
            pageEndpoints.util.upsertQueryData(
              'page',
              {
                page: data.id,
              },
              data,
            ),
          );

          dispatch(
            pageEndpoints.util.updateQueryData(
              'pages',
              { planner, period },
              (draft) => {
                const founded = draft.find((i) => i.id === data.id);

                if (!founded) {
                  const newDate = parseISO(data.date);

                  const index = draft.findIndex(
                    (i) => newDate < parseISO(i.date),
                  );

                  if (index === -1) {
                    draft.push(data);
                  } else {
                    draft.splice(index, 0, data);
                  }
                }
              },
            ),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
