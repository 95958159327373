'use client';

import { ListboxOption as HeadlessListboxOption } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import { TValues } from '../Listbox.types';
import { ListboxOptionProps } from './ListboxOption.types';

const ListboxOption = <T extends TValues>({
  option,
  className,
  children,
  onKeyDown,
  ...props
}: ListboxOptionProps<T>) => {
  return (
    <HeadlessListboxOption
      value={option}
      disabled={option.disabled}
      className={({ focus, selected, disabled, selectedOption }) =>
        classNames(
          styles.root,
          focus && styles.focus,
          selected && styles.selected,
          disabled && styles.disabled,
          className,
        )
      }
    >
      {(props) => (
        <Fragment>
          {typeof children === 'function' ? children(props) : children}
        </Fragment>
      )}
    </HeadlessListboxOption>
  );
};

const styles = {
  root: classes('py-2', 'cursor-pointer'),
  disabled: classes('cursor-default', 'opacity-70'),
  focus: classes('bg-skin-base'),
  selected: classes('bg-skin-silent'),
};

export default ListboxOption;
