'use client';

import { useElementRef } from '@nowadays/ui/hooks';
import { classes, classNames } from '@nowadays/ui/utils';
import React, { Fragment, useState } from 'react';

import Divider from '../../divider/Divider';
import Input from '../input/Input';
import { ListboxProps, TValues } from './Listbox.types';
import ListboxBase from './listbox-base/ListboxBase';

const Listbox = <T extends TValues>(
  {
    options = [],
    nullable,
    multiple,
    value,
    onChange,
    disabled,
    input,
    seperator,
    placeholder,
    hideTick,
    hideIcon,
    className,
    panel,
    ...props
  }: ListboxProps<T>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const isEmpty = options.length === 0;

  const { disable, ...inputProps } = input || {};

  const [inputNode, setInputNode] = useState<HTMLElement | null>(null);

  const isAllSelected =
    multiple &&
    value?.length === options.filter((item) => !item.disabled)?.length;

  const handleClear = () => {
    multiple && onChange && onChange([]);
    !multiple && nullable && onChange && onChange(null);
    inputNode?.click();
  };

  const handleSelectAll = () =>
    multiple && onChange && onChange(options.filter((item) => !item.disabled));

  const inputRef = useElementRef((node: HTMLDivElement) => {
    setInputNode(node);

    if (ref) {
      if (typeof ref === 'function') {
        ref(node);
      } else {
        (ref as React.MutableRefObject<HTMLDivElement>).current = node;
      }
    }
  });

  return (
    <ListboxBase.Base
      onChange={onChange}
      disabled={disabled}
      value={value || (multiple ? [] : null)}
      multiple={multiple as never}
    >
      {({ open }) => (
        <Fragment>
          <Input disable={disabled || disable} {...inputProps}>
            {({
              inputStyles,
              focusStyles,
              backgroundStyles,
              borderStyles,
              errorStyles,
              adornmentStyles,
              disabledStyles,
              customStyles,
              boxProps,
            }) => (
              <ListboxBase.Button as={Fragment}>
                <Input.Box
                  ref={inputRef}
                  tabIndex={0}
                  end={
                    <ListboxBase.Adornment
                      disabled={disabled}
                      className={adornmentStyles}
                    />
                  }
                  className={classNames(
                    backgroundStyles,
                    borderStyles,
                    errorStyles,
                    focusStyles,
                    disabledStyles,
                    customStyles,
                    styles.end,
                  )}
                  {...boxProps}
                >
                  <ListboxBase.Selection
                    selected={value}
                    disabled={disabled}
                    seperator={seperator}
                    placeholder={placeholder}
                    className={classNames(inputStyles, className)}
                    {...props}
                  />
                </Input.Box>
              </ListboxBase.Button>
            )}
          </Input>

          <ListboxBase.Panel reference={inputNode} open={open} {...panel}>
            {isEmpty && <ListboxBase.NotFound />}

            {!isEmpty && !multiple && nullable && (
              <Fragment>
                <ListboxBase.Clear
                  multiple={multiple}
                  onClear={handleClear}
                  disabled={!value}
                />
                <Divider />
              </Fragment>
            )}

            {!isEmpty && multiple && nullable && (
              <Fragment>
                {isAllSelected ? (
                  <ListboxBase.Clear
                    multiple={multiple}
                    onClear={handleClear}
                    disabled={!value || value.length === 0}
                  />
                ) : (
                  <ListboxBase.SelectAll
                    handleSelectAll={handleSelectAll}
                    disabled={options?.length === 0}
                  />
                )}
                <Divider />
              </Fragment>
            )}

            <ListboxBase.Options
              options={options}
              hideIcon={hideIcon}
              hideTick={hideTick}
            />
          </ListboxBase.Panel>
        </Fragment>
      )}
    </ListboxBase.Base>
  );
};

const styles = {
  end: classes('pr-8'),
};

export default Object.assign(React.forwardRef(Listbox), ListboxBase);
