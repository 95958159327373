import { AddTabParams, Tab } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { tabEndpoints } from './Tab.endpoints';

export const addTab = (builder: Builder) =>
  builder.mutation<Tab, AddTabParams>({
    query: ({ page, ...body }) => ({
      url: `/tab/add/${page}`,
      method: 'POST',
      body,
    }),
    async onQueryStarted({ page }, { dispatch, queryFulfilled }) {
      try {
        const { data: addedTab } = await queryFulfilled;

        dispatch(
          tabEndpoints.util.updateQueryData('tabs', { page }, (draft) => {
            draft.tabs.push(addedTab);
            draft.tab = addedTab;
          }),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
