import { CurrentUser, LoginParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const login = (builder: Builder) =>
  builder.mutation<CurrentUser, LoginParams>({
    query: (body) => ({
      url: '/auth/login-local',
      method: 'POST',
      body,
    }),
  });
