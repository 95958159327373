'use client';

import { classes } from '@nowadays/ui/utils';

import Spinner from '../../indicator/spinner/Spinner';
import { FormLoadingProps } from './FormLoading.types';

const FormLoading: React.FC<FormLoadingProps> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
};

const styles = {
  root: classes(
    'w-full',
    'h-full',
    'bg-skin-primary',
    'bg-opacity-50',
    'absolute',
    'top-0',
    'bottom-0',
    'grid',
    'place-content-center',
  ),
};

export default FormLoading;
