'use client';

import PopoverBase from '../popover-base/PopoverBase';

export default Object.assign(PopoverBase, {
  Group: PopoverBase.Group,
  Button: PopoverBase.Button,
  Panel: PopoverBase.Panel,
  Backdrop: PopoverBase.Backdrop,
});
