import { Language } from '@nowadays/base/locales';

import { HourFormat, WeekStartsOn } from '../../types/Datetime.types';
import { Rem, Theme } from '../../types/Interface.types';

export enum UserRole {
  Admin = 'ADMIN',
  Regular = 'REGULAR',
  Premium = 'PREMIUM',
}

export enum UserActivityType {
  Auth = 'auth',
  Signup = 'signup',
  Login = 'login',
  Logout = 'logout',
}

export interface UserSettings {
  language?: Language;
  rem?: Rem;
  theme?: Theme;
  timeZone?: string;
  currency?: string;
  weekStartsOn?: WeekStartsOn;
  hourFormat?: HourFormat;
  dayStartsAt?: number;
  dayEndsAt?: number;
}

export interface UserPreferences {
  announcements?: boolean;
}

export interface User {
  id: string;
  email: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isVerified: boolean;
  createdAt: string;
  lastLoginAt: string;
}

export type UserParams = Pick<User, 'id'>;
