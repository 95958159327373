'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { TablePanelProps } from './TablePanel.types';

const TablePanel: React.FC<TablePanelProps> = ({ children, className }) => {
  return (
    <table className={classNames(styles.root, className)}>{children}</table>
  );
};

const styles = {
  root: classes('w-full'),
};

export default TablePanel;
