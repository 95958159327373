import { baseApi } from '../base/query/Api';
import { addPresetPlanner } from './AddPresetPlanner.api';
import { deletePresetPlanner } from './DeletePresetPlanner.api';
import { presetPlanner } from './PresetPlanner.api';
import { presetPlanners } from './PresetPlanners.api';
import { reorderPresetPlanners } from './ReorderPresetPlanners.api';
import { updatePresetPlanner } from './UpdatePresetPlanner.api';
import { usePresetPlanner } from './UsePresetPlanner.api';

export const presetPlannerEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    presetPlanners: presetPlanners(builder),
    presetPlanner: presetPlanner(builder),
    addPresetPlanner: addPresetPlanner(builder),
    updatePresetPlanner: updatePresetPlanner(builder),
    deletePresetPlanner: deletePresetPlanner(builder),
    usePresetPlanner: usePresetPlanner(builder),
    reorderPresetPlanners: reorderPresetPlanners(builder),
  }),
});

export const {
  usePresetPlannersQuery,
  usePresetPlannerQuery,
  useLazyPresetPlannerQuery,
  useAddPresetPlannerMutation,
  useUpdatePresetPlannerMutation,
  useDeletePresetPlannerMutation,
  useUsePresetPlannerMutation,
  useReorderPresetPlannersMutation,
} = presetPlannerEndpoints;
