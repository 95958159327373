import {
  HabitSummaryDatum,
  HabitSummaryDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const habitSummaryDatum = (builder: Builder) =>
  builder.query<HabitSummaryDatum, HabitSummaryDatumParams>({
    query: (params) => ({ url: '/datum/habit-summary-datum', params }),
    providesTags: ['HabitSummary'],
  });
