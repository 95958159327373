import { useCallback, useMemo } from 'react';

import { Settings } from '../RegionContext';

export const useNumberFunctions = (settings: Settings) => {
  const formatNumber = useCallback(
    (value: number, options?: Intl.NumberFormatOptions) => {
      const { language } = settings;

      return new Intl.NumberFormat(language, {
        notation: 'compact',
        compactDisplay: 'short',
        ...options,
      }).format(value);
    },
    [settings],
  );

  const formatPercent = useCallback(
    (value: number, hideZero?: boolean) => {
      if (hideZero && !value) {
        return '';
      }
      const { language } = settings;

      return new Intl.NumberFormat(language, {
        style: 'percent',
      }).format(value / 100);
    },
    [settings],
  );

  const formatAmount = useCallback(
    (amount: number, options?: Intl.NumberFormatOptions) => {
      const { language, currency } = settings;

      return new Intl.NumberFormat(language, {
        currency,
        style: 'currency',
        notation: 'compact',
        compactDisplay: 'short',
        currencyDisplay: 'narrowSymbol',
        ...options,
      }).format(amount);
    },
    [settings],
  );

  const currencySymbol = useMemo(() => {
    const { language, currency } = settings;

    const formatter = new Intl.NumberFormat(language, {
      currency,
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
    });

    const parts = formatter.formatToParts(1);

    const symbol = parts.find((part) => part.type === 'currency')?.value || '';

    return symbol;
  }, [settings]);

  return { formatNumber, formatPercent, formatAmount, currencySymbol };
};
