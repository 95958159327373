import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { AppState, NetworkErrorType, NetworkStatus } from './App.slice.types';

const initialState: AppState = {
  status: 'online',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeNetworkStatus: (state, { payload }: PayloadAction<NetworkStatus>) => {
      state.status = payload;
    },
    changeNetworkError: (
      state,
      { payload }: PayloadAction<NetworkErrorType | undefined>,
    ) => {
      state.networkError = payload;
    },
    changeSyncing: (state, { payload }: PayloadAction<boolean>) => {
      state.syncing = payload;
    },
  },
});

export const appRuducer = appSlice.reducer;

export const useAppActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(appSlice.actions, dispatch),
    [dispatch],
  );
};
