import {
  Listbox as HeadlessListbox,
  ListboxButton as HeadlessListboxButton,
} from '@headlessui/react';

import ListboxAdornment from '../listbox-adornment/ListboxAdornment';
import ListboxClear from '../listbox-clear/ListboxClear';
import ListboxNotFound from '../listbox-not-found/ListboxNotFound';
import ListboxOption from '../listbox-option/ListboxOption';
import ListboxOptions from '../listbox-options/ListboxOptions';
import ListboxPanel from '../listbox-panel/ListboxPanel';
import ListboxSelectAll from '../listbox-select-all/ListboxSelectAll';
import ListboxSelection from '../listbox-selection/ListboxSelection';

export default {
  Base: HeadlessListbox,
  Button: HeadlessListboxButton,
  Panel: ListboxPanel,
  Options: ListboxOptions,
  Option: ListboxOption,
  Clear: ListboxClear,
  SelectAll: ListboxSelectAll,
  Adornment: ListboxAdornment,
  Selection: ListboxSelection,
  NotFound: ListboxNotFound,
};
