import { DeletePlannerImageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deletePlannerImage = (builder: Builder) =>
  builder.mutation<void, DeletePlannerImageParams>({
    query: ({ id }) => ({
      url: `/planner-image/delete/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['PlannerImages']) || [],
  });
