'use client';

import { DragOverlay } from '@dnd-kit/core';
import { classes, classNames } from '@nowadays/ui/utils';
import ReactDOM from 'react-dom';

import { SortableOverlayProps } from './SortableOverlay.types';

const SortableOverlay: React.FC<SortableOverlayProps> = ({
  portal,
  children,
  className,
  zIndex = 1800,
}) => {
  const overlay = (
    <DragOverlay
      zIndex={zIndex}
      className={classNames(styles.root, className)}
      dropAnimation={null}
    >
      {children}
    </DragOverlay>
  );

  return portal ? ReactDOM.createPortal(overlay, document.body) : overlay;
};

const styles = {
  root: classes('opacity-80', 'cursor-grabbing'),
};

export default SortableOverlay;
