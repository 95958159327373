import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgBottle = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='m14 6 2.6 3.467a2 2 0 0 1 .4 1.2V19a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-8.333a2 2 0 0 1 .4-1.2L10 6m-1-.5v-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBottle);
export default ForwardRef;
