import {
  ContentPlannerDatum,
  ContentPlannerDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const contentPlannerDatum = (builder: Builder) =>
  builder.query<ContentPlannerDatum, ContentPlannerDatumParams>({
    query: (params) => ({ url: '/datum/content-planner-datum', params }),
  });
