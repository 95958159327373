import { AddReport } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addReport = (builder: Builder) =>
  builder.mutation<void, AddReport>({
    query: (body) => ({
      url: '/report/add',
      method: 'POST',
      body,
    }),
  });
