'use client';

import { FieldErrorMessage } from '@nowadays/base/types';
import { useTranslate } from '@nowadays/ui/i18n/client';
import { FieldValues, useForm } from 'react-hook-form';

import { fieldErrorMapper } from '../field-error/useFieldError';
import { yupResolver } from '../field-error/YupResolver';
import { FormHookProps } from './FormHook.types';

export const useFormHook = <T extends FieldValues>(
  args?: FormHookProps<T> | undefined,
) => {
  const { schema, ...props } = args || {};
  const translate = useTranslate();
  const resolver = schema && yupResolver(schema);

  const hook = useForm<T>({
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver,
    ...props,
  });

  const setFieldErrors = (errors: FieldErrorMessage[]) =>
    fieldErrorMapper<T>({ translate, setError: hook.setError })(errors);

  return { hook, setFieldErrors, ...hook };
};
