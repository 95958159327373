import {
  PriorityListDatum,
  PriorityListDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const priorityListDatum = (builder: Builder) =>
  builder.query<PriorityListDatum, PriorityListDatumParams>({
    query: (params) => ({ url: '/datum/priority-list-datum', params }),
  });
