'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import Spinner from '../../../indicator/spinner/Spinner';
import { ComboboxLoadingProps } from './ComboboxLoading.types';

const ComboboxLoading: React.FC<ComboboxLoadingProps> = ({ className }) => {
  const translate = useTranslate();

  return (
    <div className={classNames(styles.root, className)}>
      <Spinner className={styles.spinner} />
      <span>{translate('ui.components.inputs.combobox.loading')}</span>
    </div>
  );
};

const styles = {
  root: classes(
    'px-2',
    'py-3',
    'text-skin-muted',
    'flex',
    'items-center',
    'space-x-3',
  ),
  spinner: classes('w-4', 'h-4'),
};

export default ComboboxLoading;
