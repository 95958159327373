import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import Feedback from '../../../feedback/Feedback';
import { useImageSlider } from '../image-slider-context/ImageSliderContext';

const ImageSliderNotFound = () => {
  const translate = useTranslate();
  const { aspectRatio } = useImageSlider();

  return (
    <div className={classNames(styles.root, aspectRatio)}>
      <Feedback
        color='muted'
        title={translate('ui.components.imageSlider.notFound')}
        border={false}
        className={styles.feedback}
        icon={{ name: 'Image', className: styles.icon }}
      />
    </div>
  );
};

const styles = {
  root: classes('w-full', 'flex', 'items-center', 'justify-center'),
  icon: classes('w-7', 'h-7'),
  feedback: classes(
    'text-md',
    'bg-transparent',
    'rounded-none',
    'items-center',
    'justify-center',
  ),
};

export default ImageSliderNotFound;
