import { DuplicateTabParams, Tab } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { tabEndpoints } from './Tab.endpoints';

export const duplicateTab = (builder: Builder) =>
  builder.mutation<Tab, DuplicateTabParams>({
    query: (params) => ({
      url: `/tab/duplicate`,
      method: 'POST',
      params,
    }),
    async onQueryStarted({ tab, page }, { dispatch, queryFulfilled }) {
      try {
        const { data: duplicatedTab } = await queryFulfilled;

        dispatch(
          tabEndpoints.util.updateQueryData('tabs', { page }, (draft) => {
            draft.tabs.splice(
              draft.tabs.findIndex((i) => i.id === tab) + 1,
              0,
              duplicatedTab,
            );
            draft.tab = duplicatedTab;
          }),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
