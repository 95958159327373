'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import Button from '../../button/button/Button';
import {
  ActionButtonsProps,
  CancelAction,
  ConfirmAction,
} from './ActionButtons.types';

const ActionButtons: React.FC<ActionButtonsProps> = ({
  confirm: customConfirm = {},
  cancel: customCancel = {},
  actions: { classsName } = {},
}) => {
  const translate = useTranslate();

  const confirm: ConfirmAction = { kind: 'ok', ...customConfirm };

  const cancel: CancelAction = { kind: 'cancel', ...customCancel };

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
  ) => {
    if (onClick) {
      onClick.constructor.name === 'AsyncFunction'
        ? await onClick(event)
        : onClick(event);
    }
  };

  if (confirm?.hidden && cancel?.hidden) {
    return null;
  }

  return (
    <div className={classNames(styles.root, classsName)}>
      {!cancel?.hidden && (
        <Button
          color='accent'
          {...cancel}
          text={
            cancel?.text ||
            translate(`ui.components.button.action.cancel.${cancel?.kind}`)
          }
          onClick={async (e) => await handleClick(e, cancel?.onClick)}
        />
      )}

      {!confirm?.hidden && (
        <Button
          type='submit'
          variant='contained'
          color='accent'
          {...confirm}
          text={
            confirm?.text ||
            translate(`ui.components.button.action.confirm.${confirm?.kind}`)
          }
          onClick={async (e) => await handleClick(e, confirm?.onClick)}
        />
      )}
    </div>
  );
};

const styles = {
  root: classes('flex', 'w-full', 'justify-end', 'gap-2'),
};

export default ActionButtons;
