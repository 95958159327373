import { ApplyTemplateParams, Page } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from '../page/Page.endpoints';

export const applyTemplate = (builder: Builder) =>
  builder.mutation<Page, ApplyTemplateParams>({
    query: (params) => ({
      url: '/template/apply',
      method: 'POST',
      params,
    }),
    async onQueryStarted(
      { planner, page },
      { dispatch, queryFulfilled, getState },
    ) {
      try {
        const { data } = await queryFulfilled;

        const activePlanner = (getState() as RootState).planner.activePlanner;
        const activePage = (getState() as RootState).page.activePage;

        if (activePlanner.kind === 'dated') {
          const activeDate = (getState() as RootState).datebar.activeDate;

          dispatch(
            pageEndpoints.util.updateQueryData(
              'pages',
              { planner, period: activeDate.period },
              (draft) => {
                const index = draft.findIndex((i) => i.id === page);

                if (index) {
                  draft[index] = data;
                }
              },
            ),
          );

          dispatch(
            pageEndpoints.util.updateQueryData(
              'datedPage',
              { planner, date: activePage.date, period: activePage.period },
              (draft) => Object.assign(draft, data),
            ),
          );
        }

        if (activePlanner.kind === 'undated') {
          dispatch(
            pageEndpoints.util.updateQueryData(
              'pages',
              { planner },
              (draft) => {
                const index = draft.findIndex((i) => i.id === page);

                if (index) {
                  draft[index] = data;
                }
              },
            ),
          );

          await dispatch(
            pageEndpoints.util.upsertQueryData(
              'undatedPage',
              { planner },
              { page: data },
            ),
          );

          dispatch(
            pageEndpoints.util.updateQueryData(
              'undatedPage',
              { planner },
              (draft) => {
                draft.page = data;
              },
            ),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
