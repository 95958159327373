import { AddPageParams, Page } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const addPage = (builder: Builder) =>
  builder.mutation<Page, AddPageParams>({
    query: ({ planner }) => ({
      url: `/page/add/${planner}`,
      method: 'POST',
    }),
    async onQueryStarted({ planner }, { dispatch, queryFulfilled }) {
      try {
        const { data: addedPage } = await queryFulfilled;

        dispatch(
          pageEndpoints.util.updateQueryData('pages', { planner }, (draft) => {
            draft.push(addedPage);
          }),
        );

        await dispatch(
          pageEndpoints.util.upsertQueryData(
            'page',
            { page: addedPage.id },
            addedPage,
          ),
        );

        await dispatch(
          pageEndpoints.util.upsertQueryData(
            'undatedPage',
            { planner },
            { page: addedPage },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
