import { baseApi } from '../base/query/Api';
import { addArticle } from './AddArticle.api';
import { article } from './Article.api';
import { articles } from './Articles.api';
import { deleteArticle } from './DeleteArticle.api';
import { reorderArticle } from './ReorderArticle.api';
import { updateArticle } from './UpdateArticle.api';

export const articleEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    articles: articles(builder),
    article: article(builder),
    addArticle: addArticle(builder),
    updateArticle: updateArticle(builder),
    deleteArticle: deleteArticle(builder),
    reorderArticle: reorderArticle(builder),
  }),
});

export const {
  useArticlesQuery,
  useLazyArticleQuery,
  useAddArticleMutation,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
  useReorderArticleMutation,
} = articleEndpoints;
