import { classes, classNames } from '@nowadays/ui/utils';

import { Divider } from '../../../../divider';
import RichTextBlockquote from '../rich-text-actions/rich-text-blockquote/RichTextBlockquote';
import RichTextBold from '../rich-text-actions/rich-text-bold/RichTextBold';
import RichTextBulletList from '../rich-text-actions/rich-text-bullet-list/RichTextBulletList';
import RichTextClearMarks from '../rich-text-actions/rich-text-clear-marks/RichTextClearMarks';
import RichTextClearNodes from '../rich-text-actions/rich-text-clear-nodes/RichTextClearNodes';
import RichTextColor from '../rich-text-actions/rich-text-color/RichTextColor';
import RichTextHeading from '../rich-text-actions/rich-text-heading/RichTextHeading';
import RichTextHorizontalRule from '../rich-text-actions/rich-text-horizontal-rule/RichTextHorizontalRule';
import RichTextImage from '../rich-text-actions/rich-text-image/RichTextImage';
import RichTextItalic from '../rich-text-actions/rich-text-italic/RichTextItalic';
import RichTextLink from '../rich-text-actions/rich-text-link/RichTextLink';
import RichTextOrderedList from '../rich-text-actions/rich-text-ordered-list/RichTextOrderedList';
import RichTextParagraph from '../rich-text-actions/rich-text-paragraph/RichTextParagraph';
import RichTextRedo from '../rich-text-actions/rich-text-redo/RichTextRedo';
import RichTextStrike from '../rich-text-actions/rich-text-strike/RichTextStrike';
import RichTextTextAlign from '../rich-text-actions/rich-text-text-align/RichTextTextAlign';
import RichTextUndo from '../rich-text-actions/rich-text-undo/RichTextUndo';
import { RichTextToolbarProps } from './RichTextToolbar.types';

const RichTextToolbar: React.FC<RichTextToolbarProps> = ({
  image,
  className,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      <RichTextUndo />
      <RichTextRedo />
      <Divider direction='vertical' className={styles.divider} />
      <RichTextBold />
      <RichTextItalic />
      <RichTextStrike />
      <RichTextColor />
      <RichTextParagraph />
      <Divider direction='vertical' className={styles.divider} />
      <RichTextTextAlign textAlign='left' />
      <RichTextTextAlign textAlign='center' />
      <RichTextTextAlign textAlign='right' />
      <RichTextTextAlign textAlign='justify' />
      <RichTextHeading />
      {image && <RichTextImage {...image} />}
      <RichTextBulletList />
      <RichTextOrderedList />
      <RichTextBlockquote />
      <RichTextLink />
      <Divider direction='vertical' className={styles.divider} />
      <RichTextHorizontalRule />
      <RichTextClearMarks />
      <RichTextClearNodes />
    </div>
  );
};

const styles = {
  root: classes(
    'pb-2',
    'mb-2',
    'flex',
    'flex-wrap',
    'items-center',
    'gap-1',
    'border-b',
    'border-skin-silent',
  ),
  divider: classes('h-6', 'bg-skin-muted'),
};

export default RichTextToolbar;
