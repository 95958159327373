import { baseApi } from '../base/query/Api';
import { addTab } from './AddTab.api';
import { deletedTabs } from './DeletedTabs.api';
import { deleteTab } from './DeleteTab.api';
import { duplicateTab } from './DuplicateTab.api';
import { permanentlyDeleteTab } from './PermanentlyDeleteTab.api';
import { restoreTab } from './RestoreTab.api';
import { tabs } from './Tabs.api';
import { updateTab } from './UpdateTab.api';

export const tabEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    tabs: tabs(builder),
    addTab: addTab(builder),
    updateTab: updateTab(builder),
    duplicateTab: duplicateTab(builder),
    deletedTabs: deletedTabs(builder),
    deleteTab: deleteTab(builder),
    restoreTab: restoreTab(builder),
    permanentlyDeleteTab: permanentlyDeleteTab(builder),
  }),
});

export const {
  useTabsQuery,
  useAddTabMutation,
  useUpdateTabMutation,
  useDuplicateTabMutation,
  useDeletedTabsQuery,
  useDeleteTabMutation,
  useRestoreTabMutation,
  usePermanentlyDeleteTabMutation,
} = tabEndpoints;
