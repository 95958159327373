export const base = {
  error: {
    array: {
      length: '{{label}} must have {{length}} items',
      max: '{{label}} must have less than or equal to {{max}} items',
      min: '{{label}} must have at least {{min}} items',
    },
    boolean: { isValue: '{{label}} must be {{value}}' },
    custom: {
      alreadyExist: '{{label}} already exists',
      alreadyUsing: "'{{value}}' already using",
      incorrect: '{{label}} is incorrect, please check',
      notMatch: '{{label}} does not match, please check',
      sameValue: '{{label}} must be diffirent than current',
    },
    date: {
      max: '{{label}} must be at earlier than {{max}}',
      min: '{{label}} must be later than {{min}}',
    },
    mixed: {
      default: '{{label}} is invalid',
      defined: '{{label}} must be defined',
      notOneOf: '{{label}} must not be one of the following values: {{values}}',
      notType: '{{label}} is not valid type',
      oneOf: '{{label}} must be one of the following values: {{values}}',
      required: '{{label}} is required',
    },
    number: {
      integer: '{{label}} must be an integer',
      lessThan: '{{label}} must be less than {{less}}',
      max: '{{label}} must be less than or equal to {{max}}',
      min: '{{label}} must be greater than or equal to {{min}}',
      moreThan: '{{label}} must be greater than {{more}}',
      negative: '{{label}} must be a negative number',
      positive: '{{label}} must be a positive number',
    },
    object: {
      noUnknown: '{{label}} cannot have keys not specified in the object shape',
    },
    string: {
      email: '{{label}} must be a valid email',
      length: '{{label}} must be exactly {{length}} characters',
      lowercase: '{{label}} must be a lowercase string',
      matches: "{{label}} must match the following: '{{regex}}'",
      max: '{{label}} must be at most {{max}} characters',
      min: '{{label}} must be at least {{min}} characters',
      trim: '{{label}} must be a trimmed string',
      uppercase: '{{label}} must be a uppercase string',
      url: '{{label}} must be a valid URL',
      uuid: '{{label}} must be a valid UUID',
    },
  },
  language: {
    constant: {
      'en-US': 'English (US)',
      'es-ES': 'Español (ES)',
      'es-LA': 'Español (LA)',
      'fr-FR': 'Français',
      'ja-JP': '日本語',
      'tr-TR': 'Türkçe',
    },
    options: {
      'en-US': 'English (US)',
      'es-ES': 'Spanish (ES)',
      'es-LA': 'Spanish (LA)',
      'fr-FR': 'French',
      'ja-JP': 'Japanese',
      'tr-TR': 'Turkish',
    },
  },
};
