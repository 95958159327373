'use client';

import {
  eachDayOfInterval,
  endOfWeek,
  isSameDay,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { useMemo } from 'react';

import { useRegion } from '../../../region/RegionContext';
import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseDaysProps } from './CalendarBaseDays.types';

const CalendarBaseDays: React.FC<CalendarBaseDaysProps> = ({
  formatStr,
  children,
}) => {
  const today = startOfToday();
  const { currentWeek } = useCalendarBase();
  const { format, formatOptions } = useRegion();

  const days = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(currentWeek, formatOptions),
        end: endOfWeek(currentWeek, formatOptions),
      }),
    [currentWeek, formatOptions],
  );

  return days.map((day) =>
    children({
      day,
      formatted: format(day, formatStr || 'EE'),
      isToday: isSameDay(day, today),
      isStartOfWeek: isSameDay(day, startOfWeek(day, formatOptions)),
      isEndOfWeek: isSameDay(day, endOfWeek(day, formatOptions)),
    }),
  );
};

export default CalendarBaseDays;
