import { CurrentUser, VerifyJwtParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const loginJwt = (builder: Builder) =>
  builder.query<CurrentUser, VerifyJwtParams>({
    query: (params) => ({
      url: '/auth/login-jwt',
      params,
    }),
  });
