'use client';

import { Language, Namespaces } from '@nowadays/base/locales';
import { findLanguage, getI18NOptions } from '@nowadays/ui/i18n/base';
import i18n from 'i18next';
import { useState } from 'react';
import { initReactI18next } from 'react-i18next';

import { I18NContext } from './I18NContext';

interface I18NProviderProps {
  language?: Language;
  namespaces: Namespaces[];
  children: React.ReactNode;
}

const I18NProvider: React.FC<I18NProviderProps> = ({
  language,
  namespaces,
  children,
}) => {
  const active = findLanguage(language);

  const [current, setCurrent] = useState<Language>(active);

  const options = getI18NOptions(current, namespaces);

  i18n.use(initReactI18next).init(options);

  const changeLanguage = (language: Language) => {
    const lang = findLanguage(language);
    setCurrent(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <I18NContext.Provider value={{ language: current, changeLanguage }}>
      {children}
    </I18NContext.Provider>
  );
};

export default I18NProvider;
