'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import Button from '../button/Button';
import { MenuButtonProps } from './MenuButton.types';

const MenuButton: React.FC<MenuButtonProps> = (
  { icon, className, ...props },
  ref: React.Ref<HTMLButtonElement>,
) => {
  return (
    <Button
      ref={ref}
      variant='text'
      color='primary'
      className={classNames(styles.button, className)}
      startIcon={icon && { name: icon, className: styles.icon }}
      spinner={{ className: styles.icon }}
      {...props}
    />
  );
};

const styles = {
  button: classes('w-full', 'justify-start', 'px-1', 'py-1'),
  icon: classes('w-5', 'h-5'),
};

export default React.forwardRef(MenuButton);
