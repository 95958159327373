import {
  WaterIntakeSummaryDatum,
  WaterIntakeSummaryDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const waterIntakeSummaryDatum = (builder: Builder) =>
  builder.query<WaterIntakeSummaryDatum, WaterIntakeSummaryDatumParams>({
    query: (params) => ({ url: '/datum/water-intake-summary-datum', params }),
    providesTags: ['WaterIntakeSummary'],
  });
