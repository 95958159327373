import { StickerPackItem, StickerPackItemParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const stickerPackItem = (builder: Builder) =>
  builder.query<StickerPackItem, StickerPackItemParams>({
    query: ({ sticker }) => `/sticker-pack-item/item/${sticker}`,
    providesTags: (res, err, { sticker }) => [
      { type: 'StickerPackItems', id: sticker },
    ],
  });
