import { baseApi } from '../base/query/Api';
import { applyTemplate } from './ApplyTemplate.api';
import { duplicateTemplate } from './DuplicateTemplate.api';
import { permanentlyDeleteTemplate } from './PermanentlyDeleteTemplate.api';
import { saveAsTemplate } from './SaveAsTemplate.api';
import { setAsDefaultTemplate } from './SetAsDefaultTemplate.api';
import { templates } from './Templates.api';
import { updateTemplate } from './UpdateTemplate.api';

export const templateEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    templates: templates(builder),
    saveAsTemplate: saveAsTemplate(builder),
    updateTemplate: updateTemplate(builder),
    setAsDefaultTemplate: setAsDefaultTemplate(builder),
    applyTemplate: applyTemplate(builder),
    permanentlyDeleteTemplate: permanentlyDeleteTemplate(builder),
    duplicateTemplate: duplicateTemplate(builder),
  }),
});

export const {
  useTemplatesQuery,
  useSaveAsTemplateMutation,
  useUpdateTemplateMutation,
  useSetAsDefaultTemplateMutation,
  useApplyTemplateMutation,
  usePermanentlyDeleteTemplateMutation,
  useDuplicateTemplateMutation,
} = templateEndpoints;
