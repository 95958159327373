'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { useMemo } from 'react';

import Listbox from '../../../inputs/listbox/Listbox';
import { ListboxOption } from '../../../inputs/listbox/Listbox.types';
import { useTable } from '../../table-context/TableContext';

const PaginationShown = () => {
  const { table } = useTable();
  const translate = useTranslate();

  const shown = translate('ui.components.table.pagination.shown.title');

  const options = useMemo(
    (): ListboxOption<number>[] => [
      { key: `${shown} 10`, value: 10 },
      { key: `${shown} 20`, value: 20 },
      { key: `${shown} 30`, value: 30 },
      { key: `${shown} 40`, value: 40 },
      { key: `${shown} 50`, value: 50 },
    ],
    [shown],
  );

  const selected = options.find(
    (selection) => selection.value === table.getState().pagination.pageSize,
  );

  const handleChange = (selected: ListboxOption<number>) =>
    table.setPageSize(Number(selected.value));

  return (
    <Listbox
      value={selected}
      options={options}
      onChange={handleChange}
      multiple={false}
      input={{ className: styles.input }}
    />
  );
};

const styles = {
  input: classes('w-auto'),
};

export default PaginationShown;
