import { Builder } from '../base/query/Api';

export const ask = (builder: Builder) =>
  builder.mutation<any, { query: string }>({
    query: (body) => ({
      url: '/ai/ask',
      method: 'POST',
      body,
    }),
  });
