import { Properties } from 'csstype';

import { PagePeriod } from '../planner/Planner.types';
import { WidgetCodes } from '../widget/Widget.types';
import { ElementTypes } from './Element.types';

export const WIDGET_CODES = [...Object.values(WidgetCodes)];

export type WidgetCode = (typeof WIDGET_CODES)[number];

export type WidgetPeriod = PagePeriod | 'undated';

export interface WidgetElementId {
  data: WidgetElementData;
  styles: WidgetElementStyles;
}

export type WidgetElementData = {
  code: WidgetCodes;
};

export type WidgetElementStyles = Pick<
  Properties,
  'width' | 'height' | 'transform' | 'zIndex'
>;

export type WidgetElementType = {
  id: string;
  type: ElementTypes.Widget;
  data: WidgetElementData;
  styles: WidgetElementStyles;
  createdAt: string;
};
