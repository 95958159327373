'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

import { useColorPicker } from '../context/ColorPickerContext';
import { CurrentColorsProps } from './CurrentColors.types';

const CurrentColors: React.FC<CurrentColorsProps> = ({ title, colors }) => {
  const translate = useTranslate();
  const { changeColor } = useColorPicker();

  return (
    <div>
      <span className={styles.root}>
        {title || translate('ui.components.colorPicker.current')}
      </span>
      <div className={styles.row}>
        {colors.map((i) => (
          <div
            key={i}
            style={{ background: i }}
            className={styles.bullet}
            onClick={() => changeColor(i)}
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  root: classes('text-xs', 'font-medium', 'text-skin-muted'),
  row: classes('grid', 'gap-2', 'grid-cols-7'),
  bullet: classes('h-5', 'w-5', 'rounded-sm', 'cursor-pointer'),
};

export default CurrentColors;
