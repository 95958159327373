'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import Listbox from '../../../inputs/listbox/Listbox';
import { ListboxOption } from '../../../inputs/listbox/Listbox.types';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormListboxProps, TValues } from './FormListbox.types';

const FormListbox = <T extends TValues>({
  by,
  field,
  label,
  disabled,
  options,
  nullable,
  multiple,
  input,
  container,
  ...props
}: FormListboxProps<T>) => {
  const { control, error, isDisabled, setValue } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div className={classNames(styles.root, container?.className)}>
      {label && <FieldLabel label={label} htmlFor={field} />}
      <Controller
        name={field}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          let selected = null;

          if (Array.isArray(value)) {
            selected = value
              .map((v) =>
                options?.find(
                  (i) => (by && (i[by] as unknown) === v) || i.key === v.key,
                ),
              )
              .filter((v) => v);
          } else {
            selected = options?.find(
              (i) =>
                (by && (i[by] as unknown) === value) || i.key === value?.key,
            );
          }

          if (value && !selected) {
            setValue(field, '');
          }

          const handleChange = (
            selections: ListboxOption<T> | ListboxOption<T>[] | null,
          ) => {
            if (Array.isArray(selections)) {
              const current = selections.map((i) => (i && by && i[by]) || i);
              setValue(field, current);
              onChange(current);
            } else {
              const current =
                (selections && by && selections[by]) || selections;
              setValue(field, current);
              onChange(current);
            }
          };

          return (
            <Listbox
              id={field}
              nullable={nullable as never}
              multiple={multiple as never}
              value={selected}
              onBlur={onBlur}
              options={options}
              disabled={isDisable}
              input={{ error: isError, disable: isDisable, ...input }}
              onChange={handleChange}
              {...props}
            />
          );
        }}
      />
      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
};

export default FormListbox;
