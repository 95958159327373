import { AddArticle } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addArticle = (builder: Builder) =>
  builder.mutation<void, AddArticle>({
    query: (body) => ({
      url: '/article/',
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['Articles']) || [],
  });
