'use client';

import React from 'react';

import * as Icons from './components';
import { IconNames, IconProps } from './Icon.types';

const Icon: React.FC<IconProps> = (
  { name, ...props },
  ref: React.Ref<SVGSVGElement>,
) => {
  const CurrentIcon = Icons[name as IconNames];

  return <CurrentIcon ref={ref} {...props} />;
};

export default React.forwardRef(Icon);
