import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

import { ImageNodeAltInfoProps } from './ImageNodeAltInfo.types';

const ImageNodeAltInfo: React.FC<ImageNodeAltInfoProps> = ({ value }) => {
  const translate = useTranslate();

  return (
    <span className={styles.text}>
      {value
        ? `${translate('ui.components.inputs.richText.node.image.alt')} "${value}"`
        : translate('ui.components.inputs.richText.node.image.altMissing')}
    </span>
  );
};

const styles = {
  text: classes('max-w-lg', 'text-xs'),
};

export default ImageNodeAltInfo;
