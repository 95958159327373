export const DAY_START_FIRST = 4;
export const DAY_START_LAST = 14;
export const DAY_END_FIRST = 15;
export const DAY_END_LAST = 27;
export const DATE_FORMAT = 'dd/MM/yyyy';
export const TIME_24_FORMAT = 'HH:mm';
export const TIME_12_FORMAT = 'hh:mm aa';
export const TIME_12_HOUR_FORMAT = 'hh aa';

export const ARTICLE_BUCKET = 'articles';
export const FONT_BUCKET = 'fonts';
export const IMAGE_BUCKET = 'images';
export const PAGE_BUCKET = 'pages';
export const STICKER_BUCKET = 'stickers';
export const USER_BUCKET = 'users';
export const WIDGET_BUCKET = 'widgets';
