import { Page, PageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const page = (builder: Builder) =>
  builder.query<Page | null, PageParams>({
    query: ({ page }) => ({
      url: `/page/page/${page}`,
    }),
  });
