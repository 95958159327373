'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { useEffect, useState } from 'react';

import IconButton from '../../../button/icon-button/IconButton';
import { Text } from '../../../inputs';
import { useTable } from '../../table-context/TableContext';

const DEBOUNCE = 250;

const CaptionSearch = () => {
  const translate = useTranslate();
  const { filter, changeFilter } = useTable();
  const [value, setValue] = useState<number | string>(filter);

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      changeFilter(value);
    }, DEBOUNCE);

    return () => clearTimeout(timeout);
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  const handleClear = () => setValue('');

  return (
    <Text
      value={value}
      onChange={handleInputChange}
      className={styles.end}
      input={{
        className: styles.input,
        end: (
          <IconButton
            name='Close'
            color='silent'
            variant='text'
            onClick={handleClear}
            icon={{ className: styles.icon }}
          />
        ),
      }}
      placeholder={translate('ui.components.table.caption.filter.text')}
    />
  );
};

const styles = {
  end: classes('pr-8'),
  input: classes('w-44'),
  icon: classes('w-4', 'h-4'),
};

export default CaptionSearch;
