import { EmailVerified, VerifyEmailParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { authEndpoints } from './Auth.endpoints';

export const verifyEmail = (builder: Builder) =>
  builder.query<EmailVerified, VerifyEmailParams>({
    query: (body) => ({
      url: '/auth/verify-email',
      method: 'POST',
      body,
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        dispatch(
          authEndpoints.util.updateQueryData('authUser', undefined, (draft) => {
            draft.user.isVerified = true;

            if (data.email) {
              draft.user.email = data.email;
            }
          }),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
