'use client';

import { DialogTitle as HeadlessDialogTitle } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import { useRef } from 'react';

import IconButton from '../../../button/icon-button/IconButton';
import { useDialog } from '../dialog-context/DialogContext';
import { DialogTitleProps } from './DialogTitle.types';

const DialogTitle: React.FC<DialogTitleProps> = ({
  title,
  color = 'accent',
  closeButtonVisible = true,
  closeButton,
  className,
  children,
}) => {
  const { onClose } = useDialog();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
  ) => {
    if (onClick) {
      onClick.constructor.name === 'AsyncFunction'
        ? await onClick(event)
        : onClick(event);
    }

    onClose && onClose();
  };

  return (
    <div className={styles.root}>
      {(title || children) && (
        <HeadlessDialogTitle
          className={classNames(styles.title, styles[color], className)}
        >
          {title}
          {children}
        </HeadlessDialogTitle>
      )}
      {closeButtonVisible && (
        <IconButton
          ref={buttonRef}
          variant='text'
          name='CloseCircle'
          {...closeButton}
          className={classNames(
            styles.button,
            closeButton?.className,
            !title && !children && styles.absolute,
          )}
          onClick={async (e) => await handleClick(e, closeButton?.onClick)}
        />
      )}
    </div>
  );
};

const styles = {
  root: classes('flex', 'items-start', 'justify-end'),
  title: classes(
    'flex',
    'items-center',
    'gap-2',
    'pb-1.5',
    'w-full',
    'font-medium',
    'text-skin-accent',
  ),
  button: classes('p-0.5', 'ml-3', 'z-10'),
  absolute: classNames('absolute'),
  accent: classes('text-skin-accent'),
  green: classes('text-skin-green'),
  red: classes('text-skin-red'),
  blue: classes('text-skin-blue'),
  orange: classes('text-skin-orange'),
};

export default DialogTitle;
