import { DeleteFolderRequest } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deleteFolder = (builder: Builder) =>
  builder.mutation<void, DeleteFolderRequest>({
    query: (body) => ({
      url: '/file/delete-folder',
      method: 'POST',
      body,
    }),
  });
