'use client';

import { AuthUser, UserRole, UserSettings } from '@nowadays/base/types';
import { useInterface, useRegion } from '@nowadays/ui/components';
import { useLanguage } from '@nowadays/ui/i18n/client';
import { useAuthUserQuery } from '@nowadays/ui/services';
import { APP_ENVS } from '@nowadays/ui/utils';
import { useEffect, useState } from 'react';

import { useLoading } from '../../loading/context/LoadingContext';
import { AuthContext } from './AuthContext';

interface AuthProviderProps {
  roles: UserRole[];
  children: React.ReactNode;
  skipUser?: boolean;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  roles,
  children,
  skipUser = false,
}) => {
  const { stopLoading } = useLoading();
  const { changeSettings } = useRegion();
  const { changeLanguage } = useLanguage();
  const { changeTheme, changeRem } = useInterface();
  const { data, isLoading, error, isSuccess, isError } = useAuthUserQuery(
    null,
    { skip: skipUser },
  );
  const [user, setUser] = useState<AuthUser | null>();

  useEffect(() => {
    if (APP_ENVS && user && user.settings) {
      setSettings(user.settings);
    }
  }, [user]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const { user } = data;

    if (user && !roles.find((i) => i === user.role)) {
      return logout();
    }

    setUser(user);
  }, [data]);

  useEffect(() => {
    if (!error) {
      return;
    }

    setUser(null);
  }, [error]);

  useEffect(() => {
    if (isSuccess || isError) {
      stopLoading('user');
    }
  }, [isSuccess, isError]);

  const setSettings = (settings: UserSettings) => {
    const {
      language,
      weekStartsOn,
      timeZone,
      currency,
      hourFormat,
      dayEndsAt,
      dayStartsAt,
      theme,
      rem,
    } = settings;

    changeLanguage(language);
    changeRem(rem);
    changeTheme(theme);
    changeSettings({
      language,
      timeZone,
      currency,
      weekStartsOn,
      hourFormat,
      dayStartsAt,
      dayEndsAt,
    });
  };

  const login = (user: AuthUser) => setUser(user);

  const logout = () => setUser(null);

  const updateUser = (
    update: Pick<
      Partial<AuthUser>,
      'firstName' | 'lastName' | 'picture' | 'settings' | 'preferences'
    >,
  ) => {
    setUser((prev) => ({
      ...prev,
      ...update,
      settings: {
        ...prev.settings,
        ...update.settings,
      },
      preferences: {
        ...prev.preferences,
        ...update.preferences,
      },
    }));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        login,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
