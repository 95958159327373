import { classes, classNames } from '@nowadays/ui/utils';

import { IconButton } from '../../button';
import { SortableHandleProps } from './SortableHandle.types';

const SortableHandle: React.FC<SortableHandleProps> = ({
  listeners,
  className,
  ...props
}) => {
  return (
    <IconButton
      name='Handle'
      color='muted'
      className={classNames(styles.handle, className)}
      {...listeners}
      {...props}
    />
  );
};

const styles = {
  handle: classes('px-0', 'py-0.5', 'cursor-grabbing'),
};

export default SortableHandle;
