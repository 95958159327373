'use client';

import { classes } from '@nowadays/ui/utils';
import React from 'react';
import { TooltipProps } from 'recharts';

import Divider from '../../divider/Divider';
import CustomTooltipContainer from './components/CustomTooltipContainer';

const CustomTooltip: React.FC<TooltipProps<Any, Any>> = ({
  active,
  payload,
  label,
  formatter,
  labelFormatter,
}) => {
  if (active && payload && payload.length) {
    const items = payload
      .map((item) => {
        const data = formatter
          ? formatter(item.value, item.name, item, 0, payload)
          : [item.name, item.value];

        const fill = item.stroke || (item as Any).fill || item.payload.fill;

        if (!data) {
          return undefined;
        }

        return { data, fill };
      })
      .filter((i) => i);

    const title = labelFormatter ? labelFormatter(label, payload) : label || '';

    return (
      <CustomTooltipContainer open={active}>
        {title && (
          <p className={styles.title}>
            <span>{`${title}`}</span>
            <Divider className={styles.divider} />
          </p>
        )}

        {items.map(({ data, fill }, key) => (
          <p key={key} className={styles.items}>
            {fill && (
              <span
                style={{ backgroundColor: fill }}
                className={styles.marker}
              />
            )}
            <span>{`${data[0]} : ${data[1]}`}</span>
          </p>
        ))}
      </CustomTooltipContainer>
    );
  }
};

const styles = {
  title: classes('text-lg-', 'font-medium', 'pb-2', 'space-y-1.5'),
  divider: classes('bg-skin-muted', 'bg-opacity-20'),
  items: classes('flex', 'items-center', 'gap-1.5'),
  marker: classes('h-2', 'w-2'),
};

export default CustomTooltip;
