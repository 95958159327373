'use client';

import { DATE_FORMAT } from '@nowadays/base/utils';
import { isSameDay, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { useCalendarBase } from '../../../calendar/calendar-base/calendar-base-context/CalendarBaseContext';
import { useRegion } from '../../../region/RegionContext';
import Text from '../../text/Text';
import { DatePickerInputProps } from './DatePickerInput.types';

const DATE_REGEX = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

const DatePickerInput: React.FC<DatePickerInputProps> = (
  props,
  ref: React.MutableRefObject<HTMLInputElement>,
) => {
  const { format } = useRegion();
  const { date, currentDay, handleDayChanged } = useCalendarBase();

  const [value, setValue] = useState<string>(
    (currentDay && format(currentDay, DATE_FORMAT)) || '',
  );

  useEffect(() => {
    if (date && !isSameDay(date, currentDay)) {
      handleDayChanged(date);
    }
  }, [date]);

  useEffect(() => {
    currentDay && setValue(format(currentDay, DATE_FORMAT));
  }, [currentDay]);

  useEffect(() => {
    if (DATE_REGEX.test(value)) {
      handleDayChanged(parse(value, DATE_FORMAT, new Date()));
    } else if (value === '') {
      handleDayChanged(undefined);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  return (
    <Text
      ref={ref}
      value={value}
      regex={/[^0-9/]/g}
      onChange={handleChange}
      {...props}
    />
  );
};

export default React.forwardRef(DatePickerInput);
