'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';

import { ChartColors } from '../chart/Chart.types';

const chartColorsArray = Object.values(ChartColors);

export const useChart = () => {
  const translate = useTranslate();

  const getColor = (index: number): string =>
    chartColorsArray[index % chartColorsArray.length];

  const tickLimitter = (value: string, limit?: number) => {
    const max = limit || 24;

    if (value.length < max) {
      return value;
    }

    return `${value.substring(0, max)}...`;
  };

  const noData = translate('ui.components.chart.noData');

  const empty = {
    text: noData,
    bar: [{ name: noData }],
    line: [{ name: noData }],
    pie: [{ name: noData, value: 1 }],
  };

  return {
    empty,
    getColor,
    tickLimitter,
  };
};
