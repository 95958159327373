import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgLink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M8 18a1 1 0 1 0 0-2v2Zm8-2a1 1 0 1 0 0 2v-2Zm0-10a1 1 0 1 0 0 2V6ZM8 8a1 1 0 0 0 0-2v2Zm0 3a1 1 0 1 0 0 2v-2Zm8 2a1 1 0 1 0 0-2v2Zm-8 3a4 4 0 0 1-4-4H2a6 6 0 0 0 6 6v-2Zm12-4a4 4 0 0 1-4 4v2a6 6 0 0 0 6-6h-2Zm-4-4a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6v2ZM8 6a6 6 0 0 0-6 6h2a4 4 0 0 1 4-4V6Zm0 7h8v-2H8v2Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
