import {
  Combobox as HeadlessCombobox,
  ComboboxButton as HeadlessComboboxButton,
  ComboboxInput as HeadlessComboboxInput,
} from '@headlessui/react';

import ComboboxAdornment from '../combobox-adornment/ComboboxAdornment';
import ComboboxClear from '../combobox-clear/ComboboxClear';
import ComboboxLoading from '../combobox-loading/ComboboxLoading';
import ComboboxNotFound from '../combobox-not-found/ComboboxNotFound';
import ComboboxOption from '../combobox-option/ComboboxOption';
import ComboboxOptions from '../combobox-options/ComboboxOptions';
import ComboboxPanel from '../combobox-panel/ComboboxPanel';
import ComboboxSelectAll from '../combobox-select-all/ComboboxSelectAll';

export default {
  Base: HeadlessCombobox,
  Button: HeadlessComboboxButton,
  Input: HeadlessComboboxInput,
  Panel: ComboboxPanel,
  Options: ComboboxOptions,
  Option: ComboboxOption,
  Clear: ComboboxClear,
  SelectAll: ComboboxSelectAll,
  Adornment: ComboboxAdornment,
  NotFound: ComboboxNotFound,
  Loading: ComboboxLoading,
};
