import { Login } from '@nowadays/admin/login';
import { AuthRoute, FormOutlet, NonAuthRoute } from '@nowadays/ui/common';
import { NotFound, RouterMenuItem } from '@nowadays/ui/components';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import Layout from '../layout/Layout';

const Home = lazy(() =>
  import('@nowadays/admin/home').then((module) => ({ default: module.Home })),
);

const Users = lazy(() =>
  import('@nowadays/admin/user').then((module) => ({ default: module.Users })),
);

const Emails = lazy(() =>
  import('@nowadays/admin/email').then((module) => ({
    default: module.Emails,
  })),
);

const WidgetSets = lazy(() =>
  import('@nowadays/admin/widget-set').then((module) => ({
    default: module.WidgetSets,
  })),
);

const StickerPacks = lazy(() =>
  import('@nowadays/admin/sticker-pack').then((module) => ({
    default: module.StickerPacks,
  })),
);

const Articles = lazy(() =>
  import('@nowadays/admin/article').then((module) => ({
    default: module.Articles,
  })),
);

const Reports = lazy(() =>
  import('@nowadays/admin/report').then((module) => ({
    default: module.Reports,
  })),
);

const Covers = lazy(() =>
  import('@nowadays/admin/cover').then((module) => ({
    default: module.Covers,
  })),
);

const FontPacks = lazy(() =>
  import('@nowadays/admin/language').then((module) => ({
    default: module.FontPacks,
  })),
);

const ManageLocales = lazy(() =>
  import('@nowadays/admin/language').then((module) => ({
    default: module.ManageLocales,
  })),
);

export const Routes: RouterMenuItem[] = [
  {
    element: (
      <AuthRoute>
        <Layout>
          <Outlet />
        </Layout>
      </AuthRoute>
    ),
    hidden: true,
    children: [
      {
        index: true,
        path: '/',
        title: 'admin.common.router.home',
        icon: 'Template',
        element: <Home />,
      },
      {
        path: '/user',
        title: 'admin.common.router.user',
        icon: 'AvatarSquare',
        element: <Users />,
      },
      {
        path: '/email',
        title: 'admin.common.router.email',
        icon: 'Email',
        element: <Emails />,
      },
      {
        path: '/planner',
        title: 'admin.common.router.cover',
        icon: 'Image',
        element: <Covers />,
      },
      {
        path: '/widget',
        title: 'admin.common.router.widget',
        icon: 'Widget',
        element: <WidgetSets />,
      },
      {
        path: '/sticker',
        title: 'admin.common.router.sticker',
        icon: 'Sticker',
        element: <StickerPacks />,
      },
      {
        path: '/article',
        title: 'admin.common.router.article',
        icon: 'Tutorial',
        element: <Articles />,
      },
      {
        path: '/report',
        title: 'admin.common.router.report',
        icon: 'InfoCircle',
        element: <Reports />,
      },
      {
        path: '/language',
        title: 'admin.common.router.language.title',
        icon: 'Globe',
        children: [
          {
            path: 'font-pack',
            title: 'admin.common.router.language.fontPack.title',
            element: <FontPacks />,
          },
          {
            path: 'manage-locale',
            title: 'admin.common.router.language.manageLocale.title',
            element: <ManageLocales />,
          },
        ],
      },
    ],
  },
  {
    element: (
      <NonAuthRoute>
        <FormOutlet />
      </NonAuthRoute>
    ),
    hidden: true,
    children: [
      {
        hidden: true,
        title: 'admin.common.router.login',
        path: '/login',
        element: <Login />,
      },
    ],
  },
  {
    hidden: true,
    title: 'admin.common.router.notFound',
    path: '*',
    element: <NotFound />,
  },
];
