import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgPartlySunny = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M17.538 14.077a3.476 3.476 0 0 0-2.077.692A6.23 6.23 0 1 0 9.231 21h8.307a3.462 3.462 0 0 0 0-6.923Zm.347-4.847a3.115 3.115 0 1 0 0-6.23 3.115 3.115 0 0 0 0 6.23Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPartlySunny);
export default ForwardRef;
