import { Template, TemplatesParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const templates = (builder: Builder) =>
  builder.query<Template[], TemplatesParams>({
    query: ({ planner, ...params }) => ({
      url: `/template/templates/${planner}`,
      params,
    }),
    providesTags: ['Templates'],
  });
