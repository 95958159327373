export const admin = {
  articles: {
    add: { title: 'Add article' },
    delete: {
      confirm: 'Are you sure you want to permanently delete article?',
      title: 'Delete article',
    },
    edit: { title: 'Edit article' },
    form: {
      add: {
        slug: 'Slug (Url path)',
        slugError: '{{label}} only contain "-"',
        type: 'Type',
      },
      edit: {
        content: 'Content',
        cover: 'Cover',
        coverAlt: 'Cover alt text',
        description: 'Description',
        slug: 'Slug (Url path)',
        status: 'Status',
        title: 'Title',
      },
    },
    header: {
      actions: 'Actions',
      slug: 'Slug (Url path)',
      status: 'Status',
      title: 'Title',
      type: 'Type',
    },
    title: 'Articles',
    types: {
      feature: 'Feature',
      policy: 'Policy',
      tutorial: 'Tutorial',
      widget: 'Widget',
    },
    preview: { title: 'Preview' },
  },
  common: {
    router: {
      article: 'Article',
      cover: 'Cover',
      email: 'Email',
      home: 'Home',
      language: {
        fontPack: { title: 'Font pack' },
        manageLocale: { title: 'Manage locale' },
        title: 'Language',
      },
      login: 'Log in',
      notFound: '404 - Not found',
      report: 'Report',
      sticker: 'Sticker',
      user: 'User',
      widget: 'Widget',
    },
    sidebar: {
      footer: {
        language: { title: 'Language' },
        logout: { title: 'Logout' },
        theme: {
          options: { dark: 'Dark', light: 'Light', system: 'System' },
          title: 'Theme',
        },
        title: { text: 'Settings' },
      },
    },
  },
  email: {
    emails: {
      add: { title: 'Add email' },
      delete: {
        confirm: 'Are you sure you want to permanently delete email?',
        title: 'Delete email',
      },
      form: {
        html: 'Email template preview',
        notSelected: 'Template not selected',
        template: 'Template',
        title: { add: 'Add email template', update: 'Update email template' },
      },
      header: {
        actions: 'Actions',
        createdAt: 'Created at',
        template: 'Template',
      },
      template: {
        changeEmailRequest: {
          message:
            'We received a request to update the email address associated with your account. If you made this request, please click the link below to confirm the change:',
          preview:
            'Confirm your email address change request by clicking the link below.',
          title: 'Confirm your email address',
          verificationLink: 'Verification link',
        },
        changeEmailSuccess: {
          description:
            'If you made this change, there is nothing more you need to do. If you did not make this change or have any concerns, please contact our support team immediately.',
          message:
            'We wanted to let you know that your email address has been successfully updated for your Nowadays.app account.',
          preview:
            'Confirmation: Your email address has been successfully updated.',
          title: 'Your email address updated',
        },
        createPasswordRequest: {
          createPasswordLink: 'Create your password',
          description: 'Click the link below to create your password:',
          message:
            'To ensure the security of your account, please create a password.',
          preview:
            'Secure your account: Create a password for Nowadays.app account.',
          title: 'Create password',
        },
        passwordResetRequest: {
          message:
            'We received a request to reset your password for your Nowadays.app account. To proceed with the password reset, please click the link below:',
          preview: 'Action required: Reset your password securely.',
          resetPasswordLink: 'Reset password link',
          title: 'Reset password',
        },
        resetPasswordSuccess: {
          message:
            "We're writing to inform you that your password for your Nowadays.app account has been successfully set.",
          preview: 'Password successfully set. Log in securely now.',
          title: 'Your password changed',
        },
        signupLocalSuccess: {
          description:
            'Verify your email address to complete the sign-up process:',
          message:
            'Thank you for joining Nowadays.app, personalized digital planning!',
          preview: 'Your account has been created successfully.',
          title: 'Welcome to Nowadays.app!',
          verificationLink: 'Verification link',
        },
        signupSocialSuccess: {
          message:
            'Thank you for joining Nowadays.app, personalized digital planning!',
          preview: 'Your account has been created successfully.',
          title: 'Welcome to Nowadays.app!',
        },
        verifyEmailRequest: {
          description:
            'Please click the link below to verify your email address:',
          preview: 'Verify your email address to complete the sign-up process.',
          title: 'Email verification',
          verificationLink: 'Verification link',
        },
      },
      templates: {
        base: {
          closure: 'Happy planning!',
          contact: 'Contact us at',
          greating: 'Hi',
          ignore: "If you didn't make this request, you can ignore this email.",
          linkExpireInfo:
            'Please note that this link will expire in {{hour}} hours.',
          motto: 'Personalized Digital Planning',
        },
        changeEmailRequest: 'Change email request',
        changeEmailSuccess: 'Change email success',
        createPasswordRequest: 'Create password request',
        passwordResetRequest: 'Password reset request',
        resetPasswordSuccess: 'Reset password success',
        signupLocalSuccess: 'Sign up success with email',
        signupSocialSuccess: 'Sign up success with social',
        verifyEmailRequest: 'Verify email request',
      },
      test: { form: { header: 'Test email', to: 'To' }, title: 'Test email' },
      title: 'Emails',
      uodate: { title: 'Update email' },
      update: { title: 'Update email' },
    },
  },
  home: { user: { dau: 'Daily active user:', total: 'Total user:' } },
  language: {
    fontPacks: {
      add: {
        file: {
          form: {
            family: 'Family',
            subsets: 'Subsets',
            title: 'Add font pack from file',
            variant: { style: 'Style', weight: 'Weight' },
            variants: 'Variants',
          },
        },
        fromFile: { title: 'From file' },
        fromWeb: { title: 'From web' },
        title: 'Add font pack',
        web: {
          fontSize: 'Size',
          fontSubsets: 'Charsets',
          header: {
            actions: 'Actions',
            family: 'Family',
            lastModified: 'Last modified',
            popularity: 'Popularity',
            version: 'Version',
          },
          title: 'Font packs',
          update: 'Update',
          upload: 'Upload',
          webFontsOffline: 'Unable to access the font server',
        },
      },
      delete: {
        confirm: 'Are you sure you want to permanently delete font pack?',
        title: 'Delete font pack',
      },
      header: {
        actions: 'Actions',
        family: 'Family',
        lastModified: 'Last modified',
        popularity: 'Popularity',
        version: 'Version',
      },
      title: 'Font packs',
    },
    manageLocales: {
      languages: {
        add: 'Add',
        delete: {
          confirm: 'Are you sure you want to delete this language?',
          title: 'Delete',
        },
        notFound: 'Language not found',
        title: 'Languages',
      },
      namespaces: {
        add: 'Add',
        delete: {
          confirm: 'Are you sure you want to delete this namespace?',
          title: 'Delete',
        },
        form: {
          header: 'Add namespace',
          namespace: 'Namespace',
          regexError: '{{label}} can only include (:) as seperator',
        },
        notFound: 'Namespace not found',
        title: 'Namespaces',
      },
      resources: {
        hotColHeader: { path: 'Path (Folder)' },
        hotMenu: {
          copy: 'Copy cell',
          cut: 'Cut cell',
          removeRow: 'Remove row',
          rowAbove: 'Insert row above',
          rowBelow: 'Insert row below',
        },
        notFound: 'Resource not found or namespace not selected',
        prod: 'You can not edit languages in production mode!',
        save: 'Save changes',
        search: 'Search resource...',
        title: 'Resources',
      },
    },
  },
  login: {
    form: {
      email: 'Email',
      login: 'Log in',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    title: 'Log in',
  },
  planner: {
    covers: {
      add: { title: 'Add cover' },
      list: {
        delete: {
          confirm: 'Are you sure you want to permanently delete cover?',
          title: 'Delete cover',
        },
      },
      notFound: 'Cover not found',
      title: 'Planner covers',
    },
  },
  reports: {
    close: {
      confirm: 'Are you sure you want to close the report?',
      title: 'Close report',
    },
    delete: {
      confirm: 'Are you sure you want to delete the report?',
      title: 'Delete report',
    },
    detail: {
      form: {
        category: 'Category',
        closedAt: 'Closed at',
        closedBy: 'Closed by',
        createdAt: 'Created at',
        description: 'Description',
        header: 'Report detail',
        reference: 'reference',
        type: 'Type',
        user: 'Created by',
      },
      title: 'Report detail',
    },
    filter: { active: 'Active', closed: 'Closed' },
    header: {
      actions: 'Actions',
      categories: {
        bug: 'Bug or error',
        feature: 'Feature or improvement',
        other: 'Other',
      },
      category: 'Category',
      createdAt: 'Create at',
      description: 'Description',
      type: 'Type',
      types: {
        application: 'Application',
        feedback: 'Feedback',
        sticker: 'Sticker',
        template: 'Template',
        widget: 'Widget',
      },
    },
    title: 'Reports',
  },
  sticker: {
    packs: {
      add: { form: { title: 'Add sticker pack' }, title: 'Add sticker pack' },
      delete: {
        confirm: 'Are you sure you want to permanently delete sticker pack?',
        desc: 'When the sticker package is deleted, it will not be displayed on existing pages, it is recommended to disable it.',
        title: 'Delete sticker pack',
      },
      edit: { form: { title: 'Edit sticker pack' } },
      form: { status: 'Status' },
      header: {
        actions: 'Actions',
        createdAt: 'Create at',
        name: 'Pack name',
        status: 'Status',
        total: 'Total sticker',
      },
      items: {
        add: {
          form: {
            allIsChangeable: 'All changeable colors',
            multiple: 'Add multiple',
            single: 'Add single',
            sticker: 'Sticker (svg)',
            title: 'Add sticker',
          },
          title: 'Add sticker',
        },
        edit: { form: { header: 'Edit sticker' } },
        form: {
          common: {
            colors: 'Changeable colors',
            file: 'Sticker (svg)',
            keywords: 'Search keywords',
            size: { height: 'Height (px)', width: 'Width (px)' },
            uploading: { file: 'File', thumbnail: 'Thumbnail' },
          },
        },
        menu: {
          delete: {
            dialog: {
              confirm: 'Are you sure you want to permanently delete sticker?',
              desc: '*You can not restore this action',
            },
            title: 'Delete sticker',
          },
          edit: { title: 'Edit sticker' },
        },
        notFound: 'Sticker not found',
      },
      title: 'Sticker packs',
    },
  },
  user: {
    users: {
      delete: {
        confirm: 'Are you sure you want to permanently delete this user?',
      },
      edit: {
        form: {
          email: 'Email',
          firstName: 'First name',
          header: 'Edit user',
          isActive: 'Status',
          isVerified: 'Verified',
          lastName: 'Last name',
          role: 'Role',
        },
      },
      header: {
        actions: 'Actions',
        createdAt: 'Created at',
        email: 'Email',
        fullName: 'Full name',
        isActive: 'Status',
        lastLoginAt: 'Last login at',
        role: 'Role',
      },
      title: 'Users',
    },
  },
  widget: {
    sets: {
      add: { form: { title: 'Add widget set' } },
      delete: {
        confirm: 'Are you sure you want to permanently delete widget set?',
        desc: 'When the widget set is deleted, it will not be displayed on existing pages, it is recommended to disable it.',
        title: 'Delete widget set',
      },
      edit: { form: { title: 'Edit widget set' } },
      form: { status: 'Status' },
      header: {
        actions: 'Actions',
        createdAt: 'Create at',
        name: 'Set name',
        status: 'Status',
        total: 'Total widget',
      },
      items: {
        add: { form: { title: 'Add widget' } },
        delete: {
          confirm: 'Are you sure you want to permanently delete widget?',
          desc: 'When the widget is deleted, it will not be displayed on existing pages, it is recommended to disable it.',
          title: 'Delete widget',
        },
        edit: { form: { title: 'Edit widget' } },
        form: {
          code: 'Code',
          keywords: 'Search keywords',
          status: 'Status',
          thumbnail: 'Thumbnail (png)',
        },
        header: {
          actions: 'Actions',
          code: 'Code',
          createdAt: 'Create at',
          name: 'Name',
          status: 'Status',
          thumbnail: 'Thumbnail',
        },
      },
      title: 'Widget sets',
    },
  },
};
