'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import { FieldErrorProps } from './FieldError.types';

const FieldError: React.FC<FieldErrorProps> = ({ error, label, className }) => {
  const translate = useTranslate();
  const labelLocale =
    (label && translate(label as Message)) ||
    (error.params?.label && translate(error.params?.label as Message));

  const translated = translate(error.message as never, {
    ...error.params,
    label: labelLocale,
  });

  return <div className={classNames(styles.root, className)}>{translated}</div>;
};

const styles = {
  root: classes('pl-1', 'py-1', 'text-2xs', 'text-skin-red'),
};

export default FieldError;
