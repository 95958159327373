'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Spinner from '../spinner/Spinner';
import { LoadingProps } from './Loading.types';

const Loading: React.FC<LoadingProps> = ({ className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <Spinner />
    </div>
  );
};

const styles = {
  root: classes('w-full', 'flex', 'items-center', 'justify-center'),
};

export default Loading;
