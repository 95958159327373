import { classes } from '@nowadays/ui/utils';

export const bgHoverActiveStyles = {
  base: {
    text: null,
    flat: classes('hover:active:bg-skin-silent', 'hover:active:bg-opacity-10'),
    outlined: classes(
      'hover:active:bg-skin-silent',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-silent',
      'hover:active:bg-opacity-80',
    ),
  },
  primary: {
    text: null,
    flat: classes('hover:active:bg-skin-muted', 'hover:active:bg-opacity-10'),
    outlined: classes(
      'hover:active:bg-skin-muted',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-inverted',
      'hover:active:bg-opacity-80',
    ),
  },
  inverted: {
    text: null,
    flat: classes('hover:active:bg-skin-primary', 'hover:active:bg-opacity-5'),
    outlined: classes(
      'hover:active:bg-skin-primary',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-primary',
      'hover:active:bg-opacity-80',
    ),
  },
  muted: {
    text: null,
    flat: classes('hover:active:bg-skin-muted', 'hover:active:bg-opacity-10'),
    outlined: classes(
      'hover:active:bg-skin-muted',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-muted',
      'hover:active:bg-opacity-80',
    ),
  },
  silent: {
    text: null,
    flat: classes('hover:active:bg-skin-silent', 'hover:active:bg-opacity-10'),
    outlined: classes(
      'hover:active:bg-skin-silent',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-silent',
      'hover:active:bg-opacity-80',
    ),
  },
  accent: {
    text: null,
    flat: classes('hover:active:bg-skin-accent', 'hover:active:bg-opacity-10'),
    outlined: classes(
      'hover:active:bg-skin-accent',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-accent',
      'hover:active:bg-opacity-80',
    ),
  },
  green: {
    text: null,
    flat: classes('hover:active:bg-skin-green', 'hover:active:bg-opacity-10'),
    outlined: classes(
      'hover:active:bg-skin-green',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-green',
      'hover:active:bg-opacity-80',
    ),
  },
  red: {
    text: null,
    flat: classes('hover:active:bg-skin-red', 'hover:active:bg-opacity-10'),
    outlined: classes('hover:active:bg-skin-red', 'hover:active:bg-opacity-10'),
    contained: classes(
      'hover:active:bg-skin-red',
      'hover:active:bg-opacity-80',
    ),
  },
  blue: {
    text: null,
    flat: classes('hover:active:bg-skin-blue', 'hover:active:bg-opacity-10'),
    outlined: classes(
      'hover:active:bg-skin-blue',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-blue',
      'hover:active:bg-opacity-80',
    ),
  },
  orange: {
    text: null,
    flat: classes('hover:active:bg-skin-orange', 'hover:active:bg-opacity-10'),
    outlined: classes(
      'hover:active:bg-skin-orange',
      'hover:active:bg-opacity-10',
    ),
    contained: classes(
      'hover:active:bg-skin-orange',
      'hover:active:bg-opacity-80',
    ),
  },
};
