'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React, { Fragment } from 'react';

import ButtonBase from '../button-base/ButtonBase';
import { AnchorButtonProps } from './AnchorButton.types';

const AnchorButton: React.FC<AnchorButtonProps> = (
  {
    variant = 'text',
    href,
    onClick,
    disabled,
    children,
    className,
    startIcon,
    endIcon,
    spinner,
    text,
    ...props
  },
  ref: React.Ref<HTMLAnchorElement>,
) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!disabled) {
      onClick && onClick(e);
    } else {
      e.preventDefault();
    }
  };

  return (
    <ButtonBase
      as='a'
      ref={ref}
      href={href}
      onClick={handleClick}
      variant={variant}
      disabled={disabled}
      className={classNames(styles.button, className)}
      {...props}
    >
      {({ loading }) => {
        return (
          <Fragment>
            {loading && <ButtonBase.Spinner {...spinner} />}
            {!loading && startIcon && <ButtonBase.Icon {...startIcon} />}
            {children || text}
            {endIcon && <ButtonBase.Icon {...endIcon} />}
          </Fragment>
        );
      }}
    </ButtonBase>
  );
};

const styles = {
  button: classes('px-1', 'py-0', 'rounded-md', 'inline-flex'),
};

export default React.forwardRef(AnchorButton);
