import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgSnowy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M9.23 3 12 5.77 14.77 3M3 14.77 5.77 12 3 9.23M14.77 21 12 18.23 9.23 21M21 9.23 18.23 12 21 14.77M7.154 7.154 9.23 9.23m0 5.54-2.076 2.076m9.692-9.692L14.77 9.23m0 5.54 2.076 2.076M12 5.77v12.46M5.77 12h12.46'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSnowy);
export default ForwardRef;
