import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgBulletList = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M8 14h11M8 18h11M8 6h11M8 10h11'
    />
    <circle cx={5} cy={6} r={1} fill='currentColor' />
    <circle cx={5} cy={10} r={1} fill='currentColor' />
    <circle cx={5} cy={14} r={1} fill='currentColor' />
    <circle cx={5} cy={18} r={1} fill='currentColor' />
  </svg>
);
const ForwardRef = forwardRef(SvgBulletList);
export default ForwardRef;
