import { baseApi } from '../base/query/Api';
import { addStickerPackItems } from './AddStickerPackItems.api';
import { deleteStickerPackItem } from './DeleteStickerPackItem.api';
import { reorderStickerPackItem } from './ReorderStickerPackItem.api';
import { stickerPackItem } from './StickerPackItem.api';
import { stickerPackItems } from './StickerPackItems.api';
import { updateStickerPackItem } from './UpdateStickerPackItem.api';

export const stickerPackItemEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    stickerPackItems: stickerPackItems(builder),
    stickerPackItem: stickerPackItem(builder),
    addStickerPackItems: addStickerPackItems(builder),
    deleteStickerPackItem: deleteStickerPackItem(builder),
    updateStickerPackItem: updateStickerPackItem(builder),
    reorderStickerPackItem: reorderStickerPackItem(builder),
  }),
});

export const {
  useStickerPackItemsQuery,
  useLazyStickerPackItemQuery,
  useAddStickerPackItemsMutation,
  useDeleteStickerPackItemMutation,
  useUpdateStickerPackItemMutation,
  useReorderStickerPackItemMutation,
} = stickerPackItemEndpoints;
