import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgWindy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12.615 4a2.154 2.154 0 0 1 0 4.308H4m13.846 8a2.154 2.154 0 0 0 0-4.308h-12m6.462 8a2.154 2.154 0 0 0 0-4.308H5.23'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWindy);
export default ForwardRef;
