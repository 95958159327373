'use client';

import RouterChildMenu from '../router-child-menu/RouterChildMenu';
import RouterMenuButton from '../router-menu-button/RouterMenuButton';
import { RouterMenuItemProps } from './RouterMenuItem.types';

const RouterMenuItem: React.FC<RouterMenuItemProps> = ({
  item,
  parentPath,
}) => {
  if (item?.hidden) {
    return item?.children?.map((child, key) => (
      <RouterMenuItem key={key} item={child} />
    ));
  }

  return item?.children ? (
    <RouterChildMenu item={item} />
  ) : (
    <RouterMenuButton item={item} parentPath={parentPath} />
  );
};

export default RouterMenuItem;
