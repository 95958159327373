export const home = {
  common: {
    header: {
      auth: {
        continue: 'Continue as {{name}}',
        login: 'Log in',
        signup: 'Sign up',
      },
    },
  },
  features: { title: 'Features' },
  home: {
    description:
      'Discover Nowadays.app: personalized digital planning made easy. Customize widgets, arrange layouts effortlessly. Redefine your routine!',
    features: {
      '1': {
        alt: 'Design your day your way with Nowadays.app. Easily arrange widgets with drag-and-drop functionality, including schedules, to-do lists, and habit trackers. Customize your planner layout to suit your needs and settings seamlessly.',
        text: 'Discover the freedom to design your day, your way. Drag-and-drop widgets like schedules, to-do lists, habit trackers, and more. Enlarge or shrink widgets with a simple stretch, and watch as your digital planner transforms into a reflection of your lifestyle.',
        title: 'Create. Orginize. Achieve.',
      },
      '2': {
        alt: "Stay organized with Nowadays.app' integrated calendar. Effortlessly navigate between days, weeks, and months, ensuring a cohesive planning experience. Synchronize your plans across timeframes for easy management of your dynamic life.",
        text: 'Effortlessly navigate between days, weeks, and months with the integrated calendar. Your plans synchronize across timeframes, providing a cohesive and organized planning experience that adapts to your dynamic life.',
        title: 'Seamless & Timeless Navigation',
      },
      '3': {
        alt: 'Discover endless possibilities with multiple planner planners in Nowadays.app. Tailor each planner to specific purposes like journaling, daily planning, or finances. Organize different aspects of your life effectively with personalized planner planners.',
        text: "Nowadays.app is a web-based app accessible from any browser. Your personalized digital planner is just a click away, whether you're on your desktop, tablet, or mobile device. Log in daily to stay on top of tasks, events, and habits.",
        title: 'Always Accessible, Anywhere',
      },
      '4': {
        alt: 'Stay organized with planner tabs in Nowadays.app. Easily segment your planner into different sections for work, personal, and health. Navigate between tabs efficiently, enhancing productivity and clarity in your planning journey.',
        text: 'Easily segment your planner into different sections with the tab feature. Whether you want separate tabs for work, personal, health, or any other category, Nowadays.app empowers you to organize your digital planner with precision and efficiency.',
        title: 'Orginize with Tabs',
      },
      '5': {
        alt: 'Access your planner anytime, anywhere with Nowadays.app. Whether on your desktop, tablet, or mobile device, Nowadays.app is accessible from any web browser. Log in daily to stay on top of tasks, events, and habits with ease.',
        text: "Create and design different planners for various purposes. Whether it's journaling, daily planning, finances, or any other aspect of your life. Nowadays.app allows you to customize multiple planner tailored to your unique needs.",
        title: 'Multiple Planners, Endless Possibilities',
      },
      '6': {
        alt: 'Achieve your goals with Nowadays.app. Redefine your daily routine and make every day count towards your journey of success. Empower yourself to organize your life effectively and embark on a personalized digital planning adventure with Nowadays.app.',
        text: 'Take the leap into a new era of planning. Transform the way you organize your life; understand your habits, tasks, and events at a glance, empowering you to make every day count. Your personalized digital planning adventure starts here.',
        title: 'Redefine Your Daily Routine',
      },
      title: 'Making every day uniquely <0>yours.</0>',
    },
    footer: { language: { title: 'Language' }, theme: { title: 'Theme' } },
    keywords:
      'Digital planner,Life organizer,Daily planner,Weekly planner,Monthly planner,Annual planner,Customizable planner,Flexible planner,Schedule,To-do list,Habit tracker,Finance tracker,Calendar,Goals,Tasks',
    laptopView:
      'The innovative interface of Nowadays.app digital planner presents a variety of customizable widgets, empowering users to tailor their planning journey. With seamless options to add, resize, and reposition widgets, users enjoy effortless organization. Nowadays.app provides a contemporary and user-friendly approach to structuring daily activities.',
    menu: { seeAll: 'See all' },
    mixAndMatch:
      '<0>Mix and match</0> the widgets you need.<1></1>Design pages that suit all your <2>unique</2> requirements.',
    motto: 'Your Gateway to<0></0> <1>Personalized</1> Digital Planning',
    signUpForFree: 'Sign Up for Free!',
    startPlanning: 'Start Planning',
    title: 'Nowadays.app: Personalized Digital Planning',
    tryForFree: 'Try For Free Now!',
  },
  tutorials: { description: 'How to use app', title: 'Tutorials' },
  widgets: {
    description: "Discover Nowadays.app's widgets and learn how to use",
    keywords:
      'Text box,Image box,To-do list,Priority list,Calendar,Schedule table,Pomodoro,Focus,Intention,Reflection,Habit tracker,Mood tracker,Water intake tracker, Weather tracker,Content planner',
    title: 'Widgets',
  },
};
