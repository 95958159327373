import { classes, classNames } from '@nowadays/ui/utils';

import { CalendarPanelProps } from './CalendarPanel.types';

const CalendarPanel: React.FC<CalendarPanelProps> = ({
  className,
  children,
}) => {
  return (
    <table className={classNames(styles.table, className)}>{children}</table>
  );
};

const styles = {
  table: classes('table-fixed', 'w-full', 'h-full'),
};

export default CalendarPanel;
