import { classes } from '@nowadays/ui/utils';

import { Popover } from '../../../../../popover';
import { ColorPicker } from '../../../../color-picker';
import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';

const RichTextColor = () => {
  const { editor } = useRichText();

  if (!editor) {
    return null;
  }

  return (
    <Popover className={styles.root}>
      <ColorPicker
        color={editor.getAttributes('textStyle').color}
        onChange={(value) => editor.chain().focus().setColor(value).run()}
      >
        <Popover.Button
          name='TextColor'
          as={RichTextToolbarButton}
          icon={{ className: styles.icon }}
        />

        <div
          style={{
            background: editor.getAttributes('textStyle').color,
          }}
          className={styles.bullet}
        />

        <Popover.Panel floating={{ offset: 10 }} className={styles.panel}>
          <ColorPicker.Header transparent />
          <ColorPicker.PresetColors />
        </Popover.Panel>
      </ColorPicker>
    </Popover>
  );
};

const styles = {
  root: classes('relative'),
  icon: classes('w-4', 'h-4', 'mb-1'),
  panel: classes('space-y-2'),
  bullet: classes(
    'absolute',
    'w-4',
    'h-1',
    'rounded-sm',
    'bottom-0.5',
    'left-[50%]',
    '-translate-x-[50%]',
    'pointer-events-none',
    'bg-skin-primary',
  ),
};

export default RichTextColor;
