'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { FieldValues } from 'react-hook-form';

import ActionButtons from '../../button/action-buttons/ActionButtons';
import {
  CancelAction,
  ConfirmAction,
} from '../../button/action-buttons/ActionButtons.types';
import { useFormControl } from '../form-control/useFormControl';
import { FormFooterProps } from './FormFooter.types';

const FormFooter = <T extends FieldValues>({
  className,
  children,
  confirm: customConfirm = {},
  cancel: customCancel = {},
  actions,
}: FormFooterProps<T>) => {
  const { getValues, isLoading, isSubmitting, isDisabled } =
    useFormControl<T>();

  const confirm: ConfirmAction = {
    disabled: isDisabled,
    loading: isSubmitting,
    ...customConfirm,
  };

  const cancel: CancelAction = { disabled: isDisabled, ...customCancel };

  return (
    <div className={classNames(styles.root, className)}>
      <ActionButtons actions={actions} confirm={confirm} cancel={cancel} />
      {typeof children === 'function'
        ? children({
            values: getValues(),
            loading: isLoading,
            disabled: isDisabled,
          })
        : children}
    </div>
  );
};

const styles = {
  root: classes('pt-2', 'flex', 'justify-end', 'gap-2'),
};

export default FormFooter;
