import { DeleteEmailParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deleteEmail = (builder: Builder) =>
  builder.mutation<void, DeleteEmailParams>({
    query: ({ id }) => ({
      url: `/email/delete/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['Emails']) || [],
  });
