/// <reference types="vite/client" />

interface AppEnvironments {
  PROD: boolean;
  BASE_PATH: string;
  HOME_URL: string;
  API_URL: string;
  SOCKET_URL: string;
  SUPPORT_EMAIL: string;
  S3_BUCKET_URL: string;
  S3_CDN_BUCKET_URL: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
}

class AppEnvironemnt {
  constructor() {
    if (!import.meta.env['VITE_BASE_PATH']) {
      throw new Error('VITE_BASE_PATH required');
    }

    if (!import.meta.env['VITE_HOME_URL']) {
      throw new Error('VITE_HOME_URL required');
    }

    if (!import.meta.env['VITE_API_URL']) {
      throw new Error('VITE_API_URL required');
    }

    if (!import.meta.env['VITE_SOCKET_URL']) {
      throw new Error('VITE_SOCKET_URL required');
    }

    if (!import.meta.env['VITE_SUPPORT_EMAIL']) {
      throw new Error('VITE_SUPPORT_EMAIL required');
    }

    if (!import.meta.env['VITE_S3_BUCKET_URL']) {
      throw new Error('VITE_S3_BUCKET_URL required');
    }

    if (!import.meta.env['VITE_S3_CDN_BUCKET_URL']) {
      throw new Error('VITE_S3_CDN_BUCKET_URL required');
    }

    if (!import.meta.env['VITE_GOOGLE_RECAPTCHA_SITE_KEY']) {
      throw new Error('VITE_GOOGLE_RECAPTCHA_SITE_KEY required');
    }
  }

  get PROD() {
    return import.meta.env['PROD'];
  }

  get BASE_PATH() {
    return import.meta.env['VITE_BASE_PATH'];
  }

  get HOME_URL() {
    return import.meta.env['VITE_HOME_URL'];
  }

  get API_URL() {
    return import.meta.env['VITE_API_URL'];
  }

  get SOCKET_URL() {
    return import.meta.env['VITE_SOCKET_URL'];
  }

  get SUPPORT_EMAIL() {
    return import.meta.env['VITE_SUPPORT_EMAIL'];
  }

  get S3_BUCKET_URL() {
    return import.meta.env['VITE_S3_BUCKET_URL'];
  }

  get S3_CDN_BUCKET_URL() {
    return import.meta.env['VITE_S3_CDN_BUCKET_URL'];
  }

  get GOOGLE_RECAPTCHA_SITE_KEY() {
    return import.meta.env['VITE_GOOGLE_RECAPTCHA_SITE_KEY'];
  }
}

let APP_ENVS: AppEnvironments = undefined;

if (import.meta.env && import.meta.env['VITE_APP'] === 'app') {
  APP_ENVS = new AppEnvironemnt();
}

export { APP_ENVS };
