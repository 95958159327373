'use client';

import { createContext, useContext } from 'react';

export type WaitFor = 'user' | 'font' | 'planner';

interface LoadingContextProps {
  isLoading: boolean;
  stopLoading: (key: WaitFor) => void;
}

export const LoadingContext = createContext<LoadingContextProps>({
  isLoading: true,
  stopLoading: () => null,
});

export const useLoading = () => {
  return useContext(LoadingContext);
};
