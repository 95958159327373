import { VerifyPasswordParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const verifyPassword = (builder: Builder) =>
  builder.mutation<void, VerifyPasswordParams>({
    query: (body) => ({
      url: '/user/verify-password',
      method: 'POST',
      body,
    }),
  });
