import { classes, classNames } from '@nowadays/ui/utils';

import CalendarBase from '../../calendar-base/CalendarBase';
import Calendar from '../Calendar';
import { CalendarYearsProps } from './CalendarYears.types';

const CalendarYears: React.FC<CalendarYearsProps> = ({
  onClick,
  className,
  ...props
}) => {
  return (
    <tbody>
      <CalendarBase.Years>
        {({ years }) => (
          <tr>
            {years.map((year, key) => (
              <td key={key} className={styles.td}>
                <Calendar.Year
                  year={year}
                  onClick={(e) => key === 1 && onClick && onClick(e)}
                  color='muted'
                  className={({ isThisYear, isSelected }) =>
                    classNames(
                      styles.year,
                      isThisYear && styles.thisYear,
                      typeof className === 'function'
                        ? className({ isThisYear, isSelected })
                        : className,
                    )
                  }
                  {...props}
                />
              </td>
            ))}
          </tr>
        )}
      </CalendarBase.Years>
    </tbody>
  );
};

const styles = {
  td: classes('text-center', 'px-1'),
  year: classes('w-full', 'font-semibold', 'p-2', 'text-lg'),
  thisYear: classes('text-skin-red', 'font-bold'),
};

export default CalendarYears;
