'use client';

import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { classes, classNames } from '@nowadays/ui/utils';

import Sortable from '../sortable/Sortable';
import { TableData, TableProps } from './Table.types';
import TableBody from './table-body/TableBody';
import TableCaption from './table-caption/TableCaption';
import { TableProvider } from './table-context/TableProvider';
import TableHead from './table-head/TableHead';
import TableLoading from './table-loading/TableLoading';
import TablePagination from './table-pagination/TablePagination';
import TablePanel from './table-panel/TablePanel';

const Table = <T extends TableData>({
  options,
  children,
  className,
  loading,
  sortable,
  items,
  onSorted,
}: TableProps<T>) => {
  return (
    <Sortable modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
      <Sortable.Context
        select={sortable}
        items={items}
        onChange={onSorted}
        strategy={verticalListSortingStrategy}
      >
        <TableProvider<T> options={options} sortable={sortable}>
          <div className={classNames(styles.root, className)}>
            <TableLoading loading={loading} />
            {children}
          </div>
        </TableProvider>
      </Sortable.Context>
    </Sortable>
  );
};

const styles = {
  root: classes('w-full', 'overflow-y-auto', 'rounded-lg', 'relative'),
};

export default Object.assign(Table, {
  Caption: TableCaption,
  Panel: TablePanel,
  Head: TableHead,
  Body: TableBody,
  Pagination: TablePagination,
});
