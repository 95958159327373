'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { BrandLogoProps } from './BrandLogo.types';

const BrandLogo: React.FC<BrandLogoProps> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 256 263'
      className={classNames(styles.root, className)}
    >
      <path
        fill='currentColor'
        className={styles.accent}
        fillRule='evenodd'
        d='m193.785 227.41 11.076-3.599c1.072-.348 1.941-1.544 1.941-2.67V83.167c0-6.01 4.633-12.386 10.348-14.243l12.811-4.163c5.715-1.857 10.348 1.51 10.348 7.519v137.974c0 1.126.869 1.758 1.94 1.409l9.118-2.962c2.5-.812 4.527-3.602 4.527-6.231V50.583c0-4.032-2.12-7.044-5.51-7.828L67.078.368c-1.491-.344-3.152-.238-4.839.31L10.48 17.496C4.762 19.353.13 25.73.13 31.739v145.766c0 4.031 2.12 7.044 5.51 7.828l14.474 3.346-18.73 51.459c-.807 2.22.404 4.45 2.705 4.981l8.335 1.925c2.302.531 4.823-.838 5.63-3.057l1.177-3.232c.808-2.219 3.328-3.588 5.63-3.057l41.675 9.622c2.301.531 3.512 2.761 2.704 4.981l-1.176 3.231c-.808 2.22.403 4.45 2.705 4.982l8.335 1.924c2.301.531 4.822-.837 5.63-3.057l18.719-51.431 85.494 19.77c1.491.344 3.152.238 4.838-.31Zm-90.332-19.46 44.729-122.892c.808-2.22-.403-4.45-2.704-4.982l-8.335-1.924c-2.302-.531-4.823.838-5.63 3.057l-1.177 3.232c-.808 2.22-3.328 3.588-5.63 3.057l-41.675-9.622c-2.301-.531-3.512-2.761-2.704-4.981l1.176-3.231c.808-2.22-.403-4.45-2.705-4.982l-8.335-1.924c-2.301-.531-4.822.837-5.63 3.057L20.114 188.68l83.339 19.271Zm-72.74 13.672c-2.302-.532-3.513-2.762-2.705-4.981l4.302-11.822c.808-2.22 3.33-3.588 5.63-3.057l41.675 9.621c2.302.532 3.513 2.762 2.705 4.982l-4.303 11.821c-.808 2.22-3.328 3.589-5.63 3.057l-41.675-9.621Zm13.08-35.937c-2.302-.531-3.513-2.761-2.705-4.981l4.302-11.821c.808-2.22 3.329-3.589 5.63-3.057l41.675 9.621c2.302.531 3.513 2.761 2.705 4.981l-4.303 11.822c-.808 2.219-3.329 3.588-5.63 3.057l-41.675-9.622Zm13.079-35.936c-2.302-.531-3.513-2.762-2.705-4.981l4.303-11.822c.808-2.219 3.329-3.588 5.63-3.057l41.675 9.622c2.302.531 3.512 2.761 2.705 4.981l-4.303 11.821c-.808 2.22-3.329 3.589-5.63 3.057l-41.675-9.621Zm13.08-35.936c-2.302-.532-3.513-2.762-2.705-4.982L71.55 97.01c.808-2.22 3.329-3.589 5.63-3.057l41.675 9.621c2.301.532 3.512 2.762 2.704 4.981l-4.302 11.822c-.808 2.22-3.329 3.588-5.631 3.057l-41.674-9.621Z'
        clipRule='evenodd'
      />
    </svg>
  );
};

const styles = {
  root: classes('h-9'),
  accent: classes('text-skin-accent'),
};

export default BrandLogo;
