'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import { useTable } from '../table-context/TableContext';
import TableExpandRow from './table-expand-row/TableExpandRow';
import TableNotFoundRow from './table-not-found-row/TableNotFoundRow';
import TableRow from './table-row/TableRow';
import TableSortableRow from './table-sortable-row/TableSortableRow';
import { TableBodyProps } from './TableBody.types';

const TableBody: React.FC<TableBodyProps> & {
  Row: typeof TableRow;
  ExpandRow: typeof TableExpandRow;
  SortableRow: typeof TableSortableRow;
  NotFoundRow: typeof TableNotFoundRow;
} = ({ className, row, expandRow, notFoundRow: NotFoundRow }) => {
  const { table, sortable } = useTable();

  const rows = table.getRowModel().rows;

  return (
    <tbody className={classNames(styles.root, className)}>
      {rows.length === 0
        ? NotFoundRow || <TableNotFoundRow />
        : rows.map((current) => (
            <Fragment key={current.id}>
              {(row && row(current)) ||
                (sortable && <TableSortableRow row={current} />) || (
                  <TableRow row={current} />
                )}
              {expandRow && expandRow(current)}
            </Fragment>
          ))}
    </tbody>
  );
};

const styles = {
  root: classes('bg-skin-primary'),
};

TableBody.Row = TableRow;
TableBody.ExpandRow = TableExpandRow;
TableBody.SortableRow = TableSortableRow;
TableBody.NotFoundRow = TableNotFoundRow;

export default TableBody;
