import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { AiState } from './Ai.slice.types';

const initialState: AiState = {
  prompt: '',
};

export const aiSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    changePrompt: (state, { payload }: PayloadAction<string>) => {
      state.prompt = payload;
    },
  },
});

export const aiReducer = aiSlice.reducer;

export const useAiActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(aiSlice.actions, dispatch),
    [dispatch],
  );
};
