import { UpdateUser, UpdateUserParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const updateUser = (builder: Builder) =>
  builder.mutation<void, UpdateUserParams & UpdateUser>({
    query: ({ id, ...body }) => ({
      url: `/user/update/${id}`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (res, err, { id }) =>
      (!err && [{ type: 'Users', id }]) || [],
  });
