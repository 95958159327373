import { Tab } from '@nowadays/base/types';
import { tabEndpoints } from '@nowadays/ui/services';
import {
  bindActionCreators,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { TabState } from './Tab.slice.types';

const initialState: TabState = {
  tabs: [],
};

export const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    changeTabs: (state, { payload }: PayloadAction<Tab[] | undefined>) => {
      state.tabs = payload;
    },
    changeActiveTab: (
      state,
      { payload }: PayloadAction<Tab | undefined | null>,
    ) => {
      state.activeTab = payload;
    },
    changeOverflowTabs: (
      state,
      { payload }: PayloadAction<Tab[] | undefined>,
    ) => {
      state.overflowTabs = payload;
    },
  },
});

export const tabReducer = tabSlice.reducer;

const changeTab = createAsyncThunk(
  'tab/changeTab',
  async ({ page, tab }: { page: string; tab: Tab }, { dispatch }) => {
    dispatch(
      tabEndpoints.util.updateQueryData('tabs', { page }, (draft) => {
        draft.tab = tab;
      }),
    );
  },
);

export const useTabActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators({ ...tabSlice.actions, changeTab }, dispatch),
    [dispatch],
  );
};
