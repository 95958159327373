import { DeletePage, DeletePageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const deletePage = (builder: Builder) =>
  builder.mutation<DeletePage, DeletePageParams>({
    query: (params) => ({
      url: `/page/delete`,
      method: 'DELETE',
      params,
    }),
    async onQueryStarted({ planner }, { dispatch, queryFulfilled }) {
      try {
        const {
          data: { deletedPage, lastEditedPage },
        } = await queryFulfilled;

        dispatch(
          pageEndpoints.util.updateQueryData('pages', { planner }, (draft) => {
            draft.splice(
              draft.findIndex((i) => i.id === deletedPage.id),
              1,
            );
          }),
        );

        dispatch(
          pageEndpoints.util.updateQueryData(
            'deletedPages',
            planner,
            (draft) => {
              draft.push(deletedPage);
            },
          ),
        );

        dispatch(
          pageEndpoints.util.updateQueryData(
            'page',
            { page: deletedPage.id },
            (draft) => {
              Object.assign(draft, undefined);
            },
          ),
        );

        if (lastEditedPage) {
          dispatch(
            pageEndpoints.util.updateQueryData(
              'undatedPage',
              { planner },
              (draft) => {
                draft.page = lastEditedPage;
              },
            ),
          );
        } else {
          dispatch(
            pageEndpoints.util.updateQueryData(
              'undatedPage',
              { planner },
              (draft) => {
                draft.page = null;
              },
            ),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
