import { Article, ArticlesQuery } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const articles = (builder: Builder) =>
  builder.query<Article[], ArticlesQuery>({
    query: (params) => ({
      url: '/article/articles',
      params,
    }),
    providesTags: (res) =>
      res
        ? [
            ...res.map(({ id }) => ({
              type: 'Articles' as const,
              id,
            })),
            { type: 'Articles', id: 'LIST' },
          ]
        : [{ type: 'Articles', id: 'LIST' }],
  });
