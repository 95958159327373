import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { DrawState } from './Draw.slice.types';

const initialState: DrawState = {};

export const drawSlice = createSlice({
  name: 'draw',
  initialState,
  reducers: {
    changeIsDrawable: (state, { payload }: PayloadAction<boolean>) => {
      state.isDrawable = payload;
    },
  },
});

export const drawReducer = drawSlice.reducer;

export const useDrawActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators({ ...drawSlice.actions }, dispatch),
    [dispatch],
  );
};
