import { classes } from '@nowadays/ui/utils';

import { SelectionTitleProps } from './SelectionTitle.types';

const SelectionTitle: React.FC<SelectionTitleProps> = ({ title }) => {
  return <div className={styles.root}>{title}</div>;
};

const styles = {
  root: classes('text-sm', 'text-skin-muted'),
};

export default SelectionTitle;
