import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';

const RichTextClearMarks = () => {
  const { editor } = useRichText();
  const translate = useTranslate();

  if (!editor) {
    return null;
  }

  return (
    <RichTextToolbarButton
      color='accent'
      variant='outlined'
      className={styles.button}
      onClick={() => editor.chain().focus().unsetAllMarks().run()}
    >
      {translate('ui.components.inputs.richText.clearMarks')}
    </RichTextToolbarButton>
  );
};

const styles = {
  button: classes('text-xs', 'px-2'),
};

export default RichTextClearMarks;
