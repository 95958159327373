'use client';

import { TableExpandRowProps } from './TableExpandRow.types';

const TableExpandRow: React.FC<TableExpandRowProps> = ({
  row,
  className,
  children,
}) => {
  const isExpanded = row.getIsExpanded();

  if (isExpanded) {
    return (
      <tr key={row.id} className={className}>
        <td colSpan={row.getVisibleCells().length}>{children}</td>
      </tr>
    );
  }

  return null;
};

export default TableExpandRow;
