import { baseApi } from '../base/query/Api';
import { favoriteSticker } from './FavoriteSticker.api';
import { stickers } from './Stickers.api';

export const stickerEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    stickers: stickers(builder),
    favoriteSticker: favoriteSticker(builder),
  }),
});

export const { useStickersQuery, useFavoriteStickerMutation } =
  stickerEndpoints;
