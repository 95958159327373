'use client';

import { TransitionFn } from '@react-spring/web';
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { createContext, useContext } from 'react';

interface BottomSheetContextProps {
  open: boolean;
  onClose: () => void;
  y: number;
  drag: () => ReactDOMAttributes;
  transition: TransitionFn<
    boolean,
    {
      height: string;
      opacity: number;
    }
  >;
}

export const BottomSheetContext = createContext<BottomSheetContextProps>({
  open: false,
  onClose: () => null,
  y: 0,
  drag: () => null,
  transition: () => null,
});

export const useBottomSheet = () => {
  return useContext(BottomSheetContext);
};
