import { AddWidgetSetParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addWidgetSet = (builder: Builder) =>
  builder.mutation<void, AddWidgetSetParams>({
    query: (body) => ({
      url: '/widget-set/add',
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['WidgetSets']) || [],
  });
