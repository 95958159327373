'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { LoaderProps } from './Loader.types';

const Loader: React.FC<LoaderProps> = ({ color = 'accent', className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className={classNames(styles.cube, styles.color[color])} />
      <div
        className={classNames(styles.cube, styles.cube2, styles.color[color])}
      />
      <div
        className={classNames(styles.cube, styles.cube3, styles.color[color])}
      />
      <div
        className={classNames(styles.cube, styles.cube4, styles.color[color])}
      />
    </div>
  );
};

const styles = {
  root: classes('w-10', 'h-10', 'p-2', 'rotate-45'),
  cube: classes(
    'scale-110',
    'float-left',
    'w-[50%]',
    'h-[50%]',
    'before:absolute',
    'before:content-[""]',
    'before:w-full',
    'before:h-full',
    'before:animate-loader',
    'before:origin-bottom-right',
  ),
  cube2: classes('scale-110', 'rotate-90', 'before:delay-300'),
  cube3: classes('scale-110', '-rotate-90', 'before:delay-1000'),
  cube4: classes('scale-110', 'rotate-180', 'before:delay-700'),
  color: {
    primary: classes('before:bg-skin-primary'),
    light: classes('before:bg-skin-light'),
    accent: classes('before:bg-skin-accent'),
    inverted: classes('before:bg-skin-inverted'),
  },
};

export default Loader;
