import { WebFontPack } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const webFontPacks = (builder: Builder) =>
  builder.query<WebFontPack[], void>({
    query: () => ({
      url: '/font/web-packs',
    }),
    providesTags: ['WebFontPacks'],
  });
