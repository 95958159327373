import {
  WeatherTrackerDatum,
  WeatherTrackerDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const weatherTrackerDatum = (builder: Builder) =>
  builder.query<WeatherTrackerDatum, WeatherTrackerDatumParams>({
    query: (params) => ({ url: '/datum/weather-tracker-datum', params }),
  });
