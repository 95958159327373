'use client';

import { generateHours } from '@nowadays/base/types';

import { useRegion } from '../../../region/RegionContext';
import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseHoursProps } from './CalendarBaseHours.types';

const CalendarBaseHours: React.FC<CalendarBaseHoursProps> = ({ children }) => {
  const { currentDay } = useCalendarBase();
  const { dayStartsAt, dayEndsAt } = useRegion();

  return generateHours(dayStartsAt, dayEndsAt).map((hour) =>
    children({
      day: currentDay,
      hour,
    }),
  );
};

export default CalendarBaseHours;
