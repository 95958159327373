'use client';

import { useState } from 'react';

import Notification from '../Notification';
import { NotificationItem } from '../Notification.types';
import { NotificationContext } from './NotificationContext';

interface NotificationProviderProps {
  children: React.ReactNode;
}

let id = 0;

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const addNotification = (notification: Omit<NotificationItem, 'key'>) => {
    setNotifications((state) => [
      ...state,
      { key: id++, dismissible: true, ...notification },
    ]);
  };

  const deleteNotification = (key: number) =>
    setNotifications((state) =>
      state.filter((notification) => notification.key !== key),
    );

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, deleteNotification }}
    >
      {children}
      <Notification />
    </NotificationContext.Provider>
  );
};
