import { Loader } from '@nowadays/ui/components';
import { classes } from '@nowadays/ui/utils';
import { Fragment, Suspense } from 'react';

import { useLoading } from '../context/LoadingContext';
import { LoadingWrapperProps } from './LoadingWrapper.types';

const FullScreenLoader: React.FC = () => {
  return (
    <div className={styles.root}>
      <Loader className={styles.loader} />
    </div>
  );
};

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ children }) => {
  const { isLoading } = useLoading();

  return (
    <Fragment>
      {isLoading && <FullScreenLoader />}

      <Suspense>{children}</Suspense>
    </Fragment>
  );
};

const styles = {
  root: classes(
    'z-[10000]',
    'absolute',
    'w-full',
    'h-full',
    'bg-skin-base',
    'grid',
    'place-content-center',
  ),
  loader: classes(
    'absolute',
    'top-[50%]',
    'left-[50%]',
    '-translate-x-[50%]',
    '-translate-y-[50%]',
  ),
};

export default LoadingWrapper;
