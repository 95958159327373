import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { ModuleType, SheetState } from './Sheet.slice.types';

const initialState: SheetState = {
  isModuleOpen: false,
  activeModule: 'widget',
};

export const sheetSlice = createSlice({
  name: 'sheet',
  initialState,
  reducers: {
    changeActiveModule: (state, { payload }: PayloadAction<ModuleType>) => {
      state.activeModule = payload;
    },
    changeIsModuleOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isModuleOpen = payload;
    },
  },
});

export const sheetReducer = sheetSlice.reducer;

export const useSheetActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators({ ...sheetSlice.actions }, dispatch),
    [dispatch],
  );
};
