import { classes, classNames } from '@nowadays/ui/utils';
import { isMacOs, isMobile } from 'react-device-detect';

import { ShortcutKeyMap, ShortcutProps } from './Shortcut.types';

const Shortcut: React.FC<ShortcutProps> = ({
  shortcut,
  color = 'primary',
  className,
}) => {
  if (isMobile) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.root,
        styles.text[color],
        styles.background[color],
        styles.border[color],
        className,
      )}
    >
      {isMacOs ? 'Cmd' : 'Ctrl'}+{ShortcutKeyMap[shortcut]}
    </div>
  );
};

const styles = {
  root: classes(
    'px-1',
    'rounded-md',
    'text-2xs',
    'border-2',
    'border-opacity-40',
    'text-opacity-70',
    'grid',
    'place-content-center',
  ),
  text: {
    primary: classes('text-skin-muted'),
    base: classes('text-skin-muted'),
    inverted: classes('text-skin-inverted'),
    muted: classes('text-skin-muted'),
  },
  background: {
    primary: classes('bg-skin-primary'),
    base: classes('bg-skin-base'),
    inverted: classes('bg-skin-inverted'),
    muted: classes('bg-skin-muted'),
  },
  border: {
    primary: classes('border-skin-silent'),
    base: classes('border-skin-silent'),
    inverted: classes('border-skin-silent'),
    muted: classes('border-skin-muted'),
  },
};

export default Shortcut;
