import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgCup = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M5.6 6h8a1.6 1.6 0 0 1 1.6 1.6v8a3.2 3.2 0 0 1-3.2 3.2H7.2A3.2 3.2 0 0 1 4 15.6v-8A1.6 1.6 0 0 1 5.6 6Zm9.6 1.6h.8a4 4 0 0 1 0 8h-.8'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCup);
export default ForwardRef;
