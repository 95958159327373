import { Divider, RouterMenu } from '@nowadays/ui/components';
import { classes } from '@nowadays/ui/utils';

import { Routes } from '../router/Router';
import SidebarFooter from './components/sidebar-footer/SidebarFooter';
import SidebarHeader from './components/sidebar-header/SidebarHeader';

const Sidebar = () => {
  return (
    <aside className={styles.root}>
      <div className={styles.header}>
        <SidebarHeader />
        <RouterMenu routes={Routes} />
      </div>
      <div className={styles.footer}>
        <Divider />
        <SidebarFooter />
      </div>
    </aside>
  );
};

const styles = {
  root: classes(
    'px-3',
    'py-4',
    'w-60',
    'flex',
    'flex-col',
    'justify-between',
    'bg-skin-primary',
    'border-r-2',
    'border-opacity-60',
    'border-skin-silent',
  ),
  header: classes('flex', 'flex-col', 'space-y-8'),
  footer: classes('space-y-3'),
};

export default Sidebar;
