import { Builder } from '../base/query/Api';

export const deleteStickerPack = (builder: Builder) =>
  builder.mutation<void, string>({
    query: (pack) => ({
      url: `/sticker-pack/delete/${pack}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['StickerPacks']) || [],
  });
