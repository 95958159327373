'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import Text from '../../../inputs/text/Text';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormTextProps } from './FormText.types';

const FormText: React.FC<FormTextProps> = ({
  field,
  type,
  label,
  disabled,
  defaultValue,
  input,
  container,
  ...props
}) => {
  const { control, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div key={field} className={classNames(styles.root, container?.className)}>
      {label && <FieldLabel label={label} htmlFor={field} />}

      <Controller
        control={control}
        name={field}
        defaultValue={defaultValue}
        render={({ field: { value, ...rest } }) => (
          <Text
            id={field}
            key={field}
            type={type}
            value={value}
            disabled={isDisable}
            input={{ error: isError, disable: isDisable, ...input }}
            {...rest}
            {...props}
          />
        )}
      />

      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
};

export default FormText;
