import { Builder } from '../base/query/Api';

export const permanentlyDeleteTemplate = (builder: Builder) =>
  builder.mutation<void, string>({
    query: (template) => ({
      url: `/template/permanently-delete/${template}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['Templates']) || [],
  });
