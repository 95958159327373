'use client';

import { isSameYear, isThisYear as isThisYearFn } from 'date-fns';
import { useMemo } from 'react';

import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseYearProps } from './CalendarBaseYear.types';

const CalendarBaseYear: React.FC<CalendarBaseYearProps> = ({
  year,
  children,
}) => {
  const { currentYear, currentPeriod, handleYearChanged, handlePeriodChanged } =
    useCalendarBase();

  const isSelected = useMemo(
    () =>
      currentPeriod === 'yearly' &&
      (year ? isSameYear(currentYear, year) : true),
    [year, currentPeriod, currentYear],
  );

  const isThisYear = year && isThisYearFn(year);

  const onChanged = (year: Date) => {
    handleYearChanged(year);
    handlePeriodChanged('yearly');
  };

  return children({
    year: year || currentYear,
    onChanged,
    isSelected,
    isThisYear,
  });
};

export default CalendarBaseYear;
