'use client';

import { Rem, REM_OPTIONS, Rems } from '@nowadays/base/types';
import { useEffect, useState } from 'react';

const isServer = typeof window === 'undefined';

const initRem = (size: Rem) => {
  if (isServer) {
    return;
  }

  (document.querySelector(':root') as HTMLElement).style.setProperty(
    'font-size',
    `${Rems[size]}px`,
  );
};

const findRem = (size?: Rem) => {
  if (isServer) {
    return;
  }

  return REM_OPTIONS.find((i) => i === size) || 'medium';
};

export const useRem = (initial?: Rem) => {
  const [currentRem, setCurrentRem] = useState<Rem>(findRem(initial));

  useEffect(() => {
    initRem(currentRem);
  }, [currentRem]);

  const changeRem = (value: Rem) => setCurrentRem(findRem(value));

  const calculateRem = (value: number) => value * Rems[currentRem];

  return { currentRem, changeRem, calculateRem };
};
