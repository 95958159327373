'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import Input from '../input/Input';
import { TextAreaProps } from './TextArea.types';

const TextArea: React.FC<TextAreaProps> = (
  {
    input,
    className,
    disabled,
    debounce = 350,
    value,
    onChange,
    onDebounceChanged,
    readOnly,
    ...props
  },
  ref: React.Ref<HTMLTextAreaElement>,
) => {
  const { disable, ...inputRest } = input || {};
  const [state, setState] = useState<string>(value || '');

  useEffect(() => {
    if (value !== undefined && value !== state) {
      setState(value);
    }
  }, [value]);

  useEffect(() => {
    if (state === undefined || (value || '') === state) {
      return;
    }

    const timeout = setTimeout(() => {
      onDebounceChanged && onDebounceChanged(state);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [state]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e);
    setState(e.target.value);
  };

  return (
    <Input disable={disabled || disable} readOnly={readOnly} {...inputRest}>
      {({
        inputStyles,
        focusStyles,
        backgroundStyles,
        borderStyles,
        errorStyles,
        disabledStyles,
        customStyles,
        boxProps,
      }) => (
        <Input.Box
          className={classNames(
            backgroundStyles,
            borderStyles,
            errorStyles,
            disabledStyles,
            customStyles,
          )}
          {...boxProps}
        >
          <TextareaAutosize
            ref={ref}
            value={state}
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleChange}
            className={classNames(
              inputStyles,
              focusStyles,
              styles.root,
              className,
            )}
            {...props}
          />
        </Input.Box>
      )}
    </Input>
  );
};

const styles = {
  root: classes('py-2', 'block', '!min-h-[3.5rem]', 'resize-none'),
};

export default React.forwardRef(TextArea);
