import { baseApi } from '../base/query/Api';
import { addPage } from './AddPage.api';
import { datedPage } from './DatedPage.api';
import { deletedPages } from './DeletedPages.api';
import { deletePage } from './DeletePage.api';
import { favoritePage } from './FavoritePage.api';
import { page } from './Page.api';
import { pages } from './Pages.api';
import { permanentlyDeletePage } from './PermanentlyDeletePage.api';
import { permanentlyDeletePages } from './PermanentlyDeletePages.api';
import { restorePage } from './RestorePage.api';
import { undatedPage } from './UndatedPage.api';

export const pageEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    pages: pages(builder),
    page: page(builder),
    datedPage: datedPage(builder),
    undatedPage: undatedPage(builder),
    addPage: addPage(builder),
    favoritePage: favoritePage(builder),
    deletedPages: deletedPages(builder),
    deletePage: deletePage(builder),
    restorePage: restorePage(builder),
    permanentlyDeletePage: permanentlyDeletePage(builder),
    permanentlyDeletePages: permanentlyDeletePages(builder),
  }),
});

export const {
  usePagesQuery,
  usePageQuery,
  useDatedPageQuery,
  useUndatedPageQuery,
  useAddPageMutation,
  useFavoritePageMutation,
  useDeletedPagesQuery,
  useDeletePageMutation,
  useRestorePageMutation,
  usePermanentlyDeletePageMutation,
  usePermanentlyDeletePagesMutation,
} = pageEndpoints;
