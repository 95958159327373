'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import CaptionSearch from './caption-filter/CaptionSearch';
import CaptionRefresh from './caption-refresh/CaptionRefresh';
import CaptionTitle from './caption-title/CaptionTitle';
import { TableCaptionProps } from './TableCaption.types';

const TableCaption: React.FC<TableCaptionProps> = ({
  title,
  className,
  search = true,
  refresh,
  children,
}) => {
  return (
    <caption className={styles.root}>
      <div className={styles.container}>
        <div className={classNames(styles.head, className)}>
          <div className={styles.title}>
            {title && <CaptionTitle title={title} />}
            {refresh && <CaptionRefresh {...refresh} />}
          </div>
          {children}
        </div>
        {search && <CaptionSearch />}
      </div>
    </caption>
  );
};

const styles = {
  root: classes('bg-skin-primary', 'text-start', 'px-4', 'py-3'),
  title: classes('flex', 'items-center', 'space-x-3'),
  container: classes('flex', 'justify-between', 'items-end'),
  head: classes('flex', 'items-center', 'space-x-3'),
};

export default TableCaption;
