'use client';

import {
  PopoverPanel as HeadlessPopover,
  Transition as HeadlessTransition,
} from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import React, { Fragment } from 'react';

import Floating from '../../../floating/Floating';
import { usePopover } from '../popover-context/PopoverContext';
import { PopoverPanelProps } from './PopoverPanel.types';

const PopoverPanel: React.FC<PopoverPanelProps> = (
  { className, children, style, floating, ...props },
  ref: React.Ref<HTMLDivElement>,
) => {
  const { open, reference, zIndex } = usePopover();
  const { className: floatingClasses, ...floatingProps } = floating || {};

  return (
    <Floating
      immediate
      open={open}
      reference={reference}
      className={floatingClasses}
      style={{ zIndex, ...style }}
      {...floatingProps}
    >
      <HeadlessTransition
        appear
        show={open || false}
        enter={styles.enter}
        enterFrom={styles.enterFrom}
        enterTo={styles.enterTo}
        leave={styles.leave}
        leaveFrom={styles.leaveFrom}
        leaveTo={styles.leaveTo}
      >
        <HeadlessPopover
          as='div'
          ref={ref}
          className={classNames(styles.panel, className)}
          {...props}
        >
          {({ close, open }) => (
            <Fragment>
              {typeof children === 'function'
                ? children({ open, close })
                : children}
            </Fragment>
          )}
        </HeadlessPopover>
      </HeadlessTransition>
    </Floating>
  );
};

const styles = {
  panel: classes(
    'p-2',
    'rounded-lg',
    'bg-skin-primary',
    'max-h-[94dvh]',
    'max-w-[94dvw]',
    'overflow-auto',
    'shadow-lg',
    'bg-skin-primary',
    'rounded-lg',
    'border-2',
    'border-opacity-60',
    'border-skin-silent',
  ),
  enter: classes('transition', 'ease-out', 'duration-100'),
  enterFrom: classes('transform', 'opacity-0', 'scale-95'),
  enterTo: classes('transform', 'opacity-100', 'scale-100'),
  leave: classes('transition', 'ease-in', 'duration-100'),
  leaveFrom: classes('transform', 'opacity-100', 'scale-100'),
  leaveTo: classes('transform', 'opacity-0', 'scale-95'),
};

export default React.forwardRef(PopoverPanel);
