import { Widgets } from '@nowadays/base/types';
import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { WidgetState } from './Widget.slice.types';

const initialState: WidgetState = {
  search: '',
  searchListed: '',
  overflows: [],
};

export const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    changeSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    changeSearchListed: (state, { payload }: PayloadAction<string>) => {
      state.searchListed = payload;
    },
    changeListedWidget: (state, action: PayloadAction<Widgets | undefined>) => {
      state.listed = action.payload;
      state.searchListed = '';
    },
    changeOverflows: (state, { payload }: PayloadAction<string[]>) => {
      state.overflows = payload;
    },
  },
});

export const widgetReducer = widgetSlice.reducer;

export const useWidgetActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(widgetSlice.actions, dispatch),
    [dispatch],
  );
};
