import { WidgetCode } from '../element/WidgetElement.types';

export enum WidgetCodes {
  Calendar = 'calendar',
  ContentPlanner = 'content-planner',
  ExpenseTracker = 'expense-tracker',
  Focus = 'focus',
  HabitTracker = 'habit-tracker',
  ImageBox = 'image-box',
  Intention = 'intention',
  MoodTracker = 'mood-tracker',
  Pomodoro = 'pomodoro',
  PriorityList = 'priority-list',
  Reflection = 'reflection',
  ScheduleTable = 'schedule-table',
  TextBox = 'text-box',
  ToDoList = 'to-do-list',
  WaterIntakeTracker = 'water-intake-tracker',
  WeatherTracker = 'weather-tracker',
  ContentSummary = 'content-summary',
  ExpenseSummary = 'expense-summary',
  HabitSummary = 'habit-summary',
  MoodSummary = 'mood-summary',
  ToDoSummary = 'to-do-summary',
  WaterIntakeSummary = 'water-intake-summary',
  WeatherSummary = 'weather-summary',
}

export type WidgetRowSize = 0.375 | 0.5 | 1 | 2 | 3 | 4;

export type WidgetColSize = 0.5 | 1 | 2 | 3 | 4;

export type WidgetSize = {
  row: WidgetRowSize;
  col: WidgetColSize;
};

export interface Widget {
  id: string;
  setId: string;
  code: WidgetCode;
  name: string;
  keywords?: string;
  thumbnail: string;
  favorite?: boolean;
}

export type Widgets = {
  id: string;
  name: string;
  widgets: Widget[];
};

export type WidgetsParams = {
  planner: string;
};
