import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgCry = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='m16.846 7.846-2.77 2.077 2.77 1.385M7.154 7.846l2.769 2.077-2.77 1.385m1.385 4.846s2.077-3.462 3.462-3.462c1.385 0 3.461 3.462 3.461 3.462'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCry);
export default ForwardRef;
