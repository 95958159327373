import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

const Title = () => {
  const translate = useTranslate();
  return (
    <div className={styles.root}>
      {translate('admin.common.sidebar.footer.title.text')}
    </div>
  );
};

const styles = {
  root: classes('font-semibold', 'text-skin-muted'),
};

export default Title;
