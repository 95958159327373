import { CloseReportParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const closeReport = (builder: Builder) =>
  builder.mutation<void, CloseReportParams>({
    query: ({ id }) => ({
      url: `/report/close/${id}`,
      method: 'POST',
    }),
    invalidatesTags: (res, err) => (!err && ['Reports']) || [],
  });
