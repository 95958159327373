'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Button from '../../../button/button/Button';
import { useRegion } from '../../../region/RegionContext';
import CalendarBase from '../../calendar-base/CalendarBase';
import { CalendarYearProps } from './CalendarYear.types';

const CalendarYear: React.FC<CalendarYearProps> = ({
  year,
  color,
  variant,
  className,
  onClick,
  children,
  ...props
}) => {
  const { format } = useRegion();

  return (
    <CalendarBase.Year year={year}>
      {({ year, onChanged, isSelected, isThisYear }) => (
        <Button
          onClick={(e) => {
            onChanged(year);
            onClick && onClick(e);
          }}
          color={
            color
              ? typeof color === 'function'
                ? color({ isSelected, isThisYear })
                : color
              : isSelected
                ? 'silent'
                : 'primary'
          }
          variant={
            variant
              ? typeof variant === 'function'
                ? variant({ isSelected, isThisYear })
                : variant
              : isSelected
                ? 'contained'
                : 'flat'
          }
          className={classNames(
            styles.button,
            isSelected && styles.selected,
            typeof className === 'function'
              ? className({ isSelected, isThisYear })
              : className,
          )}
          {...props}
        >
          {children ? children({ year, onChanged }) : format(year, 'yyyy')}
        </Button>
      )}
    </CalendarBase.Year>
  );
};

const styles = {
  button: classes('duration-0', 'text-base', 'font-medium'),
  selected: classes('font-semibold'),
};

export default CalendarYear;
