import { ErrorCode } from '@nowadays/base/types';
import { NotFound, WentWrong } from '@nowadays/ui/components';
import { isServerError } from '@nowadays/ui/services';
import { FallbackProps } from 'react-error-boundary';

const Fallback: React.FC<FallbackProps> = ({ error }) => {
  if (
    error &&
    isServerError(error) &&
    error.data.error?.code === ErrorCode.NotFoundError
  ) {
    return <NotFound />;
  }

  return <WentWrong />;
};

export default Fallback;
