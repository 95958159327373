import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgStarOn = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M14 7.886c.323.953 1.248 1.598 2.292 1.598M5.098 7.014a3.629 3.629 0 0 0 3.45-2.507c1.087-3.343 5.817-3.343 6.903 0a3.629 3.629 0 0 0 3.451 2.508c3.515 0 4.977 4.498 2.133 6.564a3.629 3.629 0 0 0-1.318 4.057c1.086 3.343-2.74 6.123-5.584 4.057a3.629 3.629 0 0 0-4.266 0c-2.844 2.066-6.67-.714-5.584-4.057a3.629 3.629 0 0 0-1.318-4.057C.12 11.513 1.583 7.015 5.098 7.015Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStarOn);
export default ForwardRef;
