import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgSearchAi = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M18.279 16.864a1 1 0 0 0-1.415 1.415l1.415-1.415Zm2.014 4.843a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm-3.429-3.428 3.429 3.428 1.414-1.414-3.428-3.429-1.415 1.415Zm-6.15-.85A6.714 6.714 0 0 1 4 10.714H2a8.714 8.714 0 0 0 8.714 8.715v-2Zm6.715-6.715a6.714 6.714 0 0 1-6.715 6.715v2a8.714 8.714 0 0 0 8.715-8.715h-2ZM10.714 4a6.714 6.714 0 0 1 6.715 6.714h2A8.714 8.714 0 0 0 10.714 2v2Zm0-2A8.714 8.714 0 0 0 2 10.714h2A6.714 6.714 0 0 1 10.714 4V2Z'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M7.349 11.011c-.398-.07-.398-.651 0-.722a3.63 3.63 0 0 0 2.902-2.826l.024-.112c.086-.4.646-.402.736-.003l.029.13a3.648 3.648 0 0 0 2.91 2.81c.4.07.4.654 0 .725a3.648 3.648 0 0 0-2.91 2.809l-.03.13c-.089.4-.649.397-.735-.003l-.024-.112a3.63 3.63 0 0 0-2.902-2.826Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSearchAi);
export default ForwardRef;
