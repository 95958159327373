import { AddStickerPackParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addStickerPack = (builder: Builder) =>
  builder.mutation<void, AddStickerPackParams>({
    query: (body) => ({
      url: '/sticker-pack/add',
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['StickerPacks']) || [],
  });
