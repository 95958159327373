import { baseApi } from '../base/query/Api';
import { addFontPack } from './AddFontPack.api';
import { deleteFontPack } from './DeleteFontPack.api';
import { fontPacks } from './FontPacks.api';
import { fonts } from './Fonts.api';
import { uploadWebFontPack } from './UploadWebFontPack.api';
import { webFontPacks } from './WebFontPacks.api';
import { webFontVariants } from './WebFontVariants.api';

export const fontEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fonts: fonts(builder),
    fontPacks: fontPacks(builder),
    addFontPack: addFontPack(builder),
    deleteFontPack: deleteFontPack(builder),
    webFontPacks: webFontPacks(builder),
    webFontVariants: webFontVariants(builder),
    uploadWebFontPack: uploadWebFontPack(builder),
  }),
});

export const {
  useFontsQuery,
  useLazyFontsQuery,
  useFontPacksQuery,
  useAddFontPackMutation,
  useDeleteFontPackMutation,
  useWebFontPacksQuery,
  useWebFontVariantsQuery,
  useUploadWebFontPackMutation,
} = fontEndpoints;
