'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { useEffect } from 'react';
import React from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';

import FieldError from '../field-error/FieldError';
import FieldLabel from '../field-label/FieldLabel';
import FormCheckbox from '../form-field/form-checkbox/FormCheckbox';
import FormColorPicker from '../form-field/form-color-picker/FormColorPicker';
import FormCombobox from '../form-field/form-combobox/FormCombobox';
import FormDatePicker from '../form-field/form-date-picker/FormDatePicker';
import FormFilePicker from '../form-field/form-file-picker/FormFilePicker';
import FormImagePicker from '../form-field/form-image-picker/FormImagePicker';
import FormListbox from '../form-field/form-listbox/FormListbox';
import FormRadio from '../form-field/form-radio/FormRadio';
import FormRichText from '../form-field/form-rich-text/FormRichText';
import FormSelectbox from '../form-field/form-selectbox/FormSelectbox';
import FormSwitch from '../form-field/form-switch/FormSwitch';
import FormText from '../form-field/form-text/FormText';
import FormTextArea from '../form-field/form-textarea/FormTextArea';
import FormFooter from '../form-footer/FormFooter';
import FormLoading from '../form-loading/FormLoading';
import FormPanel from '../form-panel/FormPanel';
import FormTitle from '../form-title/FormTitle';
import { FormProps } from './Form.types';

const Form = <T extends FieldValues>(
  { hook, className, children, handleSubmit, ...props }: FormProps<T>,
  ref: React.Ref<HTMLFormElement>,
) => {
  useEffect(() => {
    return () => hook.reset();
  }, [hook]);

  const isLoading = hook.formState.isLoading;

  return (
    <FormProvider {...hook}>
      <form
        ref={ref}
        onSubmit={handleSubmit && hook.handleSubmit(handleSubmit)}
        className={classNames(styles.root, className)}
        {...props}
      >
        {children}
        <FormLoading loading={isLoading} />
      </form>
    </FormProvider>
  );
};

const styles = {
  root: classes('relative', 'flex', 'flex-col', 'gap-2'),
};

export default Object.assign(React.forwardRef(Form), {
  Title: FormTitle,
  Panel: FormPanel,
  Footer: FormFooter,
  Label: FieldLabel,
  Error: FieldError,
  Text: FormText,
  RichText: FormRichText,
  TextArea: FormTextArea,
  Checkbox: FormCheckbox,
  Listbox: FormListbox,
  Combobox: FormCombobox,
  Selectbox: FormSelectbox,
  Switch: FormSwitch,
  Radio: FormRadio,
  DatePicker: FormDatePicker,
  FilePicker: FormFilePicker,
  ImagePicker: FormImagePicker,
  ColorPicker: FormColorPicker,
});
