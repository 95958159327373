import { UpdateEmail } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const updateEmail = (builder: Builder) =>
  builder.mutation<void, UpdateEmail>({
    query: (body) => ({
      url: '/email/update',
      method: 'PUT',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['Emails']) || [],
  });
