import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgRotateLeft = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M4 11.9a1 1 0 1 0-2 0h2Zm2.6-7.2.6.8-.6-.8ZM4.8 6.5l.74.672.031-.035L5.6 7.1l-.8-.6Zm.336-4.496a1 1 0 1 0-2-.008l2 .008ZM4.128 3.787l-1-.004 1 .004ZM7.44 7.1l.005 1-.005-1Zm1.792.992a1 1 0 0 0-.009-2l.01 2Zm-4.736-1.68-.79.612.79-.612Zm.32.32-.613.79.612-.79ZM2 11.9c0 5.523 4.477 10 10 10v-2a8 8 0 0 1-8-8H2Zm10 10c5.523 0 10-4.477 10-10h-2a8 8 0 0 1-8 8v2Zm10-10c0-5.523-4.477-10-10-10v2a8 8 0 0 1 8 8h2Zm-10-10a9.96 9.96 0 0 0-6.002 2L7.2 5.5A7.96 7.96 0 0 1 12 3.9v-2Zm-6.002 2a10.053 10.053 0 0 0-1.997 1.998L5.599 7.1a8.053 8.053 0 0 1 1.6-1.6L6 3.9ZM3.136 1.997l-.008 1.787 2 .009.008-1.788-2-.008ZM7.445 8.1l1.787-.008-.009-2-1.787.008.009 2ZM3.128 3.783c-.003.689-.007 1.29.046 1.777.056.507.183 1.015.531 1.464L5.287 5.8c-.03-.038-.089-.13-.124-.457-.038-.348-.038-.815-.035-1.551l-2-.01Zm4.308 2.316c-.736.003-1.204.004-1.551-.034-.328-.036-.42-.095-.458-.124L4.203 7.522c.45.348.958.476 1.465.531.486.053 1.088.05 1.777.046l-.009-2Zm-3.73.925c.09.118.19.227.298.329l1.367-1.46a.785.785 0 0 1-.084-.093L3.705 7.024Zm.298.329c.064.06.13.116.2.17L5.426 5.94a.785.785 0 0 1-.056-.048l-1.367 1.46Zm.056-1.527-.112.124 1.48 1.346.112-.124-1.48-1.346Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRotateLeft);
export default ForwardRef;
