import { ToDoListDatum, ToDoListDatumParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const toDoListDatum = (builder: Builder) =>
  builder.query<ToDoListDatum, ToDoListDatumParams>({
    query: (params) => ({ url: '/datum/to-do-list-datum', params }),
    serializeQueryArgs: ({ queryArgs }) => {
      const args = { ...queryArgs };
      delete args.date;
      return args;
    },
  });
