'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import CalendarBase from '../calendar-base/CalendarBase';
import { CalendarProps } from './Calendar.types';
import CalendarDay from './calendar-day/CalendarDay';
import CalendarDays from './calendar-days/CalendarDays';
import CalendarHead from './calendar-head/CalendarHead';
import CalendarIterator from './calendar-iterator/CalendarIterator';
import CalendarMonth from './calendar-month/CalendarMonth';
import CalendarMonths from './calendar-months/CalendarMonths';
import CalendarPanel from './calendar-panel/CalendarPanel';
import CalendarWeek from './calendar-week/CalendarWeek';
import CalendarWeeks from './calendar-weeks/CalendarWeeks';
import CalendarYear from './calendar-year/CalendarYear';
import CalendarYears from './calendar-years/CalendarYears';

const Calendar: React.FC<CalendarProps> = (
  {
    date,
    period,
    onChange,
    children,
    disabledBefore,
    disabledAfter,
    className,
    ...props
  },
  ref: React.MutableRefObject<HTMLDivElement>,
) => {
  return (
    <CalendarBase
      date={date}
      period={period}
      onChange={onChange}
      disabledBefore={disabledBefore}
      disabledAfter={disabledAfter}
    >
      <div ref={ref} className={classNames(styles.root, className)} {...props}>
        {children}
      </div>
    </CalendarBase>
  );
};

const RefCalendar = React.forwardRef(Calendar);

const styles = {
  root: classes('w-full', 'h-full', 'flex', 'flex-col'),
};

export default Object.assign(RefCalendar, {
  Head: CalendarHead,
  Panel: CalendarPanel,
  Years: CalendarYears,
  Year: CalendarYear,
  Months: CalendarMonths,
  Month: CalendarMonth,
  Weeks: CalendarWeeks,
  Week: CalendarWeek,
  Days: CalendarDays,
  Day: CalendarDay,
  Iterator: CalendarIterator,
});
