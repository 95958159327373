import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgMultiSize = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <rect
      width={9}
      height={5}
      x={3}
      y={6}
      stroke='currentColor'
      strokeWidth={2}
      rx={1}
    />
    <mask id='a' fill='#fff'>
      <rect width={9} height={6} x={3} y={13} rx={1} />
    </mask>
    <rect
      width={9}
      height={6}
      x={3}
      y={13}
      stroke='currentColor'
      strokeWidth={4}
      mask='url(#a)'
      rx={1}
    />
    <rect
      width={6}
      height={14}
      x={15}
      y={5}
      stroke='currentColor'
      strokeWidth={2}
      rx={1.6}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMultiSize);
export default ForwardRef;
