import { DeleteNamespaceParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deleteNamespace = (builder: Builder) =>
  builder.mutation<void, DeleteNamespaceParams>({
    query: ({ namespace }) => ({
      url: `/locale/namespaces/${namespace}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['Namespaces']) || [],
  });
