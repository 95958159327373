import { SaveAsTemplate, Template } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const saveAsTemplate = (builder: Builder) =>
  builder.mutation<Template, SaveAsTemplate>({
    query: (body) => ({
      url: '/template/save-as',
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['Templates']) || [],
  });
