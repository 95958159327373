import { AddFontPack, FontPackAdded } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addFontPack = (builder: Builder) =>
  builder.mutation<FontPackAdded[], AddFontPack>({
    query: (body) => ({
      url: '/font/add-pack',
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['FontPacks']) || [],
  });
