'use client';

import { useState } from 'react';

import { LoadingContext, WaitFor } from './LoadingContext';

interface LoadingProviderProps {
  waitFor: WaitFor[];
  children: React.ReactNode;
}

const waitForToObject = (waitFor: WaitFor[]) => {
  return waitFor.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});
};

export const LoadingProvider: React.FC<LoadingProviderProps> = ({
  waitFor,
  children,
}) => {
  const initial = waitForToObject(waitFor);

  const [loadingStates, setLoadingStates] = useState(initial);

  const stopLoading = (waited: WaitFor) =>
    setLoadingStates((prev) => ({ ...prev, [waited]: false }));

  const isLoading = Object.values(loadingStates).some((state) => state);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        stopLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
