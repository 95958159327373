import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';

const RichTextOrderedList = () => {
  const { editor } = useRichText();

  if (!editor) {
    return null;
  }

  return (
    <RichTextToolbarButton
      name='OrderedList'
      active={editor.isActive('orderedList')}
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
    />
  );
};

export default RichTextOrderedList;
