import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgRedo = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M15.71 12.29a1 1 0 0 0 1.408 1.42l-1.408-1.42Zm2.114-.688.704.71-.704-.71Zm0-5.204.704-.71-.704.71Zm-.706-2.108a1 1 0 1 0-1.408 1.42l1.408-1.42Zm2.48 4.96.992.127-.992-.126Zm0-.5.992-.127-.992.126ZM18 20a1 1 0 1 0 0-2v2Zm-.882-6.29 1.41-1.398-1.407-1.42-1.41 1.398 1.407 1.42Zm1.41-8.022-1.41-1.398-1.408 1.42 1.41 1.398 1.408-1.42Zm0 6.624c.546-.54 1.016-1.005 1.352-1.423.349-.434.634-.914.71-1.512l-1.984-.253c-.01.082-.05.22-.285.513-.248.309-.621.681-1.2 1.255l1.407 1.42Zm-1.407-5.204c.579.574.952.946 1.2 1.255.235.293.275.431.285.513l1.984-.253c-.076-.598-.361-1.078-.71-1.512-.336-.419-.806-.883-1.352-1.423l-1.407 1.42ZM19 8h-9v2h9V8Zm-9 12h8v-2h-8v2Zm-6-6a6 6 0 0 0 6 6v-2a4 4 0 0 1-4-4H4Zm6-6a6 6 0 0 0-6 6h2a4 4 0 0 1 4-4V8Zm10.59 1.377A2.97 2.97 0 0 0 20.614 9h-2a.993.993 0 0 1-.008.124l1.984.253ZM20.614 9a2.97 2.97 0 0 0-.024-.377l-1.984.253a.993.993 0 0 1 .008.124h2ZM19 10h.614V8H19v2Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRedo);
export default ForwardRef;
