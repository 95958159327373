import { ReorderStickerPack } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const reorderStickerPack = (builder: Builder) =>
  builder.mutation<void, ReorderStickerPack[]>({
    query: (body) => ({
      url: `/sticker-pack/reorder`,
      method: 'POST',
      body,
    }),
  });
