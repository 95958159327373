'use client';

import { useState } from 'react';

import { ColorPickerContext } from './ColorPickerContext';

export interface ColorPickerProviderProps {
  color?: string;
  onChange?: (color: string) => void;
  children: React.ReactNode;
}

export const ColorPickerProvider: React.FC<ColorPickerProviderProps> = ({
  color,
  onChange,
  children,
}) => {
  const [value, setValue] = useState<string>(color);

  const changeColor = (color: string) => {
    setValue(color?.toUpperCase());
    onChange && onChange(color?.toUpperCase());
  };

  return (
    <ColorPickerContext.Provider
      value={{
        color: value,
        changeColor,
      }}
    >
      {children}
    </ColorPickerContext.Provider>
  );
};
