import { Page, PermanentlyDeletePageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const permanentlyDeletePage = (builder: Builder) =>
  builder.mutation<Page, PermanentlyDeletePageParams>({
    query: (params) => ({
      url: `/page/permanently-delete`,
      method: 'DELETE',
      params,
    }),
    async onQueryStarted({ planner }, { dispatch, queryFulfilled }) {
      try {
        const { data: permanentlyDeletedPage } = await queryFulfilled;

        dispatch(
          pageEndpoints.util.updateQueryData(
            'deletedPages',
            planner,
            (draft) => {
              draft.splice(
                draft.findIndex((i) => i.id === permanentlyDeletedPage.id),
                1,
              );
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
