import { admin } from './admin/en_US';
import { api } from './api/en_US';
import { app } from './app/en_US';
import { base } from './base/en_US';
import { home } from './home/en_US';
import { ui } from './ui/en_US';

export const en_US = {
  base,
  admin,
  app,
  ui,
  home,
  api,
};
