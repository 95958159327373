import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgResize = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <rect width={4} height={4} x={18} y={18} fill='currentColor' rx={2} />
    <rect width={4} height={4} x={18} y={12} fill='currentColor' rx={2} />
    <rect width={4} height={4} x={18} y={6} fill='currentColor' rx={2} />
    <rect width={4} height={4} x={12} y={18} fill='currentColor' rx={2} />
    <rect width={4} height={4} x={12} y={12} fill='currentColor' rx={2} />
    <rect width={4} height={4} x={6} y={18} fill='currentColor' rx={2} />
  </svg>
);
const ForwardRef = forwardRef(SvgResize);
export default ForwardRef;
