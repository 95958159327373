import { Element } from '@nowadays/base/types';
import { updateObjectWithPath } from '@nowadays/base/utils';
import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { ElementState } from './Element.slice.types';

const initialState: ElementState = {};

export const elementSlice = createSlice({
  name: 'element',
  initialState,
  reducers: {
    changeIsMoving: (state, { payload }: PayloadAction<boolean>) => {
      state.isMoving = payload;
    },
    changeActiveElement: (
      state,
      { payload }: PayloadAction<Element | undefined>,
    ) => {
      state.activeElement = payload;
    },
    changeCopiedElement: (state, { payload }: PayloadAction<Element>) => {
      state.copiedElement = payload;
    },
    updateActiveElement: (
      state,
      {
        payload,
      }: PayloadAction<{
        path: string;
        value: unknown;
      }>,
    ) => {
      if (!state.activeElement) {
        return;
      }

      const { path, value } = payload;

      updateObjectWithPath(state.activeElement, path, value);
    },
  },
});

export const elementReducer = elementSlice.reducer;

export const useElementActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(elementSlice.actions, dispatch),
    [dispatch],
  );
};
