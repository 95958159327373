import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgPlusSquare = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m5.061 20.045.588-.809-.588.81ZM3.955 18.94l.809-.588-.81.588Zm16.09 0-.809-.588.81.588Zm-1.106 1.106-.588-.809.588.81Zm0-16.09-.588.809.588-.81Zm1.106 1.106-.809.588.81-.588ZM5.061 3.955l.588.809-.588-.81ZM3.955 5.06l.809.588-.81-.588ZM9 11a1 1 0 1 0 0 2v-2Zm6 2a1 1 0 1 0 0-2v2Zm-4 2a1 1 0 1 0 2 0h-2Zm2-6a1 1 0 1 0-2 0h2Zm-1 11c-1.897 0-3.24-.001-4.275-.113-1.017-.11-1.618-.318-2.076-.65l-1.176 1.617c.856.622 1.85.892 3.037 1.02 1.168.127 2.637.126 4.49.126v-2ZM2 12c0 1.853-.001 3.322.125 4.49.129 1.187.399 2.18 1.02 3.037l1.619-1.176c-.333-.458-.54-1.06-.65-2.076C4 15.24 4 13.897 4 12H2Zm3.649 7.236a4 4 0 0 1-.885-.885l-1.618 1.176a6 6 0 0 0 1.327 1.327l1.176-1.618ZM20 12c0 1.897-.001 3.24-.113 4.275-.11 1.017-.318 1.618-.65 2.076l1.617 1.176c.622-.856.892-1.85 1.02-3.037.127-1.168.126-2.637.126-4.49h-2Zm-8 10c1.853 0 3.322.001 4.49-.125 1.187-.129 2.18-.399 3.037-1.02l-1.176-1.619c-.458.333-1.06.54-2.076.65C15.24 20 13.897 20 12 20v2Zm7.236-3.649c-.247.34-.545.638-.885.885l1.176 1.618a6 6 0 0 0 1.327-1.327l-1.618-1.176ZM12 4c1.897 0 3.24.001 4.275.114 1.017.11 1.618.317 2.076.65l1.176-1.618c-.856-.622-1.85-.892-3.037-1.02C15.323 1.998 13.854 2 12 2v2Zm10 8c0-1.853.001-3.322-.125-4.49-.129-1.187-.399-2.18-1.02-3.037l-1.62 1.177c.333.458.54 1.06.65 2.076C20 8.76 20 10.103 20 12h2Zm-3.649-7.236a4 4 0 0 1 .885.885l1.618-1.176a6 6 0 0 0-1.327-1.327L18.35 4.764ZM12 2c-1.853 0-3.322-.001-4.49.125-1.187.129-2.18.399-3.037 1.02l1.177 1.62c.458-.333 1.06-.54 2.076-.65C8.76 4 10.103 4 12 4V2ZM4 12c0-1.897.001-3.24.114-4.275.11-1.017.317-1.618.65-2.076L3.146 4.473c-.622.856-.892 1.85-1.02 3.037C1.998 8.678 2 10.147 2 12h2Zm.473-8.854a6 6 0 0 0-1.327 1.327L4.764 5.65a4 4 0 0 1 .885-.885L4.473 3.146ZM9 13h6v-2H9v2Zm4 2V9h-2v6h2Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPlusSquare);
export default ForwardRef;
