'use client';

import { createContext, useContext } from 'react';

interface ColorPickerContextProps {
  color: string;
  changeColor: (color: string) => void;
}

export const ColorPickerContext = createContext<ColorPickerContextProps>({
  color: null,
  changeColor: () => null,
});

export const useColorPicker = () => {
  return useContext(ColorPickerContext);
};
