'use client';

import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';
import { useMemo } from 'react';

import { useRegion } from '../../../region/RegionContext';
import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import CalendarBase from '../CalendarBase';
import { CalendarBaseWeekDaysProps } from './CalendarBaseWeekDays.types';

const CalendarBaseWeekDays: React.FC<CalendarBaseWeekDaysProps> = ({
  children,
}) => {
  const { formatOptions } = useRegion();
  const { currentWeek } = useCalendarBase();

  const days = useMemo(
    () =>
      (currentWeek &&
        eachDayOfInterval({
          start: startOfWeek(currentWeek, formatOptions),
          end: endOfWeek(currentWeek, formatOptions),
        })) ||
      [],

    [currentWeek, formatOptions],
  );

  return (
    <CalendarBase.Hours>
      {({ hour }) =>
        children({
          hour,
          days,
        })
      }
    </CalendarBase.Hours>
  );
};

export default CalendarBaseWeekDays;
