'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Calendar from '../../../calendar/calendar/Calendar';
import { useCalendarBase } from '../../../calendar/calendar-base/calendar-base-context/CalendarBaseContext';
import { DatePickerPanelProps } from './DatePickerPanel.types';

const DatePickerPanel: React.FC<DatePickerPanelProps> = ({
  onSelect,
  className,
}) => {
  const { handleDayChanged } = useCalendarBase();

  return (
    <div className={classNames(styles.root, className)}>
      <Calendar.Head className={styles.header}>
        <div className={styles.date}>
          <Calendar.Month disabled readOnly monthFormat='MMMM' />
          <Calendar.Year disabled readOnly />
        </div>
        <Calendar.Iterator onlyMonth />
      </Calendar.Head>

      <Calendar.Panel>
        <Calendar.Days extraCellOnStart={false} />
        <Calendar.Weeks>
          {({ days }) =>
            days.map((day) => (
              <Calendar.Day
                key={day.toString()}
                day={day}
                onClick={() => {
                  handleDayChanged(day);
                  onSelect && onSelect();
                }}
              />
            ))
          }
        </Calendar.Weeks>
      </Calendar.Panel>
    </div>
  );
};

const styles = {
  root: classes('flex', 'flex-col', 'w-72'),
  header: classes('flex', 'justify-between'),
  date: classes('flex', 'gap-2'),
};

export default DatePickerPanel;
