import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { Level } from '@tiptap/extension-heading';

import { Listbox, ListboxOption } from '../../../../listbox';
import { useRichText } from '../../../context/RichTextContext';

const RichTextHeading = () => {
  const { editor } = useRichText();
  const translate = useTranslate();

  if (!editor) {
    return null;
  }

  const options: ListboxOption<Level>[] = [...new Array(6)].map(
    (i, k): ListboxOption<Level> => ({
      key: translate(
        `ui.components.inputs.richText.headings.h${(k + 1) as Level}`,
      ),
      value: (k + 1) as Level,
    }),
  );

  const value = options.find((i) =>
    editor.isActive('heading', { level: i.value }),
  );

  const handleChange = ({ value }: ListboxOption<Level>) => {
    editor.chain().focus().toggleHeading({ level: value }).run();
  };

  return (
    <Listbox
      value={value}
      options={options}
      multiple={false}
      placeholder={translate('ui.components.inputs.richText.heading')}
      onChange={handleChange}
      className={styles.listbox}
      input={{ className: styles.input }}
    />
  );
};

const styles = {
  listbox: classes('h-7'),
  input: classes('w-28', 'bg-skin-primary'),
};

export default RichTextHeading;
