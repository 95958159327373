'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { DividerProps } from './Divider.types';

const Divider: React.FC<DividerProps> = ({
  className,
  direction = 'horizontal',
}) => {
  return (
    <span
      className={classNames(styles.divider, styles[direction], className)}
    />
  );
};

const styles = {
  divider: classes('rounded', 'bg-skin-silent', 'bg-opacity-40', 'block'),
  horizontal: classes('h-[0.0625rem]', 'w-full'),
  vertical: classes('w-[0.0625rem]', 'h-full'),
};

export default Divider;
