import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgMoon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeWidth={2}
      d='M12.268 12.131c3.516 6.09 10.121 4.306 5.918 6.733A8.79 8.79 0 1 1 9.396 3.64c4.204-2.427-.643 2.402 2.872 8.491Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMoon);
export default ForwardRef;
