'use client';

import { FallbackLanguage, Language } from '@nowadays/base/locales';
import {
  DATE_LOCALES,
  getCurrenyByTimezone,
  getWeekStartByTimezone,
  HourFormat,
  WeekStartsOn,
} from '@nowadays/base/types';
import { useState } from 'react';

import { useDatetimeFunctions } from './hooks/useDatetimeFunctions';
import { useNumberFunctions } from './hooks/useNumberFunctions';
import { useTimeFunctions } from './hooks/useTimeFunctions';
import { FormatOptions, RegionContext, Settings } from './RegionContext';

interface RegionProviderProps {
  language?: Language;
  timeZone?: string;
  currency?: string;
  weekStartsOn?: WeekStartsOn;
  hourFormat?: HourFormat;
  dayStartsAt?: number;
  dayEndsAt?: number;
  children: React.ReactNode;
}

const defaultSettings = ({
  language,
  timeZone,
  currency,
  weekStartsOn,
  hourFormat,
  dayStartsAt,
  dayEndsAt,
}: Partial<Settings>): Settings => {
  const currentLanguage = language || FallbackLanguage;

  const currentTimeZone =
    timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentCurrency = currency || getCurrenyByTimezone(currentTimeZone);

  const currentWeekStartsOn =
    weekStartsOn || getWeekStartByTimezone(currentTimeZone);

  const currentHourFormat = hourFormat || '24';

  const currentDayStartsAt = dayStartsAt || 7;

  const currentDayEndsAt = dayEndsAt || 24;

  return {
    language: currentLanguage,
    timeZone: currentTimeZone,
    currency: currentCurrency,
    weekStartsOn: currentWeekStartsOn,
    hourFormat: currentHourFormat,
    dayStartsAt: currentDayStartsAt,
    dayEndsAt: currentDayEndsAt,
  };
};

const RegionProvider: React.FC<RegionProviderProps> = ({
  language,
  timeZone,
  currency,
  weekStartsOn,
  hourFormat,
  dayStartsAt,
  dayEndsAt,
  children,
}) => {
  const [settings, setSettings] = useState<Settings>(
    defaultSettings({
      language,
      timeZone,
      currency,
      weekStartsOn,
      hourFormat,
      dayStartsAt,
      dayEndsAt,
    }),
  );

  const [formatOptions, setFormatOptions] = useState<FormatOptions>({
    locale: DATE_LOCALES[settings.language],
    timeZone: settings.timeZone,
    weekStartsOn: settings.weekStartsOn === 'monday' ? 1 : 0,
  });

  const changeSettings = (updated: Partial<Settings>) => {
    setSettings(defaultSettings({ ...settings, ...updated }));

    setFormatOptions({
      locale: DATE_LOCALES[updated.language],
      timeZone: updated.timeZone,
      weekStartsOn: updated.weekStartsOn === 'monday' ? 1 : 0,
    });
  };

  return (
    <RegionContext.Provider
      value={{
        formatOptions,
        changeSettings,
        ...settings,
        ...useNumberFunctions(settings),
        ...useDatetimeFunctions(settings, formatOptions),
        ...useTimeFunctions(settings, formatOptions),
      }}
    >
      {children}
    </RegionContext.Provider>
  );
};

export default RegionProvider;
