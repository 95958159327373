'use client';

import { classes } from '@nowadays/ui/utils';

import IconButton from '../../button/icon-button/IconButton';
import { SnackbarDismissProps } from './SnackbarDismiss.types';

const SnackbarDismiss: React.FC<SnackbarDismissProps> = ({ close }) => {
  return (
    <IconButton
      name='CloseCircle'
      variant='text'
      className={styles.button}
      onClick={close}
    />
  );
};

const styles = {
  button: classes('p-0', 'flex-shrink-0'),
};

export default SnackbarDismiss;
