'use client';

import i18next from 'i18next';
import { useMemo } from 'react';

import { useLanguage } from './context/I18NContext';

export const useTranslate = (): Translate => {
  const { language } = useLanguage();

  return useMemo(() => i18next.t, [language]);
};
