import { baseApi } from '../base/query/Api';
import { addLanguage } from './AddLanguage.api';
import { addNamespace } from './AddNamespace.api';
import { deleteLanguage } from './DeleteLanguage.api';
import { deleteNamespace } from './DeleteNamespace.api';
import { languages } from './Languages.api';
import { namespaces } from './Namespaces.api';
import { resources } from './Resources.api';
import { saveResources } from './SaveResources.api';

export const localeEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    languages: languages(builder),
    addLanguage: addLanguage(builder),
    deleteLanguage: deleteLanguage(builder),
    namespaces: namespaces(builder),
    addNamespace: addNamespace(builder),
    deleteNamespace: deleteNamespace(builder),
    resources: resources(builder),
    saveResources: saveResources(builder),
  }),
});

export const {
  useLanguagesQuery,
  useAddLanguageMutation,
  useDeleteLanguageMutation,
  useNamespacesQuery,
  useAddNamespaceMutation,
  useDeleteNamespaceMutation,
  useResourcesQuery,
  useSaveResourcesMutation,
} = localeEndpoints;
