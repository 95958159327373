'use client';

import './ImageSliderPreview.styles.css';
import 'react-medium-image-zoom/dist/styles.css';

import { classes, classNames } from '@nowadays/ui/utils';
import { animated, useTransition } from '@react-spring/web';
import Zoom from 'react-medium-image-zoom';

import { useImageSlider } from '../image-slider-context/ImageSliderContext';
import ImageSliderNotFound from '../image-slider-not-found/ImageSliderNotFound';
import { ImageSliderPreviewProps } from './ImageSliderPreview.types';

const ImageSliderPreview: React.FC<ImageSliderPreviewProps> = ({
  className,
}) => {
  const { images, aspectRatio, loading, active } = useImageSlider();

  const transition = useTransition(active || loading, {
    from: { opacity: 0, translateY: '4rem' },
    enter: { opacity: 1, translateY: '0rem' },
  });

  if (!loading && (!images || images.length === 0)) {
    return <ImageSliderNotFound />;
  }

  const src = images[active];

  return transition((springs) => (
    <div
      className={classNames(
        styles.root,
        loading && styles.loader,
        loading && styles.loading,
        aspectRatio,
        className,
      )}
    >
      {!loading && (
        <div className={styles.zoom}>
          <Zoom classDialog='zoomDailog' wrapElement='div'>
            <animated.img src={src} style={springs} className={styles.img} />
          </Zoom>
        </div>
      )}
    </div>
  ));
};

const styles = {
  root: classes(
    'flex',
    'flex-1',
    'justify-center',
    'items-center',
    'overflow-hidden',
    'h-full',
    'w-full',
    'md:h-auto',
    'md:w-auto',
  ),
  loader: classes('h-full', 'w-full', 'flex', 'items-center', 'justify-center'),
  loading: classes('bg-skin-primary', 'animate-pulse', 'h-full', 'rounded-lg'),
  img: classes('object-contain', 'w-full', 'h-full', 'cursor-pointer'),
  icon: classes('w-8', 'h-8'),
  zoom: classes('w-full', 'h-full', 'zoom'),
};

export default ImageSliderPreview;
