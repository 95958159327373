import { PageLayout, WIDGET_HEIGHT, WIDGET_WIDTH } from './Planner.types';

type LayoutCols = 2 | 3 | 4;

type LayoutRows = 2 | 3 | 4;

export type LayoutColRow = {
  col: LayoutCols;
  row: LayoutRows;
};

export type LayoutAsPixel = {
  width: number;
  height: number;
};

export const layoutToSize: { [key in PageLayout]: LayoutColRow } = {
  landscape: { col: 3, row: 3 },
  portrait: { col: 2, row: 4 },
  square: { col: 3, row: 4 },
  tabloid: { col: 4, row: 4 },
};

export const layoutToPixel = (size: LayoutColRow): LayoutAsPixel => ({
  width: size.col * WIDGET_WIDTH,
  height: size.row * WIDGET_HEIGHT,
});
