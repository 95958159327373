import { UploadWebFontPackParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const uploadWebFontPack = (builder: Builder) =>
  builder.mutation<void, UploadWebFontPackParams>({
    query: ({ id, ...params }) => ({
      url: `/font/upload-web-pack/${id}`,
      method: 'POST',
      params,
    }),
  });
