import { Reports, ReportsParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const reports = (builder: Builder) =>
  builder.query<Reports[], ReportsParams>({
    query: (params) => ({
      url: '/report/reports',
      params,
    }),
    providesTags: ['Reports'],
  });
