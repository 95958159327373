import { CurrentUser, SignupParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const signup = (builder: Builder) =>
  builder.mutation<CurrentUser, SignupParams>({
    query: (body) => ({
      url: '/auth/signup-local',
      method: 'POST',
      body,
    }),
  });
