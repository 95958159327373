import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import { IconButton } from '../../../../button';
import { RichTextToolbarButtonProps } from './RichTextToolbarButton.types';

const RichTextToolbarButton: React.FC<RichTextToolbarButtonProps> = (
  { active, className, ...props },
  ref: React.Ref<HTMLButtonElement>,
) => {
  return (
    <IconButton
      ref={ref}
      className={classNames(styles.button, active && styles.active, className)}
      {...props}
    />
  );
};

const styles = {
  button: classes(
    'p-0.5',
    'h-7',
    'min-w-[1.75rem]',
    'font-medium',
    'text-base',
  ),
  active: classes('text-skin-accent'),
};

export default React.forwardRef(RichTextToolbarButton);
