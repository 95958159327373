'use client';

import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import NotFoundContainer from './not-found-container/NotFoundContainer';

const NotFound = () => {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  return (
    <NotFoundContainer
      onClick={() => {
        resetBoundary();
        navigate('/', { replace: true });
      }}
    />
  );
};

export default NotFound;
