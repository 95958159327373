'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Button from '../../../button/button/Button';
import CalendarBase from '../../calendar-base/CalendarBase';
import { CalendarWeekProps } from './CalendarWeek.types';

const CalendarWeek: React.FC<CalendarWeekProps> = ({
  week,
  color,
  variant,
  className,
  onClick,
  ...props
}) => {
  return (
    <CalendarBase.Week week={week}>
      {({ week, formatted, isSelected, onChanged }) => (
        <td>
          <div className={styles.root}>
            <Button
              onClick={(e) => {
                onChanged(week);
                onClick && onClick(e);
              }}
              color={
                color
                  ? typeof color === 'function'
                    ? color({
                        isSelected,
                      })
                    : color
                  : 'silent'
              }
              variant={
                variant
                  ? typeof variant === 'function'
                    ? variant({
                        isSelected,
                      })
                    : variant
                  : isSelected
                    ? 'contained'
                    : 'flat'
              }
              className={classNames(
                styles.button,
                isSelected && styles.selected,
                typeof className === 'function'
                  ? className({ isSelected })
                  : className,
              )}
              {...props}
            >
              {formatted}
            </Button>
            <div className={styles.divider} />
          </div>
        </td>
      )}
    </CalendarBase.Week>
  );
};

const styles = {
  root: classes('flex', 'items-center', 'gap-0.5', 'h-full'),
  button: classes(
    'px-0',
    'py-1.5',
    'w-full',
    'h-full',
    'max-h-10',
    'duration-0',
    'focus:z-10',
    'text-2xs',
  ),
  selected: classes('font-semibold'),
  divider: classes(
    'h-4',
    'mx-0.5',
    'border-l',
    'border-opacity-60',
    'border-skin-silent',
  ),
};

export default CalendarWeek;
