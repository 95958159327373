'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React, { useEffect, useRef, useState } from 'react';

import { EditableElementProps } from './EditableElement.types';

const getCaret = (el: HTMLElement) => {
  let caretAt = 0;
  const sel = window.getSelection();

  if (sel.rangeCount === 0) {
    return caretAt;
  }

  const range = sel.getRangeAt(0);
  const preRange = range.cloneRange();
  preRange.selectNodeContents(el);
  preRange.setEnd(range.endContainer, range.endOffset);
  caretAt = preRange.toString().length;

  return caretAt;
};

const setCaret = (el: HTMLElement, offset: number) => {
  const sel = window.getSelection();
  const range = document.createRange();

  const node = el.childNodes[0];

  if (!node) {
    return;
  }

  range.setStart(node, offset);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
};

const EditableElement: React.FC<EditableElementProps> = (
  {
    value = '',
    disabled,
    debounce = 350,
    onChange,
    onKeyDown,
    className,
    ...props
  },
  ref: React.Ref<HTMLDivElement>,
) => {
  const caretPos = useRef<number>(value.length);
  const innerRef = useRef<HTMLDivElement | null>(null);

  const [state, setState] = useState<string>(value);

  useEffect(() => {
    const el = getElement();

    if (!el) {
      return;
    }

    if (state === value) {
      return;
    }

    setCaret(el, caretPos.current);

    const timeout = setTimeout(() => {
      onChange && onChange(state);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [state]);

  useEffect(() => {
    if (!disabled) {
      const el = getElement();

      if (!el) {
        return;
      }

      el.focus();
    }
  }, [disabled]);

  const getElement = () =>
    (ref && typeof ref !== 'function' ? ref : innerRef).current;

  const emitChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    const el = getElement();

    if (!el) {
      return;
    }

    caretPos.current = getCaret(innerRef.current);

    setState(e.target.textContent);
  };

  const handleRef = (el: HTMLDivElement) => {
    (innerRef as React.MutableRefObject<HTMLDivElement | null>).current = el;

    if (ref) {
      if (typeof ref === 'function') {
        ref(el);
      } else {
        (ref as React.MutableRefObject<HTMLDivElement | null>).current = el;
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Escape') {
      const el = getElement();

      if (el) {
        el.blur();
      }
    }

    onKeyDown && onKeyDown(e);
  };

  return (
    <div
      ref={handleRef}
      onInput={emitChange}
      onKeyDown={handleKeyDown}
      contentEditable={!disabled}
      suppressContentEditableWarning={true}
      className={classNames(
        styles.root,
        disabled && styles.disabled,
        className,
      )}
      {...props}
    >
      {state}
    </div>
  );
};

const styles = {
  root: classes('cursor-text', 'p-1'),
  disabled: classes('cursor-default'),
};

export default React.forwardRef(EditableElement);
