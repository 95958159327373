import {
  WaterIntakeTrackerDatum,
  WaterIntakeTrackerDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const waterIntakeTrackerDatum = (builder: Builder) =>
  builder.query<WaterIntakeTrackerDatum, WaterIntakeTrackerDatumParams>({
    query: (params) => ({ url: '/datum/water-intake-tracker-datum', params }),
  });
