import { classes } from '@nowadays/ui/utils';

export const bgHoverStyles = {
  base: {
    text: null,
    flat: classes('hover:bg-skin-silent', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-silent', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-silent', 'hover:bg-opacity-70'),
  },
  primary: {
    text: null,
    flat: classes('hover:bg-skin-muted', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-muted', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-inverted', 'hover:bg-opacity-70'),
  },
  inverted: {
    text: null,
    flat: classes('hover:bg-skin-primary', 'hover:bg-opacity-10'),
    outlined: classes('hover:bg-skin-primary', 'hover:bg-opacity-10'),
    contained: classes('hover:bg-skin-primary', 'hover:bg-opacity-70'),
  },
  muted: {
    text: null,
    flat: classes('hover:bg-skin-muted', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-muted', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-muted', 'hover:bg-opacity-70'),
  },
  silent: {
    text: null,
    flat: classes('hover:bg-skin-silent', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-silent', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-silent', 'hover:bg-opacity-70'),
  },
  accent: {
    text: null,
    flat: classes('hover:bg-skin-accent', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-accent', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-accent', 'hover:bg-opacity-70'),
  },
  green: {
    text: null,
    flat: classes('hover:bg-skin-green', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-green', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-green', 'hover:bg-opacity-70'),
  },
  red: {
    text: null,
    flat: classes('hover:bg-skin-red', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-red', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-red', 'hover:bg-opacity-70'),
  },
  blue: {
    text: null,
    flat: classes('hover:bg-skin-blue', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-blue', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-blue', 'hover:bg-opacity-70'),
  },
  orange: {
    text: null,
    flat: classes('hover:bg-skin-orange', 'hover:bg-opacity-20'),
    outlined: classes('hover:bg-skin-orange', 'hover:bg-opacity-20'),
    contained: classes('hover:bg-skin-orange', 'hover:bg-opacity-70'),
  },
};
