import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgShopping = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m5.766 16.785.6-.799-.6.8ZM3.02 10.252l-.972.233.972-.233Zm2.565 6.391.637-.77-.637.77Zm16.433-5.9-.972-.234.972.233Zm-2.13 5.615-.7-.714.7.714Zm-.849.67-.53-.848.53.847Zm2.902-10.272.686-.727-.686.727Zm.362.459-.867.498.867-.498ZM1.97 1.757a1 1 0 1 0-1.94.486l1.94-.486ZM16 15a1 1 0 1 0 0-2v2Zm-6-2a1 1 0 1 0 0 2v-2ZM2.053 7.21h16.392v-2H2.053v2Zm11.03 9.58h-.504v2h.505v-2Zm-9.09-6.771-.968-4.042-1.945.467.969 4.04 1.945-.465Zm8.586 6.77c-1.85 0-3.151-.001-4.16-.118-.984-.115-1.58-.33-2.052-.685l-1.203 1.598c.863.649 1.848.937 3.024 1.073 1.15.134 2.588.133 4.39.133v-2Zm-10.53-6.304c.42 1.753.753 3.151 1.152 4.24.407 1.111.916 2.002 1.748 2.69l1.274-1.542c-.456-.377-.804-.906-1.144-1.836-.35-.954-.654-2.22-1.085-4.018l-1.945.466Zm4.318 5.501a4.002 4.002 0 0 1-.144-.113l-1.274 1.541c.07.059.142.115.215.17l1.203-1.598Zm14.68-5.477c-.373 1.556-.637 2.651-.929 3.486-.285.816-.567 1.294-.929 1.649l1.401 1.427c.664-.651 1.077-1.445 1.417-2.416.332-.953.621-2.163.984-3.68l-1.944-.466Zm-7.963 8.28c1.56 0 2.803.002 3.807-.1 1.024-.103 1.892-.32 2.68-.814L18.51 16.18c-.43.269-.96.432-1.82.52-.88.088-2.006.09-3.606.09v2Zm6.105-3.145a4.005 4.005 0 0 1-.68.536l1.062 1.695c.368-.23.71-.5 1.02-.804l-1.402-1.427Zm-.744-8.433c.999 0 1.656.001 2.14.06.468.057.607.152.67.212l1.373-1.454c-.514-.485-1.15-.664-1.8-.743-.634-.077-1.436-.075-2.383-.075v2Zm4.546 3.765c.221-.921.41-1.701.482-2.335.075-.65.05-1.311-.302-1.924l-1.735.996c.044.076.104.233.05.7-.055.485-.207 1.125-.44 2.096l1.945.467Zm-1.735-3.493a.999.999 0 0 1 .18.23l1.735-.996a2.998 2.998 0 0 0-.543-.688l-1.372 1.454ZM3.023 5.968 1.97 1.758l-1.94.485 1.052 4.21 1.94-.485ZM16 13h-6v2h6v-2Zm-5.526 7.947a1.053 1.053 0 1 1-2.105 0 1.053 1.053 0 0 1 2.105 0Zm6.316 0a1.053 1.053 0 1 1-2.106 0 1.053 1.053 0 0 1 2.106 0Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgShopping);
export default ForwardRef;
