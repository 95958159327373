'use client';

import { FallbackLanguage, Language } from '@nowadays/base/locales';
import { createContext, useContext } from 'react';

interface I18NContextProps {
  language: Language;
  changeLanguage: (language: Language) => void;
}

export const I18NContext = createContext<I18NContextProps>({
  language: FallbackLanguage,
  changeLanguage: () => null,
});

export const useLanguage = () => {
  return useContext(I18NContext);
};
