'use client';

import { createContext, useContext } from 'react';

export interface TooltipPosition {
  left: number;
  top: number;
}

interface ChartContextProps {
  tooltipPosition: TooltipPosition;
}

export const ChartContext = createContext<ChartContextProps>({
  tooltipPosition: { left: 0, top: 0 },
});

export const useChart = () => {
  return useContext(ChartContext);
};
