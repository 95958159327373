'use client';

import {
  addDays,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  getYear,
  startOfMonth,
} from 'date-fns';
import { useCallback, useMemo } from 'react';
import React from 'react';

import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseMonthsProps } from './CalendarBaseMonths.types';

const CalendarBaseMonths: React.FC<CalendarBaseMonthsProps> = ({
  children,
  chunkSize = 4,
}) => {
  const { currentMonth } = useCalendarBase();

  const months = useMemo(
    () =>
      Array.from(
        { length: 12 },
        (_, i) => new Date(getYear(currentMonth), i, 1),
      ),
    [currentMonth],
  );

  const monthDays = useMemo(() => {
    const result: Date[][] = [];

    for (let i = 0; i < months.length; i++) {
      const month = months[i];

      const interval: Date[] = [];
      const start = startOfMonth(month);
      const end = endOfMonth(month);

      interval.push(
        ...eachDayOfInterval({
          start,
          end,
        }),
      );

      const nextStart = addMonths(start, 1);

      const remain = 31 - interval.length;

      if (remain > 0) {
        const nextEnd = addDays(nextStart, remain - 1);

        interval.push(
          ...eachDayOfInterval({
            start: nextStart,
            end: nextEnd,
          }),
        );
      }

      result.push(interval);
    }

    return result;
  }, [months]);

  const chunkArray = useCallback(
    (array: Date[], chunkSize: number): Date[][] => {
      const chunks: Date[][] = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    },
    [],
  );

  const monthChunks = useMemo(
    () => chunkArray(months, chunkSize),
    [months, chunkSize],
  );

  return children({ months, monthDays, monthChunks });
};

export default React.memo(CalendarBaseMonths);
