import {
  WeatherSummaryDatum,
  WeatherSummaryDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const weatherSummaryDatum = (builder: Builder) =>
  builder.query<WeatherSummaryDatum, WeatherSummaryDatumParams>({
    query: (params) => ({ url: '/datum/weather-summary-datum', params }),
    providesTags: ['WeatherSummary'],
  });
