import { User } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const users = (builder: Builder) =>
  builder.query<User[], void>({
    query: () => ({ url: '/user/users' }),
    providesTags: (res) =>
      res
        ? [
            ...res.map(({ id }) => ({ type: 'Users' as const, id })),
            { type: 'Users', id: 'LIST' },
          ]
        : [{ type: 'Users', id: 'LIST' }],
  });
