import { ListFilesParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const listFiles = (builder: Builder) =>
  builder.query<string[], ListFilesParams>({
    query: (params) => ({
      url: '/file/list-files',
      params,
    }),
  });
