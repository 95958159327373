import { AnchorButton, BrandLogoText } from '@nowadays/ui/components';
import { ADMIN_ENVS, APP_ENVS, classes } from '@nowadays/ui/utils';
import { Outlet } from 'react-router-dom';

const FormOutlet = () => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <AnchorButton href={(APP_ENVS || ADMIN_ENVS).HOME_URL} target='_blank'>
          <BrandLogoText />
        </AnchorButton>
      </div>
      <div className={styles.outlet}>
        <Outlet />
      </div>
    </div>
  );
};

const styles = {
  root: classes('h-auto', 'flex', 'flex-col'),
  header: classes(
    'px-6',
    'py-10',
    'w-full',
    'flex',
    'items-center',
    'justify-center',
  ),
  outlet: classes('flex', 'flex-1', 'justify-center', 'px-3', 'pb-16'),
};

export default FormOutlet;
