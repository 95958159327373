'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import RichText from '../../../inputs/rich-text/RichText';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormRichTextProps } from './FormRichText.types';

const FormRichText: React.FC<FormRichTextProps> = ({
  field,
  label,
  disabled,
  input,
  onUpdate,
  container,
  ...props
}) => {
  const { control, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div key={field} className={classNames(styles.root, container?.className)}>
      {label && <FieldLabel label={label} htmlFor={field} />}

      <Controller
        control={control}
        name={field}
        render={({ field: { value, onChange } }) => (
          <RichText
            key={field}
            content={value}
            disabled={isDisable}
            input={{ error: isError, disable: isDisable, ...input }}
            onUpdate={(props) => {
              onChange(props.editor.getHTML());
              onUpdate && onUpdate(props);
            }}
            {...props}
          />
        )}
      />

      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
};

export default FormRichText;
