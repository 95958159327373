import { PagePeriod } from '@nowadays/base/types';
import { Buffer } from 'buffer';

type DatedPageParam = {
  planner: string;
  period: PagePeriod;
  date: string;
};

export const encodeDatedPageParam = ({
  planner,
  period,
  date,
}: DatedPageParam) => {
  return Buffer.from(JSON.stringify({ planner, period, date })).toString(
    'base64',
  );
};

export const decodeDatedPageParam = (param: string): DatedPageParam => {
  try {
    return JSON.parse(
      Buffer.from(param, 'base64').toString('ascii'),
    ) as DatedPageParam;
  } catch {
    return undefined;
  }
};

type UndatedPageParam = {
  planner: string;
  page: string | null;
};

export const encodeUndatedPageParam = ({ planner, page }: UndatedPageParam) => {
  return Buffer.from(JSON.stringify({ planner, page })).toString('base64');
};

export const decodeUndatedPageParam = (param: string): UndatedPageParam => {
  try {
    return JSON.parse(
      Buffer.from(param, 'base64').toString('ascii'),
    ) as UndatedPageParam;
  } catch {
    return undefined;
  }
};
