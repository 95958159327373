'use client';

import './Picker.styles.css';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import { IconButton } from '../../../button';
import Button from '../../../button/button/Button';
import Popover from '../../../popover/popover/Popover';
import { Tooltip } from '../../../tooltip';
import { useColorPicker } from '../context/ColorPickerContext';
import { PickerHeaderProps } from './PickerHeader.types';
import PickerPanel from './PickerPanel';

const PickerHeader: React.FC<PickerHeaderProps> = ({ transparent }) => {
  const translate = useTranslate();
  const { changeColor } = useColorPicker();

  const handleTransparent = () => changeColor('');

  return (
    <div className={styles.root}>
      <span>{translate('ui.components.colorPicker.preset')}</span>

      <div className={styles.container}>
        <Popover>
          {({ close }) => (
            <Fragment>
              <Popover.Button
                as={Button}
                color='base'
                variant='contained'
                className={styles.button}
                endIcon={{ name: 'ColorPicker' }}
              >
                {translate('ui.components.colorPicker.select')}
              </Popover.Button>

              <PickerPanel close={close} />
            </Fragment>
          )}
        </Popover>

        {transparent && (
          <Tooltip title={translate('ui.components.colorPicker.transparent')}>
            <IconButton
              color='base'
              name='Close'
              variant='contained'
              className={styles.remove}
              onClick={handleTransparent}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const styles = {
  root: classes(
    'text-xs',
    'font-medium',
    'text-skin-muted',
    'flex',
    'justify-between',
    'items-center',
  ),
  container: classes('flex', 'gap-1'),
  button: classes('text-xs', 'py-1', 'px-2'),
  remove: classes('p-1'),
};

export default PickerHeader;
