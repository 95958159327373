'use client';

import {
  Disclosure as HeadlessDisclosure,
  DisclosureButton as HeadlessDisclosureButton,
  DisclosurePanel as HeadlessDisclosurePanel,
} from '@headlessui/react';

export default Object.assign(HeadlessDisclosure, {
  Button: HeadlessDisclosureButton,
  Panel: HeadlessDisclosurePanel,
});
