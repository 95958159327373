import { classes } from '@nowadays/ui/utils';

export const textHoverStyles = {
  base: {
    text: classes('hover:text-skin-primary', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  primary: {
    text: classes('hover:text-skin-primary', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  inverted: {
    text: classes('hover:text-skin-inverted', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  muted: {
    text: classes('hover:text-skin-muted', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  silent: {
    text: classes('hover:text-skin-silent', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  accent: {
    text: classes('hover:text-skin-accent', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  green: {
    text: classes('hover:text-skin-green', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  red: {
    text: classes('hover:text-skin-red', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  blue: {
    text: classes('hover:text-skin-blue', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
  orange: {
    text: classes('hover:text-skin-orange', 'hover:text-opacity-70'),
    flat: null,
    outlined: null,
    contained: null,
  },
};
