import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgExport = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m7.218 20.938-.157.988.157-.988Zm-4.156-4.156.987-.156-.987.156Zm17.876 0 .988.157-.988-.157Zm-4.156 4.156.157.988-.157-.988ZM20.8 10.4a1 1 0 1 0-1.6 1.202l1.6-1.202Zm-16 1.202A1 1 0 0 0 3.2 10.4l1.6 1.202ZM11 17a1 1 0 1 0 2 0h-2ZM7.216 6.379A1 1 0 1 0 8.784 7.62L7.216 6.38Zm2.182-1.142-.784-.621.784.621Zm5.204 0 .784-.621-.784.621Zm.614 2.384a1 1 0 0 0 1.568-1.242L15.216 7.62ZM11.75 3.02l-.157-.988.157.988Zm.502 0 .157-.988-.157.988ZM20 14v1h2v-1h-2Zm-5 6H9v2h6v-2ZM4 15v-1H2v1h2Zm5 5c-.967 0-1.334-.003-1.626-.05l-.313 1.976C7.547 22.003 8.11 22 9 22v-2Zm-7-5c0 .89-.003 1.453.074 1.939l1.975-.313C4.003 16.334 4 15.967 4 15H2Zm5.374 4.95a4 4 0 0 1-3.325-3.324l-1.975.313a6 6 0 0 0 4.987 4.987l.313-1.975ZM20 15c0 .967-.003 1.334-.05 1.626l1.976.313C22.003 16.453 22 15.89 22 15h-2Zm-5 7c.89 0 1.453.003 1.939-.074l-.313-1.975c-.292.046-.659.049-1.626.049v2Zm4.95-5.374a4 4 0 0 1-3.324 3.325l.313 1.975a6 6 0 0 0 4.987-4.987l-1.975-.313ZM22 14c0-1.35-.447-2.598-1.2-3.6l-1.6 1.2c.503.669.8 1.498.8 2.4h2ZM4 14c0-.902.297-1.731.8-2.4l-1.6-1.2A5.978 5.978 0 0 0 2 14h2Zm9 3V4h-2v13h2ZM8.784 7.621l1.397-1.763-1.567-1.242-1.398 1.763L8.784 7.62Zm5.035-1.763 1.397 1.763 1.568-1.242-1.398-1.763-1.567 1.242Zm-3.638 0c.573-.722.95-1.195 1.264-1.51.31-.311.433-.336.462-.34l-.315-1.976c-.651.104-1.146.485-1.564.904-.413.415-.872.996-1.414 1.68l1.567 1.242Zm5.205-1.242c-.542-.684-1.001-1.265-1.414-1.68-.418-.42-.913-.8-1.564-.904l-.315 1.975c.029.005.152.03.462.34.314.316.691.79 1.264 1.511l1.567-1.242Zm-3.48-.609A.592.592 0 0 1 12 4V2c-.137 0-.273.01-.408.032l.315 1.975ZM12 4c.03 0 .062.002.093.007l.315-1.975A2.593 2.593 0 0 0 12 2v2Zm1 0V3h-2v1h2Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExport);
export default ForwardRef;
