export enum ErrorCode {
  ConnectionError = 'CONNECTION_ERROR',
  IntervalServerError = 'INTERVAL_SERVER_ERROR',
  BadRequestError = 'BAD_REQUEST_ERROR',
  ForbiddenError = 'FORBIDDEN_ERROR',
  NotAuthorizedError = 'NOT_AUTHORIZED_ERROR',
  AccountDisabledError = 'ACCOUNT_DISABLED_ERROR',
  NotFoundError = 'NOT_FOUND_ERROR',
  ValidationError = 'VALIDATION_ERROR',
  AccountAlreadyExistsError = 'ACCOUNT_ALREADY_EXISTS_ERROR',
  AccountNotFoundError = 'ACCOUNT_NOT_FOUND_ERROR',
  AccountNotVerifiedError = 'ACCOUNT_NOT_VERIFIED_ERROR',
  InvalidCredentialsError = 'INVALID_CREDENTIALS_ERROR',
  InvalidQueryParamsError = 'INVALID_QUERY_PARAMS_ERROR',
  InvalidTokenError = 'INVALID_TOKEN_ERROR',
  SocialEmailError = 'SOCIAL_EMAIL_ERROR',
  ExceededMaxLimit = 'EXCEEDED_MAX_LIMIT',
}
