'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Button from '../../../button/button/Button';
import { useRegion } from '../../../region/RegionContext';
import CalendarBase from '../../calendar-base/CalendarBase';
import { CalendarDayProps } from './CalendarDay.types';

const CalendarDay: React.FC<CalendarDayProps> = ({
  day,
  color,
  variant,
  className,
  disabled,
  children,
  onClick,
  ...props
}) => {
  const { format } = useRegion();

  return (
    <CalendarBase.Day day={day}>
      {({
        day,
        onChanged,
        isDisabled,
        isSelected,
        isToday,
        isDiffirentMonth,
        isWeekSelected,
        isStartOfWeek,
        isEndOfWeek,
      }) => (
        <td>
          <Button
            onClick={(e) => {
              onChanged(day);
              onClick && onClick(e);
            }}
            disabled={isDisabled || disabled}
            color={
              color
                ? typeof color === 'function'
                  ? color({
                      isSelected,
                      isToday,
                      isDiffirentMonth,
                      isWeekSelected,
                    })
                  : color
                : isSelected || isDiffirentMonth || isWeekSelected
                  ? 'silent'
                  : isDisabled
                    ? 'muted'
                    : 'primary'
            }
            variant={
              variant
                ? typeof variant === 'function'
                  ? variant({
                      isSelected,
                      isToday,
                      isDiffirentMonth,
                      isWeekSelected,
                    })
                  : variant
                : isSelected || isWeekSelected
                  ? 'contained'
                  : 'flat'
            }
            className={classNames(
              styles.button,
              isSelected && styles.selected,
              isToday && styles.today,
              isWeekSelected && styles.weekSelected,
              isWeekSelected && isStartOfWeek && styles.startOfWeek,
              isWeekSelected && isEndOfWeek && styles.endOfWeek,
              typeof className === 'function'
                ? className({
                    isSelected,
                    isToday,
                    isDiffirentMonth,
                    isWeekSelected,
                  })
                : className,
            )}
            {...props}
          >
            {(children && children({ day })) || format(day, 'dd')}
          </Button>
        </td>
      )}
    </CalendarBase.Day>
  );
};

const styles = {
  button: classes(
    'px-0',
    'py-1.5',
    'w-full',
    'h-full',
    'max-h-10',
    'duration-0',
    'focus:z-10',
    'font-medium',
  ),
  selected: classes('font-semibold'),
  today: classes('text-skin-red'),
  weekSelected: classes('rounded-none'),
  startOfWeek: classes('rounded-l-md'),
  endOfWeek: classes('rounded-r-md'),
};

export default CalendarDay;
