import { baseApi } from '../base/query/Api';
import { contentPlannerDatum } from './ContentPlannerDatum.api';
import { contentSummaryDatum } from './ContentSummaryDatum.api';
import { expenseSummaryDatum } from './ExpenseSummaryDatum.api';
import { expenseTrackerDatum } from './ExpenseTrackerDatum.api';
import { focusDatum } from './FocusDatum.api';
import { habitSummaryDatum } from './HabitSummaryDatum.api';
import { habitTrackerDatum } from './HabitTrackerDatum.api';
import { imageBoxDatum } from './ImageBoxDatum.api';
import { intentionDatum } from './IntentionDatum.api';
import { moodSummaryDatum } from './MoodSummaryDatum.api';
import { moodTrackerDatum } from './MoodTrackerDatum.api';
import { pomodoroDatum } from './PomodoroDatum.api';
import { priorityListDatum } from './PriorityListDatum.api';
import { reflectionDatum } from './ReflectionDatum.api';
import { scheduleTableDatum } from './ScheduleTableDatum.api';
import { textBoxDatum } from './TextBoxDatum.api';
import { toDoListDatum } from './ToDoListDatum.api';
import { toDoSummaryDatum } from './ToDoSummaryDatum.api';
import { waterIntakeSummaryDatum } from './WaterIntakeSummaryDatum.api';
import { waterIntakeTrackerDatum } from './WaterIntakeTrackerDatum.api';
import { weatherSummaryDatum } from './WeatherSummaryDatum.api';
import { weatherTrackerDatum } from './WeatherTrackerDatum.api';

export const datumEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    contentPlannerDatum: contentPlannerDatum(builder),
    contentSummaryDatum: contentSummaryDatum(builder),
    expenseSummaryDatum: expenseSummaryDatum(builder),
    expenseTrackerDatum: expenseTrackerDatum(builder),
    focusDatum: focusDatum(builder),
    habitSummaryDatum: habitSummaryDatum(builder),
    habitTrackerDatum: habitTrackerDatum(builder),
    imageBoxDatum: imageBoxDatum(builder),
    intentionDatum: intentionDatum(builder),
    moodSummaryDatum: moodSummaryDatum(builder),
    moodTrackerDatum: moodTrackerDatum(builder),
    pomodoroDatum: pomodoroDatum(builder),
    priorityListDatum: priorityListDatum(builder),
    reflectionDatum: reflectionDatum(builder),
    scheduleTableDatum: scheduleTableDatum(builder),
    textBoxDatum: textBoxDatum(builder),
    toDoListDatum: toDoListDatum(builder),
    toDoSummaryDatum: toDoSummaryDatum(builder),
    waterIntakeTrackerDatum: waterIntakeTrackerDatum(builder),
    waterIntakeSummaryDatum: waterIntakeSummaryDatum(builder),
    weatherSummaryDatum: weatherSummaryDatum(builder),
    weatherTrackerDatum: weatherTrackerDatum(builder),
  }),
});

export const {
  useContentPlannerDatumQuery,
  useContentSummaryDatumQuery,
  useExpenseSummaryDatumQuery,
  useExpenseTrackerDatumQuery,
  useFocusDatumQuery,
  useHabitSummaryDatumQuery,
  useHabitTrackerDatumQuery,
  useImageBoxDatumQuery,
  useIntentionDatumQuery,
  useMoodSummaryDatumQuery,
  useMoodTrackerDatumQuery,
  usePomodoroDatumQuery,
  usePriorityListDatumQuery,
  useReflectionDatumQuery,
  useScheduleTableDatumQuery,
  useTextBoxDatumQuery,
  useToDoListDatumQuery,
  useToDoSummaryDatumQuery,
  useWaterIntakeTrackerDatumQuery,
  useWaterIntakeSummaryDatumQuery,
  useWeatherSummaryDatumQuery,
  useWeatherTrackerDatumQuery,
} = datumEndpoints;
