import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import ActionButtons from '../../../button/action-buttons/ActionButtons';
import {
  CancelAction,
  ConfirmAction,
} from '../../../button/action-buttons/ActionButtons.types';
import { usePopover } from '../popover-context/PopoverContext';
import { PopoverFooterProps } from './PopoverFooter.types';

const PopoverFooter: React.FC<PopoverFooterProps> = ({
  className,
  children,
  confirm: customConfirm = {},
  cancel: customCancel = {},
  actions,
}) => {
  const { close } = usePopover();

  const confirm: ConfirmAction = { hidden: true, ...customConfirm };

  const cancel: CancelAction = {
    kind: 'close',
    onClick: close,
    ...customCancel,
  };

  return (
    <div className={classNames(styles.root, className)}>
      <ActionButtons actions={actions} confirm={confirm} cancel={cancel} />
      {children}
    </div>
  );
};

const styles = {
  root: classes('flex', 'justify-end', 'gap-2'),
};

export default React.memo(PopoverFooter);
