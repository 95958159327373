'use client';

import { classes } from '@nowadays/ui/utils';

const WentWrongWarning = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={styles.root}
      fill='none'
      viewBox='0 0 400 400'
    >
      <path
        fill='currentColor'
        className='text-skin-primary'
        d='M174 373c-65.17 0-118-10.743-118-24 0-13.25 52.83-24 118-24 65.169 0 118 10.75 118 24 0 13.257-52.831 24-118 24z'
      ></path>
      <path
        fill='currentColor'
        className='text-skin-accent'
        d='M286.683 72.007v246.779c0 5.828-2.316 11.42-6.437 15.544a21.988 21.988 0 01-15.543 6.436H86.979a21.986 21.986 0 01-15.542-6.436A21.99 21.99 0 0165 318.786V48.977A21.976 21.976 0 0186.979 27h153.095a6.377 6.377 0 014.44 1.795l40.461 39.178a5.714 5.714 0 011.264 1.845c.292.692.444 1.438.444 2.189z'
      ></path>
      <path
        fill='#BB4C02'
        d='M286.683 71.91h-21.698a23.874 23.874 0 01-16.59-6.688 23.88 23.88 0 01-7.284-16.335L240.344 27c1.494 0 2.928.578 3.999 1.618l40.704 39.408a5.41 5.41 0 011.636 3.885zM251.631 104.569H87.357a4.107 4.107 0 100 8.213H251.63a4.106 4.106 0 100-8.213zM225.105 129.21H87.725a4.476 4.476 0 100 8.952h137.38a4.475 4.475 0 004.477-4.477 4.475 4.475 0 00-4.477-4.475zM225.105 154.588H87.725a4.477 4.477 0 00-4.475 4.477 4.474 4.474 0 004.475 4.474h137.38a4.476 4.476 0 100-8.951zM172.411 180.136H87.726a4.477 4.477 0 000 8.954h84.685a4.478 4.478 0 000-8.954zM172.411 205.519H87.726a4.475 4.475 0 100 8.951h84.685a4.475 4.475 0 100-8.951zM172.411 230.899H87.726a4.476 4.476 0 100 8.952h84.685a4.476 4.476 0 000-8.952zM251.631 254.376H87.357a4.106 4.106 0 100 8.213H251.63a4.108 4.108 0 100-8.213zM225.105 279.017H87.725a4.475 4.475 0 100 8.952h137.38a4.477 4.477 0 000-8.952zM258.667 304.394H88.785c-3.057 0-5.535 2.006-5.535 4.478 0 2.471 2.478 4.474 5.535 4.474h169.882c3.058 0 5.536-2.003 5.536-4.474 0-2.472-2.478-4.478-5.536-4.478z'
      ></path>
      <path
        fill='currentColor'
        className='text-skin-accent'
        stroke='#BB4C02'
        strokeWidth='14'
        d='M238.854 136.115l-81.92 142.923C151.585 288.371 158.323 300 169.08 300h163.839c10.758 0 17.496-11.629 12.147-20.962l-81.92-142.923c-5.378-9.384-18.914-9.384-24.292 0z'
      ></path>
      <path
        fill='#BB4C02'
        d='M243 244v-68a8 8 0 0116 0v68a8 8 0 01-16 0zM251 263a8 8 0 110 16 8 8 0 010-16z'
      ></path>
    </svg>
  );
};

const styles = {
  root: classes('h-52', 'w-52', 'sm:h-72', 'sm:w-72'),
};

export default WentWrongWarning;
