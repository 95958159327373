import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgLaugh = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M8.885 9.23a.346.346 0 1 1 0-.692m0 .692a.346.346 0 1 0 0-.692m6.23.692a.346.346 0 0 1 0-.692m0 .692a.346.346 0 0 0 0-.692m-3.115 9a4.846 4.846 0 0 0 4.846-4.846H7.154A4.846 4.846 0 0 0 12 17.538Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLaugh);
export default ForwardRef;
