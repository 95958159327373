import { WebFontVariant, WebFontVariantsParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const webFontVariants = (builder: Builder) =>
  builder.query<WebFontVariant, WebFontVariantsParams>({
    query: ({ id }) => ({
      url: `/font/web-variants/${id}`,
    }),
  });
