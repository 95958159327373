'use client';

import { DeviceTheme, Rem, Theme } from '@nowadays/base/types';
import { createContext, useContext } from 'react';

interface InterfaceContextProps {
  rem: Rem;
  theme: Theme;
  deviceTheme?: DeviceTheme;
  changeRem: (rem: Rem) => void;
  calculateRem: (rem: number) => number;
  changeTheme: (theme: Theme) => void;
}

export const InterfaceContext = createContext<InterfaceContextProps>({
  rem: 'medium',
  theme: 'system',
  changeRem: () => null,
  calculateRem: () => null,
  changeTheme: () => null,
});

export const useInterface = () => {
  return useContext(InterfaceContext);
};
