import { Page, RestorePageParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const restorePage = (builder: Builder) =>
  builder.mutation<Page, RestorePageParams>({
    query: (params) => ({
      url: `/page/restore`,
      method: 'POST',
      params,
    }),
    async onQueryStarted({ planner }, { dispatch, queryFulfilled }) {
      try {
        const { data: restoredPage } = await queryFulfilled;

        dispatch(
          pageEndpoints.util.updateQueryData(
            'deletedPages',
            planner,
            (draft) => {
              draft.splice(
                draft.findIndex((i) => i.id === restoredPage.id),
                1,
              );
            },
          ),
        );

        dispatch(
          pageEndpoints.util.updateQueryData('pages', { planner }, (draft) => {
            draft.push(restoredPage);
          }),
        );

        await dispatch(
          pageEndpoints.util.upsertQueryData(
            'undatedPage',
            { planner },
            { page: restoredPage },
          ),
        );

        dispatch(
          pageEndpoints.util.updateQueryData(
            'undatedPage',
            { planner },
            (draft) => Object.assign(draft, restoredPage),
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
