import { classes } from '@nowadays/ui/utils';

import { useRichText } from '../../context/RichTextContext';

const RichTextBubbleMenu = () => {
  const { editor } = useRichText();

  if (editor) {
    return null;
  }

  return <div className={styles.root}>RichTextBubbleMenu</div>;
};

const styles = {
  root: classes(
    'p-2',
    'rounded-lg',
    'shadow-2xl',
    'bg-skin-primary',
    'border',
    'border-skin-silent',
  ),
};

export default RichTextBubbleMenu;
