interface HomeEnvironments {
  PROD: boolean;
  API_URL: string;
  APP_URL: string;
  HOME_URL: string;
  S3_BUCKET_URL: string;
  S3_CDN_BUCKET_URL: string;
  SUPPORT_EMAIL: string;
}

class HomeEnvironment {
  constructor() {
    if (!process.env['NEXT_PUBLIC_API_URL']) {
      throw new Error('NEXT_PUBLIC_API_URL required');
    }

    if (!process.env['NEXT_PUBLIC_APP_URL']) {
      throw new Error('NEXT_PUBLIC_APP_URL required');
    }

    if (!process.env['NEXT_PUBLIC_HOME_URL']) {
      throw new Error('NEXT_PUBLIC_HOME_URL required');
    }

    if (!process.env['NEXT_PUBLIC_S3_BUCKET_URL']) {
      throw new Error('NEXT_PUBLIC_S3_BUCKET_URL required');
    }

    if (!process.env['NEXT_PUBLIC_S3_CDN_BUCKET_URL']) {
      throw new Error('NEXT_PUBLIC_S3_CDN_BUCKET_URL required');
    }

    if (!process.env['NEXT_PUBLIC_SUPPORT_EMAIL']) {
      throw new Error('NEXT_PUBLIC_SUPPORT_EMAIL required');
    }
  }

  get PROD() {
    return process.env['NODE_ENV'] === 'production';
  }

  get API_URL() {
    return process.env['NEXT_PUBLIC_API_URL'];
  }

  get APP_URL() {
    return process.env['NEXT_PUBLIC_APP_URL'];
  }

  get HOME_URL() {
    return process.env['NEXT_PUBLIC_HOME_URL'];
  }

  get S3_BUCKET_URL() {
    return process.env['NEXT_PUBLIC_S3_BUCKET_URL'];
  }

  get S3_CDN_BUCKET_URL() {
    return process.env['NEXT_PUBLIC_S3_CDN_BUCKET_URL'];
  }

  get SUPPORT_EMAIL() {
    return process.env['NEXT_PUBLIC_SUPPORT_EMAIL'];
  }
}

export const HOME_ENVS: HomeEnvironments =
  typeof process !== 'undefined' && new HomeEnvironment();
