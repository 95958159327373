import { PlannerImage, PlannerImagesParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const plannerImages = (builder: Builder) =>
  builder.query<PlannerImage[], PlannerImagesParams>({
    query: (params) => ({
      url: '/planner-image/images',
      params,
    }),
    providesTags: ['PlannerImages'],
  });
