'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import ButtonBase from '../button-base/ButtonBase';
import { IconButtonProps } from './IconButton.types';

const IconButton: React.FC<IconButtonProps> = (
  { as, name, className, icon, spinner, type = 'button', children, ...props },
  ref: React.Ref<HTMLButtonElement>,
) => {
  return (
    <ButtonBase
      as={as}
      ref={ref}
      type={type}
      className={classNames(styles.button, className)}
      {...props}
    >
      {({ loading }) => {
        return loading ? (
          <ButtonBase.Spinner {...spinner} />
        ) : (
          <div>
            {children || (name && <ButtonBase.Icon name={name} {...icon} />)}
          </div>
        );
      }}
    </ButtonBase>
  );
};

const styles = {
  button: classes('p-1.5', 'rounded-md', 'h-min'),
};

export default Object.assign(React.memo(React.forwardRef(IconButton)), {
  Icon: ButtonBase.Icon,
});
