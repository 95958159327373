import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgWarning = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path fill='currentColor' d='M13 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z' />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M12 7v6m9-1v0a9 9 0 0 1-9 9v0a9 9 0 0 1-9-9v0a9 9 0 0 1 9-9v0a9 9 0 0 1 9 9Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWarning);
export default ForwardRef;
