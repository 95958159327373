import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';

const RichTextItalic = () => {
  const { editor } = useRichText();

  if (!editor) {
    return null;
  }

  return (
    <RichTextToolbarButton
      name='TextItalic'
      active={editor.isActive('italic')}
      disabled={!editor.can().chain().focus().toggleItalic().run()}
      onClick={() => editor.chain().focus().toggleItalic().run()}
    />
  );
};

export default RichTextItalic;
