import { RestoreTabParams, Tab } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { tabEndpoints } from './Tab.endpoints';

export const restoreTab = (builder: Builder) =>
  builder.mutation<Tab, RestoreTabParams>({
    query: (params) => ({
      url: '/tab/restore/',
      method: 'POST',
      params,
    }),
    async onQueryStarted({ page }, { dispatch, queryFulfilled }) {
      try {
        const { data: restoredPage } = await queryFulfilled;

        dispatch(
          tabEndpoints.util.updateQueryData(
            'deletedTabs',
            { page },
            (draft) => {
              draft.splice(
                draft.findIndex((i) => i.id === restoredPage.id),
                1,
              );
            },
          ),
        );

        dispatch(
          tabEndpoints.util.updateQueryData('tabs', { page }, (draft) => {
            draft.tabs.push(restoredPage);
            draft.tab = restoredPage;
          }),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
