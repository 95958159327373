import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';

const RichTextStrike = () => {
  const { editor } = useRichText();

  if (!editor) {
    return null;
  }

  return (
    <RichTextToolbarButton
      name='TextStrokeThrough'
      active={editor.isActive('strike')}
      disabled={!editor.can().chain().focus().toggleStrike().run()}
      onClick={() => editor.chain().focus().toggleStrike().run()}
    />
  );
};

export default RichTextStrike;
