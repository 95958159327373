import {
  DuplicateTemplate,
  DuplicateTemplateParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const duplicateTemplate = (builder: Builder) =>
  builder.mutation<void, DuplicateTemplateParams & DuplicateTemplate>({
    query: ({ template, ...body }) => ({
      url: `/template/duplicate/${template}`,
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['Templates']) || [],
  });
