import {
  HabitTrackerDatum,
  HabitTrackerDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const habitTrackerDatum = (builder: Builder) =>
  builder.query<HabitTrackerDatum, HabitTrackerDatumParams>({
    query: (params) => ({ url: '/datum/habit-tracker-datum', params }),
  });
