import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgBookmark = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m13.98 19.697.65-.76-.65.76Zm-1.314-.949-.333.943.333-.943Zm-2.645.948.65.76-.65-.76Zm1.313-.948.333.943-.333-.943ZM5.342 20.77l-.83.56.83-.56Zm1.888.868-.115-.993.115.993Zm11.428-.868.83.56-.83-.56Zm-1.888.868.115-.993-.115.993ZM15.545 3.245l-.309.95.31-.95Zm3.21 3.21-.95.309.95-.31Zm-10.3-3.21.309.95-.31-.95Zm-3.21 3.21.95.309-.95-.31ZM9 8a1 1 0 0 0 0 2V8Zm6 2a1 1 0 1 0 0-2v2Zm3 0v7.387h2V10h-2ZM6 17.387V10H4v7.387h2Zm8.63 1.55c-.574-.492-1.06-.93-1.63-1.132l-.667 1.886c.133.047.288.158.995.765l1.302-1.519Zm-3.959 1.519c.708-.607.863-.718.996-.765L11 17.805c-.572.202-1.057.64-1.631 1.132l1.302 1.519ZM13 17.806a3 3 0 0 0-2 0l.667 1.885a1 1 0 0 1 .666 0L13 17.805Zm-9-.419c0 .942 0 1.708.046 2.299.045.57.142 1.162.467 1.643l1.658-1.118c-.017-.026-.09-.168-.131-.682C6 19.035 6 18.363 6 17.387H4Zm5.37 1.55c-.741.635-1.252 1.072-1.652 1.364-.417.304-.572.34-.603.344l.23 1.987c.577-.067 1.089-.378 1.551-.715.48-.35 1.06-.849 1.775-1.461L9.37 18.937ZM4.513 21.33a3 3 0 0 0 2.832 1.303l-.23-1.987a1 1 0 0 1-.944-.434L4.513 21.33ZM18 17.387c0 .976 0 1.648-.04 2.142-.04.514-.114.656-.131.682l1.658 1.118c.325-.481.422-1.072.467-1.643.047-.591.046-1.357.046-2.299h-2Zm-4.671 3.069c.714.612 1.295 1.111 1.775 1.461.462.337.974.648 1.551.715l.23-1.987c-.03-.004-.186-.04-.603-.344-.4-.292-.911-.729-1.652-1.364l-1.302 1.519Zm4.5-.245a1 1 0 0 1-.944.434l-.23 1.987a3 3 0 0 0 2.832-1.303l-1.658-1.118ZM12 4c1.94 0 2.671.012 3.236.196l.618-1.902C14.912 1.988 13.784 2 12 2v2Zm8 6c0-1.783.012-2.912-.294-3.854l-1.902.618C17.988 7.328 18 8.06 18 10h2Zm-4.764-5.804a4 4 0 0 1 2.568 2.568l1.902-.618a6 6 0 0 0-3.852-3.852l-.618 1.902ZM12 2c-1.783 0-2.912-.012-3.854.294l.618 1.902C9.328 4.012 10.06 4 12 4V2Zm-6 8c0-1.94.012-2.672.196-3.236l-1.902-.618C3.988 7.088 4 8.216 4 10h2Zm2.146-7.706a6 6 0 0 0-3.852 3.852l1.902.618a4 4 0 0 1 2.568-2.568l-.618-1.902ZM9 10h6V8H9v2Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBookmark);
export default ForwardRef;
