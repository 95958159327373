import { classes } from '@nowadays/ui/utils';

import RouterMenuItem from './router-menu-item/RouterMenuItem';
import { RouterMenuProps } from './RouterMenu.types';

const RouterMenu: React.FC<RouterMenuProps> = ({ routes }) => {
  return (
    <div className={styles.root}>
      {routes.map((item, key) => (
        <RouterMenuItem key={key} item={item} />
      ))}
    </div>
  );
};

const styles = {
  root: classes('space-y-1'),
};

export default RouterMenu;
