import { AddPlannerImage, PlannerImageAdded } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addPlannerImage = (builder: Builder) =>
  builder.mutation<PlannerImageAdded, AddPlannerImage>({
    query: (body) => ({
      url: '/planner-image/add',
      method: 'POST',
      body,
    }),
  });
