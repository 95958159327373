import { SaveResourcesBody, SaveResourcesParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const saveResources = (builder: Builder) =>
  builder.mutation<void, SaveResourcesParams & SaveResourcesBody>({
    query: ({ namespace, body }) => ({
      url: `/locale/resources/${namespace}`,
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['Resources']) || [],
  });
