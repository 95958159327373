import { AddNamespaceParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addNamespace = (builder: Builder) =>
  builder.mutation<void, AddNamespaceParams>({
    query: (body) => ({
      url: '/locale/namespaces',
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['Namespaces']) || [],
  });
