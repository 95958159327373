export const DEVICE_THEMES = ['light', 'dark'] as const;

export type DeviceTheme = (typeof DEVICE_THEMES)[number];

export type DeviceThemes<T = Any> = {
  [key in DeviceTheme]: T;
};

export const THEMES = ['system', ...DEVICE_THEMES] as const;

export type Theme = (typeof THEMES)[number];

export const REM_OPTIONS = ['small', 'medium', 'large'] as const;

export type Rem = (typeof REM_OPTIONS)[number];

export const Rems: { [key in Rem]: number } = {
  small: 14,
  medium: 16,
  large: 18,
};
