import { Builder } from '../base/query/Api';

export const deleteStickerPackItem = (builder: Builder) =>
  builder.mutation<void, string>({
    query: (item) => ({
      url: `/sticker-pack-item/delete/${item}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['StickerPackItems']) || [],
  });
