import { Page } from '@nowadays/base/types';
import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { TemplateState } from './Template.slice.types';

const initialState: TemplateState = {
  search: '',
  isEditing: false,
  editedTemplates: [],
};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    changeIsEditing: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditing = payload;
    },
    changeSearchKeyword: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    changeTempPage: (state, { payload }: PayloadAction<Page>) => {
      state.tempPage = payload;
    },
    changeEditedTemplates: (state, { payload }: PayloadAction<string[]>) => {
      state.editedTemplates = payload;
    },
  },
});

export const templateReducer = templateSlice.reducer;

export const useTemplateActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(templateSlice.actions, dispatch),
    [dispatch],
  );
};
