'use client';

import { ChartContext, TooltipPosition } from './ChartContext';

interface ChartProviderProps {
  tooltipPosition: TooltipPosition;
  children: React.ReactNode;
}

export const ChartProvider: React.FC<ChartProviderProps> = ({
  children,
  tooltipPosition,
}) => {
  return (
    <ChartContext.Provider value={{ tooltipPosition }}>
      {children}
    </ChartContext.Provider>
  );
};
