'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React, { useEffect, useState } from 'react';

import IconButton from '../../button/icon-button/IconButton';
import Input from '../input/Input';
import { TextProps } from './Text.types';

const Text: React.FC<TextProps> = (
  {
    input,
    type,
    regex,
    className,
    value,
    onChange,
    debounce = 350,
    onDebounceChanged,
    disabled,
    readOnly,
    ...props
  },
  ref: React.Ref<HTMLInputElement>,
) => {
  const { disable, ...inputProps } = input || {};
  const isPassword = type === 'password';
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [state, setState] = useState<string | number>(value || '');

  useEffect(() => {
    if (value !== undefined && value !== null && value !== state) {
      setState(value);
    }
  }, [value]);

  useEffect(() => {
    if (state === undefined || (value || '') === state) {
      return;
    }

    const timeout = setTimeout(() => {
      onDebounceChanged && onDebounceChanged(state);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [state]);

  const handleShowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val: string | number = e.target.value;

    if (regex) {
      val = val.replace(regex, '');

      if (type === 'number') {
        val = (val && Number(val)) || '';
      }

      onChange &&
        onChange({ ...e, target: { ...e.target, value: val as string } });
    } else {
      onChange && onChange(e);
    }
    setState(val);
  };

  return (
    <Input disable={disabled || disable} readOnly={readOnly} {...inputProps}>
      {({
        inputStyles,
        focusStyles,
        backgroundStyles,
        borderStyles,
        errorStyles,
        adornmentStyles,
        disabledStyles,
        customStyles,
        boxProps,
      }) => (
        <Input.Box
          end={
            (isPassword && (
              <IconButton
                variant='text'
                disabled={disabled}
                className={styles.button}
                onClick={handleShowClick}
                name={showPassword ? 'Hide' : 'Show'}
                icon={{ className: adornmentStyles }}
              />
            )) ||
            undefined
          }
          className={classNames(
            backgroundStyles,
            borderStyles,
            errorStyles,
            disabledStyles,
            customStyles,
          )}
          {...boxProps}
        >
          <input
            ref={ref}
            value={state}
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleChange}
            type={showPassword ? 'text' : type === 'number' ? 'text' : type}
            className={classNames(
              inputStyles,
              focusStyles,
              isPassword && styles.end,
              className,
            )}
            {...props}
          />
        </Input.Box>
      )}
    </Input>
  );
};

const styles = {
  button: classes('p-1'),
  end: classes('pr-8'),
};

export default React.forwardRef(Text);
