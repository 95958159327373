import { AnchorButton } from '@nowadays/ui/components';
import { useTranslate } from '@nowadays/ui/i18n/client';
import { APP_ENVS, classes } from '@nowadays/ui/utils';
import { useState } from 'react';

import { SocialButtonProps } from './SocialButton.types';

const AppleButton: React.FC<SocialButtonProps> = ({ url }) => {
  const translate = useTranslate();
  const [loading, setLoading] = useState<boolean>(false);
  const redirect = `${APP_ENVS.API_URL}/${url}`;

  return (
    <AnchorButton
      href={redirect}
      variant='outlined'
      disabled={loading}
      onClick={() => setLoading(true)}
      loading={loading}
      startIcon={{ name: 'Apple' }}
      className={styles.button}
      tabIndex={2}
    >
      {translate('ui.common.socialButtons.withApple')}
    </AnchorButton>
  );
};

const styles = {
  button: classes(
    'dark',
    'w-full',
    'px-2',
    'py-1',
    'border-2',
    'border-skin-silent',
    'text-skin-white',
    'bg-skin-black',
    'hover:bg-skin-black',
    'hover:bg-opacity-100',
    'hover:active:bg-skin-black',
    'hover:active:bg-opacity-100',
  ),
};

export default AppleButton;
