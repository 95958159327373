import { Page } from '@nowadays/base/types';

export enum PageCategories {
  Active = 'active',
  Favorite = 'favorite',
  Deleted = 'deleted',
}

export type Position = {
  x: number;
  y: number;
};

export type Size = {
  width: number;
  height: number;
};

export interface PageState {
  activePage?: Page | null;
  isEditable: boolean;
  isPagesOpen: boolean;
  isDragging: boolean;
  isAnimating: boolean;
  search: string;
  category: PageCategories;
  boundary?: DOMRect;
  area?: DOMRect;
  position: Position;
  scale: number;
  initialScale: number;
  nonScaleItemsSize: Size;
  lastScaleOffset?: Position;
}
