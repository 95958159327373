import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import React, { useState } from 'react';

import ImageNodeAlt from './image-node-alt/ImageNodeAlt';
import ImageNodeSize from './image-node-size/ImageNodeSize';

const ImageNodeItem: React.FC<NodeViewWrapperProps> = (props) => {
  const { updateAttributes } = props;
  const { alt, width, height } = props.node.attrs;

  const [altValue, setAltValue] = useState(alt || '');

  let className = 'relative';

  if (props.selected) {
    className += ' ProseMirror-selectednode';
  }

  const handleAltChange = (value: string) => setAltValue(value);

  const handleAltConfirm = () => updateAttributes({ alt: altValue });

  const handleSizeChange = ({
    width,
    height,
  }: {
    width: number | string;
    height: number | string;
  }) => updateAttributes({ width, height });

  return (
    <NodeViewWrapper className={className} data-drag-handle>
      <img {...props.node.attrs} />
      <ImageNodeAlt
        value={altValue}
        onChange={handleAltChange}
        onConfirm={handleAltConfirm}
      />
      <ImageNodeSize
        width={width}
        height={height}
        onSizeChange={handleSizeChange}
      />
    </NodeViewWrapper>
  );
};

export default ImageNodeItem;
