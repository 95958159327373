'use client';

import { FieldErrorMessage } from '@nowadays/base/types';
import { useTranslate } from '@nowadays/ui/i18n/client';
import { FieldValues, useFormContext } from 'react-hook-form';

import { ValidationError } from '../field-error/FieldError.types';
import { fieldErrorMapper } from '../field-error/useFieldError';

const getFieldError = (errors?: Record<string, unknown>, field?: string) => {
  if (errors && Object.keys(errors).length !== 0 && field) {
    return (errors[field] ||
      field.split('.').reduce((o, x) => o && o[x], errors)) as ValidationError;
  }
};

export const useFormControl = <T extends FieldValues>(name?: string) => {
  const translate = useTranslate();

  const {
    watch,
    getValues,
    control,
    setValue,
    register,
    trigger,
    setError,
    formState: {
      disabled,
      errors,
      defaultValues,
      isLoading: isFetching,
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFormContext<T>();

  const error = name && getFieldError(errors, name);
  const isLoading = isFetching || isSubmitting;
  const isDisabled = disabled || isLoading;

  const setFieldErrors = (errors: FieldErrorMessage[]) =>
    fieldErrorMapper<T>({ translate, setError })(errors);

  return {
    watch,
    setValue,
    trigger,
    register,
    getValues,
    setError,
    setFieldErrors,
    errors,
    control,
    defaultValues,
    error,
    isValid,
    isDirty,
    isLoading,
    isSubmitting,
    isDisabled,
  };
};
