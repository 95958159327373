import { PlannerImageUpdated, UpdatePlannerImage } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const updatePlannerImage = (builder: Builder) =>
  builder.mutation<PlannerImageUpdated, UpdatePlannerImage>({
    query: (body) => ({
      url: '/planner-image/update',
      method: 'POST',
      body,
    }),
  });
