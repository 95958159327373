'use client';

import {
  Menu as HeadlessMenu,
  MenuButton as HeadlessMenuButton,
  MenuItem as HeadlessMenuItem,
} from '@headlessui/react';
import { useRef } from 'react';

import { MenuProvider } from './menu-context/MenuProvider';
import MenuItems from './menu-items/MenuItems';
import { MenuBaseProps } from './MenuBase.types';

const MenuBase: React.FC<MenuBaseProps> & {
  Button: typeof HeadlessMenuButton;
  Item: typeof HeadlessMenuItem;
  Items: typeof MenuItems;
} = ({ children, className, ...props }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <HeadlessMenu as='div' ref={ref} className={className} {...props}>
      {({ open, close }) => (
        <MenuProvider open={open} close={close} defaultreference={ref.current}>
          {typeof children === 'function'
            ? children({ open, close })
            : children}
        </MenuProvider>
      )}
    </HeadlessMenu>
  );
};

MenuBase.Button = HeadlessMenuButton;
MenuBase.Item = HeadlessMenuItem;
MenuBase.Items = MenuItems;

export default MenuBase;
