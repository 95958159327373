'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { useSnackbar } from '../../snackbar/snackbar-context/SnackbarContext';
import Input from '../input/Input';
import DropZone from './drop-zone/DropZone';
import { FilePickerProps } from './FilePicker.types';

const FilePicker: React.FC<FilePickerProps> = (
  {
    type = 'file',
    input,
    className,
    disabled,
    children,
    onBlur,
    onDrop,
    multiple,
    maxSize = 16,
    maxFiles,
    helper,
    ...props
  },
  ref: React.Ref<HTMLInputElement>,
) => {
  const translate = useTranslate();
  const { addMessage } = useSnackbar();

  const { disable, ...inputProps } = input || {};

  const handleDrop: DropzoneOptions['onDrop'] = (event, ...rest) => {
    const files = event as File[];

    if (files.length > maxFiles) {
      addMessage({
        item: translate('ui.components.inputs.file.maxFiles', {
          max: maxFiles,
        }),
        color: 'red',
      });

      return;
    }

    if (maxSize) {
      const maxInMb = maxSize * 1024 * 1024;

      const isBigger = multiple
        ? files.find((i) => i.size > maxInMb)
        : files[0].size > maxInMb;

      if (isBigger) {
        addMessage({
          item: translate('ui.components.inputs.file.maxSize', {
            size: maxSize,
          }),
          color: 'red',
        });
      } else {
        onDrop(files, ...rest);
      }
    } else {
      onDrop(files, ...rest);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    disabled,
    onDrop: handleDrop,
    ...props,
  });

  return (
    <Input disable={disabled || disable} {...inputProps}>
      {({
        backgroundStyles,
        focusStyles,
        borderStyles,
        errorStyles,
        disabledStyles,
        customStyles,
      }) => (
        <div
          {...getRootProps()}
          onBlur={onBlur}
          className={classNames(
            styles.root,
            backgroundStyles,
            focusStyles,
            borderStyles,
            errorStyles,
            disabledStyles,
            customStyles,
            className,
            isDragActive && styles.active,
          )}
        >
          <input ref={ref} {...getInputProps()} />

          {children || (
            <DropZone type={type} helper={helper} multiple={multiple} />
          )}
        </div>
      )}
    </Input>
  );
};

const styles = {
  root: classes('cursor-pointer', 'flex', 'items-center', 'justify-center'),
  active: classes('border-2', 'border-skin-accent'),
};

export default React.forwardRef(FilePicker);
