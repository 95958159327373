import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgFilter = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m16.27 11.744.635.773-.635-.773Zm-8.891.012.665-.747-.665.747Zm5.989 7.586-.578-.817.578.817Zm-1.942 1.372.577.816-.577-.817ZM4.126 8.86l-.665.746.665-.746Zm15.665-.01-.635-.772.635.773ZM16 8a1 1 0 1 0 0-2v2ZM8 6a1 1 0 1 0 0 2V6ZM6.472 4h11.112V2H6.472v2Zm6.318 14.525-1.94 1.372 1.153 1.633 1.941-1.371-1.154-1.634Zm-2.79.988v-4.177H8v4.177h2ZM8.044 11.01 4.79 8.113 3.46 9.606l3.253 2.897 1.33-1.494Zm11.112-2.93-3.52 2.893 1.27 1.545 3.52-2.894-1.27-1.545ZM13.5 15.451v1.73h2v-1.73h-2Zm2.135-4.48a5.801 5.801 0 0 0-2.135 4.48h2c0-1.123.509-2.198 1.405-2.935l-1.27-1.545ZM10 15.336c0-1.652-.714-3.22-1.956-4.327l-1.33 1.494A3.795 3.795 0 0 1 8 15.336h2Zm3.944 4.823a3.648 3.648 0 0 0 1.556-2.977h-2c0 .522-.257 1.024-.71 1.343l1.155 1.634Zm-3.095-.262a.548.548 0 0 1-.571.042.464.464 0 0 1-.278-.426H8c0 2.078 2.375 3.167 4.003 2.017l-1.154-1.633ZM2 6.373a4.33 4.33 0 0 0 1.461 3.233l1.33-1.493A2.33 2.33 0 0 1 4 6.373H2Zm18-.055a2.28 2.28 0 0 1-.844 1.76l1.27 1.545A4.28 4.28 0 0 0 22 6.318h-2ZM17.584 4C18.946 4 20 5.065 20 6.318h2C22 3.906 19.995 2 17.584 2v2ZM6.472 2C4.03 2 2 3.93 2 6.373h2C4 5.09 5.08 4 6.472 4V2ZM16 6H8v2h8V6Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFilter);
export default ForwardRef;
