import { DeleteReportParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const deleteReport = (builder: Builder) =>
  builder.mutation<void, DeleteReportParams>({
    query: ({ id }) => ({
      url: `/report/delete/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (res, err) => (!err && ['Reports']) || [],
  });
