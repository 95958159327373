'use client';

import {
  PopoverBackdrop as HeadlessPopoverBackdrop,
  Transition as HeadlessTransitionChild,
} from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';
import ReactDOM from 'react-dom';

import { usePopover } from '../popover-context/PopoverContext';
import { PopoverBackdropProps } from './PopoverBackdrop.types';

const PopoverBackdrop: React.FC<PopoverBackdropProps> = ({
  portal = true,
  className,
  ...props
}) => {
  const { zIndex } = usePopover();

  const child = (
    <HeadlessTransitionChild
      as={HeadlessPopoverBackdrop}
      style={{ zIndex: zIndex - 1 }}
      className={classNames(styles.root, className)}
      enter={styles.enter}
      enterFrom={styles.enterFrom}
      enterTo={styles.enterTo}
      leave={styles.leave}
      leaveFrom={styles.leaveFrom}
      leaveTo={styles.leaveTo}
      {...props}
    />
  );

  return portal ? ReactDOM.createPortal(child, document.body) : child;
};

const styles = {
  root: classes('fixed', 'inset-0', 'bg-skin-black', 'bg-opacity-50'),
  enter: classes('duration-200'),
  enterFrom: classes('opacity-0'),
  enterTo: classes('opacity-100'),
  leave: classes('duration-300'),
  leaveFrom: classes('opacity-100'),
  leaveTo: classes('opacity-0'),
};

export default React.memo(PopoverBackdrop);
