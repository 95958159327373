import { resources } from './resources';

export const SUPPORTED_LANGUAGES = [
  'en-US',
  'tr-TR',
  'fr-FR',
  'es-ES',
  'es-LA',
  'ja-JP',
] as const;

export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];

export type SupportedLanguages<T = Any> = {
  [key in SupportedLanguage]: T;
};

export const LANGUAGES = ['en-US', 'tr-TR'] as const;

export type Language = (typeof LANGUAGES)[number];

export type Languages<T = Any> = {
  [key in Language]: T;
};

export const FallbackLanguage: Language = 'en-US';

export type Namespaces = keyof (typeof resources)['en-US'];

declare global {
  type ObjectToType<T> = T extends object
    ? {
        [K in keyof T]: K extends string
          ? T[K] extends object
            ? `${K}.${ObjectToType<T[K]>}`
            : K
          : never;
      }[keyof T]
    : never;

  type Message = ObjectToType<(typeof resources)['en-US']>;

  type Translate = (
    key: Message,
    options?: { [key: string]: unknown },
  ) => string;
}
