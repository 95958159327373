import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';

const RichTextBlockquote = () => {
  const { editor } = useRichText();

  if (!editor) {
    return null;
  }

  return (
    <RichTextToolbarButton
      name='Apostrophe'
      active={editor.isActive('blockquote')}
      onClick={() => editor.chain().focus().toggleBlockquote().run()}
    />
  );
};

export default RichTextBlockquote;
