import {
  AddWidgetSetItem,
  AddWidgetSetItemParams,
  WidgetSetItemAdded,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addWidgetSetItem = (builder: Builder) =>
  builder.mutation<
    WidgetSetItemAdded,
    AddWidgetSetItemParams & AddWidgetSetItem
  >({
    query: ({ set, ...body }) => ({
      url: `/widget-set-item/add/${set}`,
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['WidgetSetItemsCodes']) || [],
  });
