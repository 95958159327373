import { TimeInterval } from '@nowadays/base/types';
import { TIME_12_FORMAT, TIME_24_FORMAT } from '@nowadays/base/utils';
import { add, format, startOfToday } from 'date-fns';
import { useCallback } from 'react';

import { FormatOptions, Settings } from '../RegionContext';

export const useTimeFunctions = (
  settings: Settings,
  formatOptions: FormatOptions,
) => {
  const formatTime = useCallback(
    (time: TimeInterval, formatStr?: string) => {
      const { hour, minute } = time;
      const { hourFormat } = settings;

      return format(
        add(startOfToday(), { hours: hour, minutes: minute }),
        formatStr || (hourFormat === '24' ? TIME_24_FORMAT : TIME_12_FORMAT),
        formatOptions,
      );
    },
    [settings, formatOptions],
  );

  const parseTime = useCallback((time: string): TimeInterval => {
    const [hour, minute] = time.split(':');

    return { hour: Number(hour), minute: Number(minute) };
  }, []);

  const compareTime = useCallback((left: TimeInterval, right: TimeInterval) => {
    const leftHour = left.hour || 24;
    const rightHour = right.hour || 24;

    return (
      leftHour > rightHour ||
      (leftHour === rightHour && left.minute > right.minute)
    );
  }, []);

  return { formatTime, parseTime, compareTime };
};
