'use client';

import { ListboxOptions as HeadlessListboxOptions } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import Floating from '../../../floating/Floating';
import { ListboxPanelProps } from './ListboxPanel.types';

const ListboxPanel: React.FC<ListboxPanelProps> = ({
  open,
  reference,
  floating,
  children,
  className,
}) => {
  if (reference === undefined) {
    return null;
  }

  const { className: floatingClassName, ...floatingProps } = floating || {};

  return (
    <Floating
      reference={reference}
      autoUpdate
      sameWidth
      open={open}
      shift={{ padding: 0 }}
      placement='bottom-start'
      className={classNames(styles.floating, floatingClassName)}
      {...floatingProps}
    >
      <HeadlessListboxOptions className={classNames(styles.options, className)}>
        {(props) => (
          <Fragment>
            {typeof children === 'function' ? children(props) : children}
          </Fragment>
        )}
      </HeadlessListboxOptions>
    </Floating>
  );
};

const styles = {
  floating: classes('z-[1600]'),
  options: classes(
    'max-h-64',
    'overflow-hidden',
    'overflow-y-auto',
    'text-sm',
    'shadow-lg',
    'bg-skin-primary',
    'rounded-lg',
    'border-2',
    'border-opacity-60',
    'border-skin-silent',
  ),
};

export default ListboxPanel;
