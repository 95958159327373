import { SupportedLanguage } from '@nowadays/base/locales';
import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { LanguageState } from './Language.slice.types';

const initialState: LanguageState = {
  languages: [],
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguages: (
      state,
      { payload }: PayloadAction<SupportedLanguage[]>,
    ) => {
      state.languages = payload;
    },
    changeLanguage: (state, { payload }: PayloadAction<SupportedLanguage>) => {
      state.language = payload;
    },
    changeNamespace: (state, { payload }: PayloadAction<string>) => {
      state.namespace = payload;
    },
  },
});

export const languageReducer = languageSlice.reducer;

export const useLanguageActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(languageSlice.actions, dispatch),
    [dispatch],
  );
};
