import { CurrentUser, LoginAdminParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const loginAdmin = (builder: Builder) =>
  builder.mutation<CurrentUser, LoginAdminParams>({
    query: (body) => ({
      url: '/auth/login-admin',
      method: 'POST',
      body,
    }),
  });
