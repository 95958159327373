import { Builder } from '../base/query/Api';
import { plannerEndpoints } from './Planner.endpoints';

export const permanentlyDeletePlanners = (builder: Builder) =>
  builder.mutation<void, void>({
    query: () => ({
      url: `/planner/permanently-delete-planners`,
      method: 'DELETE',
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled;

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'deletedPlanners',
            undefined,
            (draft) => {
              draft = [];
              return draft;
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
