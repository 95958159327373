import { AddPlannerCovers, PlannerCoversAdded } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addPlannerCovers = (builder: Builder) =>
  builder.mutation<PlannerCoversAdded, AddPlannerCovers>({
    query: (body) => ({
      url: '/planner-image/add-covers',
      method: 'POST',
      body,
    }),
  });
