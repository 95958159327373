import {
  ExpenseTrackerDatum,
  ExpenseTrackerDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const expenseTrackerDatum = (builder: Builder) =>
  builder.query<ExpenseTrackerDatum, ExpenseTrackerDatumParams>({
    query: (params) => ({ url: '/datum/expense-tracker-datum', params }),
  });
