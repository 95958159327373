import Image from '@tiptap/extension-image';
import {
  mergeAttributes,
  nodeInputRule,
  ReactNodeViewRenderer,
} from '@tiptap/react';

import ImageNodeItem from './ImageNodeItem';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageCustom: {
      setImage: (options: {
        src: string;
        alt?: string;
        title?: string;
        width?: string | number;
        height?: string | number;
      }) => ReturnType;
    };
  }
}

export const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export default Image.extend({
  addNodeView() {
    return ReactNodeViewRenderer(ImageNodeItem);
  },
  name: 'imageCustom',
  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {},
      resizeIcon: <>⊙</>,
      useFigure: false,
    };
  },
  addAttributes() {
    return {
      src: {
        renderHTML: (attributes) => {
          return {
            src: attributes.src,
          };
        },
      },
      alt: {
        renderHTML: (attributes) => {
          return {
            alt: attributes.alt,
          };
        },
      },
      title: {
        renderHTML: (attributes) => {
          return {
            title: attributes.title,
          };
        },
      },
      width: {
        default: 'auto',
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
      },
      height: {
        default: 'auto',
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'img',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title, height, width] = match;
          return { src, alt, title, height, width };
        },
      }),
    ];
  },
});
