'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

import IconButton from '../../../button/icon-button/IconButton';
import Tooltip from '../../../tooltip/Tooltip';
import { useTable } from '../../table-context/TableContext';

const PaginationButton = () => {
  const { table } = useTable();
  const translate = useTranslate();

  return (
    <div className={styles.root}>
      <Tooltip title={translate('ui.components.table.pagination.button.start')}>
        <IconButton
          name='LeftDouble'
          className={styles.button}
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        />
      </Tooltip>

      <Tooltip
        title={translate('ui.components.table.pagination.button.previous')}
      >
        <IconButton
          name='Left'
          className={styles.button}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        />
      </Tooltip>

      <Tooltip title={translate('ui.components.table.pagination.button.next')}>
        <IconButton
          name='Right'
          className={styles.button}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        />
      </Tooltip>

      <Tooltip title={translate('ui.components.table.pagination.button.end')}>
        <IconButton
          name='RightDouble'
          className={styles.button}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        />
      </Tooltip>
    </div>
  );
};

const styles = {
  root: classes('flex', 'gap-2'),
  button: classes('p-1'),
};

export default PaginationButton;
