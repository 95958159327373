import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgMessage = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m20.003 15.536.85.526-.85-.526Zm-.21.368-.93-.368.93.368Zm.015.75.968-.251-.968.25Zm-.076-.35.997-.076-.997.077Zm-3.078 3.504-.251.968.25-.968Zm-.35-.076.077-.997-.077.997Zm-.767.272.527.85-.527-.85Zm.365-.211.37.929-.37-.93Zm2.858.372-.2-.98.2.98Zm1.405-1.405-.98-.2.98.2ZM8.273 12.673a1 1 0 1 0 0 2v-2Zm7.2 2a1 1 0 1 0 0-2v2Zm-7.2-5.6a1 1 0 1 0 0 2v-2Zm7.2 2a1 1 0 1 0 0-2v2Zm-9.13 6.585a8 8 0 0 1 0-11.315L4.93 4.93c-3.905 3.906-3.905 10.238 0 14.144l1.414-1.415Zm0-11.315a8 8 0 0 1 11.315 0l1.415-1.414c-3.906-3.905-10.238-3.905-14.144 0l1.414 1.414Zm8.666 12.811c-1.485.923-3.143 1.043-4.711.675-1.583-.372-3.016-1.232-3.955-2.17L4.93 19.072c1.193 1.193 2.955 2.243 4.911 2.703 1.971.463 4.197.337 6.224-.923l-1.055-1.699Zm2.65-12.81c.891.891 1.752 2.322 2.135 3.922.38 1.585.274 3.262-.642 4.744l1.702 1.052c1.259-2.037 1.357-4.288.885-6.261-.469-1.96-1.514-3.72-2.666-4.872l-1.415 1.414Zm1.493 8.666c-.092.15-.219.347-.29.526l1.86.736c-.014.035-.022.044.005-.003.026-.044.063-.105.127-.207l-1.702-1.052Zm1.624 1.393a6.89 6.89 0 0 1-.046-.182c-.008-.033-.003-.022-.001.007l-1.994.154c.014.19.069.384.105.523l1.936-.502Zm-1.913-.867a1.806 1.806 0 0 0-.128.846l1.994-.154c-.001-.012 0-.013-.001-.008a.228.228 0 0 1-.009.059c-.001.005-.001.004.003-.007l-1.86-.736Zm-1.958 3.304c-.14-.036-.334-.09-.524-.105l-.153 1.994c-.029-.002-.04-.006-.007 0l.182.047.502-1.936Zm-.84 2.013.205-.126c.046-.027.037-.019.002-.005l-.74-1.858c-.179.071-.374.198-.523.29l1.055 1.7Zm.316-2.118a1.81 1.81 0 0 0-.849.129l.74 1.858c.01-.004.012-.004.007-.003a.242.242 0 0 1-.058.01h.007l.153-1.994Zm.022 2.04c.552.144 1.015.265 1.39.335.37.07.769.116 1.166.035l-.398-1.96c-.01.002-.1.016-.397-.04-.293-.055-.68-.155-1.26-.305l-.501 1.936Zm2.437-3.87c.15.58.25.966.305 1.259.056.298.042.386.04.396l1.96.4c.08-.398.035-.797-.035-1.167-.07-.375-.191-.838-.334-1.39l-1.936.502Zm.12 4.24a2.8 2.8 0 0 0 2.185-2.186l-1.96-.398a.8.8 0 0 1-.624.624l.398 1.96ZM8.272 14.673h7.2v-2h-7.2v2Zm0-3.6h7.2v-2h-7.2v2Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMessage);
export default ForwardRef;
