import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgDottedMenu = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <rect
      width={4}
      height={4}
      x={3}
      y={10}
      stroke='currentColor'
      strokeWidth={2}
      rx={1}
    />
    <rect
      width={4}
      height={4}
      x={10}
      y={10}
      stroke='currentColor'
      strokeWidth={2}
      rx={1}
    />
    <rect
      width={4}
      height={4}
      x={17}
      y={10}
      stroke='currentColor'
      strokeWidth={2}
      rx={1}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDottedMenu);
export default ForwardRef;
