import { admin } from './admin/tr_TR';
import { api } from './api/tr_TR';
import { app } from './app/tr_TR';
import { base } from './base/tr_TR';
import { home } from './home/tr_TR';
import { ui } from './ui/tr_TR';

export const tr_TR = {
  base,
  admin,
  app,
  ui,
  home,
  api,
};
