'use client';

import { classes } from '@nowadays/ui/utils';

import { BottomSheetContentProps } from './BottomSheetContent.types';

const BottomSheetContent: React.FC<BottomSheetContentProps> = ({
  children,
}) => {
  return <div className={styles.root}>{children}</div>;
};

const styles = {
  root: classes(
    'px-3',
    'pb-3',
    'w-full',
    'h-full',
    'touch-auto',
    'overflow-auto',
  ),
};

export default BottomSheetContent;
