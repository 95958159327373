'use client';

import { classes } from '@nowadays/ui/utils';
import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';

import Floating from '../../../floating/Floating';
import { useChart } from '../../chart-context/ChartContext';
import { CustomTooltipContainerProps } from './CustomTooltipContainer.types';

const CustomTooltipContainer: React.FC<CustomTooltipContainerProps> = ({
  open,
  children,
}) => {
  const { tooltipPosition } = useChart();
  const [reference, setReference] = useState(null);

  const style = {
    left: `${tooltipPosition.left}px`,
    top: `${tooltipPosition.top}px`,
  };

  return ReactDOM.createPortal(
    <Fragment>
      <div ref={setReference} style={style} className='absolute' />
      <Floating
        reference={reference}
        open={open}
        placement='top'
        strategy='fixed'
        offset={16}
        dependencies={[tooltipPosition]}
        className={styles.floating}
      >
        {children}
      </Floating>
    </Fragment>,
    document.body,
  );
};

const styles = {
  floating: classes(
    'rounded-md',
    'p-2',
    'text-sm',
    'text-skin-inverted',
    'bg-skin-inverted',
  ),
};

export default CustomTooltipContainer;
