import { classes } from '@nowadays/ui/utils';

export const bgStyles = {
  base: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-base'),
  },
  primary: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-inverted'),
  },
  inverted: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-primary'),
  },
  muted: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-muted'),
  },
  silent: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-silent'),
  },
  accent: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-accent'),
  },
  green: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-green'),
  },
  red: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-red'),
  },
  blue: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-blue'),
  },
  orange: {
    text: null,
    flat: null,
    outlined: classes('border-2'),
    contained: classes('bg-skin-orange'),
  },
};
