import { classes } from '@nowadays/ui/utils';

import { useRegion } from '../../region/RegionContext';
import { CustomPieLabelProps } from './CustomPieLabel.types';

const RADIAN = Math.PI / 180;

const CustomPieLabel: React.FC<CustomPieLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  payload,
  children,
  width = 0,
  height = 0,
}) => {
  const { formatPercent } = useRegion();

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (children) {
    return (
      <foreignObject
        x={x - 20}
        y={y - 10}
        width={width}
        height={height}
        className={styles.pointer}
      >
        {children(payload)}
      </foreignObject>
    );
  }

  return (
    <text
      x={x}
      y={y}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
      className={styles.pointer}
    >
      {formatPercent(percent * 100, true)}
    </text>
  );
};

const styles = {
  pointer: classes('pointer-events-none', 'select-none'),
};

export default CustomPieLabel;
