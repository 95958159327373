'use client';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { classes, classNames } from '@nowadays/ui/utils';
import { useMemo } from 'react';

import { SortableItemProps } from './SortableItem.types';

const SortableItem = <T extends React.ElementType = 'div'>({
  as,
  id,
  disabled,
  children,
  className,
  handle,
  tabIndex,
  transformFn,
  ...props
}: SortableItemProps<T>) => {
  const Component = as || 'div';

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled });

  const transformed = useMemo(() => {
    if (!transform) {
      return 'unset';
    }

    if (transformFn) {
      return transformFn(transform);
    } else {
      return CSS.Transform.toString(transform);
    }
  }, [transform, transformFn]);

  return (
    <Component
      ref={setNodeRef}
      style={{
        transform: transformed,
        transition: transition || undefined,
      }}
      className={classNames(
        styles.root,
        handle && styles.handle,
        disabled && styles.disabled,
        isDragging && styles.dragging,
        className,
      )}
      {...props}
      {...attributes}
      {...(!handle ? listeners : undefined)}
      tabIndex={tabIndex}
    >
      {typeof children === 'function' ? children({ listeners }) : children}
    </Component>
  );
};

const styles = {
  root: classes(
    'cursor-grab',
    'focus-visible:outline-2',
    'focus-visible:outline-offset-0',
    'focus-visible:outline-skin-accent',
  ),
  handle: classes('cursor-default'),
  disabled: classes('cursor-default', 'focus-visible:outline-0'),
  dragging: classes('cursor-grabbing', 'z-[3000]'),
};

export default SortableItem;
