'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import ActionButtons from '../../../button/action-buttons/ActionButtons';
import { DialogFooterProps } from './DialogFooter.types';

const DialogFooter: React.FC<DialogFooterProps> = ({
  className,
  children,
  confirm,
  cancel,
  actions,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      <ActionButtons actions={actions} confirm={confirm} cancel={cancel} />
      {children}
    </div>
  );
};

const styles = {
  root: classes('pt-2', 'flex', 'justify-end', 'gap-2'),
};

export default DialogFooter;
