import { baseApi } from '../base/query/Api';
import { deleteFile } from './DeleteFile.api';
import { deleteFolder } from './DeleteFolder.api';
import { listFiles } from './ListFiles.api';
import { signFileUrl } from './SignFileUrl.api';

export const fileEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listFiles: listFiles(builder),
    signFileUrl: signFileUrl(builder),
    deleteFile: deleteFile(builder),
    deleteFolder: deleteFolder(builder),
  }),
});

export const {
  useListFilesQuery,
  useSignFileUrlMutation,
  useDeleteFileMutation,
  useDeleteFolderMutation,
} = fileEndpoints;
