'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import Icon from '../../../icons/Icon';
import { DropZoneProps } from './DropZone.types';

const DropZone: React.FC<DropZoneProps> = ({
  type = 'file',
  helper = true,
  multiple,
  className,
}) => {
  const translate = useTranslate();

  return (
    <div className={classNames(styles.root, className)}>
      <Icon name={type === 'file' ? 'File' : 'Image'} className={styles.icon} />
      {helper && (
        <span>
          {translate(
            multiple
              ? `ui.components.inputs.${type}.multiple`
              : `ui.components.inputs.${type}.single`,
          )}
        </span>
      )}
    </div>
  );
};

const styles = {
  root: classes(
    'gap-1',
    'flex',
    'flex-col',
    'items-center',
    'justify-center',
    'text-xs',
    'text-center',
    'text-skin-accent',
  ),
  icon: classes('w-10', 'h-10'),
};

export default DropZone;
