import { DeleteTab, DeleteTabParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { tabEndpoints } from './Tab.endpoints';

export const deleteTab = (builder: Builder) =>
  builder.mutation<DeleteTab, DeleteTabParams>({
    query: (params) => ({
      url: '/tab/delete',
      method: 'DELETE',
      params,
    }),
    async onQueryStarted({ page }, { dispatch, queryFulfilled }) {
      try {
        const {
          data: { lastEditedTab, deletedTab },
        } = await queryFulfilled;

        dispatch(
          tabEndpoints.util.updateQueryData('tabs', { page }, (draft) => {
            draft.tabs.splice(
              draft.tabs.findIndex((i) => i.id === deletedTab.id),
              1,
            );
            draft.tab = lastEditedTab;
          }),
        );

        dispatch(
          tabEndpoints.util.updateQueryData(
            'deletedTabs',
            { page },
            (draft) => {
              draft.push(deletedTab);
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
