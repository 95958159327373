import { useNotifications } from '@nowadays/ui/components';

import { accountAlreadyExists } from './components/account-already-exists/AccountAlreadyExists';
import { accountDisabled } from './components/account-disabled/AccountDisabled';
import { accountNotFound } from './components/account-not-found/AccountNotFound';
import { invalidCredentials } from './components/invalid-credentials/InvalidCredentials';
import { socialEmailError } from './components/social-email-error/SocialEmailError';
import { somethingWentWrong } from './components/something-went-wrong/SomethingWentWrong';

const notifications = {
  AccountNotFound: accountNotFound,
  AccountDisabled: accountDisabled,
  InvalidCredentials: invalidCredentials,
  AccountAlreadyExists: accountAlreadyExists,
  SocialEmailError: socialEmailError,
  SomethingWentWrong: somethingWentWrong,
};

export const useAuthNotifications = () =>
  useNotifications<typeof notifications>(notifications);
