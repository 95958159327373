'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import ColorPicker from '../../../inputs/color-picker/ColorPicker';
import { Popover } from '../../../popover/index';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormColorPickerProps } from './FormColorPicker.types';

const FormColorPicker: React.FC<FormColorPickerProps> = ({
  field,
  label,
  container,
  ...props
}) => {
  const { control, watch, error, isDisabled } = useFormControl(field);

  const isDisable = isDisabled;
  const isError = !!error && !isDisable;

  const current = watch(field);
  const isDefault = current === 'default';

  return (
    <div key={field} className={classNames(styles.root, container?.className)}>
      <Popover>
        <Popover.Button
          className={classNames(styles.button, isDisable && styles.disabled)}
          disabled={isDisable}
        >
          {label && (
            <FieldLabel
              label={label}
              className={classNames(styles.label, isDisable && styles.disabled)}
            />
          )}

          <div
            className={classNames(styles.bullet, isDefault && styles.default)}
            style={{
              background: !isDefault && current,
            }}
          />
        </Popover.Button>

        <Popover.Panel floating={{ placement: 'bottom-start' }}>
          {({ close }) => (
            <Controller
              control={control}
              name={field}
              render={({ field: { value, onChange } }) => (
                <ColorPicker
                  key={field}
                  color={value}
                  onChange={(color) => {
                    onChange(color);
                    close();
                  }}
                  {...props}
                >
                  <ColorPicker.PresetColors />
                </ColorPicker>
              )}
            />
          )}
        </Popover.Panel>

        {isError && <FieldError error={error} label={label} />}
      </Popover>
    </div>
  );
};

const styles = {
  root: classes('w-full'),
  button: classes('flex', 'items-center', 'gap-1', 'cursor-pointer'),
  bullet: classes('h-6', 'w-8', 'rounded-md'),
  label: classes('select-none', 'cursor-pointer'),
  disabled: classes('cursor-default'),
  default: classes('bg-skin-accent'),
};

export default FormColorPicker;
