'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import Combobox from '../../../inputs/combobox/Combobox';
import { ComboboxOption } from '../../../inputs/combobox/Combobox.types';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormComboboxProps, TValues } from './FormCombobox.types';

const FormCombobox = <T extends TValues>({
  field,
  label,
  by,
  disabled,
  options,
  defaultValue,
  multiple,
  input,
  container,
  ...props
}: FormComboboxProps<T>) => {
  const { control, error, isDisabled, setValue } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div className={classNames(styles.root, container?.className)}>
      {label && <FieldLabel label={label} htmlFor={field} />}
      <Controller
        name={field}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => {
          let selected = null;

          if (Array.isArray(value)) {
            selected = value
              .map((v) =>
                options?.find(
                  (i) => (by && i[by] === (v as unknown)) || i.key === v.key,
                ),
              )
              .filter((v) => v);
          } else {
            selected = options?.find(
              (i) =>
                (by && i[by] === (value as unknown)) || i.key === value?.key,
            );
          }

          if (value && !selected) {
            setValue(field, '');
          }

          const handleChange = (
            value: ComboboxOption<T> | ComboboxOption<T>[] | null,
          ) => {
            if (Array.isArray(value)) {
              const current = value.map((i) => (i && by && i[by]) || i);
              setValue(field, current);
              onChange(current);
            } else {
              const current = (value && by && value[by]) || value;
              setValue(field, current);
              onChange(current);
            }
          };

          return (
            <Combobox
              id={field}
              key={field}
              multiple={multiple as never}
              value={selected}
              onBlur={onBlur}
              options={options}
              disabled={isDisable}
              input={{ error: isError, disable: isDisable, ...input }}
              onChange={handleChange}
              {...props}
            />
          );
        }}
      />
      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
};

export default FormCombobox;
