/// <reference types="vite/client" />

interface AdminEnvironments {
  PROD: boolean;
  HOME_URL: string;
  API_URL: string;
  S3_BUCKET_URL: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
}

class AdminEnvironment {
  constructor() {
    if (!import.meta.env['VITE_API_URL']) {
      throw new Error('VITE_API_URL required');
    }

    if (!import.meta.env['VITE_HOME_URL']) {
      throw new Error('VITE_HOME_URL required');
    }

    if (!import.meta.env['VITE_S3_BUCKET_URL']) {
      throw new Error('VITE_S3_BUCKET_URL required');
    }

    if (!import.meta.env['VITE_GOOGLE_RECAPTCHA_SITE_KEY']) {
      throw new Error('VITE_GOOGLE_RECAPTCHA_SITE_KEY required');
    }
  }

  get PROD() {
    return import.meta.env['PROD'];
  }

  get HOME_URL() {
    return import.meta.env['VITE_HOME_URL'];
  }

  get API_URL() {
    return import.meta.env['VITE_API_URL'];
  }

  get S3_BUCKET_URL() {
    return import.meta.env['VITE_S3_BUCKET_URL'];
  }

  get GOOGLE_RECAPTCHA_SITE_KEY() {
    return import.meta.env['VITE_GOOGLE_RECAPTCHA_SITE_KEY'];
  }
}

let ADMIN_ENVS: AdminEnvironments = undefined;

if (import.meta.env && import.meta.env['VITE_APP'] === 'admin') {
  ADMIN_ENVS = new AdminEnvironment();
}

export { ADMIN_ENVS };
