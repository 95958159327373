import { useNotification } from '@nowadays/ui/components';
import { useNetworkStatus } from '@nowadays/ui/hooks';
import { useTranslate } from '@nowadays/ui/i18n/client';
import { useAppActions, useStateSelector } from '@nowadays/ui/store';
import { useEffect } from 'react';

const NetworkListener = () => {
  const translate = useTranslate();
  const networkStatus = useNetworkStatus();
  const { addNotification } = useNotification();
  const { changeNetworkStatus, changeNetworkError } = useAppActions();
  const networkError = useStateSelector((state) => state.app.networkError);

  useEffect(() => {
    changeNetworkStatus(networkStatus);
    if (networkStatus === 'offline') {
      changeNetworkError('NETWORK_ERROR');
    }
  }, [networkStatus]);

  useEffect(() => {
    if (!networkError) {
      return;
    }

    switch (networkError) {
      case 'NETWORK_ERROR':
        addNotification({
          title: translate('ui.common.networkListener.networkError.title'),
          description: translate(
            'ui.common.networkListener.networkError.description',
          ),
          color: 'red',
          icon: 'Warning',
        });
        break;
      case 'ACCESS_ERROR':
        addNotification({
          title: translate('ui.common.networkListener.accessError.title'),
          description: translate(
            'ui.common.networkListener.accessError.description',
          ),
          color: 'blue',
        });
        changeNetworkStatus('offline');
        break;
      case 'REQUEST_ERROR':
        addNotification({
          title: translate('ui.common.networkListener.requestError.title'),
          color: 'red',
          icon: 'Warning',
        });
        break;
      case 'INTERVAL_SERVER_ERROR':
        addNotification({
          title: translate('ui.common.networkListener.requestError.title'),
          color: 'red',
          icon: 'Warning',
        });
        break;
      default:
        break;
    }

    changeNetworkError();
  }, [networkError]);

  return null;
};

export default NetworkListener;
