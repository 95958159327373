import { baseApi } from '../base/query/Api';
import { addWidgetSetItem } from './AddWidgetSetItem.api';
import { deleteWidgetSetItem } from './DeleteWidgetSetItem.api';
import { reorderWidgetSetItem } from './ReorderWidgetSetItem.api';
import { updateWidgetSetItem } from './UpdateWidgetSetItem.api';
import { usedWidgetSetItemCodes } from './UsedWidgetSetItemCodes.api';
import { widgetSetItem } from './WidgetSetItem.api';
import { widgetSetItems } from './WidgetSetItems.api';

export const widgetSetItemEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    widgetSetItems: widgetSetItems(builder),
    widgetSetItem: widgetSetItem(builder),
    addWidgetSetItem: addWidgetSetItem(builder),
    deleteWidgetSetItem: deleteWidgetSetItem(builder),
    updateWidgetSetItem: updateWidgetSetItem(builder),
    reorderWidgetSetItem: reorderWidgetSetItem(builder),
    usedWidgetSetItemCodes: usedWidgetSetItemCodes(builder),
  }),
});

export const {
  useWidgetSetItemsQuery,
  useLazyWidgetSetItemQuery,
  useAddWidgetSetItemMutation,
  useDeleteWidgetSetItemMutation,
  useUpdateWidgetSetItemMutation,
  useReorderWidgetSetItemMutation,
  useUsedWidgetSetItemCodesQuery,
} = widgetSetItemEndpoints;
