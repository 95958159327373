import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgExpand = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M3 17v3a1 1 0 0 0 1 1h3M21 7V4a1 1 0 0 0-1-1h-3M3.47 20.47l4.25-4.25m8.5-8.5 4.25-4.25M21 17v3a1 1 0 0 1-1 1h-3M3 7V4a1 1 0 0 1 1-1h3m13.53 17.47-4.25-4.25m-8.5-8.5L3.53 3.47'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExpand);
export default ForwardRef;
