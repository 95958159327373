'use client';

import { FieldErrorMessage } from '@nowadays/base/types';
import { FieldValue, FieldValues, UseFormSetError } from 'react-hook-form';

export const fieldErrorMapper = <T extends FieldValues>({
  translate,
  setError,
}: {
  translate: Translate;
  setError: UseFormSetError<T>;
}) => {
  return (errors: FieldErrorMessage[]) => {
    errors.forEach((error) => {
      setError(error.payload.field as FieldValue<T>, {
        message: translate(
          error.payload.message as Message,
          error.payload.params,
        ),
      });
    });
  };
};
