import { ReorderArticle } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const reorderArticle = (builder: Builder) =>
  builder.mutation<void, ReorderArticle[]>({
    query: (body) => ({
      url: `/article/reorder`,
      method: 'POST',
      body,
    }),
  });
