import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgSettings = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m5.932 5.386-.243.97.243-.97Zm3.374-1.948-.961-.274.961.274Zm-6.068 6.614.719-.696-.719.696Zm0 3.896-.718-.695.718.695Zm2.694 4.666.243.97-.243-.97Zm3.374 1.948-.961.274.961-.274Zm5.388 0 .961.274-.961-.274Zm3.374-1.948.243-.97-.243.97Zm2.694-4.666-.719.696.719-.696Zm0-3.896-.719-.696.719.696Zm-2.694-4.666-.243-.97.243.97Zm-3.375-1.948.962-.274-.961.274ZM5.69 6.356a3.801 3.801 0 0 0 4.579-2.643l-1.923-.55a1.801 1.801 0 0 1-2.17 1.253l-.486 1.94Zm-1.732 3c-1.26-1.302-.026-3.44 1.732-3l.486-1.94C2.465 3.486-.14 8 2.52 10.747l1.437-1.39Zm0 5.288a3.801 3.801 0 0 0 0-5.288L2.52 10.747a1.801 1.801 0 0 1 0 2.506l1.437 1.39Zm1.732 3c-1.758.44-2.992-1.698-1.732-3L2.52 13.253c-2.66 2.747-.054 7.26 3.655 6.33l-.486-1.94Zm4.579 2.644a3.801 3.801 0 0 0-4.58-2.644l.487 1.94a1.801 1.801 0 0 1 2.17 1.252l1.923-.548Zm3.464 0c-.497 1.742-2.967 1.742-3.464 0l-1.923.548c1.05 3.678 6.26 3.678 7.31 0l-1.923-.548Zm4.58-2.644a3.801 3.801 0 0 0-4.58 2.644l1.923.548a1.801 1.801 0 0 1 2.17-1.252l.486-1.94Zm1.731-3c1.26 1.302.026 3.44-1.732 3l-.486 1.94c3.71.93 6.315-3.584 3.655-6.331l-1.437 1.39Zm0-5.288a3.8 3.8 0 0 0 0 5.288l1.437-1.391a1.801 1.801 0 0 1 0-2.506l-1.437-1.39Zm-1.732-3c1.758-.44 2.992 1.698 1.732 3l1.437 1.391c2.66-2.747.054-7.26-3.655-6.33l.486 1.94Zm-4.58-2.643a3.801 3.801 0 0 0 4.58 2.643l-.486-1.94a1.801 1.801 0 0 1-2.17-1.252l-1.923.549Zm1.924-.55c-1.05-3.677-6.26-3.677-7.31 0l1.923.55c.497-1.743 2.967-1.743 3.464 0l1.923-.55ZM8 12a4 4 0 0 0 4 4v-2a2 2 0 0 1-2-2H8Zm4 4a4 4 0 0 0 4-4h-2a2 2 0 0 1-2 2v2Zm4-4a4 4 0 0 0-4-4v2a2 2 0 0 1 2 2h2Zm-4-4a4 4 0 0 0-4 4h2a2 2 0 0 1 2-2V8Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSettings);
export default ForwardRef;
