import { Tab, UpdateTabParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { tabEndpoints } from './Tab.endpoints';

export const updateTab = (builder: Builder) =>
  builder.mutation<Tab, UpdateTabParams>({
    query: ({ tab, page, body }) => ({
      url: '/tab/update',
      method: 'PUT',
      params: { page, tab },
      body,
    }),
    async onQueryStarted({ tab, page }, { dispatch, queryFulfilled }) {
      try {
        const { data: updatedTab } = await queryFulfilled;

        dispatch(
          tabEndpoints.util.updateQueryData('tabs', { page }, (draft) => {
            const index = draft.tabs.findIndex((i) => i.id === tab);
            draft.tabs[index] = updatedTab;
            draft.tab = updatedTab;
          }),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
