import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgDuplicate = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeWidth={2}
      d='M7 11a5 5 0 0 1 5-5h5a5 5 0 0 1 5 5v5a5 5 0 0 1-5 5h-5a5 5 0 0 1-5-5v-5Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M6.842 18.758A6.002 6.002 0 0 1 3 13.158V8a6 6 0 0 1 6-6h5.158a6.002 6.002 0 0 1 5.6 3.842h-2.232A3.997 3.997 0 0 0 14.158 4H9a4 4 0 0 0-4 4v5.158c0 1.414.734 2.657 1.842 3.368v2.232Z'
      clipRule='evenodd'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M10 13.5h9M14.5 18V9'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDuplicate);
export default ForwardRef;
