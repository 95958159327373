'use client';

import { createContext, useContext } from 'react';

import { NotificationItem } from '../Notification.types';

interface NotificationContextProps {
  notifications: NotificationItem[];
  addNotification: (item: NotificationItem) => void;
  deleteNotification: (key: number) => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
  addNotification: () => null,
  deleteNotification: () => null,
});

export const useNotification = () => {
  return useContext(NotificationContext);
};
