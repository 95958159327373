import { WidgetSetItem, WidgetSetItemParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const widgetSetItem = (builder: Builder) =>
  builder.query<WidgetSetItem, WidgetSetItemParams>({
    query: ({ widget }) => `/widget-set-item/item/${widget}`,
    providesTags: (res, err, { widget }) => [
      { type: 'WidgetSetItems', id: widget },
    ],
  });
