'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import { AdornmentProps } from './Adornment.types';

const Adornment: React.FC<AdornmentProps> = (
  { children, position, className, ...props },
  ref: React.Ref<HTMLDivElement>,
) => {
  if (!children) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={classNames(
        styles.root,
        position && styles[position],
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const styles = {
  root: classes('absolute', '', 'top-[50%]', '-translate-y-[50%]'),
  start: classes('left-0'),
  end: classes('right-0'),
};

export default React.memo(React.forwardRef(Adornment));
