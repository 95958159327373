export const app = {
  common: {
    profile: {
      logout: 'Logout',
      report: 'Report',
      settings: 'Settings',
      upgrade: { title: 'Premium' },
    },
    report: {
      dialog: {
        additionally:
          'Additionally, you can reach out to us through our social media accounts for any requests or updates. We appreciate your support and contribution as we work to make our app even better!',
        description:
          'Thank you for helping us enhance our app! Our app is currently in beta, and your feedback is crucial to our development process. If you encounter any issues or have suggestions for new features or improvements, please use the form below to share your thoughts',
        success: {
          description: {
            feedback: 'We appreciate your feedback and will review it shortly.',
            report: 'We appreciate your report and will review it shortly.',
          },
          title: {
            feedback: 'Your feedback has been successfully submitted.',
            report: 'Your report has been successfully submitted. ',
          },
        },
        title: { feedback: 'Give feedback', report: 'Report us' },
      },
      form: {
        categories: {
          bug: {
            description: 'Describe the bug or error, so that we can fix',
            title: 'Bug or error',
          },
          feature: {
            description:
              'Describe the feature or improvement you would like to suggest',
            title: 'Feature or improvement',
          },
          other: {
            description:
              'Your insights help us enhance the user experience and make our application even better',
            title: 'Other',
          },
        },
        category: 'Category',
        description: 'Description',
      },
    },
    settings: {
      account: {
        create: {
          button: 'Send create password link',
          info: 'This account uses Google or Apple signup and does not have a password, please create a password for this account before changing the email.',
          send: "We have sent the create password link. If you can not find the set password email in the 'Inbox' folder, please check the 'Spam' folder.",
          title: 'First, create a password!',
        },
        delete: {
          button: 'Delete account',
          description: 'Delete your account permanently',
          dialog: {
            confirm: 'permanently delete',
            description:
              'If you want to permanently delete your account, please type "permanently delete".',
            title: 'Delete my account',
          },
          title: 'Delete my account',
        },
        email: {
          button: 'Change email',
          dialog: { title: 'Change email' },
          form: {
            current: "Your current email '{{email}}'",
            label: 'New email',
            placeholder: 'Enter a new email',
            send: 'Send verification link',
            success:
              "We have sent the verification link. If you can not find the verification email in the 'Inbox' folder, please check the 'Spam' folder.",
          },
          title: 'Email',
          verification: {
            success: 'We have sent the verification link',
            verified: 'Verified',
            verify: 'Not verified, verify now',
          },
          verify: { label: 'Your current password' },
        },
        password: {
          button: 'Change password',
          description: 'Set a password to login to your account',
          dialog: { title: 'Change password' },
          form: {
            button: 'Change password',
            field: {
              confirmNewPassword: 'Confirm new password',
              newPassword: 'New password',
              password: 'Current password',
            },
            placeholder: {
              confirmNewPassword: 'Your new password',
              newPassword: 'Your new password',
              password: 'Your current password',
            },
            success: 'Your password has been set',
          },
          title: 'Password',
        },
        profile: { title: 'My profile' },
        section: 'My account',
        title: 'Account',
      },
      appearance: {
        rem: {
          description: 'Changes visual size of the app',
          options: { large: 'Large', medium: 'Medium', small: 'Small' },
          title: 'Interface size',
        },
        section: 'Appearance',
        theme: {
          description: 'Changes how the application appears',
          options: { dark: 'Dark', light: 'Light', system: 'Use system' },
          title: 'Interface theme',
        },
        title: 'Appearance',
      },
      emailNotification: {
        announcements: {
          description: 'Feature announcements, tips & product news',
          title: "We'll send you...",
        },
        section: { email: 'Email' },
        title: 'Email & notification',
      },
      languageRegion: {
        currency: {
          description: 'Changes currency you use',
          title: 'Currency',
        },
        dayEndsAt: {
          description: 'Events ends at this hour',
          title: 'Day ends at',
        },
        dayStartsAt: {
          description: 'Events starts at this hour',
          title: 'Day starts at',
        },
        hourFormat: {
          description: 'Changes hour format you want to use',
          hour: 'Hour',
          title: 'Use 24 hour-time',
        },
        language: {
          description: 'Changes application language',
          title: 'Language',
        },
        section: { language: 'Language', region: 'Region' },
        timeZone: {
          description: 'Changes time zone you are in',
          title: 'Time zone',
        },
        title: 'Language & region',
        weekStartsOn: {
          description: 'On calendars, week starts on this day',
          monday: 'Monday',
          sunday: 'Sunday',
          title: 'Week starts on',
        },
      },
      privacy: { title: 'Privacy' },
    },
  },
  home: {
    banner: { feedback: { button: 'Give feedback' } },
    beta: {
      description:
        'If you have any feedback or run into any issues, we’d love to hear from you. Share your thoughts and help us make the app even better.',
      info: 'Our application is currently in beta!',
      thanks: 'Your feedback is invaluable in helping us improve the app.',
      title: 'Welcome to Nowadays.app!',
    },
    greeting: {
      description: 'Ready start to make your personal planner?',
      hello: 'Hello,',
      newPlanner: 'Create your planner',
      or: 'or',
      usePreset: 'Use preset planner',
      welcome: 'WELCOME,',
    },
    planners: {
      bar: {
        category: {
          active: 'My planners',
          archived: 'Archived',
          deleted: 'Deleted',
          preset: 'Preset planners',
        },
        new: { title: 'New planner' },
        permanentlyDelete: {
          confirm: 'Are you sure you want to permanently delete all planners?',
          title: 'Delete all',
        },
        presetFilter: {
          daily: 'Daily',
          monthly: 'Monthly',
          search: 'Search planner...',
          undated: 'Undated',
          weekly: 'Weekly',
          yearly: 'Annual',
        },
      },
      card: {
        detail: {
          createdAt: 'Created at',
          createdBy: 'Created by {{ by }}',
          deletedAt: 'Deleted at',
          lastEditedAt: 'Edited at',
          layouts: {
            landscape: 'Landscape',
            portrait: 'Portrait',
            square: 'Square',
            tabloid: 'Tabloid',
          },
          periods: {
            daily: 'Daily',
            monthly: 'Monthly',
            undated: 'Undated',
            weekly: 'Weekly',
            yearly: 'Annual',
          },
        },
        image: {
          edit: 'Edit planner',
          use: 'Use planner',
          view: 'View planner',
        },
      },
      form: {
        header: 'Planner type',
        page: {
          header: 'Page view',
          layout: {
            options: {
              landscape: 'Landscape',
              portrait: 'Portrait',
              square: 'Square',
              tabloid: 'Tabloid',
            },
            title: 'Page layout',
          },
          title: 'Page preview',
        },
        planner: {
          description: 'Description',
          edit: 'Edit planner',
          editPreset: 'Edit preset planner',
          images: {
            add: {
              planner: 'Add cover image',
              presetPlanner: 'Add planner image',
            },
            delete: 'Delete cover',
            title: 'Covers',
          },
          kind: 'Planner type',
          kinds: { dated: 'Dated', undated: 'Undated' },
          period: {
            daily: 'Daily',
            monthly: 'Monthly',
            weekly: 'Weekly',
            yearly: 'Annual',
          },
          periods: 'Planner periods',
          status: 'Status',
          title: 'Title',
        },
      },
      menu: {
        archive: { title: 'Archive' },
        delete: {
          confirm: 'Are you sure you want to delete this planner?',
          title: 'Delete',
        },
        deletePreset: {
          confirm:
            'Are you sure you want to permanently delete this preset planner?',
          title: 'Delete',
        },
        edit: { title: 'Edit' },
        permanentlyDelete: {
          confirm: 'Are you sure you want to permanently delete this planner?',
          title: 'Delete permanently',
        },
        restore: { title: 'Restore' },
        unarchive: { title: 'Remove from archive' },
      },
      notFound: {
        createNew: 'Create planner',
        createNewPreset: 'Create preset planner',
        or: 'or',
        presetTitle: 'Preset planner not found',
        title: 'Planner not found',
        usePreset: 'Use preset planner',
      },
      plannerImage: {
        add: { button: 'Add page image', title: 'Add' },
        delete: {
          confirm: 'Are you sure you want to delete this image?',
          title: 'Delete',
        },
        edit: { title: 'Edit' },
        form: {
          image: 'Image',
          images: { dark: 'Dark', light: 'Light' },
          period: 'Period',
          periods: {
            daily: 'Daily',
            monthly: 'Monthly',
            weekly: 'Weekly',
            yearly: 'Annual',
          },
        },
        image: {
          period: {
            daily: 'Daily',
            monthly: 'Monthly',
            weekly: 'Weekly',
            yearly: 'Annual',
          },
        },
      },
      usePresetPlanner: { copy: 'Copy planner', edit: 'Edit planner' },
    },
  },
  login: {
    form: {
      email: 'Email',
      login: 'Log in',
      password: 'Password',
      passwordReset: 'Forgot password',
      rememberMe: 'Remember me',
    },
    redirectSignup: {
      createAccount: "Don't have an account?",
      signup: 'Sign up',
    },
    title: 'Log in',
  },
  passwordReset: {
    form: { button: 'Reset password', email: 'Email' },
    help: {
      login: 'login page',
      loginPost: '',
      loginPre: 'Go to the',
      needHelp: 'Need help?',
    },
    info: {
      info: 'Please enter your email that you used to register, we will send you an email includes a link to reset your password.',
    },
    mailSent: {
      description:
        "We have sent the reset password link. If you can not find the reset password email in the 'Inbox' folder, please check the 'Spam' folder.",
      message: 'We have sent you an email',
    },
    title: 'Password reset',
  },
  resetPassword: {
    failed: {
      expired: {
        description:
          'Password reset link is expired, please request a new link',
        message: 'Password reset link expired',
      },
      invalid: {
        description:
          'Password reset link is invalid, please request a new link',
        message: 'Password reset link invalid',
      },
      passwordReset: 'Password reset',
    },
    form: {
      button: 'Reset password',
      confirmPassword: 'New password confirm',
      password: 'New password',
    },
    success: {
      description:
        'Your password has been successfully set, you can log in now',
      login: 'Log in',
      message: 'Your password has been set',
    },
    title: 'Reset password',
  },
  router: {
    home: 'Planners',
    login: 'Log in',
    notFound: '404 - Not found',
    passwordReset: 'Password reset',
    resetPassword: 'Reset password',
    sheet: 'Sheet',
    signup: 'Signup',
    verification: 'Verification',
  },
  sheet: {
    ai: {
      greeting: { hello: 'Hello,', help: 'How can I help you today?' },
      prompt: { placeholder: 'Send a message', title: 'Ask anything' },
      suggested: { title: 'Suggested' },
    },
    appbar: {
      page: { edit: { title: 'Edit page' }, pages: { title: 'Pages' } },
      planner: { navigate: { title: 'My planners' } },
      zoom: {
        fitContent: { title: 'Fit page to screen' },
        inOut: { title: 'Zoom in/out' },
      },
    },
    datebar: {
      panel: {
        active: { week: 'W' },
        footer: { cancel: 'Cancel', confirm: 'Go to page' },
      },
    },
    element: {
      action: { redo: 'Redo', undo: 'Undo' },
      menu: {
        context: {
          copy: 'Copy',
          delete: 'Delete',
          duplicate: 'Duplicate',
          settings: 'Settings',
          title: 'More',
          toBack: 'Send to back',
          toFront: 'Bring to front',
        },
      },
      socket: { error: 'Connection lost, trying to connect...' },
    },
    module: {
      compact: { seeAll: 'See all' },
      items: {
        ai: 'AI',
        draw: 'Draw',
        sticker: 'Sticker',
        template: 'Template',
        text: 'Note',
        widget: 'Widget',
      },
      notFound: {
        seeAll: 'See all',
        sticker: 'Sticker not found',
        template: 'Template not found',
        widget: 'Widget not found',
      },
    },
    page: {
      add: { title: 'Add page' },
      notFound: { add: { title: 'Add page' }, title: 'No page found' },
    },
    pages: {
      add: { title: 'Add page' },
      bar: {
        category: {
          active: 'Pages',
          deleted: 'Deleted',
          favorite: 'Favorites',
        },
        filter: {
          permanentlyDelete: {
            confirm: 'Are you sure you want to permanently delete all pages?',
            title: 'Delete all',
          },
          search: { placeholder: 'Search page...' },
        },
      },
      card: {
        createdAt: 'Created at',
        deletedAt: 'Deleted at',
        lastEditedAt: 'Edited at',
        page: 'Page',
        period: { weekly: 'Week' },
      },
      favorite: { title: 'Favorite' },
      menu: {
        delete: {
          confirm: 'Are you sure you want to delete this page?',
          title: 'Delete',
        },
        permanentlyDelete: {
          confirm: 'Are you sure you want to permanently delete this page?',
          title: 'Delete permanently',
        },
        restore: { title: 'Restore' },
      },
      notFound: { add: { title: 'Add page' }, title: 'No page found' },
    },
    sidebar: {
      header: { toggle: { close: 'Close sidebar', open: 'Open sidebar' } },
    },
    sticker: {
      actions: { colors: 'Colors' },
      card: {
        favorite: { add: 'Add favorite', remove: 'Remove favorite' },
        menu: { favorite: { title: 'Favorite' }, report: { title: 'Report' } },
      },
      search: { placeholder: 'Search sticker...' },
    },
    tab: {
      add: {
        form: {
          header: 'Add tab',
          icon: 'Tab icon',
          max: 'You can create a maximum of {{limit}} tabs',
          title: 'Tab name',
        },
        title: 'Add tab',
      },
      deleted: {
        item: { deleted: 'Deleted at:' },
        notFound: { title: 'Deleted tab not found' },
        permanentlyDelete: {
          confirm: 'Are you sure you want to permanently delete this tab?',
          title: 'Delete permanently',
        },
        restore: {
          max: 'You can use maximum of {{limit}} tabs',
          title: 'Restore',
        },
        title: 'Deleted tabs',
      },
      icon: {
        Calendar: 'Calendar',
        Dumbbell: 'Fitness',
        Heart: 'Healt',
        Home: 'Main',
        Meal: 'Nutrition',
        Message: 'Idea',
        Shopping: 'Shopping',
        Star: 'Important',
        TickSquare: 'Habit',
        Wallet: 'Finance',
        Wellness: 'Wellness',
        Writing: 'Note',
      },
      menu: {
        delete: {
          confirm: 'Are you sure you want to delete this tab?',
          title: 'Delete',
        },
        duplicate: {
          max: 'You can create a maximum of {{limit}} tabs',
          title: 'Duplicate',
        },
        edit: {
          form: { header: 'Edit tab', icon: 'Tab icon', title: 'Tab name' },
        },
        info: { createdAt: 'Created at', lastEditedAt: 'Edited at' },
      },
      notFound: { title: 'No tab found' },
    },
    template: {
      action: {
        close: { title: 'Close editing' },
        saveAs: {
          form: {
            header: 'Save page as template',
            isDefault: 'Set as default template',
            title: 'Template name',
          },
          title: 'Save page as template',
        },
      },
      card: {
        menu: {
          apply: {
            dialog: {
              confirm: 'Apply template',
              description:
                'If you want to apply this template, the data on the current page will be lost. Do you want to continue?',
            },
            title: 'Apply',
          },
          duplicate: { title: 'Duplicate' },
          edit: {
            form: { field: { title: 'Template name' }, title: 'Edit template' },
          },
          editPage: { title: 'Edit' },
          info: { createdAt: 'Created at', updatedAt: 'Updated at' },
          permanentlyDelete: {
            confirm:
              'Are you sure you want to permanently delete this template?',
            title: 'Delete',
          },
          report: { title: 'Report' },
        },
        setAs: {
          default: 'Set as default template',
          description:
            '*This template will be used by default on subsequent pages created',
        },
      },
      search: { placeholder: 'Search template...' },
    },
    text: {
      action: {
        align: {
          center: 'Align center',
          left: 'Align left',
          right: 'Align right',
        },
        backgroundColor: { title: 'Background color' },
        color: { title: 'Text color' },
        decoration: { strikeThrough: 'Strike through', underline: 'Underline' },
        size: {
          decrease: 'Decrease',
          fontSize: 'Font size',
          increase: 'Increase',
        },
        style: { italic: 'Italic' },
        weight: { title: 'Bold' },
      },
      default: 'Your note',
    },
    widget: {
      card: {
        favorite: { add: 'Add favorite', remove: 'Remove favorite' },
        menu: { favorite: { title: 'Favorite' }, report: { title: 'Report' } },
      },
      config: {
        contentPlanner: {
          form: {
            caption: 'Caption',
            content: 'Content',
            cover: 'Cover',
            date: 'Date',
            delete: {
              confirm: 'Are you sure you want to delete content?',
              title: 'Delete',
            },
            header: { add: 'Add content', edit: 'Edit content' },
            keywords: 'Keywords',
            links: 'Links',
            note: 'Notes',
            pillar: 'Pillar',
            platform: 'Platform',
            title: 'Title',
            type: 'Type',
            view: 'View',
          },
          settings: {
            field: { pillars: 'Pillar', platforms: 'Platform', types: 'Type' },
            fields: {
              pillars: 'Pillars',
              platforms: 'Platforms',
              types: 'Types',
            },
          },
          status: {
            analyzed: 'Analyzed',
            idea: 'Idea',
            'in-progress': 'In progress',
            planned: 'Planned',
            published: 'Published',
            scheduled: 'Scheduled',
            title: 'Status',
          },
          title: 'Contents',
        },
        contentSummary: {
          feedType: { card: 'Card', list: 'List' },
          platform: 'Platform',
          status: {
            analyzed: 'Analyzed',
            idea: 'Idea',
            'in-progress': 'In progress',
            planned: 'Planned',
            published: 'Published',
            scheduled: 'Scheduled',
          },
          title: 'Content summary',
          types: {
            feed: 'Feed',
            pillar: 'Pillar',
            status: 'Status',
            view: 'View',
          },
        },
        expenseSummary: {
          category: 'Category',
          title: 'Expense summary',
          types: { breakdown: 'Breakdown', distribution: 'Distribution' },
        },
        expenseTracker: {
          form: {
            amount: 'Amount',
            category: 'Category',
            color: 'Color',
            date: 'Date',
            delete: {
              confirm: 'Are you sure you want to delete expense?',
              title: 'Delete',
            },
            header: { add: 'Add expense', edit: 'Edit expense' },
            note: 'Notes',
            subcategory: 'Subcategory',
          },
          settings: {
            addCategory: 'Add category',
            category: 'Category',
            subcategory: 'Subcategory',
            title: 'Categories',
          },
          title: 'Expenses',
          total: 'Total',
        },
        focus: { placeholder: 'Your focus...', title: 'Focus' },
        habitSummary: {
          completed: 'Completed',
          habit: 'Habit',
          remaining: 'Remaining',
          title: 'Habit summary',
          types: { checkbox: 'Checkbox', goal: 'Goal' },
          uncompleted: 'Uncompleted',
        },
        habitTracker: {
          form: {
            delete: {
              confirm: 'Are you sure you want to delete habit?',
              title: 'Delete',
            },
            endDate: 'Until',
            goal: 'Goal',
            header: { add: 'Add habit', edit: 'Edit habit' },
            placeholder: { goal: 'Numeric goal' },
            recurrence: 'Recurrence',
            recurrences: { never: 'Never', until: 'Until' },
            repeatOn: 'Repeat on',
            startDate: 'Start',
            title: 'Title',
            type: 'Tracker type',
            types: { checkbox: 'Checkbox', goal: 'Goal' },
          },
          placeholder: 'Habits',
          title: 'Habits',
        },
        intention: { placeholder: 'Your intention...', title: 'Intention' },
        moodSummary: { title: 'Mood summary' },
        moodTracker: {
          moods: {
            Bored: 'Bored face',
            Cool: 'Cool face',
            Cry: 'Crying face',
            Laugh: 'Laughing face',
            Love: 'Happy face',
            Sad: 'Sad face',
            Smile: 'Smiling face',
          },
          title: 'Mood',
        },
        pomodoro: {
          mode: {
            focus: 'Focus',
            longBreak: 'Long break',
            shortBreak: 'Short break',
          },
          reset: 'Reset',
          settings: {
            cycle: 'Pomodoro cycle',
            focus: 'Focus duration',
            longBreak: 'Long break duration',
            min: 'Minute',
            mode: 'Mode',
            modes: { auto: 'Auto', manual: 'Manual' },
            mute: 'Mute',
            remainCycle: 'Remain cycle',
            shortBreak: 'Short break duration',
          },
          start: 'Start',
          stop: 'Stop',
          title: 'Pomodoro',
        },
        priorityList: { placeholder: 'Priorities group', title: 'Priorities' },
        reflection: { placeholder: 'Your reflection...', title: 'Reflection' },
        scheduleTable: {
          cell: { halfHour: '30 min', hour: 'hour' },
          form: {
            color: 'Color',
            date: 'Date',
            delete: {
              confirm: 'Are you sure you want to delete event?',
              title: 'Delete',
            },
            description: 'Description',
            endDate: 'End date',
            endTime: 'End time',
            header: { add: 'Add event', edit: 'Edit event' },
            startDate: 'Start date',
            startTime: 'Start time',
            title: 'Title',
            type: 'Type',
            types: {
              'all-day': 'All day',
              appointment: 'Appointment',
              event: 'Event',
            },
          },
          title: 'Events',
        },
        textBox: { placeholder: 'Your text here...', title: 'Text box' },
        toDoList: { placeholder: 'To-dos group', title: 'To-dos' },
        toDoSummary: {
          completed: 'Completed',
          title: 'To-do summary',
          uncompleted: 'Uncompleted',
          ungrouped: 'Ungrouped',
        },
        waterIntakeSummary: {
          drunk: 'Drunk count',
          title: 'Water intake summary',
        },
        waterIntakeTracker: {
          settings: { cupCount: 'Count', cupType: 'Type' },
          title: 'Water intake',
        },
        weatherSummary: { title: 'Weather summary' },
        weatherTracker: {
          title: 'Weather',
          weathers: {
            Cloudy: 'Cloudy',
            PartlySunny: 'Partly sunny',
            Rainbow: 'Rainbow',
            Rainy: 'Rainy',
            Snowy: 'Snowy',
            Sunny: 'Sunny',
            Windy: 'Windy',
          },
        },
      },
      schedule: { table: { allday: 'All day' } },
      search: { placeholder: 'Search widget...' },
      settings: { title: 'Settings' },
    },
  },
  signup: {
    agreements: {
      and: ' and ',
      info1:
        'By clicking the "Continue with Google/Apple" or "Sign up" button, you agree that you have read and understood Nowadays.app\'s ',
      info2: '.',
      privacy: 'Privacy Policy',
      terms: 'Terms',
    },
    form: {
      confirmPassword: 'Password confirm',
      email: 'Email',
      firstName: 'First name',
      lastName: 'Last name',
      password: 'Password',
      signup: 'Sign up',
    },
    redirectLogin: { isAccountExist: 'Have an account?', login: 'Log in' },
    title: 'Signup',
  },
  verification: {
    email: { verifying: 'Verifying your email, please wait' },
    failed: {
      expired: {
        description:
          'Email verification link is expired, please request a new link',
        message: 'Email verification link expired',
      },
      invalid: {
        description:
          'Email verification link is invalid, please request a new link',
        message: 'Email verification link invalid',
      },
    },
    success: {
      description: 'Your email has been verified, you can log in now',
      login: 'Log in',
      message: 'Your email has been verified',
    },
    title: 'Email verification',
  },
};
