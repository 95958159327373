'use client';

import { Rem, Theme } from '@nowadays/base/types';

import { useRem } from '../interface-hook/useRem';
import { useTheme } from '../interface-hook/useTheme';
import { InterfaceContext } from './InterfaceContext';

interface InterfaceProviderProps {
  rem?: Rem;
  theme?: Theme;
  children: React.ReactNode;
}

export const InterfaceProvider: React.FC<InterfaceProviderProps> = ({
  rem,
  theme,
  children,
}) => {
  const { currentRem, changeRem, calculateRem } = useRem(rem);
  const { currentTheme, deviceTheme, changeTheme } = useTheme(theme);

  return (
    <InterfaceContext.Provider
      value={{
        rem: currentRem,
        theme: currentTheme,
        deviceTheme,
        changeRem,
        calculateRem,
        changeTheme,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  );
};
