import { ChangeEmailParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const changeEmail = (builder: Builder) =>
  builder.mutation<void, ChangeEmailParams>({
    query: (body) => ({
      url: '/user/change-email',
      method: 'POST',
      body,
    }),
  });
