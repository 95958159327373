import { Page } from '@nowadays/base/types';
import {
  bindActionCreators,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { store } from '../../store';
import { AppThunk } from '../../store/Store.types';
import { useStateDispatch } from '../../store/useStateDispatch';
import { PageCategories, PageState, Position } from './Page.slice.types';

const DEFAULT_SCALE = 1;

export const MAX_SCALE = 3;

const initialState: PageState = {
  isEditable: false,
  isPagesOpen: false,
  isAnimating: false,
  search: '',
  scale: DEFAULT_SCALE,
  initialScale: DEFAULT_SCALE,
  position: { x: 0, y: 0 },
  isDragging: false,
  category: PageCategories.Active,
  nonScaleItemsSize: { width: 32, height: 0 },
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    changeActivePage: (
      state,
      { payload }: PayloadAction<Page | undefined | null>,
    ) => {
      state.activePage = payload;
    },
    changeCategory: (state, { payload }: PayloadAction<PageCategories>) => {
      state.category = payload;
    },
    changeIsPagesOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPagesOpen = payload;
    },
    changeIsDragging: (state, { payload }: PayloadAction<boolean>) => {
      state.isDragging = payload;
    },
    changeIsEditable: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditable = payload;
    },
    changeAnimating: (state, { payload }: PayloadAction<boolean>) => {
      state.isAnimating = payload;
    },
    changeSearchKeyword: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    changeLastScaleOffset: (state, { payload }: PayloadAction<Position>) => {
      state.lastScaleOffset = payload;
    },
    updateRectangles: (
      state,
      action: PayloadAction<{
        boundary: DOMRect;
        area: DOMRect;
      }>,
    ) => {
      state.boundary = action.payload.boundary;
      state.area = action.payload.area;
    },
    findDefaultScale: (state) => {
      if (!state.boundary || !state.area) {
        return;
      }

      const widthAspect = state.area.width / state.boundary.width;
      const hightAspect = state.area.height / state.boundary.height;

      const scaleX =
        state.boundary.width /
        (state.area.width + state.nonScaleItemsSize.width * widthAspect);
      const scaleY =
        state.boundary.height /
        (state.area.height + state.nonScaleItemsSize.height * hightAspect);

      const initialScale = Math.min(scaleX, scaleY) * state.scale;

      if (initialScale > 0.01 && initialScale < MAX_SCALE) {
        state.initialScale = initialScale;
      }
    },
    useDefaultScale: (state) => {
      state.scale = state.initialScale;
    },
    useMaxScale: (state) => {
      state.scale = state.initialScale * MAX_SCALE;
    },
    updatePosition: (state, { payload }: PayloadAction<Position>) => {
      state.position.x = payload.x;
      state.position.y = payload.y;
    },
    scalePage: (
      state,
      action: PayloadAction<{
        scale: number;
      }>,
    ) => {
      if (
        action.payload.scale < state.initialScale * 0.75 ||
        action.payload.scale > state.initialScale * 1.1 * MAX_SCALE
      ) {
        return;
      }

      state.scale = action.payload.scale;
    },
  },
});

const checkValidScale = createAsyncThunk(
  'page/checkValidScale',
  async (args, { dispatch }) => {
    await new Promise(() => {
      const state = store.getState().page;

      if (state.scale < state.initialScale) {
        setTimeout(() => dispatch(fitContent()), 200);
      } else if (state.scale > state.initialScale) {
        setTimeout(() => dispatch(maximizePage()), 200);
      }
    });
  },
);

const fitContent = (): AppThunk => (dispatch) => {
  dispatch(pageSlice.actions.useDefaultScale());
  dispatch(pageSlice.actions.updatePosition({ x: 0, y: 0 }));
};

const maximizePage = (): AppThunk => (dispatch) =>
  dispatch(pageSlice.actions.useMaxScale());

const adjustPage = (): AppThunk => (dispatch) => {
  const state = store.getState().page;

  if (state.scale === state.initialScale) {
    dispatch(pageSlice.actions.updatePosition({ x: 0, y: 0 }));
  }
};

const fillBoundary = (): AppThunk => (dispatch) => {
  const state = store.getState().page;
  dispatch(pageSlice.actions.findDefaultScale());

  if (state.scale === state.initialScale) {
    dispatch(pageSlice.actions.useDefaultScale());
    dispatch(pageSlice.actions.updatePosition({ x: 0, y: 0 }));
  }
};

export const pageReducer = pageSlice.reducer;

export const usePageActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          ...pageSlice.actions,
          adjustPage,
          fillBoundary,
          fitContent,
          maximizePage,
          checkValidScale,
        },
        dispatch,
      ),
    [dispatch],
  );
};
