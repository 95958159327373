export const api = {
  datum: {
    contentPlanner: {
      defaults: {
        pillars: {
          guide: 'Guide',
          howTo: 'How-to',
          news: 'News',
          question: 'Question',
          review: 'Review',
          story: 'Story',
          tip: 'Tip',
          visual: 'Visual',
        },
        types: {
          image: 'Image',
          longVideo: 'Long video',
          shortVideo: 'Short video',
        },
      },
    },
    expenseTracker: {
      defaults: {
        categories: {
          bills: 'Bills',
          debts: 'Debts',
          expenses: 'Expenses',
          insuranceTaxes: 'Insurance & taxes',
          savings: 'Savings',
          subscriptions: 'Subscriptions',
        },
        subcategories: {
          bills: {
            electricity: 'Electricity',
            gas: 'Gas',
            internet: 'Internet',
            phone: 'Phone',
            rent: 'Rent',
            transport: 'Transport',
            water: 'Water',
          },
          debts: {
            creditCard: 'Credit card',
            debitCard: 'Debit card',
            mortgage: 'Mortgage',
            personalDebt: 'Personal debt',
            studentLoan: 'Student loan',
          },
          expenses: {
            carPayments: 'Car payments',
            coffee: 'Coffee',
            dateNight: 'Date night',
            diningOut: 'Dining out',
            groceries: 'Groceries',
            homeMaintenance: 'Home maintenance',
            personalCare: 'Personal care',
            socialEvent: 'Social event',
            thrifting: 'Thrifting',
          },
          insuranceTaxes: {
            carInsurance: 'Car insurance',
            healthInsurance: 'Health insurance',
            homeInsurance: 'Home insurance',
            lifeInsurance: 'Life insurance',
            propertyTaxes: 'Property taxes',
          },
          savings: {
            carFund: 'Car fund',
            emergencyFund: 'Emergency fund',
            houseFund: 'House fund',
            retirement: 'Retirement',
            summerHoliday: 'Summer holiday',
          },
          subscriptions: {
            googleDrive: 'Google drive',
            gym: 'Gym',
            netflix: 'Netflix',
            spotify: 'Spotify',
            youtube: 'YouTube',
          },
        },
      },
    },
  },
  email: {
    changeEmailRequest: { subject: 'Update your email address' },
    changeEmailSuccess: {
      subject: 'Your email address has been successfully updated',
    },
    createPasswordRequest: { subject: 'Create a password for your account' },
    passwordResetRequest: { subject: 'Password reset request' },
    resetPasswordSuccess: {
      subject: 'Your password has been successfully set',
    },
    signupLocalSuccess: { subject: 'Welcome to Nowadays.app!' },
    signupSocialSuccess: { subject: 'Welcome to Nowadays.app!' },
    verifyEmailRequest: { subject: 'Verify your email address' },
  },
  sticker: { stickers: { mostlyUsed: 'Mostly used' } },
  tab: { add: { default: 'Home' } },
  widget: { widgets: { mostlyUsed: 'Mostly used' } },
};
