'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Spinner from '../../../indicator/spinner/Spinner';
import { ButtonSpinnerProps } from './ButtonSpinner.types';

const ButtonSpinner: React.FC<ButtonSpinnerProps> = ({ className }) => {
  return <Spinner className={classNames(styles.spinner, className)} />;
};

const styles = {
  spinner: classes('w-5', 'h-5'),
};

export default ButtonSpinner;
