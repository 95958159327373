'use client';

import { ColorPickerProps } from './ColorPicker.types';
import { ColorPickerProvider } from './context/ColorPickerProvider';
import CurrentColors from './current-colors/CurrentColors';
import PickerHeader from './picker-header/PickerHeader';
import PresetColors from './preset-colors/PresetColors';

const ColorPicker: React.FC<ColorPickerProps> & {
  Header: typeof PickerHeader;
  PresetColors: typeof PresetColors;
  CurrentColors: typeof CurrentColors;
} = ({ color, onChange, children }) => {
  return (
    <ColorPickerProvider color={color} onChange={onChange}>
      {children}
    </ColorPickerProvider>
  );
};

ColorPicker.Header = PickerHeader;
ColorPicker.PresetColors = PresetColors;
ColorPicker.CurrentColors = CurrentColors;

export default ColorPicker;
