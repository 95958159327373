'use client';

import {
  add,
  eachDayOfInterval,
  endOfMonth,
  getDay,
  startOfMonth,
} from 'date-fns';
import { useMemo } from 'react';

import { useRegion } from '../../../region/RegionContext';
import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseWeeksProps } from './CalendarBaseWeeks.types';

const getMonthDays = (firstDayOfMonth: Date) => {
  return eachDayOfInterval({
    start: firstDayOfMonth,
    end: endOfMonth(firstDayOfMonth),
  });
};

const getPreviousMonthDays = (
  firstDayOfMonth: Date,
  weekStartsOnMonday: boolean,
) => {
  const dayIndex = getDay(firstDayOfMonth);

  const addDays = weekStartsOnMonday
    ? dayIndex === 0
      ? 6
      : dayIndex - 1
    : dayIndex;

  if (addDays === 0) {
    return [];
  }

  const previousFirstDay = add(firstDayOfMonth, {
    days: -addDays,
  });

  return eachDayOfInterval({
    start: previousFirstDay,
    end: endOfMonth(previousFirstDay),
  });
};

const getNextMonthDays = (
  firstDayOfMonth: Date,
  weekStartsOnMonday: boolean,
) => {
  const nextFirstDay = add(firstDayOfMonth, {
    months: 1,
  });
  const lastDay = endOfMonth(firstDayOfMonth);
  const dayIndex = getDay(lastDay);
  const addDays = weekStartsOnMonday ? dayIndex - 1 : dayIndex;
  const nextLastDay = add(nextFirstDay, {
    days: addDays === 6 ? addDays : 5 - addDays,
  });

  return eachDayOfInterval({
    start: nextFirstDay,
    end: nextLastDay,
  });
};

const getAWeekAfter = (afterDay: Date) => {
  const startOfWeek = add(afterDay, {
    days: 1,
  });

  const endOfWeek = add(afterDay, {
    days: 7,
  });

  return eachDayOfInterval({
    start: startOfWeek,
    end: endOfWeek,
  });
};

const divideDaysToWeeks = (days: Date[]) => {
  const result: Date[][] = [];

  for (let i = 0; i < days.length; i += 7) {
    const week = days.slice(i, i + 7);
    result.push(week);
  }

  return result;
};

const divideDaysToWeekDays = (days: Date[]) => {
  const result: Date[] = [];

  for (let i = 0; i < days.length; i++) {
    if (i % 7 === 0) {
      const day = days[i];
      result.push(day);
    }
  }

  return result;
};

const CalendarBaseWeeks: React.FC<CalendarBaseWeeksProps> = ({ children }) => {
  const { weekStartsOn } = useRegion();
  const { currentMonth } = useCalendarBase();

  const allDays = useMemo(() => {
    const firstDayOfMonth = startOfMonth(currentMonth);
    const weekStartsOnMonday = weekStartsOn === 'monday';

    let result = [
      ...getPreviousMonthDays(firstDayOfMonth, weekStartsOnMonday),
      ...getMonthDays(firstDayOfMonth),
      ...getNextMonthDays(firstDayOfMonth, weekStartsOnMonday),
    ];

    const shouldAppendLastWeek = result.length / 7 === 5;

    if (shouldAppendLastWeek) {
      const lastDay = result[result.length - 1];

      const aWeekLater = getAWeekAfter(lastDay);

      result = [...result, ...aWeekLater];
    }

    return result;
  }, [currentMonth, weekStartsOn]);

  const month = useMemo(() => divideDaysToWeeks(allDays), [allDays]);

  const weeksOfMonth = useMemo(() => divideDaysToWeekDays(allDays), [allDays]);

  return month.map((days, index) =>
    children({
      week: weeksOfMonth[index],
      days,
      isLastWeek: index === 5,
    }),
  );
};

export default CalendarBaseWeeks;
