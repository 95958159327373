import { ReorderWidgetSetItem } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const reorderWidgetSetItem = (builder: Builder) =>
  builder.mutation<void, ReorderWidgetSetItem[]>({
    query: (body) => ({
      url: '/widget-set-item/reorder',
      method: 'POST',
      body,
    }),
  });
