'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { SpinnerProps } from './Spinner.types';

const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      className={classNames(styles.spinner, className)}
      viewBox='0 0 24 24'
    >
      <path
        d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'
        opacity='.50'
      />
      <circle className={styles.circle} cx='12' cy='2.5' r='1.5' />
    </svg>
  );
};

const styles = {
  spinner: classes('w-6', 'h-6'),
  circle: classes('animate-spin', 'origin-center'),
};

export default Spinner;
