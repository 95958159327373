import { classes } from '@nowadays/ui/utils';

import { Spinner } from '../../../../../../../indicator';
import { FilePicker } from '../../../../../../../inputs';
import { AddRichTextImageProps } from './AddRichTextImage.types';

const AddRichTextImage: React.FC<AddRichTextImageProps> = ({
  onAdd,
  isUploading,
}) => {
  const handleDrop = (files: File[]) => onAdd && onAdd(files[0]);

  return (
    <FilePicker
      type='image'
      accept={{
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
      }}
      onDrop={handleDrop}
      disabled={isUploading}
      input={{ className: styles.input }}
    >
      {isUploading && <Spinner />}
    </FilePicker>
  );
};

const styles = {
  input: classes('aspect-square'),
};

export default AddRichTextImage;
