'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import Feedback from '../../../feedback/Feedback';
import { TableNotFoundRowProps } from './TableNotFoundRow.types';

const TableNotFoundRow: React.FC<TableNotFoundRowProps> = ({ className }) => {
  const translate = useTranslate();

  return (
    <tr className={styles.tr}>
      <td colSpan={50} className={classNames(styles.td, className)}>
        <Feedback
          color='muted'
          title={translate('ui.components.table.body.notFound.text')}
          border={false}
          className={styles.feedback}
        />
      </td>
    </tr>
  );
};

const styles = {
  tr: classes('w-full'),
  td: classes('bg-skin-silent'),
  feedback: classes(
    'h-12',
    'bg-transparent',
    'rounded-none',
    'items-center',
    'justify-center',
  ),
};

export default TableNotFoundRow;
