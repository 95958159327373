import { PermanentlyDeleteTabParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { tabEndpoints } from './Tab.endpoints';

export const permanentlyDeleteTab = (builder: Builder) =>
  builder.mutation<void, PermanentlyDeleteTabParams>({
    query: (params) => ({
      url: '/tab/permanently-delete',
      method: 'DELETE',
      params,
    }),
    async onQueryStarted({ page, tab }, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled;

        dispatch(
          tabEndpoints.util.updateQueryData(
            'deletedTabs',
            { page },
            (draft) => {
              draft.splice(
                draft.findIndex((i) => i.id === tab),
                1,
              );
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
