import {
  AddPlannerCustomImage,
  PlannerCustomImageAdded,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addPlannerCustomImage = (builder: Builder) =>
  builder.mutation<PlannerCustomImageAdded, AddPlannerCustomImage>({
    query: (body) => ({
      url: '/planner-image/add-custom',
      method: 'POST',
      body,
    }),
  });
