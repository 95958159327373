'use client';

import {
  Popover as HeadlessPopover,
  PopoverButton as HeadlessPopoverButton,
  PopoverGroup as HeadlessPopoverGroup,
} from '@headlessui/react';
import React, { useCallback, useRef } from 'react';

import PopoverBackdrop from './popover-backdrop/PopoverBackdrop';
import { PopoverProvider } from './popover-context/PopoverProvider';
import PopoverFooter from './popover-footer/PopoverFooter';
import PopoverPanel from './popover-panel/PopoverPanel';
import { PopoverBaseProps } from './PopoverBase.types';

const PopoverBase: React.FC<PopoverBaseProps> = (
  { level = 0, children, reference, onKeyDown, ...props },
  ref: React.Ref<HTMLDivElement>,
) => {
  const zIndex = 1600 + level * 10;
  const innerRef = useRef<HTMLDivElement | null>(null);

  const handleRef = useCallback(
    (el: HTMLDivElement) => {
      (innerRef as React.MutableRefObject<HTMLDivElement | null>).current = el;

      if (ref) {
        if (typeof ref === 'function') {
          ref(el);
        } else {
          (ref as React.MutableRefObject<HTMLDivElement | null>).current = el;
        }
      }
    },
    [ref, innerRef],
  );

  const handleKeyDown = useCallback(
    () => (e: React.KeyboardEvent<HTMLDivElement>) => {
      onKeyDown && onKeyDown(e);

      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
        e.stopPropagation();
      }
    },
    [onKeyDown],
  );

  return (
    <HeadlessPopover
      as='div'
      ref={handleRef}
      onKeyDown={handleKeyDown}
      {...props}
    >
      {({ open, close }) => (
        <PopoverProvider
          open={open}
          close={close}
          zIndex={zIndex}
          reference={reference || innerRef.current}
        >
          {typeof children === 'function'
            ? children({ open, close })
            : children}
        </PopoverProvider>
      )}
    </HeadlessPopover>
  );
};

export default Object.assign(React.memo(React.forwardRef(PopoverBase)), {
  Group: HeadlessPopoverGroup,
  Button: HeadlessPopoverButton,
  Panel: PopoverPanel,
  Backdrop: PopoverBackdrop,
  Footer: PopoverFooter,
});
