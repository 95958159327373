import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgWriting = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='M7 7h9m-9 5h5m-5 5h3M21 7V6a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h4'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='m13.209 16.716 4.378-6.873a1 1 0 0 1 1.381-.306l1.687 1.075a1 1 0 0 1 .306 1.38l-4.378 6.874a1 1 0 0 1-.521.409l-1.515.517a1 1 0 0 1-1.317-.84l-.172-1.591a1 1 0 0 1 .15-.645Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWriting);
export default ForwardRef;
