import { FavoriteStickerParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { stickerEndpoints } from './Sticker.endpoints';

export const favoriteSticker = (builder: Builder) =>
  builder.mutation<boolean, FavoriteStickerParams>({
    query: (params) => ({
      url: `/sticker/favorite`,
      method: 'POST',
      params,
    }),
    async onQueryStarted({ id, planner }, { dispatch, queryFulfilled }) {
      try {
        const { data: favorite } = await queryFulfilled;

        dispatch(
          stickerEndpoints.util.updateQueryData(
            'stickers',
            { planner },
            (draft) => {
              draft.forEach((i) => {
                i.stickers.forEach((e) => {
                  if (e.id === id) {
                    e.favorite = favorite;
                  }
                });
              });
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
