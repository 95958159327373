import { ErrorMessage, FieldErrorMessage } from '@nowadays/base/types';
import { SerializedError } from '@reduxjs/toolkit';

export type { SerializedError };

export const isServerError = (error: unknown): error is ServerError => {
  return typeof error === 'object' && error !== null && 'data' in error;
};

export interface ServerError {
  data: {
    error?: ErrorMessage;
    errors?: FieldErrorMessage[];
  };
  status: number;
}
