'use client';

import { ComboboxOptions as HeadlessComboboxOptions } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import Floating from '../../../floating/Floating';
import ComboboxClear from '../combobox-clear/ComboboxClear';
import ComboboxNotFound from '../combobox-not-found/ComboboxNotFound';
import ComboboxOption from '../combobox-option/ComboboxOption';
import ComboboxOptions from '../combobox-options/ComboboxOptions';
import ComboboxSelectAll from '../combobox-select-all/ComboboxSelectAll';
import { ComboboxPanelProps } from './ComboboxPanel.types';

const ComboboxPanel: React.FC<ComboboxPanelProps> & {
  Option: typeof ComboboxOption;
  Options: typeof ComboboxOptions;
  Clear: typeof ComboboxClear;
  SelectAll: typeof ComboboxSelectAll;
  NotFound: typeof ComboboxNotFound;
} = ({ reference, open, floating, children, className }) => {
  if (reference === undefined) {
    return null;
  }

  const { className: floatingClassName, ...floatingProps } = floating || {};

  return (
    <Floating
      reference={reference}
      autoUpdate
      sameWidth
      open={open}
      shift={{ padding: 0 }}
      placement='bottom-start'
      className={classNames(styles.root, floatingClassName)}
      {...floatingProps}
    >
      <HeadlessComboboxOptions
        className={classNames(styles.options, className)}
      >
        {(props) => (
          <Fragment>
            {typeof children === 'function' ? children(props) : children}
          </Fragment>
        )}
      </HeadlessComboboxOptions>
    </Floating>
  );
};

ComboboxPanel.Options = ComboboxOptions;
ComboboxPanel.Option = ComboboxOption;
ComboboxPanel.Clear = ComboboxClear;
ComboboxPanel.SelectAll = ComboboxSelectAll;
ComboboxPanel.NotFound = ComboboxNotFound;

const styles = {
  root: classes('z-[1600]'),
  options: classes(
    'max-h-64',
    'overflow-auto',
    'text-sm',
    'shadow-lg',
    'bg-skin-primary',
    'rounded-lg',
    'border-2',
    'border-opacity-60',
    'border-skin-silent',
  ),
};

export default ComboboxPanel;
