import { baseApi } from '../base/query/Api';
import { ask } from './Ask.api';

export const aiEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    ask: ask(builder),
  }),
});

export const { useAskMutation } = aiEndpoints;
