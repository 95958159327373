import { Languages } from '@nowadays/base/locales';
import { enUS, tr } from 'date-fns/locale';

export type TimeInterval = {
  hour: number;
  minute: number;
};

export const WEEK_STARTS_ON = ['sunday', 'monday'] as const;

export type WeekStartsOn = (typeof WEEK_STARTS_ON)[number];

export const HOUR_FORMAT = ['12', '24'] as const;

export type HourFormat = (typeof HOUR_FORMAT)[number];

export const DATE_LOCALES: Languages<object> = {
  'en-US': enUS,
  'tr-TR': tr,
};

export const generateHours = (startHour: number, endHour: number) => {
  const hours = [];

  const adjustedEndHour = endHour < startHour ? endHour + 24 : endHour;

  for (let i = startHour; i <= adjustedEndHour; i++) {
    hours.push(i);
  }

  return hours;
};

export const fixStartHour = (start: TimeInterval, dayStartsAt: number) => {
  return {
    hour: start.hour < dayStartsAt ? start.hour + 24 : start.hour,
    minute: start.minute,
  };
};

export const fixEndHour = (end: TimeInterval, start: TimeInterval) => {
  return {
    hour: end.hour < start.hour ? end.hour + 24 : end.hour,
    minute: end.minute,
  };
};

export const getWeekStartByTimezone = (timezone: string): WeekStartsOn => {
  const sundayStartTimezones = [
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Thunder_Bay',
    'America/Nipigon',
    'America/Rainy_River',
    'America/Winnipeg',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
  ];

  if (sundayStartTimezones.includes(timezone)) {
    return 'sunday';
  } else {
    return 'monday';
  }
};

export const getCurrenyByTimezone = (timezone: string) => {
  const timezoneCurrencyMapping = {
    'Africa/Abidjan': 'XOF',
    'Africa/Accra': 'GHS',
    'Africa/Addis_Ababa': 'ETB',
    'Africa/Algiers': 'DZD',
    'Africa/Asmara': 'ERN',
    'Africa/Bamako': 'XOF',
    'Africa/Bangui': 'XAF',
    'Africa/Banjul': 'GMD',
    'Africa/Bissau': 'XOF',
    'Africa/Blantyre': 'MWK',
    'Africa/Brazzaville': 'XAF',
    'Africa/Bujumbura': 'BIF',
    'Africa/Cairo': 'EGP',
    'Africa/Casablanca': 'MAD',
    'Africa/Ceuta': 'EUR',
    'Africa/Conakry': 'GNF',
    'Africa/Dakar': 'XOF',
    'Africa/Dar_es_Salaam': 'TZS',
    'Africa/Djibouti': 'DJF',
    'Africa/Douala': 'XAF',
    'Africa/El_Aaiun': 'MAD',
    'Africa/Freetown': 'SLL',
    'Africa/Gaborone': 'BWP',
    'Africa/Harare': 'ZWL',
    'Africa/Johannesburg': 'ZAR',
    'Africa/Juba': 'SSP',
    'Africa/Kampala': 'UGX',
    'Africa/Khartoum': 'SDG',
    'Africa/Kigali': 'RWF',
    'Africa/Kinshasa': 'CDF',
    'Africa/Lagos': 'NGN',
    'Africa/Libreville': 'XAF',
    'Africa/Lome': 'XOF',
    'Africa/Luanda': 'AOA',
    'Africa/Lubumbashi': 'CDF',
    'Africa/Lusaka': 'ZMW',
    'Africa/Malabo': 'XAF',
    'Africa/Maputo': 'MZN',
    'Africa/Maseru': 'LSL',
    'Africa/Mbabane': 'SZL',
    'Africa/Mogadishu': 'SOS',
    'Africa/Monrovia': 'LRD',
    'Africa/Nairobi': 'KES',
    'Africa/Ndjamena': 'XAF',
    'Africa/Niamey': 'XOF',
    'Africa/Nouakchott': 'MRU',
    'Africa/Ouagadougou': 'XOF',
    'Africa/Porto-Novo': 'XOF',
    'Africa/Sao_Tome': 'STN',
    'Africa/Tripoli': 'LYD',
    'Africa/Tunis': 'TND',
    'Africa/Windhoek': 'NAD',
    'America/Adak': 'USD',
    'America/Anchorage': 'USD',
    'America/Anguilla': 'XCD',
    'America/Antigua': 'XCD',
    'America/Araguaina': 'BRL',
    'America/Argentina/Buenos_Aires': 'ARS',
    'America/Argentina/Catamarca': 'ARS',
    'America/Argentina/Cordoba': 'ARS',
    'America/Argentina/Jujuy': 'ARS',
    'America/Argentina/La_Rioja': 'ARS',
    'America/Argentina/Mendoza': 'ARS',
    'America/Argentina/Rio_Gallegos': 'ARS',
    'America/Argentina/Salta': 'ARS',
    'America/Argentina/San_Juan': 'ARS',
    'America/Argentina/San_Luis': 'ARS',
    'America/Argentina/Tucuman': 'ARS',
    'America/Argentina/Ushuaia': 'ARS',
    'America/Aruba': 'AWG',
    'America/Asuncion': 'PYG',
    'America/Atikokan': 'CAD',
    'America/Bahia': 'BRL',
    'America/Bahia_Banderas': 'MXN',
    'America/Barbados': 'BBD',
    'America/Belem': 'BRL',
    'America/Belize': 'BZD',
    'America/Blanc-Sablon': 'CAD',
    'America/Boa_Vista': 'BRL',
    'America/Bogota': 'COP',
    'America/Boise': 'USD',
    'America/Cambridge_Bay': 'CAD',
    'America/Campo_Grande': 'BRL',
    'America/Cancun': 'MXN',
    'America/Caracas': 'VES',
    'America/Cayenne': 'EUR',
    'America/Cayman': 'KYD',
    'America/Chicago': 'USD',
    'America/Chihuahua': 'MXN',
    'America/Costa_Rica': 'CRC',
    'America/Creston': 'CAD',
    'America/Cuiaba': 'BRL',
    'America/Curacao': 'ANG',
    'America/Danmarkshavn': 'DKK',
    'America/Dawson': 'CAD',
    'America/Dawson_Creek': 'CAD',
    'America/Denver': 'USD',
    'America/Detroit': 'USD',
    'America/Dominica': 'XCD',
    'America/Edmonton': 'CAD',
    'America/Eirunepe': 'BRL',
    'America/El_Salvador': 'USD',
    'America/Fort_Nelson': 'CAD',
    'America/Fortaleza': 'BRL',
    'America/Glace_Bay': 'CAD',
    'America/Goose_Bay': 'CAD',
    'America/Grand_Turk': 'USD',
    'America/Grenada': 'XCD',
    'America/Guadeloupe': 'EUR',
    'America/Guatemala': 'GTQ',
    'America/Guayaquil': 'USD',
    'America/Guyana': 'GYD',
    'America/Halifax': 'CAD',
    'America/Havana': 'CUP',
    'America/Hermosillo': 'MXN',
    'America/Indiana/Indianapolis': 'USD',
    'America/Indiana/Knox': 'USD',
    'America/Indiana/Marengo': 'USD',
    'America/Indiana/Petersburg': 'USD',
    'America/Indiana/Tell_City': 'USD',
    'America/Indiana/Vevay': 'USD',
    'America/Indiana/Vincennes': 'USD',
    'America/Indiana/Winamac': 'USD',
    'America/Inuvik': 'CAD',
    'America/Iqaluit': 'CAD',
    'America/Jamaica': 'JMD',
    'America/Juneau': 'USD',
    'America/Kentucky/Louisville': 'USD',
    'America/Kentucky/Monticello': 'USD',
    'America/Kralendijk': 'USD',
    'America/La_Paz': 'BOB',
    'America/Lima': 'PEN',
    'America/Los_Angeles': 'USD',
    'America/Lower_Princes': 'USD',
    'America/Maceio': 'BRL',
    'America/Managua': 'NIO',
    'America/Manaus': 'BRL',
    'America/Marigot': 'EUR',
    'America/Martinique': 'EUR',
    'America/Matamoros': 'MXN',
    'America/Mazatlan': 'MXN',
    'America/Menominee': 'USD',
    'America/Merida': 'MXN',
    'America/Metlakatla': 'USD',
    'America/Mexico_City': 'MXN',
    'America/Miquelon': 'EUR',
    'America/Moncton': 'CAD',
    'America/Monterrey': 'MXN',
    'America/Montevideo': 'UYU',
    'America/Montserrat': 'XCD',
    'America/Nassau': 'BSD',
    'America/New_York': 'USD',
    'America/Nipigon': 'CAD',
    'America/Nome': 'USD',
    'America/Noronha': 'BRL',
    'America/North_Dakota/Beulah': 'USD',
    'America/North_Dakota/Center': 'USD',
    'America/North_Dakota/New_Salem': 'USD',
    'America/Ojinaga': 'MXN',
    'America/Panama': 'PAB',
    'America/Pangnirtung': 'CAD',
    'America/Paramaribo': 'SRD',
    'America/Phoenix': 'USD',
    'America/Port-au-Prince': 'HTG',
    'America/Port_of_Spain': 'TTD',
    'America/Porto_Velho': 'BRL',
    'America/Puerto_Rico': 'USD',
    'America/Punta_Arenas': 'CLP',
    'America/Rainy_River': 'CAD',
    'America/Rankin_Inlet': 'CAD',
    'America/Recife': 'BRL',
    'America/Regina': 'CAD',
    'America/Resolute': 'CAD',
    'America/Rio_Branco': 'BRL',
    'America/Santarem': 'BRL',
    'America/Santiago': 'CLP',
    'America/Santo_Domingo': 'DOP',
    'America/Sao_Paulo': 'BRL',
    'America/Scoresbysund': 'DKK',
    'America/Sitka': 'USD',
    'America/St_Barthelemy': 'EUR',
    'America/St_Johns': 'CAD',
    'America/St_Kitts': 'XCD',
    'America/St_Lucia': 'XCD',
    'America/St_Thomas': 'USD',
    'America/St_Vincent': 'XCD',
    'America/Swift_Current': 'CAD',
    'America/Tegucigalpa': 'HNL',
    'America/Thule': 'DKK',
    'America/Thunder_Bay': 'CAD',
    'America/Tijuana': 'MXN',
    'America/Toronto': 'CAD',
    'America/Tortola': 'USD',
    'America/Vancouver': 'CAD',
    'America/Whitehorse': 'CAD',
    'America/Winnipeg': 'CAD',
    'America/Yakutat': 'USD',
    'America/Yellowknife': 'CAD',
    'Antarctica/Casey': 'AUD',
    'Antarctica/Davis': 'AUD',
    'Antarctica/DumontDUrville': 'EUR',
    'Antarctica/Macquarie': 'AUD',
    'Antarctica/Mawson': 'AUD',
    'Antarctica/Palmer': 'USD',
    'Antarctica/Rothera': 'GBP',
    'Antarctica/Syowa': 'JPY',
    'Antarctica/Troll': 'NOK',
    'Antarctica/Vostok': 'RUB',
    'Arctic/Longyearbyen': 'NOK',
    'Asia/Aden': 'YER',
    'Asia/Almaty': 'KZT',
    'Asia/Amman': 'JOD',
    'Asia/Anadyr': 'RUB',
    'Asia/Aqtau': 'KZT',
    'Asia/Aqtobe': 'KZT',
    'Asia/Ashgabat': 'TMT',
    'Asia/Atyrau': 'KZT',
    'Asia/Baghdad': 'IQD',
    'Asia/Bahrain': 'BHD',
    'Asia/Baku': 'AZN',
    'Asia/Bangkok': 'THB',
    'Asia/Barnaul': 'RUB',
    'Asia/Beirut': 'LBP',
    'Asia/Bishkek': 'KGS',
    'Asia/Brunei': 'BND',
    'Asia/Chita': 'RUB',
    'Asia/Choibalsan': 'MNT',
    'Asia/Colombo': 'LKR',
    'Asia/Damascus': 'SYP',
    'Asia/Dhaka': 'BDT',
    'Asia/Dili': 'USD',
    'Asia/Dubai': 'AED',
    'Asia/Dushanbe': 'TJS',
    'Asia/Famagusta': 'EUR',
    'Asia/Gaza': 'ILS',
    'Asia/Hebron': 'ILS',
    'Asia/Ho_Chi_Minh': 'VND',
    'Asia/Hong_Kong': 'HKD',
    'Asia/Hovd': 'MNT',
    'Asia/Irkutsk': 'RUB',
    'Asia/Jakarta': 'IDR',
    'Asia/Jayapura': 'IDR',
    'Asia/Jerusalem': 'ILS',
    'Asia/Kabul': 'AFN',
    'Asia/Kamchatka': 'RUB',
    'Asia/Karachi': 'PKR',
    'Asia/Kathmandu': 'NPR',
    'Asia/Khandyga': 'RUB',
    'Asia/Kolkata': 'INR',
    'Asia/Krasnoyarsk': 'RUB',
    'Asia/Kuala_Lumpur': 'MYR',
    'Asia/Kuching': 'MYR',
    'Asia/Kuwait': 'KWD',
    'Asia/Macau': 'MOP',
    'Asia/Magadan': 'RUB',
    'Asia/Makassar': 'IDR',
    'Asia/Manila': 'PHP',
    'Asia/Muscat': 'OMR',
    'Asia/Nicosia': 'EUR',
    'Asia/Novokuznetsk': 'RUB',
    'Asia/Novosibirsk': 'RUB',
    'Asia/Omsk': 'RUB',
    'Asia/Oral': 'KZT',
    'Asia/Phnom_Penh': 'KHR',
    'Asia/Pontianak': 'IDR',
    'Asia/Pyongyang': 'KPW',
    'Asia/Qatar': 'QAR',
    'Asia/Qostanay': 'KZT',
    'Asia/Qyzylorda': 'KZT',
    'Asia/Riyadh': 'SAR',
    'Asia/Sakhalin': 'RUB',
    'Asia/Samarkand': 'UZS',
    'Asia/Seoul': 'KRW',
    'Asia/Shanghai': 'CNY',
    'Asia/Singapore': 'SGD',
    'Asia/Srednekolymsk': 'RUB',
    'Asia/Taipei': 'TWD',
    'Asia/Tashkent': 'UZS',
    'Asia/Tbilisi': 'GEL',
    'Asia/Tehran': 'IRR',
    'Asia/Thimphu': 'BTN',
    'Asia/Tokyo': 'JPY',
    'Asia/Tomsk': 'RUB',
    'Asia/Ulaanbaatar': 'MNT',
    'Asia/Urumqi': 'CNY',
    'Asia/Ust-Nera': 'RUB',
    'Asia/Vientiane': 'LAK',
    'Asia/Vladivostok': 'RUB',
    'Asia/Yakutsk': 'RUB',
    'Asia/Yangon': 'MMK',
    'Asia/Yekaterinburg': 'RUB',
    'Asia/Yerevan': 'AMD',
    'Atlantic/Azores': 'EUR',
    'Atlantic/Bermuda': 'BMD',
    'Atlantic/Canary': 'EUR',
    'Atlantic/Cape_Verde': 'CVE',
    'Atlantic/Faroe': 'DKK',
    'Atlantic/Madeira': 'EUR',
    'Atlantic/Reykjavik': 'ISK',
    'Atlantic/South_Georgia': 'GBP',
    'Atlantic/St_Helena': 'SHP',
    'Atlantic/Stanley': 'FKP',
    'Australia/Adelaide': 'AUD',
    'Australia/Brisbane': 'AUD',
    'Australia/Broken_Hill': 'AUD',
    'Australia/Currie': 'AUD',
    'Australia/Darwin': 'AUD',
    'Australia/Eucla': 'AUD',
    'Australia/Hobart': 'AUD',
    'Australia/Lindeman': 'AUD',
    'Australia/Lord_Howe': 'AUD',
    'Australia/Melbourne': 'AUD',
    'Australia/Perth': 'AUD',
    'Australia/Sydney': 'AUD',
    'Europe/Amsterdam': 'EUR',
    'Europe/Andorra': 'EUR',
    'Europe/Astrakhan': 'RUB',
    'Europe/Athens': 'EUR',
    'Europe/Belgrade': 'RSD',
    'Europe/Berlin': 'EUR',
    'Europe/Bratislava': 'EUR',
    'Europe/Brussels': 'EUR',
    'Europe/Bucharest': 'RON',
    'Europe/Budapest': 'HUF',
    'Europe/Busingen': 'EUR',
    'Europe/Chisinau': 'MDL',
    'Europe/Copenhagen': 'DKK',
    'Europe/Dublin': 'EUR',
    'Europe/Gibraltar': 'GIP',
    'Europe/Guernsey': 'GBP',
    'Europe/Helsinki': 'EUR',
    'Europe/Isle_of_Man': 'GBP',
    'Europe/Istanbul': 'TRY',
    'Europe/Jersey': 'GBP',
    'Europe/Kaliningrad': 'RUB',
    'Europe/Kiev': 'UAH',
    'Europe/Kirov': 'RUB',
    'Europe/Lisbon': 'EUR',
    'Europe/Ljubljana': 'EUR',
    'Europe/London': 'GBP',
    'Europe/Luxembourg': 'EUR',
    'Europe/Madrid': 'EUR',
    'Europe/Malta': 'EUR',
    'Europe/Mariehamn': 'EUR',
    'Europe/Minsk': 'BYN',
    'Europe/Monaco': 'EUR',
    'Europe/Moscow': 'RUB',
    'Europe/Oslo': 'NOK',
    'Europe/Paris': 'EUR',
    'Europe/Podgorica': 'EUR',
    'Europe/Prague': 'CZK',
    'Europe/Riga': 'EUR',
    'Europe/Rome': 'EUR',
    'Europe/Samara': 'RUB',
    'Europe/San_Marino': 'EUR',
    'Europe/Sarajevo': 'BAM',
    'Europe/Saratov': 'RUB',
    'Europe/Simferopol': 'RUB',
    'Europe/Skopje': 'MKD',
    'Europe/Sofia': 'BGN',
    'Europe/Stockholm': 'SEK',
    'Europe/Tallinn': 'EUR',
    'Europe/Tirane': 'ALL',
    'Europe/Ulyanovsk': 'RUB',
    'Europe/Uzhgorod': 'UAH',
    'Europe/Vaduz': 'CHF',
    'Europe/Vatican': 'EUR',
    'Europe/Vienna': 'EUR',
    'Europe/Vilnius': 'EUR',
    'Europe/Volgograd': 'RUB',
    'Europe/Warsaw': 'PLN',
    'Europe/Zagreb': 'HRK',
    'Europe/Zaporozhye': 'UAH',
    'Europe/Zurich': 'CHF',
    'Indian/Antananarivo': 'MGA',
    'Indian/Chagos': 'USD',
    'Indian/Christmas': 'AUD',
    'Indian/Cocos': 'AUD',
    'Indian/Comoro': 'KMF',
    'Indian/Kerguelen': 'EUR',
    'Indian/Mahe': 'SCR',
    'Indian/Maldives': 'MVR',
    'Indian/Mauritius': 'MUR',
    'Indian/Mayotte': 'EUR',
    'Indian/Reunion': 'EUR',
    'Pacific/Apia': 'WST',
    'Pacific/Auckland': 'NZD',
    'Pacific/Bougainville': 'PGK',
    'Pacific/Chatham': 'NZD',
    'Pacific/Chuuk': 'USD',
    'Pacific/Easter': 'CLP',
    'Pacific/Efate': 'VUV',
    'Pacific/Enderbury': 'AUD',
    'Pacific/Fakaofo': 'NZD',
    'Pacific/Fiji': 'FJD',
    'Pacific/Funafuti': 'AUD',
    'Pacific/Galapagos': 'USD',
    'Pacific/Gambier': 'XPF',
    'Pacific/Guadalcanal': 'SBD',
    'Pacific/Guam': 'USD',
    'Pacific/Honolulu': 'USD',
    'Pacific/Kiritimati': 'AUD',
    'Pacific/Kosrae': 'USD',
    'Pacific/Kwajalein': 'USD',
    'Pacific/Majuro': 'USD',
    'Pacific/Marquesas': 'XPF',
    'Pacific/Midway': 'USD',
    'Pacific/Nauru': 'AUD',
    'Pacific/Niue': 'NZD',
    'Pacific/Norfolk': 'AUD',
    'Pacific/Noumea': 'XPF',
    'Pacific/Pago_Pago': 'USD',
    'Pacific/Palau': 'USD',
    'Pacific/Pitcairn': 'NZD',
    'Pacific/Pohnpei': 'USD',
    'Pacific/Port_Moresby': 'PGK',
    'Pacific/Rarotonga': 'NZD',
    'Pacific/Saipan': 'USD',
    'Pacific/Tahiti': 'XPF',
    'Pacific/Tarawa': 'AUD',
    'Pacific/Tongatapu': 'TOP',
    'Pacific/Wake': 'USD',
    'Pacific/Wallis': 'XPF',
  };

  const founded = timezoneCurrencyMapping[timezone];

  return founded || 'USD';
};
