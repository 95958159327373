import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { Fragment, useCallback, useState } from 'react';

import { Dialog } from '../../../../../dialog';
import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';
import AddRichTextImage from './components/add-rich-text-image/AddRichTextImage';
import RichTextImageItem from './components/rich-text-image-item/RichTextImageItem';
import { RichTextImageProps } from './RichTextImage.types';

const RichTextImage: React.FC<RichTextImageProps> = ({
  images = [],
  onAdd,
  onDelete,
  isUploading,
}) => {
  const { editor } = useRichText();
  const translate = useTranslate();
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>();

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSelect = (image: string) => setSelected(image);

  const handleConfirm = useCallback(() => {
    if (selected) {
      editor.chain().focus().setImage({ src: selected }).run();
      setSelected(undefined);
      handleClose();
    }
  }, [editor, selected]);

  const handleCancel = () => {
    setSelected(undefined);
    handleClose();
  };

  if (!editor) {
    return null;
  }

  return (
    <Fragment>
      <RichTextToolbarButton name='Image' onClick={handleOpen} />

      <Dialog open={open} level={1} onClose={handleClose}>
        <Dialog.Backdrop />
        <Dialog.Panel size='xl'>
          <Dialog.Title
            title={translate('ui.components.inputs.richText.image.title')}
          />

          <div className={styles.images}>
            {images?.map((image, index) => (
              <RichTextImageItem
                key={index}
                image={image}
                selected={selected}
                onSelect={() => handleSelect(image)}
                onDelete={async () => await onDelete(index)}
              />
            ))}

            <AddRichTextImage onAdd={onAdd} isUploading={isUploading} />
          </div>
          <Dialog.Footer
            confirm={{
              disabled: !selected,
              kind: 'add',
              onClick: handleConfirm,
            }}
            cancel={{
              onClick: handleCancel,
            }}
          />
        </Dialog.Panel>
      </Dialog>
    </Fragment>
  );
};

const styles = {
  images: classes(
    'grid',
    'grid-cols-2',
    'md:grid-cols-3',
    'lg:grid-cols-4',
    'xl:grid-cols-5',
    'pt-4',
    'gap-4',
  ),
};

export default RichTextImage;
