import { Button, NotificationItem } from '@nowadays/ui/components';

export const accountAlreadyExists = (
  translate: Translate,
  onLoginClick: () => void,
): NotificationItem => {
  return {
    title: translate(
      'ui.common.auth.notifications.accountAlreadyExists.message',
    ),
    color: 'green',
    description: translate(
      'ui.common.auth.notifications.accountAlreadyExists.description',
    ),
    content: ({ close }) => (
      <Button
        variant='contained'
        color='green'
        onClick={() => {
          onLoginClick();
          close();
        }}
      >
        {translate('ui.common.auth.notifications.accountAlreadyExists.signup')}
      </Button>
    ),
  };
};
