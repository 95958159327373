'use client';

import { createContext, useContext } from 'react';

import { CalendarPeriod } from './CalendarBaseProvider';

interface CalendarBaseContextProps {
  date?: Date;
  disabledBefore?: boolean | Date;
  disabledAfter?: boolean | Date;
  currentDay?: Date;
  currentWeek?: Date;
  currentMonth?: Date;
  currentYear?: Date;
  currentPeriod?: CalendarPeriod;
  handleDayChanged: (day?: Date) => void;
  handleWeekChanged: (week?: Date) => void;
  handleMonthChanged: (month?: Date) => void;
  handleYearChanged: (month?: Date) => void;
  handlePeriodChanged: (period?: CalendarPeriod) => void;
}

export const CalendarBaseContext = createContext<CalendarBaseContextProps>({
  handleDayChanged: () => null,
  handleWeekChanged: () => null,
  handleMonthChanged: () => null,
  handleYearChanged: () => null,
  handlePeriodChanged: () => null,
});

export const useCalendarBase = () => {
  return useContext(CalendarBaseContext);
};
