'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import Button from '../../button/button/Button';

const WentWrongInfo = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { resetBoundary } = useErrorBoundary();

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.code}>
          {translate('ui.components.wentWrong.title')}
        </div>
        <div className={styles.description}>
          {translate('ui.components.wentWrong.description')}
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          color='accent'
          variant='contained'
          endIcon={{ name: 'Undo' }}
          onClick={() => {
            resetBoundary();
            navigate('/', { replace: true });
          }}
        >
          {translate('ui.components.wentWrong.home')}
        </Button>

        <Button
          color='silent'
          variant='contained'
          endIcon={{ name: 'Sync' }}
          onClick={() => resetBoundary()}
        >
          {translate('ui.components.wentWrong.try')}
        </Button>
      </div>
    </div>
  );
};

const styles = {
  container: classes(
    'flex',
    'flex-col',
    'items-center',
    'sm:items-start',
    'justify-center',
    'sm:h-full',
    'space-y-4',
    'text-center',
    'sm:text-start',
  ),
  code: classes('text-4xl', 'md:text-6xl', 'font-bold'),
  description: classes('text-skin-muted'),
  buttons: classes('flex', 'space-x-4'),
};

export default WentWrongInfo;
