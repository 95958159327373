import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';
import { useState } from 'react';

import { IconButton } from '../../../../../../../button/index';
import { ConfirmDialog } from '../../../../../../../dialog/index';
import { Tooltip } from '../../../../../../../tooltip/index';
import { RichTextImageItemProps } from './RichTextImageItem.types';

const RichTextImageItem: React.FC<RichTextImageItemProps> = ({
  image,
  selected,
  onSelect,
  onDelete,
}) => {
  const translate = useTranslate();
  const [open, setOpen] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleLoad = () => setLoaded(true);

  const handleSuccess = () => setOpen(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  return (
    <div className={classNames(styles.root, !loaded && styles.loading)}>
      <img
        src={image}
        onLoad={handleLoad}
        onClick={onSelect}
        className={classNames(
          styles.img,
          image === selected && styles.selected,
        )}
      />

      <ConfirmDialog
        open={open}
        confirm={translate('admin.articles.delete.confirm')}
        color='red'
        level={2}
        onConfirm={onDelete}
        onSuccess={handleSuccess}
        onClose={handleClose}
      >
        <Tooltip title={translate('admin.articles.delete.title')}>
          <IconButton
            name='Trash'
            color='red'
            className={styles.delete}
            onClick={handleOpen}
          />
        </Tooltip>
      </ConfirmDialog>
    </div>
  );
};

const styles = {
  root: classes('relative'),
  img: classes(
    'aspect-square',
    'object-contain',
    'rounded-lg',
    'cursor-pointer',
  ),
  delete: classes('absolute', 'top-1', 'right-1'),
  selected: classes('ring-2', 'ring-skin-accent'),
  loading: classes('bg-skin-base', 'rounded-lg', 'animate-pulse'),
};

export default RichTextImageItem;
