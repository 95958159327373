import { Planner } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { plannerEndpoints } from './Planner.endpoints';

export const unarchivePlanner = (builder: Builder) =>
  builder.mutation<Planner, string>({
    query: (planner) => ({
      url: `/planner/unarchive/${planner}`,
      method: 'POST',
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const { data: unarchivedPlanner } = await queryFulfilled;

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'archivedPlanners',
            undefined,
            (draft) => {
              draft.splice(
                draft.findIndex((i) => i.id === unarchivedPlanner.id),
                1,
              );
            },
          ),
        );

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'planners',
            undefined,
            (draft) => {
              draft.push(unarchivedPlanner);
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
