import { layoutToPixel, layoutToSize, Planner } from '@nowadays/base/types';
import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { layoutAspectRatio } from './Planner.helper';
import { PlannerState } from './Planner.slice.types';

const initialState: PlannerState = {};

export const plannerSlice = createSlice({
  name: 'planner',
  initialState,
  reducers: {
    changeActivePlanner: (
      state,
      { payload }: PayloadAction<Planner | undefined | null>,
    ) => {
      if (payload) {
        const { layout } = payload;
        state.activePlanner = payload;
        state.plannerSize = layoutToSize[layout];
        state.sizeInPixel = layoutToPixel(state.plannerSize);
        state.aspectRatio = layoutAspectRatio[layout];
      } else {
        state.activePlanner = undefined;
        state.plannerSize = undefined;
        state.sizeInPixel = undefined;
        state.aspectRatio = undefined;
      }
    },
    changeIsActivesLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.isActivesLoaded = payload;
    },
    changeIsPresetPlannersLoading: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isPresetPlannersLoading = payload;
    },
    changePresetFilter: (state, { payload }: PayloadAction<string>) => {
      state.presetFilter = payload;
    },
  },
});

export const plannerReducer = plannerSlice.reducer;

export const usePlannerActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(plannerSlice.actions, dispatch),
    [dispatch],
  );
};
