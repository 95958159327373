'use client';

import { Switch as HeadlessSwitch } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import Input from '../input/Input';
import { SwitchProps } from './Switch.types';
import SwitchOptions from './switch-options/SwitchOptions';

const Switch: React.FC<SwitchProps> = (
  { input, className, checked, value, disabled, ...props },
  ref: React.Ref<HTMLButtonElement>,
) => {
  const { disable, ...inputProps } = input || {};

  return (
    <Input disable={disabled || disable} {...inputProps}>
      {({
        focusStyles,
        backgroundStyles,
        borderStyles,
        errorStyles,
        disabledStyles,
        customStyles,
      }) => (
        <HeadlessSwitch
          ref={ref}
          checked={checked || false}
          disabled={disabled}
          value={value}
          className={classNames(
            focusStyles,
            backgroundStyles,
            borderStyles,
            errorStyles,
            disabledStyles,
            customStyles,
            styles.switch,
            checked && styles.checkedSwitch,
            className,
          )}
          {...props}
        >
          <span
            className={classNames(styles.tomb, checked && styles.checkedTomb)}
          />
        </HeadlessSwitch>
      )}
    </Input>
  );
};

const styles = {
  switch: classes(
    'h-6',
    'w-10',
    'min-h-0',
    'flex',
    'items-center',
    'rounded-full',
    'duration-200',
    'cursor-pointer',
    'disabled:cursor-default',
  ),
  checkedSwitch: classes('bg-skin-inverted'),
  tomb: classes(
    'bg-skin-inverted',
    'pointer-events-none',
    'inline-block',
    'h-5',
    'w-5',
    'rounded-full',
    'ring-0',
    'duration-200',
    'transition',
    'ease-in-out',
  ),
  checkedTomb: classes('translate-x-4', 'bg-skin-green'),
};

export default Object.assign(React.forwardRef(Switch), {
  Options: SwitchOptions,
});
