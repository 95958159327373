import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import { Button } from '../../../../../../../button';
import { Popover } from '../../../../../../../popover';
import { TextArea } from '../../../../../../textarea';
import { ImageNodeAltEditProps } from './ImageNodeAltEdit.types';

const ImageNodeAltEdit: React.FC<ImageNodeAltEditProps> = ({
  value,
  onChange,
  onConfirm,
}) => {
  const translate = useTranslate();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    onChange(e.target.value);

  return (
    <Popover>
      <Popover.Button as={Button} variant='contained'>
        {translate('ui.components.inputs.richText.node.image.editAlt')}
      </Popover.Button>
      <Popover.Panel
        className={styles.panel}
        floating={{ placement: 'right', portal: true }}
      >
        {({ close }) => (
          <Fragment>
            <div className='text-skin-accent font-medium'>
              {translate('ui.components.inputs.richText.node.image.alt')}
            </div>

            <div className={styles.container}>
              <TextArea
                value={value}
                onChange={handleChange}
                onMouseDown={(e) => e.stopPropagation()}
                onPointerDown={(e) => e.stopPropagation()}
              />
              <Button
                color='accent'
                variant='contained'
                onClick={() => {
                  onConfirm();
                  close();
                }}
              >
                {translate('ui.components.inputs.richText.node.image.save')}
              </Button>
            </div>
          </Fragment>
        )}
      </Popover.Panel>
    </Popover>
  );
};

const styles = {
  panel: classes('w-[28rem]', 'flex', 'flex-col', 'gap-2'),
  container: classes('flex', 'flex-col', 'gap-3', 'items-end'),
};

export default ImageNodeAltEdit;
