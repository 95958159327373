'use client';

import { classes } from '@nowadays/ui/utils';

import { CaptionTitleProps } from './CaptionTitle.types';

const CaptionTitle: React.FC<CaptionTitleProps> = ({ title }) => {
  return <span className={styles.root}>{title}</span>;
};

const styles = {
  root: classes('font-medium', 'text-skin-accent'),
};

export default CaptionTitle;
