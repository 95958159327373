import { AddEmailParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const addEmail = (builder: Builder) =>
  builder.mutation<void, AddEmailParams>({
    query: (body) => ({
      url: '/email/add',
      method: 'POST',
      body,
    }),
    invalidatesTags: (res, err) => (!err && ['Emails']) || [],
  });
