'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { flexRender } from '@tanstack/react-table';

import Icon from '../../../icons/Icon';
import { HeaderCellProps } from './HeaderCell.types';

const HeaderCell: React.FC<HeaderCellProps> = ({ header }) => {
  const isSortable = header.column.getCanSort();

  return (
    <th key={header.id} colSpan={header.colSpan} className={styles.root}>
      {header.isPlaceholder ? null : (
        <div
          className={classNames(
            styles.container,
            isSortable && styles.sortable,
          )}
          onClick={
            isSortable ? header.column.getToggleSortingHandler() : undefined
          }
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          {{
            asc: <Icon name='Up' className={styles.icon} />,
            desc: <Icon name='Down' className={styles.icon} />,
          }[header.column.getIsSorted() as string] ?? (
            <div className={styles.icon} />
          )}
        </div>
      )}
    </th>
  );
};

const styles = {
  root: classes('px-3', 'py-2'),
  container: classes('flex', 'items-center', 'select-none', 'font-medium'),
  sortable: classes('cursor-pointer', 'select-none', 'space-x-2'),
  icon: classes('w-5', 'h-5'),
};

export default HeaderCell;
