import {
  UpdateWidgetSetItem,
  WidgetSetItemUpdated,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const updateWidgetSetItem = (builder: Builder) =>
  builder.mutation<WidgetSetItemUpdated, UpdateWidgetSetItem>({
    query: (body) => ({
      url: `/widget-set-item/update/`,
      method: 'PUT',
      body,
    }),
  });
