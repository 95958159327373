import { SignFileUrlRequest, SignFileUrlResponse } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const signFileUrl = (builder: Builder) =>
  builder.mutation<SignFileUrlResponse, SignFileUrlRequest>({
    query: (body) => ({
      url: '/file/sign-file-url',
      method: 'POST',
      body,
    }),
  });
